import { type V3 } from "@project-rouge/rg-core";
import { useState } from "react";
import { Cluster } from "src/components/Cluster";
import { useLiveRef } from "src/hooks/useLiveRef";
import { useMapCanvasEvent } from "src/hooks/useMapCanvasEvent";
import { useMapClickOrDrag } from "src/hooks/useMapClickOrDrag";
import { usePixelToMeterDistance } from "src/hooks/useSnapMouse";
import { SnapVectorToEdge } from "src/utils/SnapVectorToEdge";
import { SnapVectorToVector } from "src/utils/SnapVectorToVector";
import { CreateMode } from "./CreateMode";
import { InsertEdgeVertexMode } from "./InsertEdgeVertex.mode";
import { MoveVertexMode } from "./MoveVertex.mode";
import { PreviewMode } from "./Preview.mode";
import { MouseCursor, useMouseCursor } from "./SnapMouseCursor";
import { useEvent } from "src/hooks/useEvent";
import { type Brief } from "src/types/Brief";
import { observer } from "mobx-react-lite";
import type { Zone } from "src/types/Zone";
import { WorldUpdateType } from "../WorldUpdateType";
enum Mode {
  INSERT = "INSERT",
  PREVIEW = "PREVIEW",
  CREATE = "CREATE",
  MOVE_VERTEX = "MOVE_VERTEX",
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  DEFAULT = "PREVIEW",
}

export const CreateTool = observer(function CreateTool(props: {
  localZones: Zone[];
  snapTolerancePx: number;
  clickTolerancePx: number;
  defaultBrief: Brief;
  onChange: (localZone: Zone[], type: WorldUpdateType) => void;
  onToolCancel: () => void;
  geoOrigin: { lng: number; lat: number };
  onGeoOriginChange: (geoOrigin: { lng: number; lat: number }) => void;
}) {
  const [mode, setMode] = useState(Mode.DEFAULT);
  const modeRef = useLiveRef(mode);
  const [startPosition, setStartPosition] = useState<V3>([0, 0, 0]);
  const boundaries = props.localZones.map(({ outerRing }) => outerRing);
  const snapTolerance = usePixelToMeterDistance(props.snapTolerancePx);
  const mouse = useMouseCursor(props.localZones, props.snapTolerancePx, false);
  useMapCanvasEvent("keydown", (evt) => {
    switch (true) {
      case evt.key === "Escape": {
        if (mode === Mode.DEFAULT) {
          props.onToolCancel();
        } else {
          setMode(Mode.DEFAULT);
        }
        return;
      }
    }
  });

  useMapClickOrDrag({
    clickTolerancePx: props.clickTolerancePx,
    onClick: ({ mapDownEvent }) => {
      if (modeRef.current === Mode.CREATE) return;
      setMode(Mode.CREATE);
      const isFirstZoneWithNoDraft = !boundaries.at(0)?.at(0);
      if (isFirstZoneWithNoDraft) {
        props.onGeoOriginChange({
          lng: mapDownEvent.lngLat.lng,
          lat: mapDownEvent.lngLat.lat,
        });
        setStartPosition([0, 0, 0]);
      } else {
        if (mouse) setStartPosition(mouse);
      }
    },
    onMouseDown: ({ map, mapDownVectorPosition }) => {
      if (
        SnapVectorToVector(mapDownVectorPosition, boundaries, snapTolerance) ||
        SnapVectorToEdge(mapDownVectorPosition, boundaries, snapTolerance)
      ) {
        map.dragRotate.disable();
        map.dragPan.disable();
      }
    },
    onMouseUp: ({ map }) => {
      map.dragRotate.enable();
      map.dragPan.enable();
    },
    onDragStart: ({ mapDownVectorPosition }) => {
      const isOnVertex = SnapVectorToVector(mapDownVectorPosition, boundaries, snapTolerance);
      if (isOnVertex) {
        setStartPosition(isOnVertex);
        setMode(Mode.MOVE_VERTEX);
        return;
      }
      const isOnEdge = SnapVectorToEdge(mapDownVectorPosition, boundaries, snapTolerance);
      if (isOnEdge) {
        setStartPosition(isOnEdge);
        setMode(Mode.INSERT);
        return;
      }
    },
  });

  const handleUpdatePolygons = useEvent((zones: Zone[]) => {
    props.onChange(zones, WorldUpdateType.CREATE);
    setMode(Mode.DEFAULT);
  });

  return (
    <>
      <Cluster visible={mode === Mode.PREVIEW}>
        <PreviewMode
          localZones={props.localZones}
          hoverEnabled={false}
          selectEnabled={false}
          showVertexes={true}
        />
        {mouse && <MouseCursor fillColor={0x58588e} radiusInPx={6} x={mouse[0]} z={mouse[2]} />}
      </Cluster>
      <Cluster visible={mode === Mode.CREATE}>
        <CreateMode
          defaultBrief={props.defaultBrief}
          localZones={props.localZones}
          snapTolerancePx={props.snapTolerancePx}
          startPosition={startPosition}
          updatePolygons={handleUpdatePolygons}
        />
      </Cluster>
      <Cluster visible={mode === Mode.INSERT}>
        <InsertEdgeVertexMode
          localZones={props.localZones}
          snapTolerance={props.snapTolerancePx}
          startPosition={startPosition}
          updatePolygons={handleUpdatePolygons}
        />
      </Cluster>
      <Cluster visible={mode === Mode.MOVE_VERTEX}>
        <MoveVertexMode
          zones={props.localZones}
          snapTolerance={props.snapTolerancePx}
          startPosition={startPosition}
          updatePolygons={handleUpdatePolygons}
        />
      </Cluster>
    </>
  );
});
