import { makeAutoObservable } from "mobx";
import type { Api } from "src/types/Api";
import { Throttle } from "./Throttle";
import type { World } from "./World";

export class AutoSave {
  private api: Api;
  private projectId: string;
  private sceanrioId: string;
  private throttle = new Throttle(500, false);
  private onAutoSaveFinishedListeners = new Set<(world: World) => void>();
  constructor(projectId: string, scenarioId: string, api: Api) {
    this.api = api;
    this.projectId = projectId;
    this.sceanrioId = scenarioId;
    makeAutoObservable(this);
  }
  save(world: World) {
    const clone = world.clone();
    this.throttle.run(async () => {
      await this.api.putWorld(this.projectId, this.sceanrioId, clone);
      this.onAutoSaveFinishedListeners.forEach((cb) => cb(clone));
    });
  }
  get isSaving() {
    return this.throttle.fetching;
  }
  onAutoSaveFinished(listener: (world: World) => void) {
    this.onAutoSaveFinishedListeners.add(listener);
    return () => this.onAutoSaveFinishedListeners.delete(listener);
  }
}
