import { Cluster } from "src/components/Cluster";
import { Table } from "../Table";
import { type ElementTable } from "./getTables";
import type { Building } from "src/types/Building";

export function ElementGroupTable(props: {
  table: ElementTable;
  selectedBuilding: Building | null;
}) {
  return (
    <div>
      <Table.Sticky>
        <Table.Row>
          <Table.Col>
            <Table.Head label={props.table.label} variant="section" />
          </Table.Col>
        </Table.Row>

        <Table.Row>
          <Table.Col>
            <Table.Head label="" variant="header" />
          </Table.Col>
          <Table.Col>
            <Table.Head subLabel="CO2" label="footprint" variant="header" position="end" />
          </Table.Col>

          {props.selectedBuilding && (
            <Table.Col>
              <Table.Head label={props.selectedBuilding.label} variant="header" position="end" />
            </Table.Col>
          )}
        </Table.Row>
      </Table.Sticky>
      {props.table.rows.map((row) => (
        <Row key={row.id} row={row} variant="cell" selectedBuilding={props.selectedBuilding} />
      ))}
    </div>
  );
}

function Row(props: {
  row: ElementTable["rows"][number];
  variant: "cell" | "subtotal" | "total";
  selectedBuilding: Building | null;
  currency?: string;
}) {
  const totalCell = (
    <Table.Col>
      <Table.Cell value={props.row.totalCost} variant={props.variant} currency={props.currency} />
    </Table.Col>
  );
  const buildingCell = (
    <Table.Col>
      <Table.Cell
        value={props.row.buildingCost}
        variant={props.variant}
        currency={props.currency}
      />
    </Table.Col>
  );
  return (
    <Table.Row>
      <Table.Col>
        <Table.Label label={props.row.label} variant={props.variant} />
      </Table.Col>
      <Cluster visible={!!props.selectedBuilding}>
        {totalCell}
        {buildingCell}
      </Cluster>
      <Cluster visible={!props.selectedBuilding}>{totalCell}</Cluster>
    </Table.Row>
  );
}
