import { TableCell } from "./TableCell";
import type { TableContentType } from "./TableContentType";
import { observer } from "mobx-react-lite";
import type { RowData } from "./RowData";
import { MathSum } from "src/utils/MathSum";
import type { ArchitecturalMetricsSnapshot } from "src/types/ArchitecturalMetrics";
import type { PropsWithChildren } from "react";
import { useRef, useState } from "react";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
  arrow,
  FloatingArrow,
} from "@floating-ui/react";
import { RoomUnit, getRoomColor, useCategories } from "src/components/Inspector/RoomUnitGroup";
import { State } from "src/components/Inspector/InspectorRow";

export const UnitMixCell = observer(function UnitMixCell(props: {
  type: TableContentType;
  data: RowData;
  width?: number;
  hover?: boolean;
  selected?: boolean;
}) {
  const metrics = props.data.architecturalMetrics;
  return (
    <TableCell type={props.type} width={props.width} hover={props.hover} selected={props.selected}>
      <div className="justify-end w-full">
        <Tooltip metrics={metrics} />
      </div>
    </TableCell>
  );
});

const Tooltip = observer(function Tooltip(
  props: PropsWithChildren<{ className?: string; metrics: ArchitecturalMetricsSnapshot }>
) {
  const [isOpen, setIsOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "top",
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        fallbackAxisSideDirection: "start",
      }),
      shift(),
    ],
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });
  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);
  const percentageCategories = useCategories(props.metrics, 0);
  const sortCategories = percentageCategories.slice().sort((a, b) => (a.name < b.name ? -1 : 1));
  const totalUnit = MathSum(
    props.metrics.UnitCategoryMixPerCategory.map((cateogry) => cateogry.count)
  );
  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        {sortCategories.map((unitmix) => {
          return <RoomUnitBar key={unitmix.name} label={unitmix.name} value={unitmix.percentage} />;
        })}
      </div>
      <FloatingPortal>
        {isOpen && (
          <div
            className="bg-white rounded shadow-md shadow-elevation-2 z-50"
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <div className="p-2">
              {sortCategories.map((cateogry) => (
                <RoomUnit
                  key={cateogry.name}
                  label={cateogry.name}
                  value={cateogry.percentage}
                  room={cateogry.count}
                  state={State.FILLED}
                />
              ))}
              <div className="w-full flex justify-end items-center mt-1">
                <span className="text-neutral-6 pr-1 text-body-1">Total:</span>
                <span className="text-neutral-8 text-subtitle-2">
                  {totalUnit.toLocaleString("en")}
                </span>
              </div>
            </div>
            <Arrow />
          </div>
        )}
      </FloatingPortal>
    </>
  );
});

const Arrow = () => {
  const arrowRef = useRef(null);
  const { refs, floatingStyles, context } = useFloating({
    placement: "top",
    middleware: [
      arrow({
        element: arrowRef,
      }),
    ],
  });
  return (
    <>
      <div ref={refs.setReference} />
      <div ref={refs.setFloating} style={floatingStyles}>
        <FloatingArrow ref={arrowRef} context={context} fill="white" />
      </div>
    </>
  );
};

const RoomUnitBar = observer(function RoomUnit(props: { label: string; value: number }) {
  return (
    <div className="h-1 relative w-full overflow-hidden mb-[1px]">
      <div className="w-full h-full absolute bg-primary-2 rounded-[1px]" />
      <div
        className="h-full relative"
        style={{ width: `${props.value}%`, background: `${getRoomColor(props.label)}` }}
      />
    </div>
  );
});
