import classNames from "classnames";
import {
  IconInfoCircleFilled,
  IconCircleCheckFilled,
  IconAlertTriangleFilled,
  IconAlertCircleFilled,
  IconX,
} from "@tabler/icons-react";
import type { Toast } from "src/contexts/toaster.context";
import { useToaster } from "src/contexts/toaster.context";
import { useEvent } from "src/hooks/useEvent";
import { useEffect } from "react";

export enum ToastType {
  INFO = "INFO",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
  DANGER = "DANGER",
}

const selectToastType = (type: ToastType) => {
  switch (type) {
    case ToastType.INFO:
      return <IconInfoCircleFilled className="w-4" />;
    case ToastType.SUCCESS:
      return <IconCircleCheckFilled className="w-4" />;
    case ToastType.WARNING:
      return <IconAlertTriangleFilled className="w-4" />;
    case ToastType.DANGER:
      return <IconAlertCircleFilled className="w-4" />;
  }
};

export function ToastFC(props: { toast: Toast }) {
  const toaster = useToaster();
  const { type } = props.toast;
  const closeToast = useEvent(() => {
    toaster.close();
  });

  useEffect(() => {
    if (!props.toast.autoHide) return;
    const timer = setTimeout(() => toaster.close(), 4000);

    return () => {
      clearTimeout(timer);
    };
  }, [props.toast.autoHide, toaster]);

  return (
    <div>
      <div className="z-50 fixed left-0 top-0 w-screen h-screen" onMouseUp={closeToast}>
        <div
          className={classNames(
            "w-[372px] h-[auto]",
            "mx-auto mt-2 flex justify-between",
            "text-subtitle-2 rounded-sm shadow-md shadow-elevation-2",
            type === "INFO" && "text-info-6 bg-info-2",
            type === "SUCCESS" && "text-success-6 bg-success-1",
            type === "WARNING" && "text-warning-6 bg-warning-2",
            type === "DANGER" && "text-danger-6 bg-danger-2"
          )}
        >
          <div className="inline-flex space-x-2 m-2">
            {selectToastType(type)}
            <span
              className={classNames(
                type === "INFO" && "text-info-7",
                type === "SUCCESS" && "text-success-7",
                type === "WARNING" && "text-warning-7",
                type === "DANGER" && "text-danger-7"
              )}
            >
              {props.toast.text}
            </span>
          </div>
          <IconX className="w-4 m-2 cursor-pointer" onClick={closeToast} />
        </div>
      </div>
    </div>
  );
}
