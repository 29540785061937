import { useBoolean } from "src/hooks/useBoolean";
import { ToggleHeaderInput } from "./ToggleHeaderInput";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { useWindowEvent } from "src/hooks/useWindowEvent";
import type { Zone } from "src/types/Zone";
import { observer } from "mobx-react-lite";

const energyGenerationOptions: SelectInputOption[] = [
  { id: "ambient-loop-network", label: "Ambient loop network district heating scheme" },
  { label: "Network district heating scheme", id: "network-district-heating-scheme" },
  { label: "Local heat pumps", id: "local-heat-pumps" },
];

const electricalPowerRequirementsOptions: SelectInputOption[] = [
  { id: "primary-low-voltage", label: "Primary low voltage (PLV) room only" },
  { label: "PLV + secondary low voltage room", id: "plv-secondary-low-voltage-room" },
  { label: "PLV + standby generator", id: "plv-standby-generator" },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CustomServiceAreaSection = observer(function CustomServiceAreaSection(props: {
  zone: Zone;
}) {
  const enabled = useBoolean();
  const sprinkles = useBoolean();
  const electric = useBoolean();
  const rainwater = useBoolean();
  const greywater = useBoolean();
  return (
    <div className="flex flex-col space-y-2">
      <ToggleHeaderInput
        label="Custom Service Areas"
        checked={enabled.isOn}
        onCheckedChange={enabled.set}
      />
      {enabled.isOn && (
        <>
          <div className="space-y-1 flex flex-col">
            <SelectInput
              label="Energy generation"
              options={energyGenerationOptions}
              selected="ambient-loop-network"
              onChange={() => {}}
            />
            <SelectInput
              label="Electrical power requirements"
              options={electricalPowerRequirementsOptions}
              selected="primary-low-voltage"
              onChange={() => {}}
            />
          </div>
          <div className="flex flex-col space-y-[2px] border-l pl-2 border-neutral-4">
            <div className="text-caption text-neutral-7">Extras</div>
            <div className="flex flex-col">
              <Checkbox checked={sprinkles.isOn} label="Sprinklers" onChange={sprinkles.set} />
              <Checkbox
                checked={electric.isOn}
                label="Electrical substation"
                onChange={electric.set}
              />
              <Checkbox
                checked={rainwater.isOn}
                label="Rainwater harvesting system"
                onChange={rainwater.set}
              />
              <Checkbox
                checked={greywater.isOn}
                label="Greywater harvesting system"
                onChange={greywater.set}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
});

const Checkbox = observer(function Checkbox(props: {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}) {
  return (
    <div className="flex flex-row items-center space-x-2">
      <input
        type="checkbox"
        checked={props.checked}
        onChange={(evt) => props.onChange(evt.target.checked)}
        className={classNames(
          "rounded",
          props.checked && `text-primary-6`,
          !props.checked && `text-white`
        )}
      />
      <span className="text-body-1 text-neutral-8">{props.label}</span>
    </div>
  );
});

interface SelectInputOption {
  id: string;
  label: string;
}

const SelectInput = observer(function SelectInput(props: {
  label: string;
  options: SelectInputOption[];
  selected: string;
  onChange: (id: string) => void;
}) {
  const selected = props.options.find((option) => option.id === props.selected) ?? props.options[0];
  const optionVisible = useBoolean();
  useWindowEvent("click", optionVisible.off);
  return (
    <div className="flex flex-col text-body-1 h-[24px] items-start flex-1 space-y-[2px]">
      <span className="flex-1 flex text-caption text-neutral-7">{props.label}</span>
      <div
        className="relative w-full border border-neutral-5 flex flex-row rounded-sm flex-1 text-neutral-8 text-body-1"
        onClick={(evt) => evt.stopPropagation()}
      >
        <div
          className="relative flex flex-1 flex-row px-[6px] w-full cursor-pointer hover:bg-primary-1"
          onClick={optionVisible.toggle}
        >
          <span className=" truncate">{selected.label}</span>
          <ChevronDownIcon className="w-4 shrink-0" />
        </div>
        {optionVisible.isOn && (
          <div className="absolute bg-white w-full border border-neutral-5 z-10">
            {props.options.map((option) => (
              <div
                className="px-[6px] hover:bg-primary-1 cursor-pointer border-b border-neutral-4"
                key={option.id}
                onClick={() => {
                  props.onChange(option.id);
                  optionVisible.off();
                }}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
});
