import { CursorArrowRaysIcon } from "@heroicons/react/20/solid";
import { Button, ButtonIntent, ButtonState } from "src/components/Button";
import type { MapState } from "src/types/MapState";
import { ToolSelection } from "src/types/MapState";

export function SelectButton({ mapState }: { mapState: MapState }) {
  const { selectedTool: mode } = mapState;
  return (
    <Button
      title="Select"
      Icon={CursorArrowRaysIcon}
      onClick={() => {
        mode.update(ToolSelection.SELECT);
      }}
      intent={ButtonIntent.SELECT}
      state={mode.value.id === ToolSelection.SELECT ? ButtonState.ACTIVE : ButtonState.NONE}
    />
  );
}
