import { type RgObject } from "@project-rouge/rg-core";
import { observer } from "mobx-react-lite";

interface SvgWindowProps {
  obj: RgObject;
  scale: number;
}

export const SvgWindow = observer<SvgWindowProps>(({ obj }) => {
  const sideMargin = 0.1;
  const leftSide = -obj.size[0] * 0.5 + sideMargin;
  const rightSide = obj.size[0] * 0.5 - sideMargin;
  return (
    <g name="window">
      <line
        name="left"
        x1={leftSide}
        x2={leftSide}
        y1={-obj.size[2] * 0.5}
        y2={obj.size[2] * 0.5}
        stroke="#000"
        strokeWidth={0.01}
      />
      <line
        name="right"
        x1={rightSide}
        x2={rightSide}
        y1={-obj.size[2] * 0.5}
        y2={obj.size[2] * 0.5}
        stroke="#000"
        strokeWidth={0.01}
      />
      <line name="middle" x1={leftSide} x2={rightSide} stroke="#000" strokeWidth={0.01} />
    </g>
  );
});

SvgWindow.displayName = "SvgWindow";
