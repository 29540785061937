import { type V3 } from "@project-rouge/rg-core";
import { distanceToLine } from "@project-rouge/rg-three";
import { GetPolygonEdges } from "./GetPolygonEdges";
import { GetVectorDistance } from "./GetVectorDistance";
import { GetVectorLerp } from "./GetVectorLerp";

export function SnapVectorToEdge(mouse: V3, polygons: V3[][], tolerance: number): V3 | undefined {
  const closestLine = GetClosestLine(mouse, polygons);
  if (!closestLine) return;
  if (closestLine.distance > tolerance) return;
  const distanceA = GetVectorDistance(closestLine.line[0], mouse);
  const distanceB = GetVectorDistance(closestLine.line[1], mouse);
  const ralativeDistance = distanceA / (distanceA + distanceB);
  const point = GetVectorLerp(closestLine.line[0], closestLine.line[1], ralativeDistance);
  return point;
}

function GetClosestLine(
  point: V3,
  polygons: V3[][]
): {
  line: [V3, V3];
  distance: number;
  lineIndex: number;
} | null {
  let closest: {
    line: [V3, V3];
    lineIndex: number;
    distance: number;
  } | null = null;
  for (const polygon of polygons) {
    const lines = GetPolygonEdges(polygon);
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      const value = distanceToLine(point, line);
      closest =
        !closest || closest.distance > value.distance
          ? { distance: value.distance, line, lineIndex: i }
          : closest;
    }
  }
  if (!closest) return null;
  return {
    distance: closest.distance,
    line: closest.line,
    lineIndex: closest.lineIndex,
  };
}
