import { makeAutoObservable } from "mobx";
import type { RgBuilding } from "./RgCorePackage";
import { getApartmentTypeCounts } from "@project-rouge/rg-three";
import { ArchitecturalMetricsCalculator } from "src/utils/ArchitecturalMetricsCalculator";
import { RG_TYPE } from "@project-rouge/rg-core";
import type { World } from "./World";
import { computedAliveMap } from "./computedAliveMap";
import { GetAreaPerApartmentType } from "./GetAreaPerApartmentType";
import type { Building } from "./Building";
import type { Zone } from "./Zone";
import type { Site } from "./Site";

function toArray<T>(value: T | T[] | undefined): T[] | null {
  if (!value) return null;
  return Array.isArray(value) ? value : [value];
}

export class ArchitecturalMetrics {
  private readonly selectedBuildings: Building[] | null = null;
  constructor(buildings?: Building[] | Building) {
    this.selectedBuildings = toArray(buildings);
    makeAutoObservable(this, {
      ...computedAliveMap(this),
    });
  }

  private get rgBuildings(): RgBuilding[] {
    if (!this.parent) return [];
    return this.selectedBuildings
      ? this.selectedBuildings?.map((b) => b.rgParentlessSnapshot)
      : this.parent.buildings ?? [];
  }
  get parent(): Zone | Site | World | undefined {
    const zones = this.selectedBuildings?.map((building) => building.parent?.parent);
    if (new Set(zones).size === 1) return zones?.at(0);
    const sites = zones?.map((zone) => zone?.parent);
    if (new Set(sites).size === 1) return sites?.at(0);

    return sites?.at(0)?.parent;
  }
  get buildingsCount() {
    return this.rgBuildings.length;
  }

  get totalDistributionMetrics() {
    return GetDistributionTotal(this.rgBuildings);
  }
  get floorsCount() {
    return ArchitecturalMetricsCalculator.Floor.Max(this.rgBuildings);
  }
  get areaPerApartmentType() {
    return GetAreaPerApartmentType(this.rgBuildings);
  }
  get groupedRatiosByBedroomCategories() {
    return GetGroupedRatiosByBedroomCategories(this.rgBuildings);
  }

  get BuildingHeightMax() {
    return ArchitecturalMetricsCalculator.BuildingHeight.Max(this.rgBuildings);
  }
  get FloorMax() {
    return ArchitecturalMetricsCalculator.Floor.Max(this.rgBuildings, false);
  }
  get FootPrintTotal() {
    return ArchitecturalMetricsCalculator.FootPrint.Total(this.rgBuildings);
  }
  get GIATotal() {
    return ArchitecturalMetricsCalculator.GIA.Total(this.rgBuildings);
  }
  get GIAPerLevel() {
    return ArchitecturalMetricsCalculator.GIA.PerLevel(this.rgBuildings);
  }
  get GEATotal() {
    return ArchitecturalMetricsCalculator.GEA.Total(this.rgBuildings);
  }
  get GEAPerLevel() {
    return ArchitecturalMetricsCalculator.GEA.PerLevel(this.rgBuildings);
  }
  get NIATotal() {
    return ArchitecturalMetricsCalculator.NIA.Total(this.rgBuildings);
  }
  get NIAPerLevel() {
    return ArchitecturalMetricsCalculator.NIA.PerLevel(this.rgBuildings);
  }
  get AmenitiesAreaTotal() {
    return ArchitecturalMetricsCalculator.AmenitiesArea.Total(this.rgBuildings);
  }
  get AmenitiesAreaPerLevel() {
    return ArchitecturalMetricsCalculator.AmenitiesArea.PerLevel(this.rgBuildings);
  }
  get ServiceAreaTotal() {
    return ArchitecturalMetricsCalculator.ServiceArea.Total(this.rgBuildings);
  }
  get ServiceAreaPerLevel() {
    return ArchitecturalMetricsCalculator.ServiceArea.PerLevel(this.rgBuildings);
  }
  get CommercialAreaTotal() {
    return ArchitecturalMetricsCalculator.CommercialArea.Total(this.rgBuildings);
  }
  get CommercialAreaPerLevel() {
    return ArchitecturalMetricsCalculator.CommercialArea.PerLevel(this.rgBuildings);
  }
  get NonResidentialAreaTotal() {
    return ArchitecturalMetricsCalculator.NonResidentialArea.Total(this.rgBuildings);
  }
  get NonResidentialAreaPerLevel() {
    return ArchitecturalMetricsCalculator.NonResidentialArea.PerLevel(this.rgBuildings);
  }
  get ApartmentModuleQuantityTotal() {
    return ArchitecturalMetricsCalculator.ApartmentMduleQuantity.Total(this.rgBuildings);
  }
  get ApartmentModuleQuantityPerLevel() {
    return ArchitecturalMetricsCalculator.ApartmentMduleQuantity.PerLevel(this.rgBuildings);
  }
  get ApartmentQuantityTotal() {
    return ArchitecturalMetricsCalculator.ApartmentQuantity.Total(this.rgBuildings);
  }
  get ModuleQuantityTotal() {
    return ArchitecturalMetricsCalculator.ModuleQuantity.Total(this.rgBuildings);
  }
  // need the area of the zone
  get DensityUnitsTotal() {
    return this.parent ? ArchitecturalMetricsCalculator.DensityUnits.Total(this.parent) : 0;
  }
  // need the area of the zone
  get DensityHabitableRoomsTotal() {
    return this.parent
      ? ArchitecturalMetricsCalculator.DensityHabitableRooms.Total(this.parent)
      : 0;
  }
  // need the area of the zone
  get LandCoverageTotal() {
    return this.parent ? ArchitecturalMetricsCalculator.LandCoverage.Total(this.parent) : 0;
  }
  get ResidentialEfficiencyTotal() {
    return ArchitecturalMetricsCalculator.ResidentialEfficiency.Total(this.rgBuildings);
  }
  get ResidentialEfficiencyPerLevel() {
    return ArchitecturalMetricsCalculator.ResidentialEfficiency.PerLevel(this.rgBuildings);
  }
  get ResidentialLevelCountMax() {
    return ArchitecturalMetricsCalculator.ResidentialLevelCount.Max(this.rgBuildings);
  }
  get BuildingEfficiencyTotal() {
    return ArchitecturalMetricsCalculator.BuildingEfficiency.Total(this.rgBuildings);
  }
  get BuildingEfficiencyPerLevel() {
    return ArchitecturalMetricsCalculator.BuildingEfficiency.PerLevel(this.rgBuildings);
  }
  get FacadeTotal() {
    return ArchitecturalMetricsCalculator.Facade.Total(this.rgBuildings);
  }
  get GlazedFacade() {
    return ArchitecturalMetricsCalculator.GlazedFacade.Total(this.rgBuildings);
  }
  get FacadePerLevel() {
    return ArchitecturalMetricsCalculator.Facade.PerLevel(this.rgBuildings);
  }
  get WallToFloorRatioTotal() {
    return ArchitecturalMetricsCalculator.WallToFloorRatio.Total(this.rgBuildings);
  }
  get ClearInternalHeightTotal() {
    return this.rgBuildings.length
      ? ArchitecturalMetricsCalculator.ClearInternalHeight.Total(this.rgBuildings)
      : 0;
  }
  get ClearInternalHeightPerLevel() {
    return ArchitecturalMetricsCalculator.ClearInternalHeight.PerLevel(this.rgBuildings);
  }
  get IndicativeFormFactorTotal() {
    return ArchitecturalMetricsCalculator.IndicativeFormFactor.Total(this.rgBuildings);
  }
  get UnitCategoryMixPerCategory() {
    return ArchitecturalMetricsCalculator.UnitCategoryMix.PerCategory(this.rgBuildings);
  }
  get UnitCategoryMixPerLevel() {
    return this.parent ? ArchitecturalMetricsCalculator.UnitCategoryMix.PerLevel(this.parent) : 0;
  }
  get EntrancesTotal() {
    return ArchitecturalMetricsCalculator.Entrances.Total(this.rgBuildings);
  }
  get CoresTotal() {
    return ArchitecturalMetricsCalculator.Cores.Total(this.rgBuildings);
  }
  get FireCoresTotal() {
    return ArchitecturalMetricsCalculator.FireCores.Total(this.rgBuildings, 0);
  }
  get LiftCoresTotal() {
    return ArchitecturalMetricsCalculator.LiftCores.Total(this.rgBuildings, 0);
  }
  get FFLMax() {
    return ArchitecturalMetricsCalculator.FFL.Max(this.rgBuildings);
  }
  get FFLPerLevel() {
    return ArchitecturalMetricsCalculator.FFL.PerLevel(this.rgBuildings);
  }
  get CoreAndCorridorAreaTotal() {
    return ArchitecturalMetricsCalculator.CoreAndCorridorArea.Total(this.rgBuildings);
  }
  get CoreAndCorridorAreaPerLevel() {
    return ArchitecturalMetricsCalculator.CoreAndCorridorArea.PerLevel(this.rgBuildings);
  }
  get RoofAreaPerLevel() {
    return ArchitecturalMetricsCalculator.RoofArea.PerLevel(this.rgBuildings);
  }
  get RoofAreaTotal() {
    return ArchitecturalMetricsCalculator.RoofArea.Total(this.rgBuildings);
  }
  get NorthFacingUnitsTotal() {
    return ArchitecturalMetricsCalculator.NorthFacingUnits.Total(this.rgBuildings);
  }
  get NorthFacingUnitsPerLevel() {
    return ArchitecturalMetricsCalculator.NorthFacingUnits.PerLevel(this.rgBuildings);
  }
  get PodiumHeightSumTotal() {
    return ArchitecturalMetricsCalculator.PodiumHeightSum.Total(this.rgBuildings);
  }
  get UnitToLiftCoreRatio() {
    return ArchitecturalMetricsCalculator.UnitToLiftCoreRatio.Total(this.rgBuildings);
  }
  get FacadeInternalCornerLength() {
    return ArchitecturalMetricsCalculator.FacadeInternalCornerLength.Total(this.rgBuildings);
  }
  get FacadeExternalCornerLength() {
    return ArchitecturalMetricsCalculator.FacadeExternalCornerLength.Total(this.rgBuildings);
  }

  get siteAreaHectares() {
    return this.parent?.siteAreaHectares ?? 0;
  }

  get siteAreaAcres() {
    return this.siteAreaHectares * 2.471054;
  }

  get snapshot(): ArchitecturalMetricsSnapshot {
    return {
      totalDistributionMetrics: this.totalDistributionMetrics,
      floorsCount: this.floorsCount,
      areaPerApartmentType: this.areaPerApartmentType,
      groupedRatiosByBedroomCategories: this.groupedRatiosByBedroomCategories,
      BuildingHeightMax: this.BuildingHeightMax,
      FloorMax: this.FloorMax,
      FootPrintTotal: this.FootPrintTotal,
      GIATotal: this.GIATotal,
      GIAPerLevel: this.GIAPerLevel,
      GEATotal: this.GEATotal,
      GEAPerLevel: this.GEAPerLevel,
      NIATotal: this.NIATotal,
      NIAPerLevel: this.NIAPerLevel,
      AmenitiesAreaTotal: this.AmenitiesAreaTotal,
      AmenitiesAreaPerLevel: this.AmenitiesAreaPerLevel,
      ServiceAreaTotal: this.ServiceAreaTotal,
      ServiceAreaPerLevel: this.ServiceAreaPerLevel,
      CommercialAreaTotal: this.CommercialAreaTotal,
      CommercialAreaPerLevel: this.CommercialAreaPerLevel,
      NonResidentialAreaTotal: this.NonResidentialAreaTotal,
      NonResidentialAreaPerLevel: this.NonResidentialAreaPerLevel,
      ApartmentModuleQuantityTotal: this.ApartmentModuleQuantityTotal,
      ApartmentModuleQuantityPerLevel: this.ApartmentModuleQuantityPerLevel,
      ApartmentQuantityTotal: this.ApartmentQuantityTotal,
      ModuleQuantityTotal: this.ModuleQuantityTotal,
      DensityUnitsTotal: this.DensityUnitsTotal,
      DensityHabitableRoomsTotal: this.DensityHabitableRoomsTotal,
      LandCoverageTotal: this.LandCoverageTotal,
      ResidentialEfficiencyTotal: this.ResidentialEfficiencyTotal,
      ResidentialEfficiencyPerLevel: this.ResidentialEfficiencyPerLevel,
      ResidentialLevelCountMax: this.ResidentialLevelCountMax,
      BuildingEfficiencyTotal: this.BuildingEfficiencyTotal,
      BuildingEfficiencyPerLevel: this.BuildingEfficiencyPerLevel,
      FacadeTotal: this.FacadeTotal,
      GlazedFacade: this.GlazedFacade,
      FacadePerLevel: this.FacadePerLevel,
      WallToFloorRatioTotal: this.WallToFloorRatioTotal,
      ClearInternalHeightTotal: this.ClearInternalHeightTotal,
      ClearInternalHeightPerLevel: this.ClearInternalHeightPerLevel,
      IndicativeFormFactorTotal: this.IndicativeFormFactorTotal,
      UnitCategoryMixPerCategory: this.UnitCategoryMixPerCategory,
      UnitCategoryMixPerLevel: this.UnitCategoryMixPerLevel,
      EntrancesTotal: this.EntrancesTotal,
      CoresTotal: this.CoresTotal,
      FireCoresTotal: this.FireCoresTotal,
      LiftCoresTotal: this.LiftCoresTotal,
      FFLMax: this.FFLMax,
      FFLPerLevel: this.FFLPerLevel,
      CoreAndCorridorAreaTotal: this.CoreAndCorridorAreaTotal,
      CoreAndCorridorAreaPerLevel: this.CommercialAreaPerLevel,
      RoofAreaTotal: this.RoofAreaTotal,
      RoofAreaPerLevel: this.RoofAreaPerLevel,
      NorthFacingUnitsTotal: this.NorthFacingUnitsTotal,
      NorthFacingUnitsPerLevel: this.NorthFacingUnitsPerLevel,
      PodiumHeightSumTotal: this.PodiumHeightSumTotal,
      UnitToLiftCoreRatio: this.UnitToLiftCoreRatio,
      FacadeInternalCornerLength: this.FacadeInternalCornerLength,
      FacadeExternalCornerLength: this.FacadeExternalCornerLength,
      siteAreaAcres: this.siteAreaAcres,
      siteAreaHectares: this.siteAreaHectares,
      buildingsCount: this.buildingsCount,
    };
  }
}

export type ArchitecturalMetricsSnapshot = Omit<
  {
    [K in keyof ArchitecturalMetrics]: ArchitecturalMetrics[K];
  },
  "snapshot" | "parent"
>;

function GetDistributionTotal(
  buildings: RgBuilding[]
): { name: string; count: number; ratio: number }[] {
  type UnitName = string;

  const units = new Map<UnitName, { count: number; ratios: number[] }>();

  for (const building of buildings) {
    const apartmentDistribution = getApartmentTypeCounts(building).map((r) => {
      return {
        name: r.name,
        count: r.count,
        ratio: r.ratio,
      };
    });
    for (const { name, count, ratio } of apartmentDistribution) {
      const unit = units.get(name) ?? { count: 0, ratios: [] };
      units.set(name, unit);
      unit.count += count;
      unit.ratios.push(ratio);
    }
  }

  return [...units.entries()]
    .map(([name, { count, ratios }]) => ({
      name,
      count,
      ratio: ratios.reduce((a, b) => a + b) / ratios.length,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
}

function GetGroupedRatiosByBedroomCategories(
  buildings: RgBuilding[]
): { ratio: string; typeCount: number }[] {
  type BedroomCount = number;
  let allApartmentsCount = 0;
  const mix = new Map<BedroomCount, { bedrooms: number; quantity: number; types: Set<string> }>();
  for (const building of buildings) {
    for (const child of building.children) {
      if (child.type !== RG_TYPE.Apartment) continue;
      allApartmentsCount += 1;
      const { bedrooms } = child.data;
      const item = mix.get(bedrooms) ?? { bedrooms, quantity: 0, types: new Set() };
      mix.set(bedrooms, item);
      item.quantity += 1;
      item.types.add(child.name);
    }
  }
  return [...mix.values()]
    .sort((a, b) => a.bedrooms - b.bedrooms)
    .map(({ quantity, types }) => ({
      ratio: `${(quantity / allApartmentsCount) * 100}`,
      typeCount: types.size,
    }));
}
