import {
  RgCreate,
  DefaultBuilding,
  RG_TYPE,
  type LevelData,
  type RgObject,
  RgChildren,
} from "@project-rouge/rg-core";
import { getRgObjectsOfType, getMatrix, matrixToRgTransform } from "@project-rouge/rg-three";
import { useMemo } from "react";
import { type RgWorld, type RgBuilding } from "src/types/RgCorePackage";
import { type Matrix4 } from "three";

type BuildingSelection = "ALL" | string[];

export function useMergeBuildings(rgWorld: RgWorld | null, buildings: BuildingSelection) {
  return useMemo(() => GetUniBuilding(rgWorld, buildings), [buildings, rgWorld]);
}

function GetUniBuilding(rgWorld: RgWorld | null, buildings: BuildingSelection) {
  if (!rgWorld) return null;
  if (buildings === "ALL") {
    const uniBuilding = RgCreate<RgBuilding>({ ...DefaultBuilding, uuid: crypto.randomUUID() }, []);
    MergeBuildings(rgWorld, uniBuilding.children, null);
    const levels = GetMaxLevel(getRgObjectsOfType<RgBuilding>(rgWorld, RG_TYPE.Building));
    uniBuilding.data.levels = levels;
    return uniBuilding;
  }
  if (buildings.length === 1) {
    return getRgObjectsOfType<RgBuilding>(rgWorld, RG_TYPE.Building).find(
      ({ uuid }) => uuid === buildings[0]
    );
  }
  const uniBuilding = RgCreate<RgBuilding>({ ...DefaultBuilding, uuid: crypto.randomUUID() }, []);
  MergeBuildings(rgWorld, uniBuilding.children, new Set(buildings));
  const levels = GetMaxLevel(getRgObjectsOfType<RgBuilding>(rgWorld, RG_TYPE.Building));
  uniBuilding.data.levels = levels;
  return uniBuilding;
}

function GetMaxLevel(buildings: RgBuilding[]): LevelData[] {
  const levels: LevelData[] = [];
  for (const building of buildings) {
    if (building.data.levels.length > levels.length) levels.push(...building.data.levels);
  }
  return levels;
}

function MergeBuildings(
  obj: RgObject,
  mergedChildren: RgObject[] = [],
  ids: Set<string> | null,
  parentMx?: Matrix4
): void {
  if (ids && obj.type === RG_TYPE.Building && !ids.has(obj.uuid)) return;

  const objMx = getMatrix(obj);
  if (parentMx) objMx.premultiply(parentMx);
  if (RgChildren.Building.includes(obj.type)) {
    obj = Object.assign({}, obj); // make a copy
    ({ pos: obj.pos, rot: obj.rot } = matrixToRgTransform(objMx));
    mergedChildren.push(obj);
    return;
  }
  for (const child of obj.children) {
    MergeBuildings(child, mergedChildren, ids, objMx);
  }
}
