export enum UnitType {
  Imperial = "Imperial",
  Metric = "Metric",
}
export enum UnitCategory {
  Area = "Area",
  Length = "Length",
  MassPerArea = "MassPerArea",
  Mass = "Mass",
  Count = "Count",
  Percentage = "Percentage",
  Cost = "Cost",
  CountPerHectare = "CountPerHectare",
  CostPerArea = "CostPerArea",
  CostPerUnit = "CostPerUnit",
}
export function unitSuffix(unitType: UnitType, unitCategory: UnitCategory | null): string {
  switch (unitType) {
    case UnitType.Metric:
      switch (unitCategory) {
        case UnitCategory.Area:
          return "sqm";
        case UnitCategory.Length:
          return "m";
        case UnitCategory.MassPerArea:
          return "kg/sqm";
        case UnitCategory.Count:
          return "no.";
        case UnitCategory.Percentage:
          return "%";
        case UnitCategory.Cost:
          return "£";
        case UnitCategory.Mass:
          return "kg";
        case UnitCategory.CountPerHectare:
          return "no/ha";
        case UnitCategory.CostPerArea:
          return "£/sqm";
        case UnitCategory.CostPerUnit:
          return "£/unit";
        default:
          return "";
      }
    case UnitType.Imperial:
      switch (unitCategory) {
        case UnitCategory.Area:
          return "sqft";
        case UnitCategory.Length:
          return "ft";
        case UnitCategory.MassPerArea:
          return "kg/sqft";
        case UnitCategory.Count:
          return "no.";
        case UnitCategory.Percentage:
          return "%";
        case UnitCategory.Cost:
          return "£";
        case UnitCategory.Mass:
          return "kg";
        case UnitCategory.CountPerHectare:
          return "no/ha";
        case UnitCategory.CostPerArea:
          return "£/sqft";
        case UnitCategory.CostPerUnit:
          return "£/unit";
        default:
          return "";
      }
  }
}
