import { type V2 } from "@project-rouge/rg-core";
import clip from "polygon-clipping";
import { IsVectorEqualV2 } from "src/utils/IsVectorEqual";
import { SanitizePolygonVertexesV2 } from "src/utils/SanitizeZoneBoundaryx";

interface OffsetPolygons {
  innerPolygons: V2[][];
  /** ring around inner polygons. The output is multiplygon as the first is outter ring and others are the holes */
  outterPolygons: V2[][];
  outterPolygonsHoles: V2[][];
}

export function GetOffsetPolygons(polygon: V2[], offsets: number[]): OffsetPolygons {
  try {
    const outterPolygonsRing = [
      ...GetLineToOffsetPolygon(polygon, offsets),
      ...GetIntersectPolygons(polygon, offsets),
    ];
    const outterPolygonsUnion = clip.union(outterPolygonsRing.map((p) => [p]));
    const outterMultiPolygons = clip.intersection([polygon], outterPolygonsUnion);
    const clippedPolygons = clip.difference([polygon], outterPolygonsUnion).map(([v]) => v);
    const sanitizedPolygons = SanitizePolygonVertexesV2(clippedPolygons, 0.001);
    const innerPolygons = sanitizedPolygons.filter((v) => !IsPolygonLine(v));
    const outterPolygonsHoles = outterMultiPolygons.map(([, ...rest]) => rest).flat();
    const outterPolygons = outterMultiPolygons.map(([v]) => v);
    return { innerPolygons, outterPolygons, outterPolygonsHoles };
  } catch (e: unknown) {
    if (
      typeof e === "object" &&
      e !== null &&
      "message" in e &&
      typeof e.message === "string" &&
      e.message.startsWith("Unable to complete output ring starting at")
    ) {
    } else {
      console.log(e);
    }
    // Sometimes polygon-clipping will throw an error becuse it can't difference the polygons.
    // In this case we just add a small offset to the polygon and try again.
    return GetOffsetPolygons(
      polygon,
      offsets.map((o) => o + 0.001)
    );
  }
}

function GetLineToOffsetPolygon(originalPolygon: V2[], offsets: number[]): V2[][] {
  const originalLines = GetLinesFromPolygon(originalPolygon);
  const lines = GetExtrudedLines(originalPolygon, offsets);
  const extrudedLinesPolygon = originalLines.map((line, i) => {
    const a = line;
    const b = lines[i];
    const polygon = [...a, b[1], b[0], a[0]];
    return polygon;
  });
  return extrudedLinesPolygon;
}

function GetIntersectPolygons(originalPolygon: V2[], offsets: number[]) {
  const lines = GetLinesFromPolygon(originalPolygon);
  const extrudedLines = GetExtrudedLines(originalPolygon, offsets);
  const extrudedPolygons = lines.map((line, i) => {
    const a = line[0];
    const b = extrudedLines[i][0];
    const c = extrudedLines.at(i - 1)?.[1] ?? [0, 0];
    const triangle: [V2, V2, V2, V2] = [a, b, c, a];
    return triangle;
  });
  return extrudedPolygons;
}

function IsPolygonLine(polygon: V2[]): boolean {
  const purePoints = [...new Set(polygon.map((p) => p.join(",")))].map((p) =>
    p.split(",").map(Number)
  );
  if (purePoints.length < 3) return true;
  if (purePoints.length === 3) {
    return IsVectorEqualV2(polygon[0], polygon[3]) && IsVectorEqualV2(polygon[1], polygon[2]);
  }
  return false;
}

function GetLinesFromPolygon(polygon: V2[]): [V2, V2][] {
  const lines: [V2, V2][] = [];
  for (let i = 0; i < polygon.length - 1; i++) {
    lines.push([polygon[i], polygon[i + 1]]);
  }
  return lines;
}

function GetExtrudedLines(polygon: V2[], offsets: number[]): [V2, V2][] {
  const lines = GetLinesFromPolygon(polygon);
  const extrudedLines = lines.map((line, i) => {
    const lineAngle =
      Math.atan2(line[1][1] - line[0][1], line[1][0] - line[0][0]) + (90 * Math.PI) / 180;
    const a = GetPointByDistanceAndDirection(line[0], offsets[i], lineAngle);
    const b = GetPointByDistanceAndDirection(line[1], offsets[i], lineAngle);
    return [a, b] as [V2, V2];
  });
  return extrudedLines;
}

function GetPointByDistanceAndDirection(point: V2, distance: number, radian: number): V2 {
  return [point[0] + distance * Math.cos(radian), point[1] + distance * Math.sin(radian)];
}
