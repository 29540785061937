import { observer } from "mobx-react-lite";
import type { SelectOption } from "src/types/SelectState";

export const Select = observer(function Select<
  T extends readonly (SelectOption & { label: string })[],
>({
  items,
  selected,
  onChange,
}: {
  items: T;
  selected: string;
  onChange: (id: T[number]["id"]) => void;
}) {
  return (
    <select
      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-0 focus-within:border-gray-300 block w-full hover:bg-[#EEE] cursor-pointer"
      value={selected}
      onChange={(e) => onChange(e.target.value)}
    >
      {items.map(({ id, label }) => (
        <option value={id} key={id}>
          {label}
        </option>
      ))}
    </select>
  );
});
