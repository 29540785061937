import type { PropsWithChildren } from "react";
import { ViewValue } from "./ViewValue";
import { State } from "./InspectorRow";

export const InspectorRowXL = (
  props: PropsWithChildren<{
    value: number;
    unit?: string;
    state: State;
    decimalPoint?: number;
  }>
) => {
  return (
    <div
      className="w-[248px] bg-white py-[6px] flex justify-between 
      border-b-[1px] border-neutral-3 text-body-1 text-neutral-6"
    >
      {props.children}
      <div className="text-end flex items-center">
        {props.state === State.EMPTY && <span className="px-1 text-neutral-6">-</span>}
        {props.state === State.FILLED && (
          <ViewValue value={props.value} decimalPoint={props.decimalPoint} />
        )}
        {props.state === State.LOADING && (
          <div className="mx-1 w-3 h-3 border-[2px] rounded-full border-primary-6 border-t-transparent animate-spin" />
        )}
        <span className="text-neutral-6 text-body-1 text-start w-[50px]">{props.unit}</span>
      </div>
    </div>
  );
};
