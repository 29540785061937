import { useMemo, useState } from "react";
import { useEvent } from "./useEvent";
import { useLiveRef } from "./useLiveRef";

export interface UseBoolean {
  on: () => void;
  off: () => void;
  toggle: () => void;
  set: (value: boolean) => void;
  /** @deprecated - use `isOn` or `isOff` */
  value: boolean;
  isOn: boolean;
  isOff: boolean;
}

export function useBoolean(
  defaultValue?: boolean,
  onChange?: (value: boolean) => void
): UseBoolean {
  const [value, setValue] = useState(defaultValue || false);
  const valueRef = useLiveRef(value);
  const onChangeCallback = useEvent((value: boolean) => {
    setValue(value);
    onChange?.(value);
  });
  const on = useEvent(() => onChangeCallback(true));
  const off = useEvent(() => onChangeCallback(false));
  const toggle = useEvent(() => onChangeCallback(!valueRef.current));
  const set = useEvent((value: boolean) => onChangeCallback(value));
  const isOn = value;
  const isOff = !value;
  return useMemo(
    () => ({ on, off, toggle, value, isOff, isOn, set }),
    [isOff, isOn, off, on, toggle, value, set]
  );
}
