import { EdgeOffsetButton } from "./EdgeOffsetButton";
import { DeleteButton } from "./DeleteButton";
import { MergeButton } from "./MergeButton";
import { CutButton } from "./CutButton";
import { BuildingPresetButton } from "./BuildingPresetButton";
import { CreateButton } from "./CreateButton";
import { HistoryRedoButton } from "./HistoryRedoButton";
import { HistoryUndoButton } from "./HistoryUndoButton";
import { MapViewButton } from "./MapViewButton";
import { SelectButton } from "./SelectButton";
import { Wrapper } from "./Wrapper";
import { Group } from "./Group";
import { SearchButton } from "./SearchButton";
import { ExportButton } from "./ExportButton";
import { SaveButton } from "./SaveButton";
import type { ModelVisibilityPreset } from "src/types/MapState";
export interface SelectOption {
  id: ModelVisibilityPreset;
  label: string;
}

export const Toolbox = {
  Wrapper,
  Group,
  MapViewButton,
  BuildingPresetButton,
  SelectButton,
  CreateButton,
  HistoryRedoButton,
  HistoryUndoButton,
  CutButton,
  MergeButton,
  DeleteButton,
  EdgeOffsetButton,
  ExportButton,
  SaveButton,
  SearchButton,
};
