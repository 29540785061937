import { useMemo } from "react";
import { Modal } from "src/components/Modal";
import { Form, FormIntent, FormState } from "./Form/Form";
import { useEvent } from "src/hooks/useEvent";
import { useImmer } from "use-immer";
import { useApi } from "src/contexts/api.context";
import { useQueryClient } from "@tanstack/react-query";
import { ProjectsQueryKey } from "src/hooks/useProjectQuery";
import { useToaster } from "src/contexts/toaster.context";
import { ToastType } from "./Toast";

interface FormData {
  projectId: string;
  label: string;
  notes: string;
}

export function UpdateProjectModal({
  projectName,
  projectId,
  projectNotes,
  onClose,
}: {
  projectName: string;
  projectId: string;
  projectNotes: string;
  onClose: () => void;
}) {
  const api = useApi();
  const queryClient = useQueryClient();
  const [form, updateForm] = useImmer<FormData>({
    projectId,
    label: projectName,
    notes: projectNotes,
  });
  const toaster = useToaster();
  const updateProject = useEvent(async () => {
    await api.patchProject({
      projectId,
      label: form.label,
      notes: form.notes,
    });
    queryClient.invalidateQueries(ProjectsQueryKey);
    toaster.show({
      text: "Saved",
      type: ToastType.SUCCESS,
      autoHide: true,
      id: crypto.randomUUID(),
    });
    onClose();
  });

  const nameError = useMemo(() => (form.label ? "" : "Required project name"), [form.label]);
  let characterCount = useMemo(() => form.notes.length, [form.notes]);
  const notesError = useMemo(
    () => (characterCount === 120 ? "Maximum 120 characters" : ""),
    [characterCount]
  );
  const formState = useMemo(
    () => (nameError === "" && form.label !== projectName) || form.notes !== projectNotes,
    [form.label, form.notes, projectName, projectNotes, nameError]
  );

  return (
    <Modal.Card onClose={onClose}>
      <Modal.Header onClose={onClose} label="Edit Project" />
      <div>
        <Form.Text
          onChange={(v) =>
            updateForm((draft) => {
              draft.label = v;
            })
          }
          type="text"
          label="Project name"
          helperText={nameError}
          intent={nameError ? FormIntent.ERROR : FormIntent.NONE}
          value={form.label}
          autoFocus
        />
        <Form.Area
          label="Notes"
          value={form.notes}
          helperText={notesError}
          maxLength={200}
          characterCount={characterCount}
          autoFocus
          placeholder="Type here..."
          intent={notesError ? FormIntent.ERROR : FormIntent.NONE}
          onChange={(v) =>
            updateForm((draft) => {
              draft.notes = v;
            })
          }
        />
      </div>

      <Modal.Actions>
        <Modal.ActionCancelButton onClick={onClose} label="Cancel" />
        <Form.SubmitButton
          state={formState ? FormState.NONE : FormState.DISABLED}
          label="Save"
          onClick={updateProject}
        />
      </Modal.Actions>
    </Modal.Card>
  );
}
