import { type V3 } from "@project-rouge/rg-core";
import { usePixelToMeterDistance } from "src/hooks/useSnapMouse";
import { Circle, Ring } from "@react-three/drei";
import { DoubleSide, MathUtils } from "three";
import { observer } from "mobx-react-lite";

const VERTEX_POS_Y = 0.03;
const ROTATION_UP_REV: V3 = [-MathUtils.DEG2RAD * 90, 0, 0];

export const PolygonVertex = observer(function PolygonVertex(props: {
  position: V3;
  radiusPx: number;
  fillColor: number;
  borderWidthPx: number;
  borderColor: number;
}) {
  const position: V3 = [props.position[0], VERTEX_POS_Y, props.position[2]];
  const borderWidth = usePixelToMeterDistance(props.borderWidthPx);
  const radius = usePixelToMeterDistance(props.radiusPx);
  return (
    <group position={position} rotation={ROTATION_UP_REV}>
      <Circle args={[radius, 32]}>
        <meshBasicMaterial
          color={props.fillColor}
          side={DoubleSide}
          opacity={1}
          transparent={false}
          toneMapped={false}
          depthTest={true}
        />
      </Circle>
      <Ring args={[radius, radius + borderWidth, 32]} material-color={props.borderColor}>
        <meshBasicMaterial
          color={props.borderColor}
          side={DoubleSide}
          opacity={1}
          transparent={false}
          toneMapped={false}
          depthTest={true}
        />
      </Ring>
    </group>
  );
});
