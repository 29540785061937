import { type V3 } from "@project-rouge/rg-core";
import { useEffect } from "react";
import { useLiveRef } from "src/hooks/useLiveRef";
import { useProjectScreenToPlanePoint } from "./useProjectScreenToPlanePoint";
import { useMap } from "react-three-map";

export function useMapClickOrDrag(props: {
  onDragStart?: (data: {
    mapDownEvent: mapboxgl.MapMouseEvent;
    map: mapboxgl.Map;
    mapDownVectorPosition: V3;
  }) => void;
  onClick?: (data: {
    mapDownEvent: mapboxgl.MapMouseEvent;
    map: mapboxgl.Map;
    mapDownVectorPosition: V3;
  }) => void;
  onMouseDown?: (data: {
    mapDownEvent: mapboxgl.MapMouseEvent;
    map: mapboxgl.Map;
    mapDownVectorPosition: V3;
  }) => void;
  onMouseUp?: (data: {
    mapDownEvent: mapboxgl.MapMouseEvent;
    map: mapboxgl.Map;
    mapDownVectorPosition: V3;
  }) => void;
  clickTolerancePx: number;
}) {
  const map = useMap();
  const propsRef = useLiveRef(props);
  const getMousePoint = useProjectScreenToPlanePoint();
  useEffect(() => {
    if (!map) return;
    const mousedown = (mapDownEvent: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      if (mapDownEvent.originalEvent.button !== 0) return;
      const mapDownVectorPosition = getMousePoint().toArray();
      propsRef.current.onMouseDown?.({ mapDownEvent, mapDownVectorPosition, map });
      const mouseup = (upEvent: MouseEvent) => {
        window.removeEventListener("mouseup", mouseup);
        window.removeEventListener("mousemove", mousemove);
        propsRef.current.onMouseUp?.({ map, mapDownEvent, mapDownVectorPosition });
        if (!IsClick(mapDownEvent.originalEvent, upEvent, propsRef.current.clickTolerancePx))
          return;
        if (!map) return;
        propsRef.current.onClick?.({ mapDownEvent, map, mapDownVectorPosition });
      };
      window.addEventListener("mouseup", mouseup);

      const mousemove = (moveEvent: MouseEvent) => {
        if (IsClick(mapDownEvent.originalEvent, moveEvent, propsRef.current.clickTolerancePx))
          return;
        window.removeEventListener("mouseup", mouseup);
        window.removeEventListener("mousemove", mousemove);
        propsRef.current.onMouseUp?.({ map, mapDownEvent, mapDownVectorPosition });
        if (!map) return;
        propsRef.current.onDragStart?.({ mapDownEvent, map, mapDownVectorPosition });
      };
      window.addEventListener("mousemove", mousemove);
    };
    map.on("mousedown", mousedown);
    return () => void map.off("mousedown", mousedown);
  }, [getMousePoint, map, propsRef]);
}

function IsClick<T extends { clientX: number; clientY: number; button: number }>(
  a: T,
  b: T,
  tolerance = 10
) {
  if (a.button !== 0 || b.button !== 0) return false;
  const distance = Math.sqrt(
    Math.abs(b.clientX - a.clientX) ** 2 + Math.abs(b.clientY - a.clientY) ** 2
  );
  return distance <= tolerance * window.devicePixelRatio;
}
