import { makeAutoObservable } from "mobx";

export class ExplorePanel {
  private expandHeight = 289;
  private collapseHeight = 41;
  private thresholdHeight = 50;
  bottomPanelHeight: number;
  constructor() {
    this.bottomPanelHeight = this.collapseHeight;
    makeAutoObservable(this, {}, { autoBind: true });
  }
  moveBy(movementY: number) {
    this.bottomPanelHeight += movementY;
  }
  toggleVisible(visible?: boolean): void {
    if (visible === undefined) {
      this.bottomPanelHeight =
        this.bottomPanelHeight <= this.thresholdHeight ? this.expandHeight : this.collapseHeight;
      return;
    }
    this.bottomPanelHeight = visible ? this.expandHeight : this.collapseHeight;
  }
  get visible() {
    return this.bottomPanelHeight > this.collapseHeight;
  }
}
