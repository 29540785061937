export function sqmToSqft(sqms: number) {
  return sqms * 10.764;
}
export function meterToFeet(meters: number) {
  return meters * 3.28084;
}

export function perSqmToPerHectare(sqms: number) {
  return sqms / 0.0001;
}

export function perSqmToPerSqft(perSqms: number) {
  return perSqms / 10.764;
}
