import { BlocksInfoItem } from "./Blocks";
import { type Unit } from "./UnitSelect";
import { meterToFeet } from "../../hooks/meterToFeet";
import type { World } from "src/types/World";
import type { Building } from "src/types/Building";

export function BlocksInfo(props: {
  design: World;
  unit: Unit;
  selectedBuilding: Building | null;
}) {
  const unitCount =
    props.selectedBuilding?.metrics.ApartmentModuleQuantityTotal ??
    props.design.architecturalMetrics.ApartmentModuleQuantityTotal;

  const moduleCount =
    props.selectedBuilding?.metrics.ModuleQuantityTotal ??
    props.design.architecturalMetrics.ModuleQuantityTotal;

  const nia =
    props.selectedBuilding?.metrics.NIATotal ?? props.design.architecturalMetrics.NIATotal;

  const gia =
    props.selectedBuilding?.metrics.GIATotal ?? props.design.architecturalMetrics.GIATotal;

  return (
    <div className="w-full flex items-center justify-evenly p-3">
      <BlocksInfoItem label="Units" value={unitCount} />
      <BlocksInfoItem label="Modules" value={moduleCount} />
      <BlocksInfoItem
        label="NIA"
        value={props.unit === "sqm" ? nia : meterToFeet(nia)}
        unit={props.unit}
      />
      <BlocksInfoItem
        label="GIA"
        value={props.unit === "sqm" ? gia : meterToFeet(gia)}
        excludeSeperator={true}
        unit={props.unit}
      />
    </div>
  );
}
