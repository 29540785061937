import { Cluster } from "src/components/Cluster";
import { Table } from "../Table";
// import { type Building } from "../useBuildings";
import { type ElementTable } from "./useElementsTables";
import type { Building } from "src/types/Building";

export function ElementGroupTable(props: {
  table: ElementTable;
  selectedBuilding: Building | null;
}) {
  return (
    <div>
      <Headers selectedBuilding={props.selectedBuilding} table={props.table} />
      {props.table.rows.map((row) => (
        <Row key={row.id} row={row} variant="cell" selectedBuilding={props.selectedBuilding} />
      ))}
      <Row
        row={props.table.subTotal}
        variant="subtotal"
        selectedBuilding={props.selectedBuilding}
      />
    </div>
  );
}

function Headers(props: { table: ElementTable; selectedBuilding: Building | null }) {
  return (
    <Table.Sticky top={36}>
      <Table.Row>
        <Table.Col>
          <Table.Head label={props.table.label} variant="section" />
        </Table.Col>
      </Table.Row>

      <Table.Row>
        <Table.Col>
          <Table.Head label="" variant="header" />
        </Table.Col>
        <Table.Col>
          <Table.Head label="Total Cost" variant="header" symbol="(£)" position="end" />
        </Table.Col>
        {props.selectedBuilding && (
          <Table.Col>
            <Table.Head
              label={props.selectedBuilding.label}
              variant="header"
              symbol="(£)"
              position="end"
            />
          </Table.Col>
        )}
      </Table.Row>
    </Table.Sticky>
  );
}

function Row(props: {
  row: ElementTable["rows"][number];
  variant: "cell" | "subtotal" | "total";
  selectedBuilding: Building | null;
  currency?: string;
}) {
  const totalCell = (
    <Table.Col>
      <Table.Cell value={props.row.totalCost} variant={props.variant} currency={props.currency} />
    </Table.Col>
  );
  const buildingCell = (
    <Table.Col>
      <Table.Cell
        value={props.row.buildingCost}
        variant={props.variant}
        currency={props.currency}
      />
    </Table.Col>
  );
  return (
    <Table.Row>
      <Table.Col>
        <Table.Label label={props.row.label} variant={props.variant} />
      </Table.Col>
      <Cluster visible={!!props.selectedBuilding}>
        {totalCell}
        {buildingCell}
      </Cluster>
      <Cluster visible={!props.selectedBuilding}>{totalCell}</Cluster>
    </Table.Row>
  );
}
