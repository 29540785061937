import { IconChevronsLeft, IconChevronsRight } from "@tabler/icons-react";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import type { FunctionComponent, PropsWithChildren } from "react";
import { useBoolean } from "src/hooks/useBoolean";
import { Cluster } from "../Cluster";

type CollapsedProps = {
  panelState: boolean;
  onPanelStateChange: (panelValue: boolean) => void;
};
const Collapsed: FunctionComponent<CollapsedProps> = ({ panelState, onPanelStateChange }) => {
  return (
    <button
      onClick={() => onPanelStateChange(!panelState)}
      className={classNames(
        "h-10 w-10 flex items-center justify-center border-neutral-4 hover:bg-primary-1",
        panelState && "border-b",
        !panelState && "border-l"
      )}
    >
      {!panelState ? (
        <IconChevronsRight className="w-4 text-primary-6" />
      ) : (
        <IconChevronsLeft className="w-4 text-primary-6" />
      )}
    </button>
  );
};

export const InspectorsGroup = observer(({ children }: PropsWithChildren) => {
  const panelVisible = useBoolean(false);

  return (
    <div className="border-neutral-4 border-l flex flex-col">
      <div
        className={classNames(
          "bg-white border-neutral-4 border-b items-center justify-between relative",
          "text-overline text-neutral-7",
          panelVisible.isOn && "w-[272px] flex",
          panelVisible.isOff && "h-screen"
        )}
      >
        {panelVisible.isOn && <div className="ml-3">INSPECTOR</div>}
        <Collapsed panelState={panelVisible.isOff} onPanelStateChange={panelVisible.toggle} />
        {panelVisible.isOff && (
          <div className="absolute top-20 -rotate-90 -translate-x-4">INSPECTOR</div>
        )}
      </div>
      <Cluster visible={panelVisible.isOn}>
        <div className="bg-neutral-2 flex-1 overflow-y-scroll scrollbar-none">{children}</div>
      </Cluster>
    </div>
  );
});
