import { type V3 } from "@project-rouge/rg-core";
import { useEffect, useState } from "react";
import { useMap } from "react-three-map";
import { useEvent } from "src/hooks/useEvent";
import { SnapVectorToEdge } from "src/utils/SnapVectorToEdge";
import { SnapVectorToVector } from "src/utils/SnapVectorToVector";
import { useProjectScreenToPlanePoint } from "./useProjectScreenToPlanePoint";
import type mapboxgl from "mapbox-gl";

interface BaseParams {
  polygons: V3[][];
  snapVertex: boolean;
  snapEdge: boolean;
  /** absolute tolerance directly from editor settings */
  tolerance: number;
}

type StrictParams = BaseParams & {
  /** will only return position if snap is successful  */
  strict: true;
};
type NoStrictParams = BaseParams & {
  /** will fallback to passed position if snap is NOT successful */
  strict: false;
};
type Params = StrictParams | NoStrictParams;

export function useGetSnapVector() {
  const map = useMap();
  const getPoint = useProjectScreenToPlanePoint();
  return useEvent(function <T extends Params>({
    snapEdge,
    snapVertex,
    polygons,
    tolerance,
    strict,
  }: T): T extends { strict: true } ? V3 | null : V3 {
    if (!map) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return strict ? null : ([0, 0, 0] as any);
    }
    const mouse = getPoint();
    const relativeTolerance = PixelsToMeters(map, map.getCenter().lat, tolerance);
    const mousePosition = mouse.toArray();
    const point =
      (snapVertex && SnapVectorToVector(mousePosition, polygons, relativeTolerance)) ||
      (snapEdge && SnapVectorToEdge(mousePosition, polygons, relativeTolerance));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (!strict ? point || mousePosition : point || null) as any;
  });
}

function PixelsToMeters(map: mapboxgl.Map, latitude: number, pixels: number) {
  const zoom = map.getZoom();
  const metersPerPixel = (156543.03392 * Math.cos((latitude * Math.PI) / 180)) / Math.pow(2, zoom);
  // always devide by 2. Not sure why but this is the only way how we can keep devicePixelRatio works
  const meters = pixels * (metersPerPixel / 2);
  return meters;
}

export function usePixelToMeterDistance(pxValue: number) {
  const map = useMap();
  const [distance, setDistance] = useState<number>(0);
  useEffect(() => {
    if (!map) return;
    if (pxValue === 0) return setDistance(0);
    const updateDistance = () => {
      if (!map) return;
      setDistance(PixelsToMeters(map, map.getCenter().lat, pxValue));
    };
    map.on("zoom", updateDistance);
    map.on("pitch", updateDistance);
    updateDistance();
    return () => {
      map.off("zoom", updateDistance);
      map.off("pitch", updateDistance);
    };
  }, [map, pxValue]);

  return distance;
}
