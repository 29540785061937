import { useState, useEffect, useLayoutEffect, useRef, useMemo } from "react";
import { useRedirect } from "src/hooks/useRedirect";
import { useBoolean } from "src/hooks/useBoolean";
import { Modal } from "src/components/Modal";
import { useEvent } from "src/hooks/useEvent";
import { useApi } from "src/contexts/api.context";
import { Cluster } from "src/components/Cluster";
import { Form } from "src/components/Form/Form";
import type { Project } from "src/hooks/useProjectQuery";
import { useProjectsQuery } from "src/hooks/useProjectQuery";
import createProject from "src/assets/create-project.svg";
import { observer } from "mobx-react-lite";
import { HomeNavigator } from "src/components/HomeNavigator";
import { Tooltip } from "src/components/Tooltip";
import { IconNote, IconDotsVertical } from "@tabler/icons-react";
import classNames from "classnames";
import { ContextMenu, Intent } from "src/components/ContextMenu";
import { DeleteProjectModal } from "src/components/DeleteProjectModal";
import { UpdateProjectModal } from "src/components/UpdateProjectModal";
import { EmptyState } from "src/components/EmptyState";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/20/solid";

const CreateProjectModal = ({ onClose }: { onClose: () => void }) => {
  const [projectName, setProjectName] = useState("");
  const redirect = useRedirect();
  const api = useApi();
  const isLoading = useBoolean();
  const postProject = useEvent(async () => {
    isLoading.on();
    const project = await api.PostProject({ label: projectName });
    if (!project) return;
    await api.PostScenario({ projectId: project.id });
    onClose();
    redirect.project({ projectId: project.id });
  });

  return (
    <Modal.Card onClose={onClose}>
      <Modal.Header onClose={onClose} label="New Project" />
      <Form.Text
        type="text"
        label="Project name"
        value={projectName}
        onChange={setProjectName}
        autoFocus
      />
      <Modal.Actions>
        <Modal.ActionCancelButton onClick={onClose} label="Cancel" />
        <Modal.ActionPrimaryButton
          onClick={postProject}
          disabled={!projectName}
          label="Create Project"
        />
      </Modal.Actions>
    </Modal.Card>
  );
};

export function HomeScreen() {
  const createModal = useBoolean();
  const projects = useProjectsQuery();
  const [searchProjectValue, setSearchProjectValue] = useState("");

  const projectList = useMemo(
    () =>
      projects.data?.filter((item) =>
        item.name.toLowerCase().includes(searchProjectValue.toLowerCase())
      ),
    [projects.data, searchProjectValue]
  );

  const clearInput = useEvent(() => {
    setSearchProjectValue("");
  });

  return (
    <>
      <div className="overflow-hidden w-screen h-screen flex flex-row bg-back text-gray-200 text-sm">
        <HomeNavigator />
        <div className="flex flex-1 bg-white flex-col relative overflow-hidden">
          <div className="bg-white border-b border-neutral-4 grid grid-cols-3  items-center relative basis-12 px-5">
            <div className="text-header-4 text-neutral-8">Projects</div>
            <Form.Search
              value={searchProjectValue}
              onChange={setSearchProjectValue}
              placeholder="Search projects"
              clear={clearInput}
            />
          </div>
          <div className="flex-1 w-full h-full overflow-y-auto bg-neutral-2">
            <Cluster visible={projects.isLoading}>
              <ProgressBar />
            </Cluster>
            <Cluster visible={!projects.isLoading}>
              <div className="flex gap-4 p-4 flex-wrap">
                <CreateProject name="Create New Project" onClick={createModal.on} />
                {projectList?.map((project) => <ProjectCard key={project.id} project={project} />)}
                {projectList?.length === 0 && (
                  <EmptyState title="No results found">
                    <p>
                      We couldn’t find a match for <b>{`#${searchProjectValue}`}</b>.
                      <br />
                      Please try another search.
                    </p>
                  </EmptyState>
                )}
              </div>
            </Cluster>
          </div>
        </div>
      </div>
      <Modal.Portal isOpen={createModal.isOn}>
        <CreateProjectModal onClose={createModal.off} />
      </Modal.Portal>
    </>
  );
}
const ProgressBar = () => {
  const [progress, setProgress] = useState<number>(0);
  const intervalSpeed = 15;
  const incrementSpeed = 5;

  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgress((prevProgress) => prevProgress + incrementSpeed);
      if (progress >= 100) {
        clearInterval(progressInterval);
      }
    }, intervalSpeed);

    return () => {
      clearInterval(progressInterval);
    };
  }, [progress]);

  return (
    <div className="grid place-content-center w-full h-full">
      <div className="h-1 relative w-[260px] rounded-full overflow-hidden">
        <div className="w-full h-full bg-primary-3 absolute"></div>
        <div className="h-full bg-primary-5 relative" style={{ width: `${progress}%` }}></div>
      </div>
      <p className="text-center text-body-1 text-black mt-2">Loading projects</p>
    </div>
  );
};

const CreateProject = ({ name, onClick }: { name: string; onClick: () => void }) => {
  return (
    <div
      className="z-10 w-[220px] basis-[220px] h-[144px]
      bg-gradient-r from-primary-6 to-neutral-8 
      border-[1px] border-neutral-4  cursor-pointer rounded
      flex flex-col text-subtitle-2 text-white p-5
      hover:from-primary-6 hover:to-black  hover:shadow-md hover:shadow-elevation-4"
      onClick={onClick}
    >
      <img className="h-[70%]" src={createProject} alt="createProject" />
      <p className="mx-auto mt-3">{name}</p>
    </div>
  );
};

const ProjectCard = observer(({ project }: { project: Project }) => {
  const redirect = useRedirect();
  const deleteLoading = useBoolean();
  const truncate = (input: string) => (input?.length > 15 ? `${input.substring(0, 15)}...` : input);
  return (
    <div
      key={project.id}
      className="group relative w-[220px] h-[144px] basis-[220px] bg-white rounded border-neutral-4 
      justify-center flex-col border cursor-pointer 
      hover:shadow-md hover:shadow-elevation-3"
    >
      <div
        className="w-full"
        onClick={() => {
          if (deleteLoading.isOn) return;
          redirect.project({ projectId: project.id });
        }}
      >
        <div className="w-[220px] h-[16px] bg-gradient from-black via-primary-7 to-black rounded-t"></div>
        <div className="w-full flex flex-col px-3 space-y-1">
          {project.name.length > 15 ? (
            <Tooltip text={project.name}>
              <p className="text-subtitle-2 text-neutral-8 mt-2 mb-1">{truncate(project.name)} </p>
            </Tooltip>
          ) : (
            <p className="text-subtitle-2 text-neutral-8 mt-2 mb-1">{project.name} </p>
          )}

          <p className="m-0 text-overline text-neutral-6 mt-2">Saved designs </p>
          <p className="text-subtitle-2 text-neutral-8">{project.designsCount} </p>
          <LastModified updatedAt={project.updatedAt} />
          <div className="text-caption text-neutral-6">
            <span>Date Created: </span>
            {new Date(project.createdAt).toLocaleDateString("en-UK")}
          </div>
        </div>
      </div>
      <KebabMenu project={project} />
    </div>
  );
});

const LastModified = ({ updatedAt }: { updatedAt: string }) => {
  const RelativeTimeFormat = new Intl.RelativeTimeFormat("en", { style: "long" });
  const currentDate = new Date().getTime();
  const updateDate = new Date(updatedAt).getTime();
  const timeDifference = currentDate - updateDate;
  const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hoursAgo = Math.floor(timeDifference / (1000 * 60 * 60));
  return (
    <div className="text-caption text-neutral-8">
      {daysAgo > 0 ? (
        <span> Last Modified: {RelativeTimeFormat.format(-daysAgo, "day")} </span>
      ) : (
        <span> Last Modified: {RelativeTimeFormat.format(-hoursAgo, "hour")}</span>
      )}
    </div>
  );
};

function KebabMenu(props: { project: Project }) {
  const settingsModal = useBoolean();
  const deleteModal = useBoolean();
  const optionsVisible = useBoolean();
  const notes = props.project.notes ?? "";

  useLayoutEffect(() => {
    window.addEventListener("mousedown", optionsVisible.off);
    return () => window.removeEventListener("mousedown", optionsVisible.off);
  }, [optionsVisible.off]);

  const ref = useRef<HTMLButtonElement>(null);

  return (
    <>
      {notes !== "" && (
        <Tooltip
          text={notes}
          className={classNames(
            "absolute top-6 right-8 w-6 h-6 z-40",
            "text-neutral-7 rounded cursor-pointer flex flex-1 items-center space-x-2 flex-shrink-0",
            "hover:text-primary-7 hover:bg-primary-2"
          )}
        >
          <IconNote className="w-4 h-4 shrink-0 text-primary-7 mx-auto" />
        </Tooltip>
      )}
      <button
        ref={ref}
        className={classNames(
          "absolute top-6 right-2 w-6 h-6 z-40",
          "text-neutral-7 rounded cursor-pointer flex flex-1 items-center space-x-2 flex-shrink-0",
          "hover:text-primary-7 hover:bg-primary-2"
        )}
        onClick={optionsVisible.on}
        title="project settings"
      >
        <IconDotsVertical className="w-4 h-4 shrink-0 text-primary-7 mx-auto" />
      </button>
      <ContextMenu visible={optionsVisible.isOn} onHide={optionsVisible.off} refRoot={ref.current}>
        <ContextMenu.Card>
          <ContextMenu.Button
            label="Edit"
            Icon={PencilSquareIcon}
            onClick={() => {
              optionsVisible.off();
              settingsModal.on();
            }}
          />
          <ContextMenu.Separator />
          <ContextMenu.Button
            intent={Intent.DANGER}
            label="Delete"
            Icon={TrashIcon}
            onClick={() => {
              optionsVisible.off();
              deleteModal.on();
            }}
          />
        </ContextMenu.Card>
      </ContextMenu>
      <Cluster visible={deleteModal.isOn}>
        <DeleteProjectModal
          projectName={props.project.name}
          projectId={props.project.id}
          onClose={deleteModal.off}
        />
      </Cluster>
      <Modal.Portal isOpen={settingsModal.isOn}>
        <UpdateProjectModal
          onClose={settingsModal.off}
          projectName={props.project.name}
          projectId={props.project.id}
          projectNotes={notes}
        />
      </Modal.Portal>
    </>
  );
}
