import type { RgZoneBrief } from "@project-rouge/rg-aggregator";
import {
  Brief2PipeProps,
  aggPropsToDto,
  getRgVersions,
  rgBriefToDto,
  rgObjectToDto,
} from "@project-rouge/rg-aggregator";
import { DefaultWorld, RgCreate } from "@project-rouge/rg-core";
import type { ProjectScenarioHttpResourceWithEverything } from "@project-rouge/service-project-client/resource/project-scenario";
import { Dataset } from "src/constants/Dataset";
import type { AggDesignOption } from "src/types/AggDesignOption";
import type { AggDesignOptionDto } from "src/types/AggDesignOptionDto";
import type { RgWorld } from "src/types/RgCorePackage";
import { CompressAggDesignDto } from "src/utils/CompressAggDesignDto";

export function createDefaultAggDtoZip(
  scenario: ProjectScenarioHttpResourceWithEverything
): Uint8Array {
  const rgZoneBriefs = scenario.sites
    .map(({ zones }) => zones)
    .flat()
    .map(({ brief, id }) => {
      const aggZoneBrief: RgZoneBrief = {
        unitMix: brief.unit_category_mix.map((mix) => ({
          apartmentNames: mix.units,
          label: mix.category,
          ratio: mix.value,
        })),
        floors: brief.residential_floor_count + (brief.podium ? brief.podium.floor_count : 0),
        buildings: brief.building_count,
        landCoverage: 1,
        technicalSpaces: {},
      };

      if (brief.podium) {
        if (brief.podium.height && brief.podium.floor_count) {
          aggZoneBrief.podium = {
            replaceLevels: brief.podium.floor_count,
            levelHeight: brief.podium.height,
          };
        } else {
          console.warn(`podium enabled, but height and levels are not set`);
        }
      }

      return [id, aggZoneBrief] as [string, RgZoneBrief];
    });
  const zoneBriefs = Object.fromEntries(rgZoneBriefs);
  const rgBrief = {
    dataset: Dataset,
    zoneBriefs,
    face2FaceSeparation: scenario.brief.building_separation,
    obj: RgCreate<RgWorld>(DefaultWorld, Dataset.rvData),
  };
  const defaultAggDesign: AggDesignOption = {
    id: "",
    briefIndex: 0,
    brief: rgBrief,
    aggProps: Brief2PipeProps({
      brief: rgBrief,
      start: 0,
      count: 1,
    })[0].aggProps,
    obj: rgBrief.obj,
  };
  const dto: AggDesignOptionDto = {
    aggProps: aggPropsToDto(defaultAggDesign.aggProps),
    briefDto: rgBriefToDto(defaultAggDesign.brief),
    briefIndex: defaultAggDesign.briefIndex,
    id: defaultAggDesign.id,
    obj: rgObjectToDto(defaultAggDesign.obj),
    versions: getRgVersions(),
  };
  return CompressAggDesignDto(dto);
}
