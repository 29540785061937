import type { BuildingOrAncestor } from "@project-rouge/rg-core";
import { type Building, RG_TYPE } from "@project-rouge/rg-core";
import {
  getResidentialEfficiency,
  getEfficiency,
  getIndicativeFormFactor,
  getLandCoverage,
  getDensityUnits,
  getFootprint,
  getGIA,
  getNIA,
  getGEA,
  getMaxHeight,
  getGrossFacadeArea,
  getApartmentCategoryCounts,
  getCountOfType,
  getDensityHabitableRooms,
  getWallToFloorRatio,
  getLevelCount,
  getLevelArrayGIA,
  getLevelArrayGEA,
  getLevelArrayNIA,
  getLevelArrayCommercialArea,
  getLevelArrayServicesArea,
  getLevelArrayAmenitiesArea,
  getNonResidentialArea,
  getLevelArrayResidentialEfficiency,
  getLevelArrayEfficiency,
  getClearInternalHeight,
  getLevelArrayClearInternalHeight,
  getLiftCoreCount,
  getFireCoreCount,
  getRgObjectsAtLevel,
  getMaxFfl,
  getLevelArea,
  getNetFacadeArea,
  getRoofGrossFacadeArea,
  countNorthFacingUnits,
  getPodiumHeightSum,
  getUnitToLiftCoreRatio,
  getFacadeInternalCornerLength,
  getFacadeExternalCornerLength,
  getGlazedFacadeArea,
  getLevelArrayCoreAndCorridorArea,
  getLevelArrayFFL,
  getLevelArrayModuleCount,
  getLevelArrayNonResidentialArea,
  getLevelArrayCountNorthFacingUnits,
  getLevelArrayRoofArea,
  getLevelArrayUnitToLiftCoreRatio,
  getApartmentModuleCount,
  getLevelArrayApartmentCount,
  getAmenitiesArea,
  getCommercialArea,
  getServicesArea,
  getLevelArrayApartmentCategoryCounts,
  getResidentialLevelCount,
} from "@project-rouge/rg-three";

export const ArchitecturalMetricsCalculator = {
  BuildingHeight: {
    Max: getMaxHeight,
  },
  Floor: {
    Max: getLevelCount,
  },
  FootPrint: {
    Total: getFootprint,
  },
  GIA: {
    Total: getGIA,
    PerLevel: getLevelArrayGIA,
  },
  GEA: {
    Total: getGEA,
    PerLevel: getLevelArrayGEA,
  },
  NIA: {
    Total: getNIA,
    PerLevel: getLevelArrayNIA,
  },

  CommercialArea: {
    Total: getCommercialArea,
    PerLevel: getLevelArrayCommercialArea,
  },
  ServiceArea: {
    Total: getServicesArea,
    PerLevel: getLevelArrayServicesArea,
  },
  AmenitiesArea: {
    PerLevel: getLevelArrayAmenitiesArea,
    Total: getAmenitiesArea,
  },
  NonResidentialArea: {
    Total: getNonResidentialArea,
    PerLevel: getLevelArrayNonResidentialArea,
  },
  ApartmentMduleQuantity: {
    Total: getApartmentModuleCount,
    PerLevel: getLevelArrayApartmentCount,
  },
  ApartmentQuantity: {
    Total: (obj: BuildingOrAncestor | BuildingOrAncestor[]) =>
      getCountOfType(obj, RG_TYPE.Apartment),
  },
  ModuleQuantity: {
    Total: SumModuleQuantity,
    PerLevel: getLevelArrayModuleCount,
  },
  DensityUnits: {
    Total: getDensityUnits,
  },
  DensityHabitableRooms: {
    Total: getDensityHabitableRooms,
  },
  LandCoverage: {
    Total: getLandCoverage,
  },
  ResidentialEfficiency: {
    Total: getResidentialEfficiency,
    PerLevel: getLevelArrayResidentialEfficiency,
  },
  ResidentialLevelCount: {
    Max: (obj: BuildingOrAncestor | BuildingOrAncestor[]) =>
      Math.max(...(Array.isArray(obj) ? obj : [obj]).map(getResidentialLevelCount)),
  },
  BuildingEfficiency: {
    Total: getEfficiency,
    PerLevel: getLevelArrayEfficiency,
  },
  Facade: {
    Total: getGrossFacadeArea,
    PerLevel: getNetFacadeArea,
  },
  GlazedFacade: {
    Total: getGlazedFacadeArea,
  },
  WallToFloorRatio: {
    Total: getWallToFloorRatio,
  },
  ClearInternalHeight: {
    Total: getClearInternalHeight,
    PerLevel: getLevelArrayClearInternalHeight,
  },
  IndicativeFormFactor: {
    Total: getIndicativeFormFactor,
  },
  UnitCategoryMix: {
    PerCategory: getApartmentCategoryCounts,
    PerLevel: getLevelArrayApartmentCategoryCounts,
  },
  Entrances: {
    Total: SumEntranceQuantity,
  },
  Cores: {
    Total: SumCoreCount,
  },
  FireCores: {
    Total: getFireCoreCount,
  },
  LiftCores: {
    Total: getLiftCoreCount,
  },
  FFL: {
    Max: getMaxFfl,
    PerLevel: getLevelArrayFFL,
  },
  CoreAndCorridorArea: {
    Total: SumCoreAndCorridorArea,
    PerLevel: getLevelArrayCoreAndCorridorArea,
  },
  RoofArea: {
    Total: getRoofGrossFacadeArea,
    PerLevel: getLevelArrayRoofArea,
  },
  NorthFacingUnits: {
    Total: countNorthFacingUnits,
    PerLevel: getLevelArrayCountNorthFacingUnits,
  },
  PodiumHeightSum: {
    Total: getPodiumHeightSum,
  },
  // PodiumLevel: {
  //   Total: getBuildingPodiumLevelCount,
  // },
  UnitToLiftCoreRatio: {
    Total: getUnitToLiftCoreRatio,
    PerLevel: getLevelArrayUnitToLiftCoreRatio,
  },
  FacadeInternalCornerLength: {
    Total: getFacadeInternalCornerLength,
  },
  FacadeExternalCornerLength: {
    Total: getFacadeExternalCornerLength,
  },
};

// Module Quantity
function SumModuleQuantity(buildings: Building[]): number {
  let count = 0;
  for (const building of buildings) {
    for (const child of building.children) {
      for (const a of child.children) {
        if (a.type === RG_TYPE.Module) count += 1;
      }
    }
  }
  return count;
}

// Entrance Quantity
function SumEntranceQuantity(building: Building[]): number {
  return getCountOfType(building, RG_TYPE.Entrance);
}

// Core count
function SumCoreCount(building: Building[]): number {
  return getRgObjectsAtLevel(building, RG_TYPE.Core, 0).length;
}

// Core and Corridor Area
function SumCoreAndCorridorArea(building: Building[]): number {
  return getLevelArea(building, 0, [RG_TYPE.Core, RG_TYPE.Corridor]);
}
