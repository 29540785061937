import "mapbox-gl/dist/mapbox-gl.css";
import { useMemo, type ReactNode, useEffect } from "react";
import type { ViewStateChangeEvent } from "react-map-gl";
import MapGl, { useMap } from "react-map-gl";
import { ENV } from "src/constants/env";
import { observer } from "mobx-react-lite";
import type { MapState } from "src/types/MapState";
import { useEvent } from "src/hooks/useEvent";
const DEFAULT_MAP_CENTER = {
  lng: -0.1276473,
  lat: 51.5073218,
};

interface Props {
  children: ReactNode;
  mapState: MapState;
}

export const MAP_ID = "tessa-map";

export const Map = observer(function (props: Props) {
  const state = props.mapState;
  if (!state.hasWorld) return null;
  const initialStateView = useMemo(
    () => ({
      zoom: 17,
      latitude: state.geoPointOfInterest?.lat ?? DEFAULT_MAP_CENTER.lat,
      longitude: state.geoPointOfInterest?.lng ?? DEFAULT_MAP_CENTER.lng,
      bearing: state.bearing.value,
      pitch: state.pitch.value,
      bounds: state.geoBounds,
    }),
    [
      state.bearing.value,
      state.geoBounds,
      state.geoPointOfInterest?.lat,
      state.geoPointOfInterest?.lng,
      state.pitch.value,
    ]
  );
  const onRotate = useEvent((e: ViewStateChangeEvent) => {
    state.bearing.set(e.target.getBearing() * (Math.PI / 180));
    state.pitch.set((e.target.getPitch() * -1 + 90) * (Math.PI / 180));
  });
  return (
    <div id={MAP_ID} className="w-full h-full relative">
      <MapGl
        antialias
        initialViewState={initialStateView}
        maxPitch={85}
        cursor={state.cursor}
        mapboxAccessToken={ENV.MAPBOX_KEY}
        mapStyle={state.viewStyle.value.style}
        attributionControl={true}
        boxZoom={false}
        logoPosition="bottom-right"
        onRotate={onRotate}
      >
        <OnMapLoad onAdd={props.mapState.setMapGl} />
        {props.children}
      </MapGl>
    </div>
  );
});

/** this is needed becuase MapGl ref is not behave as actual ref. Whenever we call `fitBounds` or any other
 * method on the ref, the ref will be updated as null and only then as a new instance of the map `map.getMap()`
 */
const OnMapLoad = observer(({ onAdd }: { onAdd: (map: mapboxgl.Map | null) => void }) => {
  const { current: map } = useMap();
  useEffect(() => void onAdd(map?.getMap() ?? null), [map, onAdd]);
  return null;
});
