import { type V2, type V3 } from "@project-rouge/rg-core";

export function GetVectorDistance([x1, , y1]: V3, [x2, , y2]: V3) {
  const deltaX = x2 - x1;
  const deltaY = y2 - y1;
  const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2);
  return distance;
}

export function GetVectorDistanceV2([x1, y1]: V2, [x2, y2]: V2) {
  const deltaX = x2 - x1;
  const deltaY = y2 - y1;
  const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2);
  return distance;
}
