import classNames from "classnames";
import type { ButtonHTMLAttributes } from "react";
import { useState } from "react";
import { useWindowEvent } from "src/hooks/useWindowEvent";
import { useEvent } from "src/hooks/useEvent";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { IconSearch, IconCircleX } from "@tabler/icons-react";
export enum FormIntent {
  "ERROR" = "ERROR",
  "NONE" = "NONE",
}

export enum FormState {
  "DISABLED" = "DISABLED",
  "NONE" = "NONE",
}

const { DISABLED, NONE } = FormState;
const { ERROR } = FormIntent;

const Text = ({
  label,
  value,
  type,
  onChange,
  helperText,
  intent = FormIntent.NONE,
  autoFocus,
  maxLength,
  placeholder,
  onBlur,
}: {
  label: string;
  value: string;
  type: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  helperText?: string | null;
  intent?: FormIntent;
  autoFocus?: boolean;
  maxLength?: number;
  placeholder?: string;
}) => {
  return (
    <div className="flex flex-col space-y-1">
      <span className={classNames("text-neutral-8 text-caption")}>{label}</span>
      <input
        autoFocus={autoFocus}
        className={classNames(
          // base
          "rounded-sm ring-neutral-6 ring-[1px] border-0 text-body-1",
          // hover
          "hover:ring-[1.5px] hover:ring-primary-6",
          // focus
          "focus:ring-primary-6 ring-[1.5px]",
          // intent
          intent === ERROR && "ring-red-100 ring-[1.5px]"
        )}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(evt) => onChange(evt.currentTarget.value)}
        onBlur={onBlur && (() => onBlur())}
        maxLength={maxLength}
      />

      <div className="h-4 flex space-x-1 items-center pb-1">
        {helperText && (
          <>
            <span className="text-red-100 w-[14px]">
              <ExclamationCircleIcon />
            </span>
            <span className={classNames(intent === ERROR && "text-red-100 text-body-2 ")}>
              {helperText}
            </span>
          </>
        )}
      </div>
    </div>
  );
};

const Area = ({
  label,
  value,
  onChange,
  helperText,
  intent = FormIntent.NONE,
  autoFocus,
  placeholder,
  onBlur,
  maxLength,
  characterCount,
}: {
  label: string;
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  helperText?: string | null;
  intent?: FormIntent;
  autoFocus?: boolean;
  placeholder?: string;
  maxLength?: number;
  characterCount?: number;
}) => {
  return (
    <div className="flex flex-col space-y-1">
      <div className=" flex justify-between">
        <span className="text-neutral-8 text-caption">{label}</span>
        <span className="text-neutral-6 text-body-2">
          {characterCount}/{maxLength}
        </span>
      </div>
      <textarea
        rows={4}
        autoFocus={autoFocus}
        className={classNames(
          // base
          "rounded-sm ring-neutral-6 ring-[1px] border-0 text-body-1",
          // hover
          "hover:ring-[1.5px] hover:ring-primary-6",
          // focus
          "focus:ring-primary-6 ring-[1.5px]",
          // intent
          intent === ERROR && "ring-red-100 ring-[1.5px]"
        )}
        placeholder={placeholder}
        value={value}
        onChange={(evt) => onChange(evt.currentTarget.value)}
        onBlur={onBlur && (() => onBlur())}
        maxLength={maxLength}
      />

      <div className="h-4 flex space-x-1 items-center pb-1">
        {helperText && (
          <>
            <span className="text-red-100 w-[14px]">
              <ExclamationCircleIcon />
            </span>
            <span className={classNames(intent === ERROR && "text-red-100 text-body-2 ")}>
              {helperText}
            </span>
          </>
        )}
      </div>
    </div>
  );
};
const Search = ({
  value,
  onChange,
  clear,
  autoFocus,
  placeholder,
}: {
  value: string;
  onChange: (value: string) => void;
  clear: () => void;
  autoFocus?: boolean;
  placeholder?: string;
}) => {
  return (
    <div className="relative">
      <span className="absolute inset-y-0 left-0 flex items-center pl-2">
        <IconSearch className="p-1 focus:outline-none focus:shadow-outline" />
      </span>
      <input
        type="text"
        className={classNames(
          // base
          "pl-9 w-full rounded-sm ring-neutral-5 ring-[1px] border-0 text-body-1 h-8",
          // hover
          "hover:ring-[1.5px] hover:ring-primary-6",
          // focus
          "focus:ring-primary-6 ring-[1.5px] focus:outline-none focus:bg-white focus:text-neutral-8"
        )}
        placeholder={placeholder}
        value={value}
        onChange={(evt) => onChange(evt.currentTarget.value)}
        autoFocus={autoFocus}
      />
      {value !== "" && (
        <div
          className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
          onClick={clear}
        >
          <IconCircleX className="p-1 focus:outline-none focus:shadow-outline" />
        </div>
      )}
    </div>
  );
};
const SubmitButton = ({
  label,
  state = NONE,
  onClick,
  onEnterKey,
  fill,
  type,
  loading,
}: {
  label: string;
  state?: FormState;
  fill?: boolean;
  onClick?: () => Promise<void> | void;
  onEnterKey?: () => Promise<void> | void;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  loading?: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = useEvent(async () => {
    if (!onClick) return;
    if (isLoading) return;
    if (state === DISABLED) return;
    setIsLoading(true);
    await onClick();
    setIsLoading(false);
  });
  useWindowEvent("keydown", ({ key }) => {
    // @todo - this should be handled by the form
    if (key === "Enter" && onEnterKey) onSubmit();
  });
  return (
    <button
      className={classNames(
        "font-semibold text-white py-2 px-4 rounded flex flex-row items-center space-x-2 justify-center h-[32px] text-sm",
        state === NONE && "bg-primary-6 cursor-pointer hover:bg-primary-7 active:bg-primary-8",
        state === NONE && "bg-primary-6 cursor-pointer active:bg-primary-6",
        state === DISABLED && "bg-neutral-3 !text-neutral-6 cursor-not-allowed",
        fill && "flex-1 w-full"
      )}
      onClick={onClick ? onSubmit : undefined}
      type={type}
    >
      {(loading || isLoading) && (
        <div className="border-2 border-transparent border-t-white rounded-full w-4 h-4 animate-spin" />
      )}
      <span>{label}</span>
    </button>
  );
};

const CancelButton = ({
  label,
  state = NONE,
  onClick,
}: {
  label: string;
  state?: FormState;
  onClick: () => Promise<void> | void;
}) => {
  return (
    <button
      className={classNames(
        "text-white py-2 px-4 rounded font-semibold flex flex-row items-center space-x-2 justify-center h-[32px] text-sm",
        state === NONE && "text-primary-6 cursor-pointer hover:bg-primary-1",
        state === DISABLED && "bg-gray-200 cursor-not-allowed"
      )}
      onClick={onClick}
    >
      <span>{label}</span>
    </button>
  );
};

const DangerButton = ({
  label,
  state = NONE,
  onClick,
}: {
  label: string;
  state?: FormState;
  onClick: () => Promise<void> | void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const onDelete = useEvent(async () => {
    if (isLoading) return;
    if (state === DISABLED) return;
    setIsLoading(true);
    await onClick();
    setIsLoading(false);
  });
  return (
    <button
      className={classNames(
        "text-white py-2 px-4 rounded font-semibold flex flex-row items-center space-x-2 justify-center h-[32px] text-sm",
        state === NONE && "bg-danger-5 cursor-pointer hover:bg-danger-6 active:bg-danger-7",
        state === DISABLED && "bg-gray-200 cursor-not-allowed"
      )}
      onClick={onDelete}
    >
      {isLoading && (
        <div className="border-2 border-transparent border-t-white rounded-full w-4 h-4 animate-spin" />
      )}
      <span>{label}</span>
    </button>
  );
};

export const Form = {
  Text,
  Area,
  Search,
  SubmitButton,
  CancelButton,
  DangerButton,
};
