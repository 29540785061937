import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useMap } from "react-map-gl";
import { useActiveProject } from "src/hooks/useActiveProject";

export const MapUtilAreaOfInterestCenter = observer(() => {
  const project = useActiveProject();
  const map = useMap();

  useEffect(() => {
    if (!project.data?.areaOfInterest || !map.current) return;
    const lat = project.data.areaOfInterest.latitude;
    const lng = project.data.areaOfInterest.longitude;
    map.current?.setCenter([lng, lat]);
  }, [map, project.data?.areaOfInterest]);
  return null;
});
