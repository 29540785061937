import { Form, FormIntent, FormState } from "src/components/Form/Form";
import { useImmer } from "use-immer";
import { useRedirect } from "src/hooks/useRedirect";
import { useEffect, useMemo, useState } from "react";
import modulousLogo from "src/assets/tessa-logo.svg";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { useApi } from "src/contexts/api.context";
import { useEvent } from "src/hooks/useEvent";
import { useParams } from "react-router-dom";
import { PasswordResetModal } from "src/components/PasswordResetModal";

interface FormData {
  password: string;
  passwordConfirm: string;
}

export const ResetScreen = () => {
  const [showResetSuccess, setShowResetSuccess] = useState<boolean>(false);
  const [showLinkExpired, setShowLinkExpired] = useState<boolean>(false);
  const [showResetModal, setShowResetModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [invalidPasswordReset, setInvalidPasswordReset] = useState(false);
  const redirect = useRedirect();
  const { resetToken } = useParams<{ resetToken: string }>();
  const api = useApi();

  // Check to see if reset token has expired
  const resetTokenCheck = useEvent(async (path: { resetToken: string }) => {
    const valid = await api.isUserPasswordResetTokenValid(path);
    setShowLinkExpired(!valid);
  });

  const [form, updateForm] = useImmer<FormData>({
    password: "",
    passwordConfirm: "",
  });

  useEffect(() => {
    if (form) setInvalidPasswordReset(false);
    if (!resetToken) return;
    resetTokenCheck({ resetToken });
  }, [form, resetToken, resetTokenCheck]);

  const errors = useMemo(
    () => ({
      password: !form.password ? "" : null,
      passwordConfirm: form.password !== form.passwordConfirm ? "Passwords need to match" : null,
    }),
    [form.password, form.passwordConfirm]
  );
  const hasErrors = useMemo(() => Object.values(errors).some((v) => v !== null), [errors]);

  const passwordReset = useEvent(async (resetToken: string, payload: { password: string }) => {
    const result = await api.putUserNewPassword(resetToken, payload);

    return result;
  });

  return (
    <>
      <div className="flex flex-1 w-screen h-screen  bg-cover bg-no-repeat flex-col bg-neutral-2">
        <div className="w-screen h-[50px] bg-white flex items-center px-4">
          <img src={modulousLogo} alt="Tessa Logo" className="w-[100px] h-[26px]" />
        </div>

        <div className="justify-center flex flex-1">
          <div className="flex flex-col w-[1024px] bg-white items-center">
            <div className="flex flex-col px-4 py-8 space-y-6">
              {showLinkExpired ? (
                <div className="flex flex-col items-center space-y-6">
                  <div className=" text-2xl font-semibold">Link Expired</div>
                  <div className=" text-neutral-7 max-w-[400px] text-center">
                    For security reasons, password reset links expire after 24 hours. If you still
                    need to reset your password, you can{" "}
                    <button
                      className="text-primary-6 underline"
                      onClick={() => setShowResetModal(!showResetModal)}
                    >
                      request a new link.
                    </button>
                  </div>
                  <button
                    className="w-[150px] h-8 bg-primary-6 text-white flex items-center justify-center rounded"
                    onClick={async () => await redirect.home()}
                  >
                    Go to login page
                  </button>
                </div>
              ) : !showResetSuccess ? (
                <>
                  <div className="text-2xl font-semibold">Reset Password</div>
                  {invalidPasswordReset && <span className="text-red-100">Invalid password</span>}

                  <Form.Text
                    label="Password"
                    type="password"
                    value={form.password}
                    helperText={errors.password}
                    placeholder="Enter password"
                    intent={errors.password ? FormIntent.ERROR : FormIntent.NONE}
                    onChange={(v) =>
                      updateForm((draft) => {
                        draft.password = v;
                      })
                    }
                  />

                  <Form.Text
                    label="Confirm password"
                    type="password"
                    value={form.passwordConfirm}
                    helperText={errors.passwordConfirm}
                    placeholder="Enter confirm password"
                    intent={errors.passwordConfirm ? FormIntent.ERROR : FormIntent.NONE}
                    onChange={(v) =>
                      updateForm((draft) => {
                        draft.passwordConfirm = v;
                      })
                    }
                  />

                  <div className="justify-between flex flex-1">
                    <Form.SubmitButton
                      state={
                        hasErrors || loading || invalidPasswordReset
                          ? FormState.DISABLED
                          : FormState.NONE
                      }
                      label="Reset Password"
                      onClick={async () => {
                        try {
                          if (!resetToken) return;
                          setLoading(true);
                          await passwordReset(resetToken, { password: form.password });
                          setShowResetSuccess(!showResetSuccess);
                        } catch (e) {
                          setInvalidPasswordReset(true);
                        }
                        setLoading(false);
                      }}
                    />
                  </div>
                </>
              ) : (
                <div className="flex flex-col items-center space-y-6">
                  <CheckCircleIcon className="text-primary-5 w-16" />
                  <div className=" text-2xl font-semibold">Password Reset</div>
                  <div className=" text-neutral-7">Password Reset</div>
                  <button
                    className="w-[150px] h-8 bg-primary-6 text-white flex items-center justify-center rounded"
                    onClick={async () => await redirect.home()}
                  >
                    Login to TESSA
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {showResetModal && <PasswordResetModal isActive={() => setShowResetModal(!showResetModal)} />}
    </>
  );
};
