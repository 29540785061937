import { useMemo, useRef, useState } from "react";
import { ENV } from "src/constants/env";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import type { GeoPoint } from "src/types/GeoPoint";
import { useQuery } from "@tanstack/react-query";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { useBoolean } from "src/hooks/useBoolean";
import { Marker } from "react-map-gl";
import { ClockIcon } from "@heroicons/react/24/outline";
import { useEvent } from "src/hooks/useEvent";
import type { MapState } from "src/types/MapState";

interface SearchRawItem {
  id: string | null | undefined;
  place_name: string;
  place_type: string[];
  center: [number, number];
  bbox?: [number, number, number, number];
}
export interface Place {
  id: string | null | undefined;
  placeName: string;
  center: GeoPoint;
  placeType: string[];
  bbox?: [number, number, number, number];
}

interface Props {
  placesHistory: Place[];
  onPlacesHistoryChange: (places: Place[]) => void;
  mapState: MapState;
}

export const MapSearch = observer(function MapSearch(props: Props) {
  const rootRef = useRef<HTMLDivElement>(null);
  const showSuggestions = useBoolean(true);
  const [selectedSuggestionsIndex, setSeletedSuggestion] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const [text, setText] = useState(props.placesHistory[0]?.placeName ?? "");
  const placesQuery = useQuery({
    queryKey: ["places", text],
    staleTime: Infinity,
    queryFn: async () => (!!text ? fetchPlaces(text, ENV.MAPBOX_KEY) : []),
  });

  const updatePlace = useEvent((place: Place) => {
    inputRef.current?.blur();
    setText(place.placeName);
    props.onPlacesHistoryChange([place, ...props.placesHistory]);
    if (place.bbox) {
      props.mapState.fitBounds(place.bbox);
    } else {
      props.mapState.flyTo(place.center);
    }
  });

  const onTextUpdate = (value: string) => {
    setSeletedSuggestion(0);
    setText(value);
  };

  const place = props.placesHistory.at(0);
  const marker = place && (
    <Marker longitude={place.center.longitude} latitude={place.center.latitude} color="red" />
  );
  const historyIdSet = useMemo(
    () => new Set(props.placesHistory.map((p) => p.id)),
    [props.placesHistory]
  );

  const onRootKeyDown = (evt: React.KeyboardEvent) => {
    switch (evt.key) {
      case "ArrowUp": {
        setSeletedSuggestion(Math.max(0, selectedSuggestionsIndex - 1));
        break;
      }
      case "ArrowDown": {
        setSeletedSuggestion(Math.min(placesQuery.data?.length ?? 0, selectedSuggestionsIndex + 1));
        break;
      }
      case "Enter": {
        if (placesQuery.data) {
          updatePlace(placesQuery.data[selectedSuggestionsIndex]);
        }
        break;
      }
    }
  };

  const list = showSuggestions.isOn && !!placesQuery.data?.length && (
    <>
      <div className="border-t border-neutral-4 mx-2" />
      <div className="w-full flex flex-col">
        <ul className=" w-full pt-1">
          {placesQuery.data?.map((place, i) => (
            <ListItem
              key={place.id}
              place={place}
              selected={i === selectedSuggestionsIndex}
              inHistory={historyIdSet.has(place.id)}
              onClick={() => {
                showSuggestions.off();
                updatePlace(place);
              }}
            />
          ))}
        </ul>
      </div>
    </>
  );

  return (
    <div
      ref={rootRef}
      style={{ width: 448, left: "50%", top: 104, transform: `translate(-50%, 0)` }}
      className="bg-white shadow-md border border-black/10 rounded-md absolute focus-within:outline outline-primary-6 outline-1 text-body-1"
      onKeyDown={onRootKeyDown}
    >
      <div className="flex flex-col">
        <div className="px-2 relative flex flex-row space-x-1 py-[6px] items-center">
          <MagnifyingGlassIcon className="w-4 h-4 text-neutral-7 shrink-0" />
          <input
            ref={inputRef}
            className="h-[20px] flex-1 outline-none truncate caret-primary-6"
            autoFocus
            value={text}
            onBlur={showSuggestions.off}
            onFocus={showSuggestions.on}
            onChange={(e) => onTextUpdate(e.target.value)}
          />
        </div>
        {list}
      </div>
      {marker}
    </div>
  );
});

function ListItem(props: {
  place: Place;
  selected: boolean;
  inHistory: boolean;
  onClick: () => void;
}) {
  const icon = props.inHistory ? (
    <ClockIcon className="w-4 h-4 text-neutral-7 shrink-0" />
  ) : (
    <MagnifyingGlassIcon className="w-4 h-4 text-neutral-7 shrink-0" />
  );
  return (
    <li
      onMouseDown={(evt) => {
        evt.preventDefault();
      }}
      onClick={props.onClick}
      className={classNames(
        "px-2 w-full cursor-pointer py-1 flex flex-row space-x-1 hover:bg-primary-1 items-center",
        props.selected && "bg-primary-1"
      )}
    >
      {icon}
      <span className="flex-nowrap truncate">{props.place.placeName}</span>
    </li>
  );
}

async function fetchPlaces(value: string, token: string) {
  const results = await window.fetch(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?access_token=${token}`
  );
  const data: { features: SearchRawItem[] } = await results.json();
  return data.features.map((raw) => {
    const place: Place = {
      center: { longitude: raw.center[0], latitude: raw.center[1] },
      bbox: raw.bbox,
      id: raw.id,
      placeName: raw.place_name,
      placeType: raw.place_type,
    };
    return place;
  });
}
