import { Brief2PipeProps, FeWorldEditor, changeBoneFloors } from "@project-rouge/rg-aggregator";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useMap } from "react-three-map";
import { useEvent } from "src/hooks/useEvent";
import type { RgWorld } from "src/types/RgCorePackage";
import skyUrl from "src/assets/sky.hdr?url";
import type { Selection } from "src/types/Selection";
import type { World } from "src/types/World";
import type { MapState } from "src/types/MapState";
import { ModelVisibilityPreset } from "src/types/MapState";
import { ZoneBrief } from "src/types/ZoneBrief";

interface Props {
  design: World;
  readonly: boolean;
  selection: Selection;
  mapState: MapState;
  onDesignChange: (design: RgWorld) => void;
}

export const WorldEditor = observer(function WorldEditor(props: Props) {
  const rgWorld = props.design.rgWorld;
  const map = useMap();

  const enableKeyboardControls = useEvent(() => map?.keyboard.enable());
  const disableKeyboardControls = useEvent(() => map?.keyboard.disable());

  const aggProps = useMemo(
    () =>
      Brief2PipeProps({
        brief: props.design.rgBrief,
        start: 0,
        count: 1,
      })[0].aggProps,
    [props.design.rgBrief]
  );

  const onFloorChange = useEvent(
    ({ floors, boneId, buildingId }: { floors: number; boneId: string; buildingId: string }) => {
      if (!rgWorld) return;
      const result = changeBoneFloors<RgWorld>({
        obj: rgWorld,
        floors: Math.min(ZoneBrief.maximumFloorCount, floors),
        boneId,
        buildingId,
        aggProps,
      });
      props.onDesignChange(result.output.obj);
      props.selection.setBuildings([]);
    }
  );

  const onBuildingSelectionChange = useEvent((buildingId: string | null) => {
    props.selection.setBuildings(buildingId ? [buildingId] : []);
    if (!buildingId || !props.selection.zones.length) return;
    // if a building has been selected, clear any zone selection
    props.selection.clearZones();
  });

  const mapState = props.mapState;

  if (!rgWorld) return null;
  return (
    <FeWorldEditor
      skyUrl={skyUrl}
      floorLines={mapState.floorLevels.isOn}
      abstract={mapState.modelVisibility.preset === ModelVisibilityPreset.Abstract}
      layers={mapState.modelVisibility.layers}
      readonly={props.readonly}
      wingLevelsInput={LevelInput}
      obj={rgWorld}
      onFloorChange={onFloorChange}
      enableKeyboardControls={enableKeyboardControls}
      disableKeyboardControls={disableKeyboardControls}
      buildingId={props.selection.buildings.at(0)?.uuid ?? null}
      onBuildingSelectionChange={onBuildingSelectionChange}
      visibility={mapState.modelVisibility.visibility}
      aggProps={aggProps}
    />
  );
});

const LevelInput = (props: { value: number; onChange: (v: number) => void; min: number }) => {
  return (
    <div
      className="relative flex items-center shadow-md"
      onDoubleClick={(evt) => {
        evt.stopPropagation();
        evt.preventDefault();
      }}
    >
      <input
        className={classNames(
          // hide arrow for input number
          "[-moz-appearance:_textfield] [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none",
          "[&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none",
          // base
          "border border-neutral-5 text-neutral-8 pr-[62px] text-body-1 text-end",
          "rounded-sm w-[86px] h-[23px] p-0",
          // focus
          "focus:ring-[0px] focus:border-primary-6 "
        )}
        type="number"
        value={props.value}
        min={props.min}
        onChange={(e) => props.onChange(parseInt(e.target.value))}
      />
      <span className="absolute right-5 text-neutral-6 text-body-1">floors</span>
      <div className="absolute flex flex-col items-center justify-center right-0 flex-1 h-full py-[1px] pr-[1px]">
        <button
          type="button"
          onClick={() => props.onChange(props.value + 1)}
          className="hover:bg-green-100 hover:text-white flex flex-1 rounded-tr-[1px] text-neutral-8"
          onKeyDown={(evt) => {
            if (evt.key === "Enter") {
              evt.preventDefault();
            }
          }}
        >
          <IconChevronUp className=" w-[15px] h-[9.5px] " />
        </button>
        <button
          type="button"
          onClick={() => props.onChange(props.value - 1)}
          className="hover:bg-green-100 hover:text-white flex flex-1 rounded-br-[1px] text-neutral-8"
          onKeyDown={(evt) => {
            if (evt.key === "Enter") {
              evt.preventDefault();
            }
          }}
        >
          <IconChevronDown className="w-[15px] h-[9.5px]" />
        </button>
      </div>
    </div>
  );
};
