import { Cluster } from "src/components/Cluster";
import { useEvent } from "src/hooks/useEvent";
import { SelectTool } from "./components/Select.tool";
import { CreateTool } from "./components/Create.tool";
import { CutTool } from "./components/Cut.tool";
import { EdgeOffsetTool } from "./components/EdgeOffsetTool";
import { observer } from "mobx-react-lite";
import type { Brief } from "src/types/Brief";
import type { Selection } from "src/types/Selection";
import type { Zone } from "src/types/Zone";
import type { MapState } from "src/types/MapState";
import { ToolSelection } from "src/types/MapState";
import type { World } from "src/types/World";
import type { WorldUpdateType } from "./WorldUpdateType";

const SNAP_TOLERANCE_IN_PIXELS = 12;
const CLICK_TOLERANCE_IN_PIXELS = 10;
interface Props {
  world: World;
  selectedTool: ToolSelection;
  defaultBrief: Brief;
  geoOrigin: { lng: number; lat: number };
  selection: Selection;
  mapState: MapState;
  onSelectedToolCancel: () => void;
  onWorldUpdate?: (type?: WorldUpdateType) => void;
}

function isZoneRingsSame(a: Zone, b: Zone) {
  return a.outerRing.join() === b.outerRing.join() && a.offsets?.join() === b.offsets?.join();
}

export const ZoneEditor = observer(function ZoneEditor(props: Props) {
  const world = props.world;
  const saveDraft = useEvent((zones: Zone[], type?: WorldUpdateType) => {
    const updatedZones: Zone[] = [];
    for (const zone of zones) {
      const originalZone = world.zones.find((z) => z.id === zone.id);
      // its a new zone
      if (!originalZone) {
        updatedZones.push(zone);
        continue;
      }
      // its an existing zone with no changes. We want to keep all the original data including buildings
      if (isZoneRingsSame(zone, originalZone)) {
        updatedZones.push(originalZone);
        continue;
      }
      updatedZones.push(zone);
    }
    if (!world) return;

    if (new Set([...updatedZones, ...world.zones]).size === world.zones.length) return;
    world.replaceZones(updatedZones);
    props.onWorldUpdate?.(type);
  });

  const onGeoOriginChange = useEvent((geoOrigin: { lng: number; lat: number }) => {
    world.setGeoOrigin({
      longitude: geoOrigin.lng,
      latitude: geoOrigin.lat,
    });
  });

  if (!world) return null;

  const value: Zone[] = world.zones;

  return (
    <>
      <Cluster
        visible={
          props.selectedTool === ToolSelection.SEARCH_PLACE ||
          props.selectedTool === ToolSelection.SELECT
        }
      >
        <SelectTool
          selection={props.selection}
          localZones={value}
          snapTolerancePx={SNAP_TOLERANCE_IN_PIXELS}
          clickTolerancePx={CLICK_TOLERANCE_IN_PIXELS}
        />
      </Cluster>
      <Cluster visible={props.selectedTool === ToolSelection.CREATE}>
        <CreateTool
          defaultBrief={props.defaultBrief}
          localZones={value}
          snapTolerancePx={SNAP_TOLERANCE_IN_PIXELS}
          clickTolerancePx={CLICK_TOLERANCE_IN_PIXELS}
          onChange={saveDraft}
          onToolCancel={props.onSelectedToolCancel}
          geoOrigin={props.geoOrigin}
          onGeoOriginChange={onGeoOriginChange}
        />
      </Cluster>
      <Cluster visible={props.selectedTool === ToolSelection.CUT}>
        <CutTool
          clickTolerancePx={CLICK_TOLERANCE_IN_PIXELS}
          localZones={value}
          onChange={saveDraft}
          snapTolerancePx={SNAP_TOLERANCE_IN_PIXELS}
          onToolCancel={props.onSelectedToolCancel}
        />
      </Cluster>
      <Cluster visible={props.selectedTool === ToolSelection.EDGE_OFFSET}>
        <EdgeOffsetTool
          selection={props.selection}
          localZones={value}
          onChange={saveDraft}
          mapState={props.mapState}
          clickTolerancePx={CLICK_TOLERANCE_IN_PIXELS}
        />
      </Cluster>
    </>
  );
});
