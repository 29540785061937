import { type V3 } from "@project-rouge/rg-core";
import { useMemo, useState } from "react";
import { useWindowEvent } from "src/hooks/useWindowEvent";
import { useGetSnapVector, usePixelToMeterDistance } from "src/hooks/useSnapMouse";
import { IsVectorOnEdge } from "src/utils/IsVectorOnEdge";
import { Circle, Line } from "@react-three/drei";
import { MathUtils } from "three";
import { ZonePolygon, ZonePolygonType } from "src/components/ZonePolygon";
import { CutPolygons } from "src/utils/CutPolygons";
import { GetLinesSegementsIntersections } from "src/utils/GetLinesSegmentsIntersections";
import { SnapMouseCursor } from "./SnapMouseCursor";
import { observer } from "mobx-react-lite";
import type { Zone } from "src/types/Zone";

function IsVectorOnPolygonEdge(vector: V3, polygon: V3[], tolerance: number) {
  for (let vi = 0; vi < polygon.length - 1; vi++) {
    const edge: [V3, V3] = [polygon[vi], polygon[vi + 1]];
    const onPointOrEdge = IsVectorOnEdge(vector, edge, tolerance);
    if (onPointOrEdge) {
      return true;
    }
  }
  return false;
}

function GetLocalZonesPoints(localZones: Zone[]): V3[][] {
  return localZones.map(({ outerRing }) => outerRing);
}

export const CutFaceMode = observer(function CutFaceMode(props: {
  localZones: Zone[];
  snapTolerancePx: number;
  startPosition: V3;
  updatePolygons: (polygons: Zone[]) => void;
}) {
  const [cutPoints, setCutPoints] = useState<V3[]>([props.startPosition]);
  const getPoint = useGetSnapVector();
  const polygonsPoints = useMemo(() => GetLocalZonesPoints(props.localZones), [props.localZones]);
  useWindowEvent("mousedown", (event) => {
    const mouse = getPoint({
      event,
      snapEdge: true,
      snapVertex: true,
      tolerance: props.snapTolerancePx,
      polygons: polygonsPoints,
      strict: false,
    });
    const isOnEdge = polygonsPoints.some((polygon) => IsVectorOnPolygonEdge(mouse, polygon, 0.01));
    if (!isOnEdge) return setCutPoints((v) => [...v, mouse]);
    props.updatePolygons(CutPolygons(props.localZones, [...cutPoints, mouse]));
  });
  return (
    <>
      {props.localZones.map((zone) => (
        <ZonePolygon
          zone={zone}
          offsets={zone.offsets}
          key={zone.id}
          points={zone.outerRing}
          type={
            zone.zoneType === "buildable" ? ZonePolygonType.BUILDABLE : ZonePolygonType.EXCLUSION
          }
        />
      ))}
      <SnapMouseCursor
        localZones={props.localZones}
        snapTolerance={props.snapTolerancePx}
        fillColor={0x58588e}
        radiusInPx={6}
      />
      <CutLine
        cutPoints={cutPoints}
        polygons={polygonsPoints}
        snapTolerance={props.snapTolerancePx}
      />
    </>
  );
});

const CutLine = (props: { polygons: V3[][]; cutPoints: V3[]; snapTolerance: number }) => {
  const getMouse = useGetSnapVector();
  const [line, setLine] = useState<V3[]>([]);
  useWindowEvent("mousemove", () => {
    const mouse = getMouse({
      polygons: props.polygons,
      snapEdge: true,
      snapVertex: true,
      tolerance: props.snapTolerance,
      strict: false,
    });
    const lastPoint = props.cutPoints[props.cutPoints.length - 1];
    const line =
      lastPoint[0] === mouse[0] && lastPoint[1] === mouse[1] && lastPoint[2] === mouse[2]
        ? props.cutPoints
        : [...props.cutPoints, mouse];
    setLine(line.map(([x, y, z]) => [x, y, z] as V3));
  });

  const intersectPoints = useMemo(() => {
    return props.polygons.map((polygon) => GetLinesSegementsIntersections(line, polygon)).flat();
  }, [line, props.polygons]);
  const tolerance = usePixelToMeterDistance(props.snapTolerance);
  const size = tolerance * 0.5;
  const rotation: V3 = useMemo(() => [-MathUtils.DEG2RAD * 90, 0, 0], []);
  if (line.length < 2) return null;
  return (
    <>
      {intersectPoints.map((position, i) => (
        <Circle
          key={`${position.join()}${i}`}
          args={[size]}
          material-color={"hotpink"}
          position={position}
          rotation={rotation}
        />
      ))}
      <Line points={line} color={0xff0000} renderOrder={100} depthTest={false} />
    </>
  );
};
