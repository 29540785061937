import { makeAutoObservable } from "mobx";
import { UnitType } from "../../types/UnitType";
import type { DesignColumn } from "./RegisteredColumns";
import type { RowData } from "./RowData";
import { RegisteredColumns, SortDirection } from "./RegisteredColumns";
import type { Editor } from "src/types/Editor";
import { type TableConfig } from "src/hooks/useWorkspaceQuery";
import type { RegisteredColumnId } from "./RegisteredColumnId";
import { calculateValuePerMetrics } from "@project-rouge/service-cost-client/data/value-per-metric";
import type { GeneratedItem } from "src/types/GeneratedItem";

interface SortColumn {
  key: string;
  order: SortDirection;
}

export type Column = DesignColumn<RegisteredColumnId>;

export class TableStore {
  static createRowData(index: number, item: GeneratedItem, label?: string): RowData {
    const superstructure = calculateValuePerMetrics(
      item.sustainabilityMetrics.summary.superstructure,
      item.architecturalMetrics.ApartmentModuleQuantityTotal,
      item.architecturalMetrics.GIATotal
    ).sqm;

    const total = calculateValuePerMetrics(
      item.sustainabilityMetrics.summary.total,
      item.architecturalMetrics.ApartmentModuleQuantityTotal,
      item.architecturalMetrics.GIATotal
    ).sqm;

    const construction = calculateValuePerMetrics(
      item.costMetrics.summary.construction,
      item.architecturalMetrics.ApartmentModuleQuantityTotal,
      item.architecturalMetrics.GIATotal
    );

    const constructionPerArea = construction.sqm;

    const constructionPerUnit = construction.unit;
    return {
      id: crypto.randomUUID(),
      index,
      label: label ?? `Option ${index + 1}`,
      thumbnail: item.thumbnail,
      rgWorldZip: item.rgWorldZip,
      rgWorldHashKey: item.rgWorldHashKey,
      architecturalMetrics: item.architecturalMetrics,
      sustainabilityMetrics: {
        ...item.sustainabilityMetrics,
        perMetrics: {
          superstructure,
          total,
        },
      },
      costMetrics: {
        ...item.costMetrics,
        perMetrics: {
          constructionPerArea,
          constructionPerUnit,
        },
      },
    };
  }
  unitType = UnitType.Metric;
  sortedColumn: null | SortColumn = null;
  sortedIndex = -1;
  hoveredHashKey: null | string = null;
  config: TableConfig;
  private editor: Editor;
  constructor(editor: Editor, tableConfig: TableConfig) {
    this.editor = editor;
    this.config = tableConfig;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get columnsById() {
    return new Map(this.config.cols.map((col) => [col.id, col]));
  }
  get rows() {
    return this.finalData;
  }
  get pinnedColumns(): Column[] {
    return this.finalColumns.pinnedCols;
  }
  get unpinnedColumns(): Column[] {
    return this.finalColumns.unpinnedCols;
  }
  get selectedHashKey() {
    return this.editor.world?.rgWorldHashKey ?? null;
  }
  setColumnWidth(id: string, width: number) {
    const col = this.config.cols.find((col) => col.id === id);
    if (!col) return;
    col.width = width;
  }
  setHoverById(id: string, hovered: boolean) {
    const row = this.list.find((item) => item.id === id);
    if (!row) return;
    if (hovered) {
      this.hoveredHashKey = row.rgWorldHashKey;
    } else if (this.hoveredHashKey === row.rgWorldHashKey) {
      this.hoveredHashKey = null;
    }
  }
  setConfig(config: TableConfig) {
    this.config = config;
  }
  setUnit(unit: UnitType) {
    this.unitType = unit;
  }
  setSortedColumn(key: string, order = SortDirection.Asc) {
    this.sortedColumn = {
      key,
      order,
    };
  }
  toggleSortDirection() {
    if (this.sortedColumn) {
      this.sortedColumn.order =
        this.sortedColumn.order === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;
    }
  }
  get selectedRowIndex() {
    if (!this.selectedHashKey) return -1;
    const index = this.list.findIndex((design) => design.rgWorldHashKey === this.selectedHashKey);
    return index;
  }
  selectRowById(rowId: string) {
    const row = this.list.find((item) => item.id === rowId);
    if (!row) {
      return;
    }
    const item = this.editor.designGenerator.list.find(
      (item) => item.rgWorldHashKey === row.rgWorldHashKey
    );
    if (!item) return;
    this.editor.loadFromDesignGenerator(item);
  }

  selectRowByIndex(input: number) {
    const selectedId = this.list[input].id;
    this.selectRowById(selectedId);
  }

  get loadingRowsSize() {
    return this.editor.designGenerator.isRunning ? this.editor.designGenerator.pendingSize : 0;
  }

  get list(): RowData[] {
    const list: RowData[] = this.editor.designGenerator.list.map((item, index) => {
      return TableStore.createRowData(index, item);
    });
    return list;
  }
  get progress() {
    return this.list.length;
  }
  get canvasWorld() {
    return this.editor.world;
  }
  get columns() {
    // Create arrays for pinned and unpinned columns
    const pinnedCols: DesignColumn[] = [];
    const unpinnedCols: DesignColumn[] = [];

    for (let i = 0; i < this.config.cols.length; i++) {
      const colId = this.config.cols[i].id;

      const column = RegisteredColumns[colId] as DesignColumn<RegisteredColumnId>;
      if (i < this.config.pinnedLength) {
        pinnedCols.push(column);
      } else {
        unpinnedCols.push(column);
      }
    }

    return { pinnedCols, unpinnedCols };
  }
  get sortDirection() {
    return this.sortedColumn?.order ?? SortDirection.Asc;
  }
  get sortedData() {
    const id = this.sortedColumn?.key as RegisteredColumnId | undefined;
    if (!id) return this.list;
    const columnsById = new Map(
      [...this.columns.pinnedCols, ...this.columns.unpinnedCols].map((col) => [col.id, col])
    );
    const column = columnsById.get(id);
    if (!column) return this.list;
    const sortBy = column?.sortBy;
    if (!sortBy) return this.list;
    const sortedData = [...this.list].sort((a, b) =>
      sortBy(a, b, { sortedColumn: this.sortedColumn?.order ?? null })
    );
    return sortedData;
  }
  get finalColumns() {
    return this.columns;
  }
  get finalData() {
    return this.sortedData;
  }

  onColumnDragStart(e: React.DragEvent<HTMLDivElement>, index: number) {
    e.dataTransfer.setData("text/plain", index.toString());
  }

  onColumnDragOver(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
  }

  onColumnDrop(
    e: React.DragEvent<HTMLDivElement>,
    destinationIndex: number,
    destinationID: RegisteredColumnId
  ) {
    // Find which column type it is first pinned or unpinned
    const pinnedColumnsIndexItems = this.config.cols.slice(0, this.config.pinnedLength);
    const unpinnedColumnsIndexItems = this.config.cols.slice(this.config.pinnedLength);
    if (pinnedColumnsIndexItems.find((item) => item.id === destinationID)) {
      // Get the source index of the pinned item being dragged
      const sourceIndex = Number(e.dataTransfer.getData("text/plain"));
      const updatedItems = [...pinnedColumnsIndexItems];
      const [movedColumn] = updatedItems.splice(sourceIndex, 1);
      updatedItems.splice(destinationIndex, 0, movedColumn);
      this.config = {
        cols: [...updatedItems, ...unpinnedColumnsIndexItems],
        pinnedLength: this.config.pinnedLength,
        sortedColumnID: "",
        sortDirection: SortDirection.Asc,
      };
    } else {
      // Get the source index of the unpinned item being dragged
      const sourceIndex = Number(e.dataTransfer.getData("text/plain"));
      const updatedItems = [...unpinnedColumnsIndexItems];
      const [movedColumn] = updatedItems.splice(sourceIndex, 1);
      updatedItems.splice(destinationIndex, 0, movedColumn);
      this.config = {
        cols: [...pinnedColumnsIndexItems, ...updatedItems],
        pinnedLength: this.config.pinnedLength,
        sortedColumnID: "",
        sortDirection: SortDirection.Asc,
      };
    }
  }

  onColumnPin(columnId: string) {
    const id = this.config.cols.find((col) => col.id === columnId)?.id;

    // Get the index of the column clicked
    const getColumnsIndex = this.config.cols.findIndex((col) => col.id === columnId);

    // Check to see if the clicked item falls in the pinned index
    const pinnedColumnsIndexItems = this.config.cols.slice(0, this.config.pinnedLength);
    if (pinnedColumnsIndexItems.find((item) => item.id === id)) {
      const pinnedItems = pinnedColumnsIndexItems.slice(0, this.config.pinnedLength);
      const updatedPinnedItems = [...pinnedItems];
      const [movedPinnedColumn] = updatedPinnedItems.splice(getColumnsIndex, 1);

      // Move the item to the end of the array
      updatedPinnedItems.push(movedPinnedColumn);

      // Combine updated pinned items with the rest of the column order
      const newColumnOrder = [
        ...updatedPinnedItems,
        ...this.config.cols.slice(this.config.pinnedLength),
      ];
      this.setConfig({
        cols: newColumnOrder,
        pinnedLength: this.config.pinnedLength - 1,
        sortedColumnID: "",
        sortDirection: SortDirection.Asc,
      });
    } else {
      // If not check to see what the current length of pinned columns is + 1 for the new item
      const updatedItems = [...this.config.cols];
      const [movedColumn] = updatedItems.splice(getColumnsIndex, 1);
      updatedItems.splice(this.config.pinnedLength, 0, movedColumn);

      this.setConfig({
        cols: updatedItems,
        pinnedLength: this.config.pinnedLength + 1,
        sortedColumnID: "",
        sortDirection: SortDirection.Asc,
      });
    }
  }

  onColumnSort(columnId: string) {
    if (columnId === this.sortedColumn?.key) {
      this.toggleSortDirection();
    } else {
      this.setSortedColumn(columnId);
    }
  }
}
