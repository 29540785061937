import { useState } from "react";

type ToggleProps = {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
};

export const Toggle = ({ label, value, onChange }: ToggleProps): JSX.Element | null => {
  const [isChecked, setIsChecked] = useState(value);

  const handleClick = () => {
    setIsChecked(!isChecked);
    onChange(!isChecked);
  };

  return (
    <div className="relative flex flex-col items-left justify-center overflow-hidden">
      <div className="flex">
        <span className="font-semibold pr-2">{label}</span>
        <label className="inline-flex relative items-center mr-5 cursor-pointer">
          <input type="checkbox" className="sr-only peer" checked={isChecked} readOnly />
          <div
            onClick={handleClick}
            className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary-6"
          ></div>
        </label>
      </div>
    </div>
  );
};
