import type { Unit } from "./UnitSelect";
import { useMemo } from "react";
import { sqmToSqft } from "src/hooks/meterToFeet";
import { formatOrdinals } from "./formatOrdinals";
import { Table } from "./Table";
import type { ArchitecturalMetrics } from "src/types/ArchitecturalMetrics";

export function AreaMeasurementsTable(props: { metrics: ArchitecturalMetrics; unit: Unit }) {
  const { metrics, unit } = props;
  const rows = useRows(metrics, unit);
  return (
    <div>
      <Table.Sticky>
        <Table.Head label="Area measurements" variant="section" />

        <Table.Row>
          <Table.Head label="Level" variant="header" position="start" />
          <Table.Head label="GEA" variant="header" symbol={unit} position="end" />
          <Table.Head label="GIA" variant="header" symbol={unit} position="end" />
          <Table.Head label="NIA" variant="header" symbol={unit} position="end" />
        </Table.Row>
      </Table.Sticky>
      {rows.map((level) => (
        <Table.Row key={level.label}>
          <Table.Col>
            <Table.Label label={level.label} variant="cell" />
          </Table.Col>
          <Table.Col>
            <Table.Cell value={level.gea} precision={2} variant="cell" />
          </Table.Col>
          <Table.Col>
            <Table.Cell value={level.gia} precision={2} variant="cell" />
          </Table.Col>
          <Table.Col>
            <Table.Cell value={level.nia} precision={2} variant="cell" />
          </Table.Col>
        </Table.Row>
      ))}
      <Table.Row>
        <Table.Col>
          <Table.Label label="Total" variant="total" />
        </Table.Col>
        <Table.Col>
          <Table.Cell value={metrics.GEATotal} precision={2} variant="total" />
        </Table.Col>
        <Table.Col>
          <Table.Cell value={metrics.GIATotal} precision={2} variant="total" />
        </Table.Col>
        <Table.Col>
          <Table.Cell value={metrics.NIATotal} precision={2} variant="total" />
        </Table.Col>
      </Table.Row>
    </div>
  );
}

function useRows(metrics: ArchitecturalMetrics, unit: Unit) {
  return useMemo(
    () =>
      metrics.GEAPerLevel.map((gea, index) => {
        const gia = metrics.GIAPerLevel[index];
        const nia = metrics.NIAPerLevel[index];
        return {
          label: `${formatOrdinals(index)} Floor`,
          gea: unit === "sqm" ? gea : sqmToSqft(gea),
          gia: unit === "sqm" ? gia : sqmToSqft(gia),
          nia: unit === "sqm" ? nia : sqmToSqft(nia),
        };
      }),
    [metrics.GEAPerLevel, metrics.GIAPerLevel, metrics.NIAPerLevel, unit]
  );
}
