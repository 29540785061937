import { type V3 } from "@project-rouge/rg-core";
import { useState } from "react";
import { useLiveRef } from "src/hooks/useLiveRef";
import { useImmer } from "use-immer";
import { useMapMouseEvent } from "../../../useMapEvent";
import { useGetSnapVector } from "src/hooks/useSnapMouse";
import { IsVectorEqual } from "src/utils/IsVectorEqual";
import { ZonePolygon, ZonePolygonType } from "src/components/ZonePolygon";
import { useWindowEvent } from "src/hooks/useWindowEvent";
import { IsVectorOnEdge } from "src/utils/IsVectorOnEdge";
import { IsVectorOnVertex } from "src/utils/IsVectorOnVertex";
import { useMapCursor } from "src/hooks/useMapCursor";
import { ClipLocalZones } from "src/utils/ClipLocalZones";
import { type Brief } from "src/types/Brief";
import { PolygonEdges } from "src/components/PolygonEdges";
import { PolygonFace } from "src/components/PolygonFace";
import { PolygonVertex } from "src/components/PolygonVertex";
import { RewindPolygonAnticlockwise } from "src/utils/RewindPolygonAnticlockwise";
import type { ZoneType } from "src/types/Zone";
import { Measurement } from "src/components/Measurement";
import { observer } from "mobx-react-lite";
import { Zone } from "src/types/Zone";

interface CreateModeProps {
  localZones: Zone[];
  snapTolerancePx: number;
  startPosition: V3;
  defaultBrief: Brief;
  updatePolygons: (polygons: Zone[]) => void;
}

function LocalZonesPoints(zonePolygons: Zone[]): Array<V3[]> {
  return zonePolygons.map(({ outerRing }) => outerRing);
}

function useSnapMouse(
  polygons: V3[][],
  snapVertex: boolean,
  snapEdge: boolean,
  tolerance: number,
  defaultPosition: V3
): V3 {
  const [position, setPosition] = useState<V3>(defaultPosition);
  const getPoint = useGetSnapVector();

  useWindowEvent("mousemove", (event) => {
    const point = getPoint({
      event,
      snapEdge,
      snapVertex,
      tolerance,
      polygons,
      strict: false,
    });

    setPosition(point);
  });

  return position;
}

function IsPolygonClosed(polygon: V3[]): boolean {
  const last = polygon.at(-1);
  const first = polygon.at(0);
  return first && last ? IsVectorEqual(first, last) : false;
}

export const CreateMode = observer((props: CreateModeProps) => {
  const [draft, updateDraft] = useImmer<V3[]>([props.startPosition]);
  const draftRef = useLiveRef(draft);
  const localPoints = LocalZonesPoints(props.localZones);
  const polygonsPoints = [...localPoints, draft];

  const mouse = useSnapMouse(
    polygonsPoints,
    true,
    true,
    props.snapTolerancePx,
    props.startPosition
  );

  useMapCursor("crosshair");
  useMapMouseClickEvent(() => {
    const isClosed = draft ? IsPolygonClosed([...draft, mouse]) : false;
    if (isClosed && draftRef.current) {
      const clipBoundary = RewindPolygonAnticlockwise([...draftRef.current, mouse]);
      const cliped = ClipLocalZones(props.localZones, clipBoundary);
      const boundary = AddVertexIntersectsOnLines(clipBoundary, cliped);
      if (boundary.length < 3) return;
      const zone = new Zone();
      zone.setOuterRing(boundary);
      props.updatePolygons([...cliped, zone]);
    }
    updateDraft((draft) => void draft.push(mouse));
  });

  const lastPoint = draft.at(-1) ?? null;

  return (
    <>
      {props.localZones.map((zone) => (
        <ZonePolygon
          zone={zone}
          key={zone.id}
          points={zone.outerRing}
          type={GetZonePolygonType(zone.zoneType)}
          offsets={zone.offsets}
        />
      ))}
      {lastPoint && <Measurement start={lastPoint} end={mouse} />}
      <DraftPolygons points={[...draft, mouse]} />
    </>
  );
});

function GetZonePolygonType(zoneType: ZoneType) {
  return zoneType === "buildable" ? ZonePolygonType.BUILDABLE : ZonePolygonType.EXCLUSION;
}

const DraftPolygons = ({ points }: { points: V3[] }) => {
  const radius = 6.5 / 2;
  const border = 1.5;
  const solidLine: V3[] = points.slice(0, -1);
  const lastPoint = points.at(-1);
  const aPoint = points.at(-2);
  const ghostLine = aPoint && lastPoint && ([aPoint, lastPoint] as [V3, V3]);
  return (
    <>
      <PolygonFace points={points} opacity={0.4} fill={0xbabade} />
      <PolygonEdges
        points={solidLine}
        offsetPx={radius + border}
        color={0x58588e}
        lineWidthPx={2}
        opacity={1}
      />
      {points.map((point, i) => (
        <PolygonVertex
          key={i}
          position={point}
          radiusPx={radius}
          fillColor={point === points.at(-1) ? 0x58588e : 0xffffff}
          borderWidthPx={border}
          borderColor={0x58588e}
        />
      ))}
      {ghostLine && <GhostLine line={ghostLine} offset={radius} />}
    </>
  );
};

const GhostLine = ({ line: [start, end], offset }: { line: [V3, V3]; offset: number }) => {
  const dashSize = 3;
  const gapSize = 1;
  if (IsVectorEqual(start, end)) return null;
  return (
    <PolygonEdges
      points={[start, end]}
      offsetPx={offset}
      lineWidthPx={2}
      dashed
      color={0x58588e}
      dashSizePx={dashSize}
      gapSizePx={gapSize}
    />
  );
};

function AddVertexIntersectsOnLines(target: V3[], localZones: Zone[]): V3[] {
  const lines: Array<V3[]> = [];
  const localZonesPoints = localZones.map(({ outerRing }) => outerRing).flat();
  for (let i = 0; i < target.length - 1; i++) {
    const a = target[i];
    const b = target[i + 1];
    const line: [V3, V3] = [a, b];
    const updatedLine = [...line];
    localZonesPoints.forEach((point) => {
      if (IsVectorOnVertex(point, a, 0.01) || IsVectorOnVertex(point, b, 0.01)) return;
      if (
        IsVectorOnEdge(point, line, 0.01) &&
        updatedLine.every((a) => !IsVectorOnVertex(point, a, 0.01))
      ) {
        updatedLine.splice(1, 0, point);
      }
    });
    lines.push(updatedLine);
  }
  const points: V3[] = [];
  lines.forEach((line) => {
    const cloned = [...line];
    cloned.pop();
    points.push(...cloned);
  });
  points.push(target[target.length - 1]);
  return points;
}

function useMapMouseClickEvent(cb: () => void) {
  const cbRef = useLiveRef(cb);
  useMapMouseEvent("mousedown", (mapDownEvent) => {
    const mouseup = (upEvent: MouseEvent) => {
      if (IsClick(mapDownEvent.originalEvent, upEvent)) cbRef.current();
      window.removeEventListener("mouseup", mouseup);
    };
    window.addEventListener("mouseup", mouseup);
  });
}

function IsClick<T extends { clientX: number; clientY: number; button: number }>(
  a: T,
  b: T,
  tolerance = 10
) {
  if (a.button !== 0 || b.button !== 0) return false;
  const distance = Math.sqrt(
    Math.abs(b.clientX - a.clientX) ** 2 + Math.abs(b.clientY - a.clientY) ** 2
  );
  return distance <= tolerance * window.devicePixelRatio;
}
