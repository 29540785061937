import { Html } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { observer } from "mobx-react-lite";

export const Compass2D = observer(() => {
  const width = 0.1;
  const length = 2.35;
  const mapOrientation = { bearing: 0, pitch: 1.5 };

  return (
    <div className="absolute w-16 h-16 z-10 overflow-visible top-2 right-2 rounded-full bg-white/75 border-black/40 border">
      <div className="w-[64px] h-[64px] bg-white/0 rounded-full overflow-hidden">
        <Canvas linear>
          <group rotation-x={mapOrientation.pitch} rotation-y={mapOrientation.bearing}>
            <mesh position={[length, 0, 0]} rotation={[0, 0, (-90 * Math.PI) / 180]}>
              <coneGeometry args={[width * 2.25, 0.25, 10]} />
              <meshBasicMaterial color={[0, 0, 0]} />
            </mesh>
            <mesh scale={[length, width, width]} position={[length / 2, 0, 0]}>
              <boxGeometry />
              <meshBasicMaterial color={[0, 0, 0]} />
            </mesh>
            <mesh position={[0, 0, -length]} rotation={[(-90 * Math.PI) / 180, 0, 0]}>
              <coneGeometry args={[width * 2.25, 0.25, 10]} />
              <meshBasicMaterial color={[0.14, 0.56, 0.45]} />
            </mesh>
            <mesh scale={[width, width, -length]} position={[0, 0, -length / 2]}>
              <boxGeometry />
              <meshBasicMaterial color={[0.14, 0.56, 0.45]} />
            </mesh>
            <Html position={[0, 0, -length - 0.75]} center sprite>
              <h1 className="bg-red-100/0 text-[9px] text-dark-green-100 font-semibold">N</h1>
            </Html>
          </group>
        </Canvas>
      </div>
    </div>
  );
});
