import { Plane } from "@react-three/drei";
import type { ThreeEvent } from "@react-three/fiber";
import { useEvent } from "src/hooks/useEvent";
import { IsVectorOnFace } from "src/utils/IsVectorOnFace";
import { MathUtils } from "three";
import { PreviewMode } from "./Preview.mode";
import { observer } from "mobx-react-lite";
import type { Selection } from "src/types/Selection";
import type { Zone } from "src/types/Zone";

export const SelectTool = observer(
  (props: {
    localZones: Zone[];
    snapTolerancePx: number;
    clickTolerancePx: number;
    selection: Selection;
  }) => {
    const onClick = useEvent((e: ThreeEvent<MouseEvent>) => {
      if (e.delta > props.clickTolerancePx) return;
      const selectedZone = props.localZones.find((zone) =>
        IsVectorOnFace(e.point.toArray(), zone.outerRing)
      );
      if (selectedZone) {
        if (e.shiftKey) {
          if (props.selection.hasZone(selectedZone.id)) {
            props.selection.setZones(
              props.selection.zones.filter((zone) => zone.id !== selectedZone.id).map((id) => id.id)
            );
          } else {
            props.selection.addZone(selectedZone.id);
          }
        } else {
          props.selection.setZones([selectedZone.id]);
        }
      } else {
        props.selection.clearZones();
      }
    });
    return (
      <>
        <PreviewMode
          localZones={props.localZones}
          hoverEnabled={true}
          showVertexes={false}
          selection={props.selection}
          selectEnabled={true}
        />
        <Plane
          args={[1000, 1000]}
          rotation={[-90 * MathUtils.DEG2RAD, 0, 0]}
          onClick={onClick}
          visible={false}
        />
      </>
    );
  }
);
