import { getDefaultMix } from "@project-rouge/rg-core";
import { Dataset } from "src/constants/Dataset";
import type { Unit } from "../components/BriefPanel/UnitMixSection/Unit";

const defaultUnits = new Map(getDefaultMix(Dataset).map((unit) => [unit.label, unit]));
function getApartmentNames(id: string) {
  return defaultUnits.get(id)?.apartmentNames ?? [];
}

export const UnitsRegister: Record<string, Unit> = deepFreeze({
  // disabled for now as it has no apartment names
  // "1B1P": {
  //   id: "1B1P",
  //   label: "1B1P",
  //   unitColor: `#F57EB6`,
  //   textColor: "#fff",
  //   apartmentNames: getApartmentNames("1B1P"),
  //   locked: false,
  //   enabled: true,
  //   value: 0,
  // },
  "1B2P": {
    id: "1B2P",
    label: "1B2P",
    unitColor: `#D06381`,
    textColor: "#fff",
    apartmentNames: getApartmentNames("1B2P"),
    locked: false,
    enabled: true,
    value: 0,
  },
  "2B3P": {
    id: "2B3P",
    label: "2B3P",
    unitColor: `#4D9CDD`,
    textColor: "#fff",
    apartmentNames: getApartmentNames("2B3P"),
    locked: false,
    enabled: true,
    value: 0,
  },
  "2B4P": {
    id: "2B4P",
    label: "2B4P",
    unitColor: "#4DB58E",
    textColor: "#000",
    apartmentNames: getApartmentNames("2B4P"),
    locked: false,
    enabled: true,
    value: 0,
  },
  "3B5P": {
    id: "3B5P",
    label: "3B5P",
    unitColor: "#FFD87D",
    textColor: "#000",
    apartmentNames: getApartmentNames("3B5P"),
    locked: false,
    enabled: true,
    value: 0,
  },
  "3B6P": {
    id: "3B6P",
    label: "3B6P",
    unitColor: "#FF9562",
    textColor: "#000",
    apartmentNames: getApartmentNames("3B6P"),
    locked: false,
    enabled: true,
    value: 0,
  },
});

function deepFreeze<T>(obj: T): T {
  const propNames = Object.getOwnPropertyNames(obj);
  Object.freeze(obj);
  for (const propName of propNames) {
    const propValue = (obj as never)[propName];
    if (typeof propValue === "object" && propValue !== null && !Object.isFrozen(propValue)) {
      deepFreeze(propValue);
    }
  }
  return obj;
}
