import { type V3 } from "@project-rouge/rg-core";
import { type ThreeEvent } from "@react-three/fiber";
import { observer } from "mobx-react-lite";
import { MathUtils, Shape, Vector2, DoubleSide, Path } from "three";

const FACE_POS_Y = 0.01;
const ROTATION_UP: V3 = [MathUtils.DEG2RAD * 90, 0, 0];

export const PolygonFace = observer(
  (props: {
    points: V3[];
    fill: number | `#${string}`;
    opacity: number;
    holes?: V3[][];
    y?: number;
    onPointerEnter?: (evt: ThreeEvent<PointerEvent>) => void;
    onPointerLeave?: (evt: ThreeEvent<PointerEvent>) => void;
    onPointerDown?: (event: ThreeEvent<PointerEvent>) => void;
  }) => {
    const shapeWithHoles = getShapeWithHoles(props.points, props.holes);
    const position = getPosition(props.y);

    return (
      <group
        rotation={ROTATION_UP}
        position={position}
        onPointerEnter={props.onPointerEnter}
        onPointerLeave={props.onPointerLeave}
        onPointerDown={props.onPointerDown}
      >
        <mesh>
          <shapeGeometry attach="geometry" args={[shapeWithHoles]} />
          <meshBasicMaterial
            color={props.fill}
            side={DoubleSide}
            opacity={props.opacity}
            transparent={props.opacity !== 1}
            toneMapped={false}
            depthTest={true}
          />
        </mesh>
      </group>
    );
  }
);
function getPosition(y: number = FACE_POS_Y) {
  return [0, y, 0] as V3;
}
function getShapeWithHoles(points: V3[], holes?: V3[][]) {
  const shape = new Shape(points.map(([x, , z]) => new Vector2(x, z)));
  holes?.forEach((hole) => {
    const path = new Path(hole.map(([x, , z]) => new Vector2(x, z)));
    shape.holes.push(path);
  });
  return shape;
}
