import { useId } from "react";
import { Layer, Source } from "react-map-gl";
import { observer } from "mobx-react-lite";
import type { MapState } from "src/types/MapState";

interface Props {
  mapState: MapState;
}

export const ExtrusionLayer = observer((props: Props) => {
  const id = useId();
  if (!props.mapState.layerSiteContext.isOn) return null;
  return (
    <Source id={id} type="vector" url="mapbox://mapbox.mapbox-streets-v8">
      <Layer
        id={id}
        type="fill-extrusion"
        source-layer="building"
        minzoom={15}
        filter={[
          "all",
          ["!=", ["get", "type"], "building:part"],
          ["==", ["get", "underground"], "false"],
        ]}
        paint={{
          "fill-extrusion-color": "#656565",
          "fill-extrusion-height": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            ["get", "height"],
          ],
          "fill-extrusion-base": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            ["get", "min_height"],
          ],
          "fill-extrusion-opacity": 1.0,
        }}
      />
    </Source>
  );
});
