import type { V3 } from "@project-rouge/rg-core";

export function GetPolygonCentroid(polygon: V3[]): V3 {
  let sumX = 0;
  let sumY = 0;
  let sumZ = 0;
  const numVertices = polygon.length;

  for (const point of polygon) {
    sumX += point[0];
    sumY += point[1];
    sumZ += point[2];
  }

  const centroidX = sumX / numVertices;
  const centroidY = sumY / numVertices;
  const centroidZ = sumZ / numVertices;

  return [centroidX, centroidY, centroidZ];
}
