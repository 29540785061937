import type { ReactNode } from "react";
import { useRef, useState } from "react";
import { PodiumSection } from "./PodiumSection";
import { CustomServiceAreaSection } from "./CustomServiceAreasSection";
import { UnitMixSection } from "./UnitMixSection/UnitMixSection";
import { FacadeFinishMaterialSection } from "./FacadeFinishMaterialSection";
import { observer } from "mobx-react-lite";
import { useEvent } from "src/hooks/useEvent";
import type { Zone } from "src/types/Zone";

export const ZoneBrief = observer(function ZoneBrief(props: { zone: Zone; onUpdate: () => void }) {
  return (
    <div className="border-b border-neutral-4">
      <div className="flex flex-col sticky top-0 bg-white z-10 border-t border-neutral-4">
        <Header zone={props.zone} />
        <Divider />
      </div>
      <div className="flex flex-col border-neutral-4 bg-white overflow-hidden relative">
        <Section>
          <ResidentialFloor zone={props.zone} onUpdate={props.onUpdate} />
        </Section>
        <Divider />
        <Section>
          <PodiumSection zone={props.zone} onUpdate={props.onUpdate} />
        </Section>
        <Divider />
        <Section>
          <CustomServiceAreaSection zone={props.zone} />
        </Section>
        <Divider />
        <Section>
          <UnitMixSection zone={props.zone} onUpdate={props.onUpdate} />
        </Section>
        <Divider />
        <Section>
          <FacadeFinishMaterialSection />
        </Section>
        {props.zone.locked && <div className="w-full h-full bg-white/70 absolute" />}
      </div>
    </div>
  );
});

const ResidentialFloor = observer(function ResidentialFloor(props: {
  zone: Zone;
  onUpdate: () => void;
}) {
  const onChange = useEvent((value: number) => {
    props.zone.brief.setResidentialFloorCount(value);
    props.onUpdate();
  });
  return (
    <NumberInput
      label="Residential floors"
      value={props.zone.brief.residentialFloorCount}
      onChange={onChange}
      helperText={
        <span className="flex flex-row space-x-1 tiny-label text-neutral-7 justify-end pr-[2px]">
          <span>{props.zone.brief.totalBuildingHeight}</span>
          <span className="text-neutral-6">m</span>
        </span>
      }
    />
  );
});

function Section(props: { children: ReactNode }) {
  return <div className="px-3 py-2">{props.children}</div>;
}

const NumberInput = observer(function NumberInput(props: {
  label: string;
  value: number;
  helperText?: React.ReactNode;
  onChange: (value: number) => void;
}) {
  const [draft, setDraft] = useState<string | null>(null);
  const onSubmit = () => {
    if (draft === null) return;
    const value = Number(draft);
    if (!isNaN(value)) {
      props.onChange(value);
    }
    setDraft(null);
  };
  const ref = useRef<HTMLInputElement>(null);
  return (
    <div className="flex flex-col">
      <div className="flex flex-row text-body-1 text-neutral-7 h-[24px] items-center">
        <span className="flex-1">{props.label}</span>
        <input
          ref={ref}
          className="border border-neutral-5 text-body-1 px-[6px] text-center w-[84px] rounded-sm 
           focus:outline-none focus:ring-1.5 focus:ring-neutral-5"
          value={draft ?? props.value}
          onInput={(evt) => setDraft(evt.currentTarget.value)}
          onBlur={onSubmit}
          onKeyDown={(evt) => {
            if (evt.key === "Enter") ref.current?.blur();
          }}
        />
      </div>
      {props.helperText && <>{props.helperText}</>}
    </div>
  );
});

function Divider() {
  return <div className="border-b border-neutral-3" />;
}

function Header(props: { zone: Zone }) {
  return (
    <div className="px-3 py-2 items-center flex">
      <span className="flex-1 text-neutral-8 text-subtitle-2">{props.zone.label}</span>
    </div>
  );
}
