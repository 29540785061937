import { useActiveProject } from "src/hooks/useActiveProject";
import { useInitials } from "./useInitials";
import type { UseBoolean } from "src/hooks/useBoolean";
import classNames from "classnames";

export function ProjectName(props: { expanded: UseBoolean }) {
  const projectQuery = useActiveProject();
  const project = projectQuery.data;
  const name = project?.name ?? "";
  const initials = useInitials(name);
  const label = props.expanded.isOff ? initials : name;
  return (
    <div
      className={classNames(
        "text-subtitle-1 text-neutral-8 flex w-full flex-1 transition-all",
        props.expanded.isOn && "px-3 justify-start",
        props.expanded.isOff && "justify-center"
      )}
    >
      <span className="h-full">{label}</span>
    </div>
  );
}
