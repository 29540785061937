import { useMemo } from "react";
import { type RgBuilding } from "src/types/RgCorePackage";
import { type LegendItem, viableNonResidentialAreas, viableResidentialAreas } from "./StylePresets";
import { RG_TYPE, type TechnicalSpace } from "@project-rouge/rg-core";
import { ListBulletIcon } from "@heroicons/react/20/solid";

const viableNonResidentialAreaTypes = [
  RG_TYPE.Core,
  RG_TYPE.Corridor,
  RG_TYPE.Entrance,
  RG_TYPE.TechnicalSpace,
  RG_TYPE.EmptySpace,
];

export function Legend(props: { uniBuilding: RgBuilding; selectedLevel: number }) {
  const { residentialAreas, nonResidentialAreas } = useMemo(() => {
    const items = ExtractLegendItems(props.uniBuilding, props.selectedLevel);
    return items;
  }, [props.selectedLevel, props.uniBuilding]);
  return (
    <div
      onMouseDown={(evt) => {
        evt.preventDefault();
        evt.stopPropagation();
      }}
      className="absolute right-0 py-16 px-2 h-fit overflow-hidden align-middle top-0"
    >
      <div className="overflow-y-auto h-fit align-middle border border-neutral-5 rounded-md bg-white px-2 shadow-md">
        <div className="py-3 flex flex-1 justify-left items-center space-x-2 text-gray-200">
          <ListBulletIcon className="w-4 h-4" />
          <span className="font-semibold">Legend</span>
        </div>
        {residentialAreas.length !== 0 && (
          <div className="my-1 font-bold text-sm">RESIDENTIAL AREAS</div>
        )}
        {residentialAreas.map(({ label, color }) => (
          <div
            key={label}
            className={`h-8 my-1 px-4 rounded text-neutral-8 leading-8`}
            style={{ backgroundColor: HexToRgba(color, 0.3) }}
          >
            {label}
          </div>
        ))}
        {nonResidentialAreas.length !== 0 && (
          <div className="my-1 font-bold text-sm">NON-RESIDENTIAL AREAS</div>
        )}
        {nonResidentialAreas.map(({ label, color }) => (
          <div
            key={label}
            className={`h-8 my-1 px-4 rounded text-neutral-8 leading-8`}
            style={{ backgroundColor: HexToRgba(color) }}
          >
            {label}
          </div>
        ))}
      </div>
    </div>
  );
}

function ExtractLegendItems(uniBuilding: RgBuilding, selectedLevel: number) {
  let residentialAreas: LegendItem[] = [];
  let nonResidentialAreas: LegendItem[] = [];

  const residentialAreasLabels: string[] = [];
  const nonResidentialAreasLabels: string[] = [];
  for (let i = 0; i < uniBuilding.children.length - 1; i++) {
    if (!uniBuilding.children[i].rg && selectedLevel) continue;
    if (uniBuilding.data.levels.length === 0) continue;
    if (
      uniBuilding.children[i].pos[1] !== uniBuilding.data.levels.at(selectedLevel)?.floorWorldBottom
    )
      continue;
    if (uniBuilding.children[i].type === RG_TYPE.Apartment) {
      // Residential Areas : Apartments
      const label = uniBuilding.children[i].name.slice(-6).slice(0, -2);
      if (!residentialAreasLabels.includes(label)) residentialAreasLabels.push(label);
    } else if (viableNonResidentialAreaTypes.includes(uniBuilding.children[i].type)) {
      let label = CapitalizeFirstLetter(uniBuilding.children[i].name);
      if (label === "Podium empty space") label = "Commercial Spaces";
      if (uniBuilding.children[i].type === RG_TYPE.TechnicalSpace) {
        label = (uniBuilding.children[i] as TechnicalSpace).data.instance.spaceClass ?? "";
      }
      if (!nonResidentialAreasLabels.includes(label)) nonResidentialAreasLabels.push(label);
    }
  }

  residentialAreas = viableResidentialAreas.filter((item) =>
    residentialAreasLabels.includes(item.label)
  );

  nonResidentialAreas = viableNonResidentialAreas.filter((item) =>
    nonResidentialAreasLabels.includes(item.label)
  );

  return { residentialAreas, nonResidentialAreas };
}

function HexToRgba(hex: string, opacity: number = 1): string {
  // Convert the hex value to decimal values for red, green, and blue channels
  const red = parseInt(hex.slice(1, 3), 16);
  const green = parseInt(hex.slice(3, 5), 16);
  const blue = parseInt(hex.slice(5, 7), 16);

  // Combine the decimal values with the opacity value to create the RGBA value
  return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
}
function CapitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
