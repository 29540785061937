import { ScissorsIcon } from "@heroicons/react/20/solid";
import { Button, ButtonIntent, ButtonState } from "src/components/Button";
import type { MapState } from "src/types/MapState";
import { ToolSelection } from "src/types/MapState";

export function CutButton({ mapState }: { mapState: MapState }) {
  return (
    <Button
      title="Cut"
      Icon={ScissorsIcon}
      onClick={() => mapState.selectedTool.update(ToolSelection.CUT)}
      intent={ButtonIntent.SELECT}
      state={
        mapState.selectedTool.value.id === ToolSelection.CUT ? ButtonState.ACTIVE : ButtonState.NONE
      }
    />
  );
}
