import { MapIcon } from "@heroicons/react/20/solid";
import { ChecboxItem } from "./CheckboxItem";
import { ListToolButton } from "./ListToolButton";
import { Select } from "./Select";
import type { MapState } from "src/types/MapState";
import { observer } from "mobx-react-lite";

export const MapViewButton = observer(function MapViewButton({ mapState }: { mapState: MapState }) {
  return (
    <ListToolButton Icon={MapIcon}>
      <div className="space-y-4 pt-2">
        <div className="flex flex-1 justify-center items-center space-x-2 text-gray-200">
          <MapIcon className="w-4 h-4" />
          <span className="font-semibold">Map View</span>
        </div>
        <Select
          items={mapState.viewStyle.options}
          onChange={mapState.viewStyle.update}
          selected={mapState.viewStyle.value.id}
        />
        <ul className="space-y-1">
          <ChecboxItem
            label="Site Context"
            selected={mapState.layerSiteContext.isOn}
            onClick={mapState.layerSiteContext.toggle}
          />
          <ChecboxItem
            label="Topography lines"
            selected={mapState.layerTopographyLines.isOn}
            onClick={mapState.layerTopographyLines.toggle}
          />
          {/* disabled until https://app.clickup.com/t/86933zwq3 */}
          {/* <ChecboxItem
            label="Site boundary"
            selected={mapState.layerSiteBoundary.isOn}
            onClick={mapState.layerSiteBoundary.toggle}
          /> */}
          <ChecboxItem
            label="Zones"
            selected={mapState.layerZones.isOn}
            onClick={mapState.layerZones.toggle}
          />
        </ul>
      </div>
    </ListToolButton>
  );
});
