import { useEffect } from "react";
import { useMap } from "react-three-map";

export function useMapCursor(cursor: string) {
  const mapRef = useMap();
  useEffect(() => {
    if (!mapRef) return;
    mapRef.getCanvas().style.cursor = cursor;
  }, [cursor, mapRef]);
}
