import { type FunctionComponent, useState, useLayoutEffect, useRef } from "react";
import { InformationCircleIcon, Cog6ToothIcon } from "@heroicons/react/24/outline";
import { useApi } from "src/contexts/api.context";
import { useQueryClient } from "@tanstack/react-query";
import { useEvent } from "src/hooks/useEvent";
import { useCostSettings } from "src/hooks/useCostSettings";
import { Modal } from "src/components/Modal";
import type { UseBoolean } from "src/hooks/useBoolean";
import { useBoolean } from "src/hooks/useBoolean";
import { type CostSettings } from "src/types/CostSettings";
import { useActiveParams } from "src/hooks/useActiveParams";
import { Cluster } from "../Cluster";
import classNames from "classnames";
import {
  ChevronRightIcon,
  PencilSquareIcon,
  TrashIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/20/solid";
import { ContextMenu, Intent } from "../ContextMenu";
import { DeleteProjectModal } from "../DeleteProjectModal";
import { UpdateProjectModal } from "../UpdateProjectModal";
import { useActiveProject } from "src/hooks/useActiveProject";
import { observer } from "mobx-react-lite";
import { makeAutoObservable } from "mobx";

interface CostSettingsModalProps {
  onClose: () => void;
  onSave: () => void;
  costSettings: CostSettings;
}

class InputNumber {
  private static getValue(value: string | number) {
    if (value === "") return null;
    const parsed = parseFloat(Number(value).toFixed(1));
    return isNaN(parsed) ? null : Math.max(0, Math.min(100, parsed));
  }

  original: number;
  private draft: null | string | number = null;
  private decimalPlaces = 1;
  constructor(value: number) {
    this.original = value;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get isInvalidDraft() {
    if (this.draft === null) return false;
    return InputNumber.getValue(this.draft) === null;
  }

  /** returns value in formated percentage */
  get value(): string {
    if (this.draft === null) return `${(this.original * 100).toFixed(this.decimalPlaces)}`;
    return this.draft.toString();
  }

  get hasDraft() {
    return this.value !== `${(this.original * 100).toFixed(this.decimalPlaces)}`;
  }

  get data(): number {
    const value = this.draft ? InputNumber.getValue(this.draft) : null;
    if (value !== null) return value / 100;
    return this.original;
  }

  setDraft(value: string) {
    this.draft = value;
  }
}

class Form {
  contingency: InputNumber;
  ohp: InputNumber;
  prelims: InputNumber;
  constructor(data: CostSettings) {
    this.contingency = new InputNumber(data.contingency);
    this.ohp = new InputNumber(data.ohp);
    this.prelims = new InputNumber(data.prelims);
    makeAutoObservable(this);
  }

  get data() {
    return {
      contingency: this.contingency.data,
      ohp: this.ohp.data,
      prelims: this.prelims.data,
    };
  }

  get isInvalid() {
    return (
      this.contingency.isInvalidDraft || this.ohp.isInvalidDraft || this.prelims.isInvalidDraft
    );
  }

  get hasDraft() {
    return this.contingency.hasDraft || this.ohp.hasDraft || this.prelims.hasDraft;
  }
}

const CostSettingsModal = observer(function CostSettingsModal({
  onClose,
  costSettings,
  onSave,
}: CostSettingsModalProps) {
  const { projectId } = useActiveParams();
  const api = useApi();
  const [form] = useState(() => new Form(costSettings));
  const updateCostSettings = useEvent(async () => {
    if (!projectId) return;
    await api.patchCostingSettings(projectId, form.data);
    onSave();
  });

  const disabled = form.isInvalid || !form.hasDraft;

  return (
    <Modal.Card onClose={onClose}>
      <Modal.Header label="Cost Settings" onClose={onClose} />
      <div className="flex flex-col w-[560px] space-y-4 text-sm">
        <div className="bg-info-2 w-full h-14 px-3 p-2 flex flex-row space-x-2">
          <InformationCircleIcon className="w-6 fill-info-6 text-info-2" />
          <p className="text-info-7 font-semibold">
            Adjustments to cost settings will be reflected across all Design Options within this
            project.
          </p>
        </div>
        <div className="flex border-neutral-4 border-x border-t flex-col">
          <CostSettingsBlock
            title="Prelims"
            description="applied to Construction cost"
            value={form.prelims.value}
            onChange={form.prelims.setDraft}
          />

          <CostSettingsBlock
            title="Contingency"
            description="applied to Construction and Prelims costs"
            value={form.contingency.value}
            onChange={form.contingency.setDraft}
          />

          <CostSettingsBlock
            title="OH&P Allowance"
            description="applied to Construction, Prelims and Contingency costs"
            value={form.ohp.value}
            onChange={form.ohp.setDraft}
          />
        </div>
      </div>
      <Modal.Actions>
        <Modal.ActionCancelButton onClick={onClose} label="Cancel" />
        <Modal.ActionPrimaryButton onClick={updateCostSettings} label="Save" disabled={disabled} />
      </Modal.Actions>
    </Modal.Card>
  );
});

export function SettingsButton(props: { expand: UseBoolean }) {
  const projectQuery = useActiveProject();
  const project = projectQuery.data;
  const settingsModal = useBoolean();
  const deleteModal = useBoolean();
  const projectEdit = useBoolean();
  const costSettingsQuery = useCostSettings(project?.id);
  const queryClient = useQueryClient();
  const optionsVisible = useBoolean();

  useLayoutEffect(() => {
    window.addEventListener("mousedown", optionsVisible.off);
    return () => window.removeEventListener("mousedown", optionsVisible.off);
  }, [optionsVisible.off]);

  const ref = useRef<HTMLButtonElement>(null);
  const label = props.expand.isOn ? (
    <span className="text-subtitle-2 text-primary-7 flex-1 flex">Settings</span>
  ) : null;
  const chevron = props.expand.isOn ? <ChevronRightIcon className="w-4 flex" /> : null;

  return (
    <>
      <button
        ref={ref}
        className={classNames(
          "text-neutral-7 rounded cursor-pointer flex flex-1 items-center space-x-2 h-[28px] min-h-[28px] justify-center transition-all hover:text-primary-7 hover:bg-primary-2",
          props.expand.isOn && "px-[8px]"
        )}
        onClick={optionsVisible.on}
        title="Cost settings"
      >
        <Cog6ToothIcon className="w-4 h-4 shrink-0 text-primary-7" />
        {label}
        {chevron}
      </button>
      <ContextMenu visible={optionsVisible.isOn} onHide={optionsVisible.off} refRoot={ref.current}>
        <ContextMenu.Card>
          <ContextMenu.Button
            label="Edit Project"
            Icon={PencilSquareIcon}
            onClick={() => {
              optionsVisible.off();
              projectEdit.on();
            }}
          />
          <ContextMenu.Button
            label="Cost settings"
            Icon={CurrencyDollarIcon}
            onClick={() => {
              optionsVisible.off();
              settingsModal.on();
            }}
          />
          <ContextMenu.Separator />
          <ContextMenu.Button
            intent={Intent.DANGER}
            label="Delete project"
            Icon={TrashIcon}
            onClick={() => {
              optionsVisible.off();
              deleteModal.on();
            }}
          />
        </ContextMenu.Card>
      </ContextMenu>
      <Cluster visible={deleteModal.isOn}>
        <DeleteProjectModal
          projectName={project?.name ?? ""}
          projectId={project?.id ?? ""}
          onClose={deleteModal.off}
        />
      </Cluster>
      <Modal.Portal isOpen={projectEdit.isOn}>
        <UpdateProjectModal
          onClose={() => {
            projectQuery.refetch();
            projectEdit.off();
          }}
          projectName={project?.name ?? ""}
          projectId={project?.id ?? ""}
          projectNotes={project?.notes ?? ""}
        />
      </Modal.Portal>
      <Modal.Portal isOpen={settingsModal.isOn}>
        {costSettingsQuery.data && (
          <CostSettingsModal
            onClose={settingsModal.off}
            costSettings={costSettingsQuery.data}
            onSave={() => {
              settingsModal.off();
              queryClient.invalidateQueries({
                // we need to invalidate the cost metrics query because it depends on the cost settings.
                // "cost-metrics" is the query key of the cost metrics query.
                predicate: (query) => query.queryKey.includes("cost-metrics"),
              });
            }}
          />
        )}
      </Modal.Portal>
    </>
  );
}

type CostSettingsBlockProps = {
  title: string;
  description: string;
  value: string;
  onChange: (value: string) => void;
};
const CostSettingsBlock: FunctionComponent<CostSettingsBlockProps> = (
  props: CostSettingsBlockProps
) => {
  return (
    <div className="flex border-b border-neutral-4 h-[77px] px-4 py-3 flex-col gap-2">
      <p className="text-neutral-8">{props.title}</p>
      <div className="flex gap-2 items-center">
        <div className="w-[77px] h-6 border border-neutral-4 rounded flex items-center">
          <input
            className="w-full h-5 text-neutral-8 text-end border-none text-sm px-1 focus-visible:outline-none"
            value={props.value}
            onChange={(evt) => props.onChange(evt.target.value)}
          />
          <span className="text-neutral-6 pr-1">%</span>
        </div>

        <p className="text-neutral-6">{props.description}</p>
      </div>
    </div>
  );
};
