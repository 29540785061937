import { useState } from "react";
import type { MetricsCalculations } from "src/hooks/useMetricsCalculationsQuery";
import { useMetricsCalculationsQuery } from "src/hooks/useMetricsCalculationsQuery";
import { BlockTags } from "../BlockTags";
import { Unit, UnitSelector } from "../UnitSelect";
import { BlocksInfo } from "../BlocksInfo";
import { SummaryTable } from "./SummaryTable";
import { ElementGroupTable } from "./ElementGroupTable";
import { ALL_BLOCKS, type BlockId } from "../BlockId";
import { GetTables } from "./getTables";
import { observer } from "mobx-react-lite";
import type { World } from "src/types/World";
import { useActiveParams } from "src/hooks/useActiveParams";

export const SustainabilityMetricsSection = observer(({ design }: { design: World }) => {
  const projectId = useActiveParams().projectId ?? "";
  const carbon = useMetricsCalculationsQuery(projectId, design);
  if (carbon.isLoading) {
    return (
      <>
        <div className="w-full h-full overflow-hidden flex flex-col items-center justify-center">
          <div className="animate-spin inline-block w-11 h-11 border-[3px] border-current border-t-transparent text-blue-600 rounded-full" />
        </div>
      </>
    );
  }

  if (!carbon.data) return null;

  return <Content design={design} metricsCalculations={carbon.data} />;
});

function Content(props: { design: World; metricsCalculations: MetricsCalculations }) {
  const [unit, setUnit] = useState(Unit.sqm);
  const [blockId, setBlockId] = useState<BlockId>(ALL_BLOCKS);
  // const buildings = useBuildings(props.design);
  const selectedBuilding =
    (blockId === ALL_BLOCKS ? null : props.design.buildings[blockId]) ?? null;
  // const selectedBuilding = (blockId === ALL_BLOCKS ? null : buildings[blockId]) ?? null;

  const tables = GetTables(props.design, selectedBuilding, props.metricsCalculations);
  return (
    <div className="w-full h-full overflow-hidden flex flex-col pt-3">
      <BlockTags blockId={blockId} design={props.design} onChange={setBlockId} />
      <UnitSelector onUnitChange={setUnit} unit={unit} />

      <div className="flex flex-1 flex-col scrollbar-none overflow-scroll">
        <BlocksInfo design={props.design} unit={unit} selectedBuilding={selectedBuilding} />
        <SummaryTable
          selectedBuilding={selectedBuilding}
          metricsCalculations={props.metricsCalculations}
          unit={unit}
          design={props.design}
        />
        {tables.map((table) => (
          <ElementGroupTable key={table.id} table={table} selectedBuilding={selectedBuilding} />
        ))}
      </div>
    </div>
  );
}
