import { useMemo, useState } from "react";
import { ZONE_POLYGON_STYLE, ZonePolygon, ZonePolygonType } from "src/components/ZonePolygon";
import { MapAnnotation } from "src/components/MapAnnotation";
import { useEvent } from "src/hooks/useEvent";
import { GetVectorLerpV2 } from "src/utils/GetVectorLerp";
import { V2ToV3 } from "src/utils/V2ToV3";
import { GetLinesFromPolygon } from "src/utils/GetLinesFromPolygon";
import { type V2 } from "@project-rouge/rg-core";
import { V3ToV2 } from "src/utils/V3ToV2";
import { PolygonEdges } from "src/components/PolygonEdges";
import { useBoolean } from "src/hooks/useBoolean";
import { type ThreeEvent } from "@react-three/fiber";
import { useProjectScreenToPlanePoint } from "src/hooks/useProjectScreenToPlanePoint";
import { useMapClickOrDrag } from "src/hooks/useMapClickOrDrag";
import { IsVectorOnFace } from "src/utils/IsVectorOnFace";
import { Cluster } from "src/components/Cluster";
import { PolygonVertexSquare } from "src/components/PolygonVertexSquare";
import { PolygonVertex } from "src/components/PolygonVertex";
import { useMapCanvasEvent } from "src/hooks/useMapCanvasEvent";
import { ZoneType } from "src/types/Zone";
import { observer, useLocalObservable } from "mobx-react-lite";
import type { Selection } from "src/types/Selection";
import type { Zone } from "src/types/Zone";
import { OffsetInput } from "./OffsetInput";
import type { MapState } from "src/types/MapState";
import { ToolSelection } from "src/types/MapState";
import { useMap } from "react-three-map";

export const EdgeOffsetTool = observer(function EdgeOffsetTool(props: {
  localZones: Zone[];
  clickTolerancePx: number;
  onChange: (localZone: Zone[]) => void;
  selection: Selection;
  mapState: MapState;
}) {
  const draft = useLocalObservable(() => props.localZones.map((z) => z.clone(false)));
  const [hoveredZoneId, setHoveredZoneId] = useState("");
  const zones = draft ?? props.localZones;

  const selectedZone = getSelectedZone(props.selection, zones);

  const hoveredZone = useMemo(
    () => zones.find((zone) => zone.id === hoveredZoneId),
    [hoveredZoneId, zones]
  );

  useMapClickOrDrag({
    clickTolerancePx: props.clickTolerancePx,
    onClick: ({ mapDownVectorPosition }) => {
      const zone = zones.find((zone) => IsVectorOnFace(mapDownVectorPosition, zone.outerRing));
      if (!zone) return;
      props.selection.setZones([zone.id]);
    },
  });

  useMapCanvasEvent("keydown", (evt) => {
    switch (true) {
      case evt.key === "Enter":
      case evt.key === "Escape": {
        props.mapState.selectedTool.update(ToolSelection.SELECT);
        return;
      }
    }
  });

  const onOffsetChange = useEvent((offsets: number[], submit: boolean) => {
    if (!selectedZone) return;
    const zone = zones.find((zone) => zone.id === selectedZone.id);
    if (!zone) return;
    zone.setOffsets(offsets);
    if (submit) {
      props.onChange(zones);
    }
  });

  return (
    <>
      {zones.map((zone) => (
        <ZonePolygon
          zone={zone}
          key={zone.id}
          points={zone.outerRing}
          type={GetZonePolygonType(zone.zoneType)}
          offsets={zone.offsets}
          hoverEnabled
          showVertexes={false}
          selectEnabled
          showOffsetEdges
          onHoverChange={(hovered) => setHoveredZoneId(hovered ? zone.id : "")}
        />
      ))}
      {selectedZone && selectedZone.zoneType === ZoneType.buildable && (
        <OffsetsIndicators zone={selectedZone} readonly={false} onOffsetChange={onOffsetChange} />
      )}
      <Cluster
        visible={
          hoveredZone?.zoneType === ZoneType.buildable && hoveredZone.id !== selectedZone?.id
        }
      >
        {hoveredZone && <OffsetsIndicators zone={hoveredZone} readonly />}
      </Cluster>
    </>
  );
});

function getSelectedZone(selection: Selection, zones: Zone[]) {
  const originalId = selection.zones.find((zone) => zone.zoneType === ZoneType.buildable)?.id;
  return zones.find((zone) => zone.id === originalId);
}

const OffsetsIndicators = observer(function OffsetsIndicators(props: {
  zone: Zone;
  readonly: boolean;
  onOffsetChange?: (offsets: number[], submit: boolean) => void;
}) {
  const lines = usePolygonLines(props.zone);
  const [synced, setSynced] = useState(() => new Set(props.zone.offsets).size === 1);
  const dragging = useBoolean();
  const zoneOffsets = useMemo<number[]>(
    () => props.zone.offsets ?? Array.from({ length: lines.length }).map(() => 0),
    [lines.length, props.zone.offsets]
  );
  const onDragEnd = useEvent(() => {
    dragging.off();
    props.onOffsetChange?.(zoneOffsets, true);
  });
  if (!lines) return null;
  if (!zoneOffsets) return null;
  return (
    <>
      {lines.map((line, i) => (
        <OffsetIndicator
          key={`${line.join()}_${i}`}
          offset={zoneOffsets[i]}
          sourceLine={line}
          readonly={props.readonly}
          onDragStart={dragging.on}
          onDragEnd={onDragEnd}
          dragging={dragging.isOn}
          onOffsetChange={(value, submit) => {
            const offsets = [...zoneOffsets];
            if (synced) {
              offsets.fill(value);
            } else {
              offsets[i] = value;
            }
            props.onOffsetChange?.(offsets, submit);
          }}
          synced={synced}
          onSyncClick={() => {
            const sync = !synced;
            setSynced(sync);
            if (!sync) return;
            const offset = zoneOffsets[i];
            const offsets = [...zoneOffsets].fill(offset);
            props.onOffsetChange?.(offsets, true);
          }}
        />
      ))}
    </>
  );
});

const OffsetIndicator = observer(function OffsetIndicator(props: {
  onOffsetChange?: (value: number, submit: boolean) => void;
  sourceLine: [V2, V2];
  offset: number;
  readonly: boolean;
  synced: boolean;
  dragging: boolean;
  onSyncClick: () => void;
  onDragStart: () => void;
  onDragEnd: () => void;
}) {
  const line = props.sourceLine;
  const sourcePoint = useMemo(() => {
    const sourcePointV2 = GetVectorLerpV2(line[0], line[1], 0.5);
    const sourcePoint = V2ToV3(sourcePointV2);
    return sourcePoint;
  }, [line]);
  const lineAngle = useMemo(() => GetLineRightAngle(line), [line]);
  const targetPoint = useMemo(() => {
    const point = V3ToV2(sourcePoint);
    return V2ToV3(MovePointAlongLine(point, props.offset, lineAngle));
  }, [lineAngle, props.offset, sourcePoint]);
  const hovered = useBoolean();
  const getPoint = useProjectScreenToPlanePoint();
  const map = useMap();
  const selected = useBoolean();

  const onPointerDown = useEvent(() => {
    if (props.readonly) return;
    map?.dragPan.disable();
    map?.dragRotate.disable();
    props.onDragStart();
    selected.on();
    const move = (evt: MouseEvent) => {
      evt.stopPropagation();
      evt.preventDefault();
      const mouse = V3ToV2(getPoint().toArray());
      const parallelDistance = GetParallelDistance(props.sourceLine, mouse);
      props.onOffsetChange?.(Math.max(parallelDistance, 0), false);
    };
    const up = () => {
      selected.off();
      props.onDragEnd();
      map?.dragPan.enable();
      map?.dragRotate.enable();
      window.removeEventListener("mousemove", move);
      window.removeEventListener("mouseup", up);
    };
    window.addEventListener("mousemove", move);
    window.addEventListener("mouseup", up);
  });

  const onPointerEnter = useEvent((evt: ThreeEvent<PointerEvent>) => {
    if (props.readonly) return;
    evt.stopPropagation();
    evt.nativeEvent.stopPropagation();
    hovered.on();
  });
  return (
    <group
      onPointerEnter={onPointerEnter}
      onPointerDown={onPointerDown}
      onPointerLeave={hovered.off}
    >
      <Cluster visible={!props.readonly && (!props.dragging || selected.isOn)}>
        <PolygonVertexSquare
          position={targetPoint}
          rotation={lineAngle + Math.PI / 2}
          fillColor={hovered.isOn || selected.isOn ? 0x00ff00 : 0xffffff}
          sizePx={8}
          clickTargetSizePx={24}
          opacity={1}
          y={0.02}
          borderWidthPx={1}
        />
      </Cluster>
      <Cluster visible={props.readonly || (props.dragging && selected.isOff)}>
        <PolygonVertex
          radiusPx={2}
          fillColor={0x00000}
          borderColor={0x000000}
          borderWidthPx={0}
          position={targetPoint}
        />
      </Cluster>
      <PolygonEdges
        points={[sourcePoint, targetPoint]}
        lineWidthPx={ZONE_POLYGON_STYLE.BUILDIABLE_OFFSET.NONE.BORDER_WIDTH}
        color={ZONE_POLYGON_STYLE.BUILDIABLE_OFFSET.NONE.BORDER_COLOR}
        opacity={ZONE_POLYGON_STYLE.BUILDIABLE_OFFSET.NONE.BORDER_ALPHA}
        dashSizePx={ZONE_POLYGON_STYLE.BUILDIABLE_OFFSET.NONE.DASH_SIZE_PX}
        gapSizePx={ZONE_POLYGON_STYLE.BUILDIABLE_OFFSET.NONE.GAP_SIZE_PX}
        dashed
        y={0}
        depthTest={true}
      />
      <MapAnnotation position={targetPoint}>
        <OffsetInput
          value={props.offset}
          onChange={(val) => props.onOffsetChange?.(val, true)}
          enabledEvents={!props.dragging}
          disabled={props.readonly}
          synced={props.synced}
          onSyncClick={props.onSyncClick}
        />
      </MapAnnotation>
    </group>
  );
});

function MovePointAlongLine(point: V2, distance: number, radian: number): V2 {
  return [point[0] + distance * Math.cos(radian), point[1] + distance * Math.sin(radian)];
}

function GetLineRightAngle(line: [V2, V2]) {
  return Math.atan2(line[1][1] - line[0][1], line[1][0] - line[0][0]) + (90 * Math.PI) / 180;
}

function usePolygonLines(localZone?: Zone) {
  return useMemo(
    () => GetLinesFromPolygon(V3ToV2(localZone?.outerRing ?? [])),
    [localZone?.outerRing]
  );
}

function GetParallelDistance(line: [V2, V2], point: V2): number {
  const [linePoint1, linePoint2] = line;
  const A = linePoint2[1] - linePoint1[1];
  const B = linePoint1[0] - linePoint2[0];
  const C = linePoint1[1] * linePoint2[0] - linePoint1[0] * linePoint2[1];
  const numerator = A * point[0] + B * point[1] + C;
  const denominator = Math.sqrt(A * A + B * B);
  const result = numerator / denominator;
  return -result;
}

function GetZonePolygonType(type: ZoneType): ZonePolygonType {
  return type === "buildable" ? ZonePolygonType.BUILDABLE : ZonePolygonType.EXCLUSION;
}
