import { makeAutoObservable, observable, toJS } from "mobx";
import type { RgPlot } from "./RgCorePackage";
import type { PlotData, RgGeneric, V3 } from "@project-rouge/rg-core";
import { RG_TYPE } from "@project-rouge/rg-core";
import { Building } from "./Building";
import type { Zone } from "./Zone";

interface PlotConfig {
  rgPlot?: RgPlot;
}

export class Plot implements RgGeneric<RG_TYPE.Plot, Zone, Building, PlotData> {
  alpha: number;
  children: Building[] = [];
  data: PlotData;
  name: string;
  type = RG_TYPE.Plot as const;
  uuid: string;
  pos: V3;
  rot: V3;
  size: V3;
  anchor: V3;
  color: string;
  hash?: string | undefined;
  rg = true as const;
  modulousId: string | undefined;
  templateUuid: string | undefined;
  parent: Zone | undefined;
  constructor(config?: PlotConfig) {
    // const plot = createRgObject(DefaultPlot, data);
    this.alpha = config?.rgPlot?.alpha ?? 1;
    // this.children = config?.rgPlot?.children.map((building) => new Building(building));
    // this.children = [];
    config?.rgPlot?.children.forEach((rgBuilding) =>
      this.addBuilding(new Building({ rgBuilding }))
    );
    this.data = config?.rgPlot?.data ?? CreateDefaultData();
    this.name = config?.rgPlot?.name ?? "";
    this.type = config?.rgPlot?.type ?? RG_TYPE.Plot;
    this.uuid = config?.rgPlot?.uuid ?? crypto.randomUUID();
    this.pos = config?.rgPlot?.pos ?? [0, 0, 0];
    this.rot = config?.rgPlot?.rot ?? [0, 0, 0];
    this.size = config?.rgPlot?.size ?? [0, 0, 0];
    this.anchor = config?.rgPlot?.anchor ?? [0, 0, 0];
    this.color = config?.rgPlot?.color ?? "#cccccc";
    this.modulousId = config?.rgPlot?.modulousId;
    this.templateUuid = config?.rgPlot?.templateUuid;
    makeAutoObservable(this, {
      data: observable.ref,
      pos: observable.ref,
      rot: observable.ref,
      size: observable.ref,
      anchor: observable.ref,
    });
  }
  get buildings(): Building[] {
    return this.children;
  }
  get rgParentlessSnapshot(): RgPlot {
    const snapshot: RgPlot = {
      parent: undefined,
      data: toJS(this.data),
      pos: toJS(this.pos),
      rot: toJS(this.rot),
      size: toJS(this.size),
      children: [],
      rg: this.rg,
      hash: this.hash,
      alpha: this.alpha,
      name: this.name,
      type: this.type,
      uuid: this.uuid,
      anchor: toJS(this.anchor),
      color: this.color,
      modulousId: this.modulousId,
      templateUuid: this.templateUuid,
    };
    snapshot.children = this.children.map((child) => {
      const v = child.rgParentlessSnapshot;
      v.parent = snapshot;
      return v;
    });
    return snapshot;
  }
  addBuilding(building: Building): void {
    if (this.children.includes(building)) return;
    building.parent = this;
    this.children.push(building);
  }
  removeBuilding(building: Building | Building[]): void {
    const buildingsSet = new Set(Array.isArray(building) ? building : [building]);
    for (const building of buildingsSet) {
      const childIndex = this.children.findIndex((child) => child === building);
      if (childIndex < 0) continue;
      const child = this.children[childIndex];
      child.parent = undefined;
      this.children.splice(childIndex, 1);
    }
  }
  setId(id: string): void {
    this.uuid = id;
  }
}
function CreateDefaultData(): PlotData {
  return {
    polygon: [],
    zonePolyIndex: 0,
  };
}
