import { useEffect, useRef } from "react";
import { useEvent } from "./useEvent";

export function useDebounce(delay: number) {
  const fn = useRef<() => void>();
  useEffect(() => {
    const int = window.setInterval(() => {
      if (!fn.current) return;
      const callback = fn.current;
      fn.current = undefined;
      callback();
    }, delay);
    return () => window.clearInterval(int);
  }, [delay]);
  return useEvent((callback: () => void) => {
    fn.current = callback;
  });
}
