import { type V2, type V3 } from "@project-rouge/rg-core";

export function GetVectorLerp([x1, , y1]: V3, [x2, , y2]: V3, t: number): V3 {
  const x = (1 - t) * x1 + t * x2;
  const y = (1 - t) * y1 + t * y2;
  return [x, 0, y];
}

export function GetVectorLerpV2([x1, y1]: V2, [x2, y2]: V2, t: number): V2 {
  const x = (1 - t) * x1 + t * x2;
  const y = (1 - t) * y1 + t * y2;
  return [x, y];
}
