import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useBoolean } from "src/hooks/useBoolean";
import { useWindowEvent } from "src/hooks/useWindowEvent";

export interface SelectInputOption {
  id: string;
  label: string;
}

export function SelectInput(props: {
  label: string;
  options: SelectInputOption[];
  selected: string;
  onChange: (id: string) => void;
}) {
  const selected = props.options.find((option) => option.id === props.selected) ?? props.options[0];
  const optionVisible = useBoolean();
  useWindowEvent("click", optionVisible.off);
  return (
    <div className="flex flex-row text-body-1 text-neutral-7 h-[24px] items-center">
      <span className="flex-1">{props.label}</span>
      <div
        className="relative w-[136px] border border-neutral-5 flex flex-row rounded-sm"
        onClick={(evt) => evt.stopPropagation()}
      >
        <div className="flex flex-1 flex-row px-[6px] cursor-pointer" onClick={optionVisible.on}>
          <span className="flex-1">{selected.label}</span>
          <ChevronDownIcon className="w-4" />
        </div>
        {optionVisible.isOn && (
          <div className="absolute bg-white w-full border border-neutral-5">
            {props.options.map((option) => (
              <div
                className="px-[6px] hover:bg-primary-1 cursor-pointer"
                key={option.id}
                onClick={() => {
                  props.onChange(option.id);
                  optionVisible.off();
                }}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
