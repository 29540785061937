import { type V3 } from "@project-rouge/rg-core";
import { useMemo } from "react";
import { useThree } from "@react-three/fiber";
import { useMap } from "react-three-map";
import { MapPortal } from "src/components/MapPortal";
import { IsAngleInRange } from "src/utils/IsAngleInRange";
import { GetPointByDistanceAndDirection } from "src/utils/GetPointByDistanceAndDirection";
import { ProjectToCanvas } from "src/utils/ProjectToCanvas";

export function Measurement(props: { start: V3; end: V3 }) {
  const camera = useThree().raycaster.camera;
  const canvas = useMap()?.getCanvas();
  const distance = useMemo(() => {
    if (!props.start || !props.end) return null;
    const x = props.start[0] - props.end[0];
    const y = props.start[1] - props.end[1];
    const z = props.start[2] - props.end[2];
    return Math.hypot(x, y, z).toFixed(1);
  }, [props.start, props.end]);

  const data = useMemo(() => {
    if (!props.start || !props.end || !canvas) return null;
    const [x1, y1] = ProjectToCanvas(props.start, camera, canvas);
    const [x2, y2] = ProjectToCanvas(props.end, camera, canvas);
    const x = (x1 + x2) / 2;
    const y = (y1 + y2) / 2;
    const lineAngle = Math.atan2(y2 - y1, x2 - x1);
    const isPositive = IsAngleInRange(lineAngle, -90, 90);
    const angle = isPositive ? lineAngle : lineAngle + Math.PI;
    const position = GetPointByDistanceAndDirection([x, y], 12, Math.PI / 2 + angle);
    return { angle, position };
  }, [props.start, props.end, camera, canvas]);
  if (!data) return null;
  return (
    <MapPortal position={data.position}>
      <div className="absolute flex items-center justify-center w-[1px] h-[1px] pointer-events-none text-body-2">
        <div
          style={{
            transform: `rotate(${data.angle}rad)`,
          }}
          className="space-x-1 bg-white rounded flex flex-row justify-center items-center flex-1 p-[2px] h-[14px]"
        >
          <span className="text-neutral-7">{distance}</span>
          <span className="text-neutral-6">m</span>
        </div>
      </div>
    </MapPortal>
  );
}
