import { type FunctionComponent } from "react";

export const MergeIcon: FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20">
      <g fill="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.67164 3.67164V11.5522H7.37313C7.96662 11.5522 8.44775 12.0333 8.44775 12.6268V16.3283H16.3283V8.44775H12.6268C12.0333 8.44775 11.5522 7.96662 11.5522 7.37313V3.67164H3.67164ZM2 3.07462C2 2.48112 2.48113 2 3.07463 2H12.1492C12.7427 2 13.2239 2.48113 13.2239 3.07463V6.77611H16.9253C17.5188 6.77611 18 7.25724 18 7.85074V16.9253C18 17.5188 17.5188 18 16.9253 18H7.85074C7.25724 18 6.77611 17.5188 6.77611 16.9253V13.2239H3.07463C2.48113 13.2239 2 12.7427 2 12.1492V3.07462Z"
        />
        <path d="M8 7C8 7.55228 7.55228 8 7 8C6.44772 8 6 7.55228 6 7C6 6.44772 6.44772 6 7 6C7.55228 6 8 6.44772 8 7Z" />
        <path d="M8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10Z" />
        <path d="M11 7C11 7.55228 10.5523 8 10 8C9.44772 8 9 7.55228 9 7C9 6.44772 9.44772 6 10 6C10.5523 6 11 6.44772 11 7Z" />
        <path d="M12 13C12 12.4477 12.4477 12 13 12C13.5523 12 14 12.4477 14 13C14 13.5523 13.5523 14 13 14C12.4477 14 12 13.5523 12 13Z" />
        <path d="M12 10C12 9.44772 12.4477 9 13 9C13.5523 9 14 9.44772 14 10C14 10.5523 13.5523 11 13 11C12.4477 11 12 10.5523 12 10Z" />
        <path d="M9 13C9 12.4477 9.44772 12 10 12C10.5523 12 11 12.4477 11 13C11 13.5523 10.5523 14 10 14C9.44772 14 9 13.5523 9 13Z" />
      </g>
    </svg>
  );
};
