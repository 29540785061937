import { CubeIcon, StopIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { Button, ButtonIntent, ButtonState } from "src/components/Button";
import { observer } from "mobx-react-lite";
import { EditorInspector } from "./EditorInspector";
import { EditorMap } from "./EditorMap";
import type { Editor } from "src/types/Editor";
import type { WorkspaceConfig } from "src/hooks/useWorkspaceQuery";
import { useLiveRef } from "src/hooks/useLiveRef";
import { ExplorePanel } from "./ExplorePanel/ExplorePanel";
import { Plan2D } from "./Plan2D";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "src/contexts/api.context";
import { useActiveParams } from "src/hooks/useActiveParams";
import { ToolSelection } from "src/types/MapState";

const getScenarioQueryKey = (projectId: string) => [projectId, "scenario"];

function useScenarioQuery(editor: Editor) {
  const projectId = useActiveParams().projectId ?? "";
  const api = useApi();
  return useQuery({
    queryKey: getScenarioQueryKey(projectId),
    queryFn: async () => {
      const scenario = await api.getScenarioWithDesignFile(projectId);
      if (!scenario) return null;
      return await editor.loader.loadFromScenarioWithDesign(scenario);
    },
    staleTime: Infinity,
  });
}

export const DesignEditor = observer(function Editor(props: {
  editor: Editor;
  workspace: WorkspaceConfig;
}) {
  const { projectId } = useActiveParams();
  const [section, setSection] = useState<"3D" | "2D">("3D");
  const workspaceRef = useLiveRef(props.workspace);
  const api = useApi();
  const scnearioQuery = useScenarioQuery(props.editor);
  const queryClient = useQueryClient();
  const mapState = props.editor.mapState;
  // optimistic update on save. ScenarioQuery must contain the latest world from editor
  useEffect(() => {
    props.editor.autoSave.onAutoSaveFinished(() => {
      if (!projectId) return;
      queryClient.setQueryData(getScenarioQueryKey(projectId), () => props.editor.world);
    });
  }, [projectId, props.editor, queryClient]);

  // load/reload world from scenario
  useEffect(() => {
    (async () => {
      if (!projectId) return;
      const scenario = scnearioQuery.data;
      if (!scenario) return;

      await props.editor.loadFromWorld(scenario);
      mapState.setWorld(props.editor.world);
      if (!!props.editor.world?.hasZone) props.editor.view.explorePanel.toggleVisible(true);
      if (
        !!props.editor.world?.hasZone &&
        mapState.selectedTool.value.id === ToolSelection.SEARCH_PLACE
      ) {
        mapState.selectedTool.update(ToolSelection.SELECT);
      }
    })();
  }, [
    api,
    mapState,
    mapState.selectedTool,
    projectId,
    props.editor,
    scnearioQuery.data,
    workspaceRef,
  ]);

  return (
    <>
      <div className="flex flex-1 bg-white flex-col relative overflow-hidden">
        <div className="flex w-full h-full relative">
          <div className="top-2 flex items-center flex-row overflow-hidden flex-shrink-0 rounded-t border-black/10 absolute z-10 right-1/2 translate-x-1/2">
            <Button.Group>
              <Button
                label="3D"
                Icon={CubeIcon}
                intent={ButtonIntent.SELECT}
                state={section === "3D" && ButtonState.ACTIVE}
                onClick={() => setSection("3D")}
              />
              <Button
                label="2D"
                Icon={StopIcon}
                intent={ButtonIntent.SELECT}
                state={section === "2D" && ButtonState.ACTIVE}
                onClick={() => setSection("2D")}
              />
            </Button.Group>
          </div>
          {section === "3D" && <EditorMap editor={props.editor} />}
          {section === "2D" && !!props.editor.world && <Plan2D world={props.editor.world} />}
        </div>
        <ExplorePanel editor={props.editor} workspaceConfig={props.workspace} />
      </div>
      <EditorInspector editor={props.editor} />
    </>
  );
});
