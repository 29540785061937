import type { Place } from "./MapSearch";
import { useState, useEffect } from "react";
import { Button, ButtonIntent, ButtonState } from "src/components/Button";
import { CubeIcon, StopIcon } from "@heroicons/react/20/solid";
import { Toolbox } from "./Toolbox/Toolbox";
import { useBoolean } from "src/hooks/useBoolean";
import { Plan2D } from "./Plan2D";
import { ArchitecturalMetricsInspector } from "./Inspector/ArchitecturalMetricsInspector";
import { CostMetricsInspector } from "./Inspector/CostMetricsInspector";
import { useOnMount } from "src/hooks/useOnMount";
import { SustainabilityMetricsInspector } from "./Inspector/SustainabilityMetricsInspector";
import { observer } from "mobx-react-lite";
import { WorldEditor } from "./WorldEditor";
import { SitesBoundaries } from "./Map/Layers/three-layer/components/SitesBoundaries";
import { CreateBrief } from "src/utils/CreateBrief";
import { useActiveParams } from "src/hooks/useActiveParams";
import { useApi } from "src/contexts/api.context";
import { MapSearch } from "./MapSearch";
import { ZoneInspector } from "./Inspector/ZoneInspector";
import { MetricsButton } from "./Inspector/MetricsButton";
import { InspectorsGroup } from "./Inspector/InspectorsGroup";
import { UnitType } from "../types/UnitType";
import { AreaView } from "./Inspector/AreaView";
import { Selection } from "src/types/Selection";
import type { World } from "src/types/World";
import { MapState, ToolSelection } from "src/types/MapState";
import { useQuery } from "@tanstack/react-query";
import { WorldLoader } from "src/types/WorldLoader";
import { ElevationLayer } from "./Map/Layers/Elevation.layer";
import { ExtrusionLayer } from "./Map/Layers/Extrusion";
import { ThreeLayer } from "./Map/Layers/three-layer/Three.layer";
import { ZoneEditor } from "./Map/Layers/three-layer/ZoneEditor";
import { MapUtilAreaOfInterestCenter } from "./MapUtilAreaOfInterestCenter";
import { MapUtilResizeObserver } from "./MapUtilResizeObserver";
import { Map } from "src/components/Map/Map";
import { ScaleControl } from "react-map-gl";

export const DesignPreview = observer(function DesignPreview() {
  const { designId, projectId, scenarioId } = useActiveParams();
  const api = useApi();
  const worldQuery = useQuery({
    enabled: !!projectId && !!scenarioId && !!designId,
    queryFn: async () => {
      if (!projectId || !scenarioId || !designId) return;
      const loader = new WorldLoader();
      const scenario = await api.getSavedDesignWithDesignFile(projectId, scenarioId, designId);
      if (!scenario) throw new Error("Scenario not found");
      const world = await loader.loadFromScenarioWithDesign(scenario);
      return world;
    },
    queryKey: ["scenario", projectId, scenarioId, designId],
    structuralSharing: false,
    staleTime: Infinity,
  });

  const [section, setSection] = useState<"3D" | "2D">("3D");
  const [unit, setUnit] = useState(UnitType.Metric);
  const [selection] = useState(() => new Selection(null));

  useEffect(() => {
    selection.setWorld(worldQuery.data ?? null);
  }, [selection, worldQuery.data]);
  const world = worldQuery.data ?? null;
  return (
    <div className="flex w-full h-full flex-1 flex-row overflow-hidden">
      <div className="flex flex-1 bg-white flex-col relative overflow-hidden">
        <div className="flex w-full h-full relative">
          <div className="top-2 flex items-center flex-row overflow-hidden flex-shrink-0 rounded-t border-black/10 absolute z-10 right-1/2 translate-x-1/2">
            <Button.Group>
              <Button
                label="3D"
                Icon={CubeIcon}
                intent={ButtonIntent.SELECT}
                state={section === "3D" && ButtonState.ACTIVE}
                onClick={() => setSection("3D")}
              />
              <Button
                label="2D"
                Icon={StopIcon}
                intent={ButtonIntent.SELECT}
                state={section === "2D" && ButtonState.ACTIVE}
                onClick={() => setSection("2D")}
              />
            </Button.Group>
          </div>
          {section === "3D" && !!world && <SavedDesignCanvas world={world} selection={selection} />}
          {section === "2D" && !!world && <Plan2D world={world} />}
        </div>
      </div>
      <InspectorsGroup>
        <AreaView onUnitChange={setUnit} world={world} unit={unit} />
        {world && <ZoneInspector world={world} readonly selection={selection} />}

        {world && <ArchitecturalMetricsInspector world={world} unit={unit} selection={selection} />}
        {world && <CostMetricsInspector world={world} unit={unit} selection={selection} />}
        {world && (
          <SustainabilityMetricsInspector world={world} unit={unit} selection={selection} />
        )}
        {world && <MetricsButton world={world} />}
      </InspectorsGroup>
    </div>
  );
});

interface SavedDesignCanvasProps {
  // scenario: Scenario;
  selection: Selection;
  world: World;
}

const SavedDesignCanvas = observer((props: SavedDesignCanvasProps) => {
  const [mapState] = useState(() => new MapState(props.world));
  const [defaultBrief] = useState(() => CreateBrief());
  const [placesHistory, setPlacesHistory] = useState<Place[]>([]);
  const worldEditor = mapState.selectedTool.value.id === ToolSelection.SELECT && (
    <WorldEditor
      mapState={mapState}
      design={props.world}
      onDesignChange={() => {}}
      readonly
      selection={props.selection}
    />
  );
  const siteBoundariesLayer = mapState.layerSiteBoundary.isOn && <SitesBoundaries />;
  const toolboxPanel = <ToolboxPanel world={props.world} mapState={mapState} />;
  useOnMount(() => {
    mapState.selectedTool.update(ToolSelection.SELECT);
  });
  const mapSearch = mapState.selectedTool.value.id === ToolSelection.SEARCH_PLACE && (
    <MapSearch
      mapState={mapState}
      onPlacesHistoryChange={setPlacesHistory}
      placesHistory={placesHistory}
    />
  );
  const geoOrigin = { lng: props.world.longitude, lat: props.world.latitude };
  return (
    <div className="w-full h-full relative">
      <Map mapState={mapState}>
        <MapUtilAreaOfInterestCenter />
        <MapUtilResizeObserver />
        <ElevationLayer mapState={mapState} />
        <ExtrusionLayer mapState={mapState} />
        <ThreeLayer origin={geoOrigin}>
          <ZoneEditor
            mapState={mapState}
            world={props.world}
            defaultBrief={defaultBrief}
            onSelectedToolCancel={() => mapState.selectedTool.update(ToolSelection.SELECT)}
            selectedTool={mapState.selectedTool.value.id}
            geoOrigin={geoOrigin}
            selection={props.selection}
          />
          {worldEditor}
          {siteBoundariesLayer}
        </ThreeLayer>
        {mapSearch}
        <ScaleControl />
      </Map>
      {toolboxPanel}
    </div>
  );
});

const ToolboxPanel = observer(function ToolboxPanel(props: { world: World; mapState: MapState }) {
  const searchBoxOpen = useBoolean(!props.world.hasZone);
  return (
    <Toolbox.Wrapper>
      <Toolbox.SearchButton
        onClick={() => props.mapState.selectedTool.update(ToolSelection.SEARCH_PLACE)}
      />

      <Toolbox.Group visible={searchBoxOpen.isOff}>
        <Toolbox.MapViewButton mapState={props.mapState} />
        <Toolbox.BuildingPresetButton mapState={props.mapState} />
      </Toolbox.Group>

      <Toolbox.Group>
        <Toolbox.ExportButton world={props.world} />
      </Toolbox.Group>
    </Toolbox.Wrapper>
  );
});
