import type { ArchitecturalMetrics } from "src/types/ArchitecturalMetrics";
import { Table } from "../Table";
import { formatOrdinals } from "../formatOrdinals";
import { useMemo } from "react";

export function EfficiencyTable(props: { metrics: ArchitecturalMetrics }) {
  const rows = useRows(props.metrics);
  return (
    <div>
      <Table.Sticky>
        <Table.Head label="Efficiency" variant="section" />
        <Table.Row>
          <Table.Head label="Level" variant="header" position="start" />
          <Table.Head label="Efficiency" variant="header" position="end" />
          <Table.Head label="Units" variant="header" position="end" />
        </Table.Row>
      </Table.Sticky>
      {rows.map((row) => (
        <Table.Row key={row.label}>
          <Table.Col>
            <Table.Label label={row.label} variant="cell" />
          </Table.Col>
          <Table.Col>
            <Table.Cell value={row.efficiency} precision={1} variant="cell" symbol="%" />
          </Table.Col>
          <Table.Col>
            <Table.Cell value={row.units} variant="cell" />
          </Table.Col>
        </Table.Row>
      ))}
      <Table.Row>
        <Table.Col>
          <Table.Label label="Residential efficiency" variant="subtotal" />
        </Table.Col>
        <Table.Col>
          <Table.Cell
            value={props.metrics.ResidentialEfficiencyTotal * 100}
            precision={1}
            variant="subtotal"
            symbol="%"
          />
        </Table.Col>
        <Table.Col>
          <Table.Cell value={props.metrics.ApartmentModuleQuantityTotal} variant="subtotal" />
        </Table.Col>
      </Table.Row>
      <Table.Row>
        <Table.Col>
          <Table.Label label="Building efficiency" variant="total" />
        </Table.Col>
        <Table.Col>
          <Table.Cell
            value={props.metrics.BuildingEfficiencyTotal * 100}
            precision={1}
            variant="total"
            symbol="%"
          />
        </Table.Col>
        <Table.Col>
          <Table.Cell value={props.metrics.ApartmentModuleQuantityTotal} variant="total" />
        </Table.Col>
      </Table.Row>
    </div>
  );
}

function useRows(metrics: ArchitecturalMetrics) {
  return useMemo(
    () =>
      metrics.ResidentialEfficiencyPerLevel.map((resEff, index) => ({
        label: `${formatOrdinals(index)} Floor`,
        efficiency: resEff * 100,
        units: metrics.ApartmentModuleQuantityPerLevel[index],
      })),
    [metrics.ApartmentModuleQuantityPerLevel, metrics.ResidentialEfficiencyPerLevel]
  );
}
