import { useRef, useEffect } from "react";

export const useWindowEvent = <K extends keyof WindowEventMap>(
  event: K,
  cb: (evt: WindowEventMap[K]) => void
) => {
  const cbRef = useRef(cb);
  cbRef.current = cb;
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const callback = (evt: any) => cbRef.current(evt);
    window.addEventListener(event, callback);
    return () => window.removeEventListener(event, callback);
  }, [event]);
};
