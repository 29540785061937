import { type FunctionComponent, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";

export enum Unit {
  sqm = "sqm",
  sqft = "sqft",
}

type UnitSelectorProps = {
  unit: string;
  onUnitChange: (selectedUnit: Unit) => void;
};

export const UnitSelector: FunctionComponent<UnitSelectorProps> = ({ unit, onUnitChange }) => {
  const [hidden, isHidden] = useState<boolean>(true);

  return (
    <div className="bg-neutral-1 border-neutral-4 border-y flex items-center w-full p-4 h-9 px-3 gap-2 text-neutral-8 text-body-1">
      Show measurements in
      <div className="relative inline-block">
        <button
          className="flex items-center justify-between px-1 w-[92px] h-6 border-neutral-4 border bg-white text-sm text-neutral-7"
          onClick={hidden === true ? () => isHidden(false) : () => isHidden(true)}
        >
          {unit === "sqm" ? "Metric" : "Imperial"}
          {!hidden ? <ChevronUpIcon className="w-4" /> : <ChevronDownIcon className="w-4" />}
        </button>

        <button
          className={classNames(
            hidden === true && "hidden",
            hidden === false && "block",
            "z-[999] absolute  w-[92px] text-left px-1 h-6 border-neutral-4 border bg-white text-sm hover:bg-neutral-4 text-neutral-7"
          )}
          onClick={() => {
            hidden ? isHidden(false) : isHidden(true);
            unit === "sqm" ? onUnitChange(Unit.sqft) : onUnitChange(Unit.sqm);
          }}
        >
          {unit === "sqm" ? "Imperial" : "Metric"}
        </button>
      </div>
    </div>
  );
};
