/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useRef } from "react";

export const useEvent = <T extends (...args: any[]) => any>(event: T): T => {
  const eventRef = useRef(event);
  eventRef.current = event;
  return useCallback((...args: any[]) => {
    return eventRef.current(...args);
  }, []) as T;
};
