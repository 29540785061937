import { SparklesIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { useRedirect } from "src/hooks/useRedirect";
import { useActiveParams } from "src/hooks/useActiveParams";
import { useActiveProject } from "src/hooks/useActiveProject";
import type { UseBoolean } from "src/hooks/useBoolean";

export function CanvasButton(props: { expand: UseBoolean }) {
  const redirect = useRedirect();
  const isActive = !useActiveParams().designId;
  const projectQuery = useActiveProject();
  const project = projectQuery.data;
  const label = props.expand.isOn ? (
    <span className="flex-1 truncate text-start text-subtitle-2">Canvas</span>
  ) : null;
  return (
    <button
      className={classNames(
        "flex flex-row space-x-2 rounded cursor-pointer flex-1 justify-center items-center h-[28px] min-h-[28px] transition-all",
        props.expand.isOn && "px-[8px]",
        isActive && "text-primary-7 bg-primary-3 hover:text-primary-7",
        !isActive && "text-neutral-7 hover:text-primary-7 hover:bg-primary-1"
      )}
      onClick={async () => {
        if (!project) return;
        await redirect.scenarioCanvas({ projectId: project?.id });
      }}
    >
      <SparklesIcon className="w-4 h-4 shrink-0" />
      {label}
    </button>
  );
}
