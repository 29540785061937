import { BrowserRouter, Route, useParams, Routes, Navigate } from "react-router-dom";
import { LoginScreen } from "src/screens/[login].screen";
import { HomeScreen } from "src/screens/[home].screen";
import { useAuth } from "src/contexts/auth.context";
import { ProfileScreen } from "src/screens/[profile].screen";
import { SsoLoginScreen } from "src/screens/[login-sso].screen";
import { ResetScreen } from "src/screens/[reset].screen";
import { observer } from "mobx-react-lite";
import { Editor } from "src/types/Editor";
import { useApi } from "src/contexts/api.context";
import { useEffect, useMemo, useState } from "react";
import {
  SnapshotService,
  useSnapshotService,
} from "src/services/useSnapshot.service/useSnapshot.service";
import {
  ActiveProjectService,
  useActiveProjectService,
} from "src/services/useActiveProject.service";
import { Navigator } from "src/components/Navigator/Navigator";
import { DesignPreview } from "./DesignPreview";
import { DesignEditor } from "./DesignEditor";
import { useWorkspaceQuery } from "src/hooks/useWorkspaceQuery";
import { useActiveParams } from "src/hooks/useActiveParams";

const Nav = (props: { to: string }) => {
  const params = useParams();
  const to = useMemo(() => {
    const map = new Map(Object.keys(params).map((key) => [`:${key}`, params[key] as string]));
    let path = props.to;
    for (const [key, value] of map) {
      path = path.replace(key, value);
    }
    return path;
  }, [params, props.to]);
  return <Navigate to={to} replace />;
};

export const Router = observer(() => {
  const auth = useAuth();
  const authorizedRoues = auth.isAuthorized ? (
    <>
      <Route path="/login/sso/:adminToken/:actorToken" Component={SsoLoginScreen} />
      <Route path="/login/reset/:resetToken" Component={ResetScreen} />
      <Route path="/home" Component={HomeScreen} />
      <Route path="/profile" Component={ProfileScreen} />
      <Route path="/projects/:projectId/*" element={<ProjectRouter />} />
      <Route path="/projects" element={<Nav to="/home" />} />
      <Route path="/" element={<Nav to="/home" />} />
      <Route path="*" element={<Nav to="/home" />} />
    </>
  ) : null;
  const unauthorizedRoutes = !auth.isAuthorized ? (
    <>
      <Route path="/login/sso/:adminToken/:actorToken" Component={SsoLoginScreen} />
      <Route path="/login" Component={LoginScreen} />
      <Route path="/login/reset/:resetToken" Component={ResetScreen} />
      <Route path="*" element={<Nav to="/login" />} />
    </>
  ) : null;
  return (
    <BrowserRouter>
      <Routes>
        {authorizedRoues}
        {unauthorizedRoutes}
      </Routes>
    </BrowserRouter>
  );
});

const ProjectRouter = observer(() => {
  const { projectId, scenarioId } = useActiveParams();
  const api = useApi();
  const [editor, setEditor] = useState<Editor | null>(null);
  const project = useActiveProjectService();
  const snapshot = useSnapshotService();
  const workspace = useWorkspaceQuery();
  useEffect(() => {
    if (!projectId || !scenarioId) return;
    const editor = new Editor(api, projectId, scenarioId);
    setEditor(editor);
    return () => editor.destory();
  }, [api, projectId, scenarioId]);

  if (!editor) return null;
  if (!workspace.data) return null;

  return (
    <SnapshotService.Provider value={snapshot}>
      <ActiveProjectService.Provider value={project}>
        <div className="overflow-hidden w-screen h-screen flex flex-row bg-elevation-3 text-gray-200 text-sm">
          <Navigator editor={editor} />
          <div className="flex flex-1 overflow-hidden">
            <Routes>
              <Route
                path="canvas"
                element={<DesignEditor editor={editor} workspace={workspace.data} />}
              />
              <Route path="designs/:designId" element={<DesignPreview />} />
              <Route path="*" element={<Nav to="canvas" />} />
            </Routes>
          </div>
        </div>
      </ActiveProjectService.Provider>
    </SnapshotService.Provider>
  );
});
