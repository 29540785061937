import { makeAutoObservable, reaction, runInAction } from "mobx";
import { World } from "./World";
import { Compress } from "src/utils/Compress";
import { Decompress } from "src/utils/Decompress";
import { dtoToRgObject } from "@project-rouge/rg-core";
import type { RgWorld } from "./RgCorePackage";

type HistoryEntry = {
  rgWorldHashKey: string;
  promise: Promise<() => World>;
};

function CreateEntry(world: World): HistoryEntry {
  const promise: HistoryEntry["promise"] = new Promise(async (resolve) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { rgWorld, ...rest } = world.config;
    const rgWorldDto = world.rgWorldDto;
    const entryConfig = {
      ...rest,
      rgWorldDto,
    } as const;
    const compressed = Compress(entryConfig);
    resolve(() => {
      const { rgWorldDto, ...rest } = Decompress<typeof entryConfig>(compressed);
      const rgWorld = dtoToRgObject<RgWorld>(rgWorldDto);
      const world = new World({
        ...rest,
        rgWorld,
      });
      return world;
    });
  });
  return {
    rgWorldHashKey: world.rgWorldHashKey,
    promise,
  };
}

export class History {
  private activeIndex = 0;
  entries: HistoryEntry[] = [];

  /** active entry is the one which is mutable */
  world: World | null = null;

  private onDestroyListeners: (() => void)[] = [];

  private pushInProcess = false;

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.world?.config,
      () => {
        if (this.pushInProcess) return;
        if (!this.world) return;
        const activeHashKey = this.entries.at(this.activeIndex)?.rgWorldHashKey;
        if (this.world?.rgWorldHashKey !== activeHashKey) {
          this.push(this.world.clone());
        }
      }
    );
  }

  get hasHistoryBack() {
    return this.activeIndex > 0;
  }

  get hasHistoryForward() {
    return this.activeIndex < this.entries.length - 1;
  }

  setActiveEntry(world: World) {
    this.world = world;
  }

  private push(world: World) {
    this.pushInProcess = true;
    this.entries = this.entries.slice(0, this.activeIndex + 1);
    this.entries.push(CreateEntry(world.clone()));
    this.activeIndex = this.entries.length - 1;
    this.world = world;
    this.pushInProcess = false;
  }

  async historyBack() {
    if (!this.hasHistoryBack) return null;
    const activeIndex = this.activeIndex - 1;
    const entry = await this.entries[activeIndex].promise;
    runInAction(() => {
      this.activeIndex = activeIndex;
      this.world = entry();
    });
  }

  async historyForward() {
    if (!this.hasHistoryForward) return null;
    const activeIndex = this.activeIndex + 1;
    const entry = await this.entries[activeIndex].promise;
    runInAction(() => {
      this.activeIndex = activeIndex;
      this.world = entry();
    });
  }

  destory() {
    this.onDestroyListeners.forEach((cb) => cb());
    this.onDestroyListeners = [];
  }
}
