import { RG_TYPE, type AggDebugDto } from "@project-rouge/rg-core";
import { useState, type ReactNode } from "react";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { ListToolButton } from "./ListToolButton";
import { IconFileDownload } from "@tabler/icons-react";
import { observer } from "mobx-react-lite";
import { useWindowEvent } from "src/hooks/useWindowEvent";
import { useBoolean } from "src/hooks/useBoolean";
import { Button } from "src/components/Button";
import type { ExportOptions, metricOptions } from "src/components/ExportModal";
import { ExportModal } from "src/components/ExportModal";
import { useApi } from "src/contexts/api.context";
import { useQueryClient } from "@tanstack/react-query";
import { useEvent } from "src/hooks/useEvent";
import { getSavedDesignListQueryKey } from "../Navigator/useSavedDesignsList";
import { useToaster } from "src/contexts/toaster.context";
import { ToastType } from "../Toast";
import { useActiveParams } from "src/hooks/useActiveParams";
import type { World } from "src/types/World";
import { metricsToCSV } from "src/utils/metricsToCSV";
import { fetchMetricCalculations } from "src/hooks/useMetricsCalculationsQuery";
import type {
  CarbonView,
  CostingView,
} from "@project-rouge/service-cost-client/utility/custom-view";
import {
  createCarbonViewForBuilding,
  createCarbonViewForTotal,
  createCostingViewForBuilding,
  createCostingViewForTotal,
} from "@project-rouge/service-cost-client/utility/custom-view";

import type { ArchitecturalMetrics } from "src/types/ArchitecturalMetrics";
import type { ProjectCostingCalculationHttpResource } from "@project-rouge/service-cost-client/resource/project/project-costing-calculation-resource";
import type { UnitType } from "../../types/UnitType";
import type { Api } from "src/types/Api";
import { getCountOfType, getRgObjectsOfType } from "@project-rouge/rg-three";
import type { RgBuilding } from "src/types/RgCorePackage";

export const ExportButton = observer(function ExportButton(props: { world: World | null }) {
  const { designId, projectId, scenarioId } = useActiveParams();
  const isInEditorCanvas = !designId;

  const showModal = useBoolean();
  const isAltkeyDown = useIsAltKeyDown();

  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();

  const handleClick = useEvent(async (designIncluded: boolean) => {
    if (!props.world) return;
    const value: AggDebugDto = {
      aggPropsDto: null as never,
      briefDto: props.world.rgBriefDto,
      briefIndex: props.world.briefIndex,
      versions: props.world.versions,
      designOption: designIncluded ? props.world.rgWorldDto : undefined,
    };

    const blob = new Blob([JSON.stringify(value)], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const element = document.createElement("a");
    element.href = url;
    element.download = `${props.world.id}-snapshot-${new Date().toISOString()}.json`;
    element.click();
    element.remove();
  });

  const postAndExportDesign = useEvent(async (exportOptions) => {
    if (!props.world) return;
    if (!projectId || !scenarioId) return;
    const world = props.world.clone();
    world.setLabel(exportOptions.options.scenarioName);
    let savedDesignId = isInEditorCanvas
      ? await api.saveWorld(projectId, scenarioId, world)
      : designId;
    if (!savedDesignId) return;
    const buildingsIdsSet = new Set(exportOptions.buildingIds);
    const rgBuildings = getRgObjectsOfType<RgBuilding>(world.rgWorld, RG_TYPE.Building).filter(
      (b) => buildingsIdsSet.has(b.uuid)
    );
    const unitsCount = getCountOfType(rgBuildings, RG_TYPE.Apartment);
    await api.putDesignExport({
      projectId,
      scenarioId,
      designId: savedDesignId,
      buildingsIds: exportOptions.buildingIds,
      unitsCount,
    });
    if (isInEditorCanvas) queryClient.invalidateQueries(getSavedDesignListQueryKey(projectId));

    toaster.show({
      text: "Export",
      type: ToastType.INFO,
      autoHide: true,
      id: crypto.randomUUID(),
    });
  });

  const exportClick = useEvent(
    async (exportOptions: { options: ExportOptions; zoneIds: string[]; buildingIds: string[] }) => {
      if (exportOptions.options.bim) {
        await postAndExportDesign(exportOptions);
      }
      if (exportOptions.options.metrics) {
        let selectedMetric = exportOptions.options.metrics;
        let selectedUnit = exportOptions.options.unit;
        csvHandlerClick(selectedMetric, selectedUnit);
      }
      showModal.off();
    }
  );
  const csvHandlerClick = useEvent(
    async (selectedMetric: metricOptions, selectedUnit: UnitType) => {
      if (!props.world) return;
      if (!projectId) return;
      const csvMetric = await csvData(projectId, props.world, api);
      if (!csvMetric) return;

      const blob = metricsToCSV(props.world, csvMetric, selectedMetric, selectedUnit);
      const url = URL.createObjectURL(await blob);
      const element = document.createElement("a");
      element.href = url;
      element.download = "Metrics";
      element.click();
      element.remove();
    }
  );
  if (!props.world) return;

  if (!isAltkeyDown)
    return (
      <>
        <Button Icon={ArrowDownTrayIcon} onClick={showModal.on} />
        {showModal.isOn && (
          <ExportModal
            isEditMode={isInEditorCanvas}
            onClose={showModal.off}
            world={props.world}
            onExport={exportClick}
          />
        )}
      </>
    );
  return (
    <ListToolButton Icon={ArrowDownTrayIcon}>
      <div className="flex flex-col">
        <ItemButton
          Icon={IconFileDownload}
          label="Brief snapshot"
          onClick={async () => handleClick(false)}
        />
        <ItemButton
          Icon={IconFileDownload}
          label="Full snapshot"
          onClick={async () => handleClick(true)}
        />
      </div>
    </ListToolButton>
  );
});

const ItemButton = ({
  label,
  onClick,
  Icon,
}: {
  label: string;
  onClick: () => void;
  Icon: (props: { className: string }) => ReactNode;
}) => {
  return (
    <button
      onClick={onClick}
      className="flex flex-row flex-1 items-center space-x-2 hover:bg-primary-1 py-2 px-4 text-neutral-8 text-body-1 whitespace-nowrap"
    >
      <Icon className="w-4 text-neutral-7" />
      <span>{label}</span>
    </button>
  );
};

function useIsAltKeyDown() {
  const [isAltKeyDown, setIsAltKeyDown] = useState(false);
  useWindowEvent("keydown", (evt) => {
    if (evt.altKey) {
      setIsAltKeyDown(true);
    }
  });
  useWindowEvent("keyup", (evt) => {
    if (!evt.altKey) {
      setIsAltKeyDown(false);
    }
  });
  return isAltKeyDown;
}
export interface csvMetricData {
  architecturalMetrics: ArchitecturalMetrics;
  costMetrics: {
    world: CostingView;
    zones: CostingView[];
    buildings: CostingView[];
  };
  sustainabilityMetrics: {
    world: CarbonView;
    zones: CarbonView[];
    buildings: CarbonView[];
  };
}
async function csvData(projectId: string, world: World, api: Api): Promise<csvMetricData | null> {
  const worldCalculation = await fetchMetricCalculations(api, projectId, world, world.buildings);
  if (!worldCalculation) return null;

  const zoneCalculations = await Promise.all(
    world.zones.map(async (zone) => fetchMetricCalculations(api, projectId, world, zone.buildings))
  );

  const worldCostMetric = costingData(worldCalculation);
  const worldSustainabilityMetric = sustainabilityData(worldCalculation);

  const zoneCostMetrics = zoneCalculations.map((zone) => costingData(zone));
  const zoneSustainabilityMetrics = zoneCalculations.map((zone) => sustainabilityData(zone));

  const buildingCostMetrics = world.buildings.map((_building, index) =>
    createCostingViewForBuilding(worldCalculation, index)
  );
  const buildingSustainabilityMetrics = world.buildings.map((_building, index) =>
    createCarbonViewForBuilding(worldCalculation, index)
  );

  if (!worldCostMetric || !worldSustainabilityMetric) return null;
  const row = {
    architecturalMetrics: world.architecturalMetrics,
    costMetrics: {
      world: worldCostMetric,
      zones: zoneCostMetrics,
      buildings: buildingCostMetrics,
    },
    sustainabilityMetrics: {
      world: worldSustainabilityMetric,
      zones: zoneSustainabilityMetrics,
      buildings: buildingSustainabilityMetrics,
    },
  };
  return row;
}

function costingData(data: ProjectCostingCalculationHttpResource | null | undefined) {
  const costingView: CostingView = data
    ? createCostingViewForTotal(data)
    : {
        element: {
          modular: [],
          basebuild: [],
        },
        summary: {
          construction: 0,
          constructionBaseBuild: 0,
          constructionFitOut: 0,
          constructionModules: 0,
          contingencies: 0,
          overheadsAndProfits: 0,
          preliminaries: 0,
          total: 0,
        },
      };
  return costingView;
}

function sustainabilityData(data: ProjectCostingCalculationHttpResource | null | undefined) {
  const sustainabilityView: CarbonView = data
    ? createCarbonViewForTotal(data)
    : { element: [], summary: { total: 0, substructure: 0, other: 0, superstructure: 0 } };
  return sustainabilityView;
}
