import { type V2, type V3 } from "@project-rouge/rg-core";

export function V2ToV3(items: V2, y?: number): V3;
export function V2ToV3(items: V2[], y?: number): V3[];
export function V2ToV3(items: V2 | V2[]) {
  const y = 0;
  return Array.isArray(items[0])
    ? (items as V2[]).map(([x, z]) => [x, y, z] as V3)
    : ([items[0], y, items[1]] as V3);
}
