import { XMarkIcon } from "@heroicons/react/20/solid";
import { type PropsWithChildren, useEffect, useRef, useState, type ReactNode } from "react";
import { createPortal } from "react-dom";
import { Form, FormState } from "./Form/Form";
import { observer } from "mobx-react-lite";
import classNames from "classnames";

const Portal = observer(
  ({
    isOpen,
    children,
    onBackgroundClick,
  }: PropsWithChildren<{
    isOpen: boolean;
    /** @deprecated */
    onBackgroundClick?: () => void;
  }>) => {
    const [root, setRoot] = useState<null | HTMLDivElement>(null);
    useEffect(() => {
      if (!isOpen) return;
      const portalId = "react-rg-portal-modal";
      if (!document.getElementById(portalId)) {
        const root = document.createElement("div");
        root.id = portalId;
        document.body.appendChild(root);
      }
      const portalElement = document.getElementById(portalId) as HTMLDivElement;
      setRoot(portalElement);
    }, [isOpen]);

    return root && isOpen
      ? createPortal(
          <div
            className="z-50 fixed left-0 top-0 w-screen h-screen bg-secondary-8/30"
            onMouseUp={onBackgroundClick}
          >
            {children}
          </div>,
          root
        )
      : null;
  }
);

const Header = ({ onClose, label }: { onClose: () => void; label: string | ReactNode }) => {
  return (
    <div className="flex justify-between">
      <div className="text-neutral-8 text-base font-semibold">{label}</div>
      <XMarkIcon className="w-5 text-neutral-8 cursor-pointer" onClick={onClose} />
    </div>
  );
};

const Card = (props: PropsWithChildren<{ onClose: () => void; height?: "full" | "inline" }>) => {
  const height = props.height ?? "inline";
  const ignoreClose = useRef(false);
  return (
    <div
      className="w-full h-full justify-center items-center flex"
      onMouseUp={() => {
        if (!ignoreClose.current) props.onClose();
        ignoreClose.current = false;
      }}
    >
      <div
        className={classNames(
          "inline-flex bg-white p-4 rounded-md shadow flex-col space-y-4 max-h-full min-w-[355px]",
          height === "full" && "h-full",
          height === "inline" && ""
        )}
        onMouseDown={() => (ignoreClose.current = true)}
        onMouseUp={(ev) => {
          ev.stopPropagation();
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

const Actions = (props: PropsWithChildren) => {
  return <div className="flex space-x-2 items-center justify-end h-8">{props.children}</div>;
};

const ActionCancelButton = ({ onClick, label }: { onClick: () => void; label: string }) => (
  <button className="px-4 hover:bg-primary-1 h-full rounded" onClick={onClick}>
    {label}
  </button>
);

const ActionPrimaryButton = ({
  onClick,
  label,
  disabled,
  loading,
}: {
  disabled?: boolean;
  label: string;
  onClick: () => void | Promise<void>;
  loading?: boolean;
}) => {
  return (
    <Form.SubmitButton
      state={disabled ? FormState.DISABLED : FormState.NONE}
      label={label}
      onClick={onClick}
      onEnterKey={onClick}
      loading={loading}
    />
  );
};

const ActionDangerButton = ({
  onClick,
  label,
  disabled,
}: {
  disabled?: boolean;
  label: string;
  onClick: () => void | Promise<void>;
}) => {
  return (
    <Form.DangerButton
      label={label}
      onClick={onClick}
      state={disabled ? FormState.DISABLED : FormState.NONE}
    />
  );
};

export const Modal = {
  Portal,
  Header,
  Card,
  Actions,
  ActionCancelButton,
  ActionPrimaryButton,
  ActionDangerButton,
};
