import { observer } from "mobx-react-lite";
import { ZoneBrief } from "./ZoneBrief";
import { SiteBrief } from "./SiteBrief";
import { useEvent } from "src/hooks/useEvent";
import type { Editor } from "src/types/Editor";

export const BriefPanel = observer(function BriefPanel(props: { editor: Editor }) {
  const onUpdate = useEvent(() => {
    props.editor.regenerate(false);
  });

  const selectedZones = getSelectedZones(props.editor);
  return (
    <div className="bg-neutral-2 flex flex-col border-neutral-4 border rounded space-y-[2px] overflow-hidden pointer-events-auto w-full">
      <SiteBrief editor={props.editor} />
      <div className="overflow-auto relative space-y-[2px] scrollbar-none">
        {selectedZones.map((zone) => (
          <ZoneBrief key={zone.id} zone={zone} onUpdate={onUpdate} />
        ))}
      </div>
    </div>
  );
});

function getSelectedZones(editor: Editor) {
  if (editor.view.selection.relativeZones.length) return editor.view.selection.relativeZones;
  return editor.world?.zones ?? [];
}
