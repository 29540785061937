import type { AnnotationsMap } from "mobx";
import { computed } from "mobx";

export function computedAliveMap<T>(obj: T): AnnotationsMap<T, never> {
  return Object.fromEntries(
    getAllGetters(obj).map((key) => [key, computed({ keepAlive: true })])
  ) as AnnotationsMap<T, never>;
}
function getAllGetters(obj: unknown): string[] {
  const getters: string[] = [];
  let proto = Object.getPrototypeOf(obj);

  while (proto) {
    const descriptors = Object.getOwnPropertyDescriptors(proto);

    for (const key in descriptors) {
      if (key === "__proto__") continue;
      if (descriptors[key] && typeof descriptors[key].get === "function") {
        getters.push(key);
      }
    }

    proto = Object.getPrototypeOf(proto);
  }

  return getters;
}
