import clip from "polygon-clipping";
import { Button, ButtonState } from "src/components/Button";
import { useEvent } from "src/hooks/useEvent";
import { MergeIcon } from "src/components/Icons/MergeIcon";
import { observer } from "mobx-react-lite";
import { V3ToV2 } from "src/utils/V3ToV2";
import type { Zone } from "src/types/Zone";
import { V2ToV3 } from "src/utils/V2ToV3";
import type { Editor } from "src/types/Editor";

interface Props {
  editor: Editor;
}

export const MergeButton = observer(function MergeButton(props: Props) {
  const world = props.editor.world;
  const selection = props.editor.view.selection;
  const onClick = useEvent(() => {
    if (!world?.hasZone) return;
    if (!selection.zones.length) return;
    const selectedZonesSet = new Set(selection.zones);
    const nonSelectedZones = world.zones.filter((zone) => !selectedZonesSet.has(zone));
    const forUnion = selection.zones.map((zone) => [V3ToV2(zone.outerRing)]);
    const rest = clip.union(forUnion);

    const selectedZone = selection.zones[0];
    const merged: Zone[] = rest.map((boundary) => {
      const zone = selectedZone.clone(false);
      zone.setId(crypto.randomUUID());
      zone.setOuterRing(V2ToV3(boundary[0]));
      return zone;
    });

    const zones: Zone[] = [...nonSelectedZones, ...merged];
    console.log(zones);
    world.replaceZones(zones);
    props.editor.regenerate(true);
  });
  return (
    <Button
      title="Merge"
      Icon={MergeIcon}
      state={selection.zones.length > 1 ? ButtonState.ACTIVE : ButtonState.DISABLED}
      onClick={onClick}
    />
  );
});
