import { type V3 } from "@project-rouge/rg-core";
import { PolygonVertex } from "./PolygonVertex";
import { observer } from "mobx-react-lite";

export const PolygonVertexes = observer(function PolygonVertexes(props: {
  points: V3[];
  radiusPx: number;
  fillColor: number;
  borderWidthPx: number;
  borderColor: number;
}) {
  return (
    <>
      {props.points.map((point, i) => (
        <PolygonVertex
          key={i}
          position={point}
          radiusPx={props.radiusPx}
          fillColor={props.fillColor}
          borderWidthPx={props.borderWidthPx}
          borderColor={props.borderColor}
        />
      ))}
    </>
  );
});
