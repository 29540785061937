import { type V3 } from "@project-rouge/rg-core";
import { type ThreeEvent } from "@react-three/fiber";

import { useBoolean } from "src/hooks/useBoolean";
import { useEvent } from "src/hooks/useEvent";
import { Cluster } from "src/components/Cluster";
import { PolygonEdges } from "./PolygonEdges";
import { PolygonFace } from "./PolygonFace";
import { PolygonVertexes } from "./PolygonVertexes";
import { observer } from "mobx-react-lite";
import type { Zone } from "src/types/Zone";

interface StyleSheetRecord {
  FILL_COLOR: number;
  FILL_ALPHA: number;
  BORDER_COLOR: number;
  BORDER_ALPHA: number;
  BORDER_WIDTH: number;
  DASH_SIZE_PX?: number;
  GAP_SIZE_PX?: number;
}

interface StyleSheet {
  NONE: StyleSheetRecord;
  HOVER: StyleSheetRecord;
  SELECT: StyleSheetRecord;
  SELECT_HOVER: StyleSheetRecord;
}

export const ZONE_POLYGON_STYLE = {
  BUILDIABLE_OFFSET: {
    NONE: {
      FILL_COLOR: 0xfde7c0,
      FILL_ALPHA: 0.4,
      BORDER_COLOR: 0x000000, // 0x60666c,
      BORDER_ALPHA: 0.7,
      BORDER_WIDTH: 1,
      DASH_SIZE_PX: 4,
      GAP_SIZE_PX: 4,
    },
    HOVER: {
      FILL_COLOR: 0xfbcc7a,
      FILL_ALPHA: 0.4,
      BORDER_COLOR: 0x000000, // 0x60666c,
      BORDER_ALPHA: 0.7,
      BORDER_WIDTH: 1,
      DASH_SIZE_PX: 4,
      GAP_SIZE_PX: 4,
    },
    SELECT: {
      FILL_COLOR: 0xfde7c0,
      FILL_ALPHA: 0.4,
      BORDER_COLOR: 0x000000, // 0x60666c,
      BORDER_ALPHA: 0.7,
      BORDER_WIDTH: 1,
      DASH_SIZE_PX: 4,
      GAP_SIZE_PX: 4,
    },
    SELECT_HOVER: {
      FILL_COLOR: 0xfde7c0,
      FILL_ALPHA: 0.4,
      BORDER_COLOR: 0x000000, // 0x60666c,
      BORDER_ALPHA: 0.7,
      BORDER_WIDTH: 1,
      DASH_SIZE_PX: 4,
      GAP_SIZE_PX: 4,
    },
  },
  BUILDABLE: {
    NONE: {
      FILL_COLOR: 0xbedfdd,
      FILL_ALPHA: 0.4,
      BORDER_COLOR: 0x60666c,
      BORDER_ALPHA: 0.7,
      BORDER_WIDTH: 1,
    },
    HOVER: {
      FILL_COLOR: 0xbabade,
      FILL_ALPHA: 0.4,
      BORDER_COLOR: 0x60666c,
      BORDER_ALPHA: 0.7,
      BORDER_WIDTH: 1,
    },
    SELECT: {
      FILL_COLOR: 0x9e9ed1,
      FILL_ALPHA: 0.4,
      BORDER_COLOR: 0x58588e,
      BORDER_ALPHA: 1,
      BORDER_WIDTH: 3,
    },
    SELECT_HOVER: {
      FILL_COLOR: 0xbabade,
      FILL_ALPHA: 0.4,
      BORDER_COLOR: 0x58588e,
      BORDER_ALPHA: 1,
      BORDER_WIDTH: 3,
    },
  },
  EXCLUSION: {
    NONE: {
      FILL_COLOR: 0xf9ccd0,
      FILL_ALPHA: 0.4,
      BORDER_COLOR: 0x60666c,
      BORDER_ALPHA: 0.7,
      BORDER_WIDTH: 1,
    },
    HOVER: {
      FILL_COLOR: 0xf6b3b9,
      FILL_ALPHA: 0.5,
      BORDER_COLOR: 0x60666c,
      BORDER_ALPHA: 0.7,
      BORDER_WIDTH: 1,
    },
    SELECT: {
      FILL_COLOR: 0xf76470,
      FILL_ALPHA: 0.3,
      BORDER_COLOR: 0xf76470,
      BORDER_ALPHA: 1,
      BORDER_WIDTH: 3,
    },
    SELECT_HOVER: {
      FILL_COLOR: 0xf6b3b9,
      FILL_ALPHA: 0.5,
      BORDER_COLOR: 0xf76470,
      BORDER_ALPHA: 1,
      BORDER_WIDTH: 3,
    },
  },
} satisfies Record<string, StyleSheet>;

function GetStyle(props: { styleSheet: StyleSheet; hover?: boolean; select?: boolean }) {
  if (props.hover && props.select) return props.styleSheet.SELECT_HOVER;
  if (props.hover) return props.styleSheet.HOVER;
  if (props.select) return props.styleSheet.SELECT;
  return props.styleSheet.NONE;
}

export enum ZonePolygonType {
  BUILDABLE = "BUILDABLE",
  EXCLUSION = "EXCLUSION",
}

export const ZonePolygon = observer(
  (props: {
    zone: Zone;
    points: V3[];
    type: ZonePolygonType;
    showEdges?: boolean;
    showVertexes?: boolean;
    hoverEnabled?: boolean;
    selectEnabled?: boolean;
    selected?: boolean;
    depthTest?: boolean;
    showOffsetEdges?: boolean;
    offsets: number[] | null;
    onSelect?: (event: ThreeEvent<PointerEvent>) => void;
    onHoverChange?: (isHovered: boolean, event: ThreeEvent<PointerEvent>) => void;
  }) => {
    const vertexRadius = 6.5 / 2;
    const vertexBorderWidth = 1.5;
    const showEdges = props.showEdges ?? true;
    const showVertexes = props.showVertexes ?? true;
    const isHover = useBoolean();

    const style = GetStyle({
      styleSheet: ZONE_POLYGON_STYLE[props.type],
      hover: props.hoverEnabled && isHover.isOn,
      select: props.selectEnabled && props.selected,
    });

    const styleOffset = GetStyle({
      styleSheet: ZONE_POLYGON_STYLE.BUILDIABLE_OFFSET,
      hover: props.hoverEnabled && isHover.isOn,
      select: props.selectEnabled && props.selected,
    });

    const handleHoverEnter = useEvent((evt: ThreeEvent<PointerEvent>) => {
      isHover.on();
      props.onHoverChange?.(true, evt);
    });
    const handleHoverLeave = useEvent((evt: ThreeEvent<PointerEvent>) => {
      isHover.off();
      props.onHoverChange?.(false, evt);
    });

    return (
      <group
        onPointerEnter={handleHoverEnter}
        onPointerLeave={handleHoverLeave}
        onPointerDown={props.onSelect}
      >
        {props.zone.polygons.innerPolygons.map((polygon, i) => (
          <PolygonFace
            key={PolygonToId(polygon, i)}
            points={polygon}
            fill={style.FILL_COLOR}
            opacity={style.FILL_ALPHA}
          />
        ))}

        {props.zone.polygons.outterPolygons.map((polygon, i) => (
          <PolygonFace
            key={PolygonToId(polygon, i)}
            fill={styleOffset.FILL_COLOR}
            opacity={styleOffset.FILL_ALPHA}
            points={polygon}
            holes={props.zone.polygons.outterPolygonsHoles}
          />
        ))}
        <Cluster visible={!!props.showOffsetEdges}>
          {props.zone.polygons.innerPolygons.map((offsetPolygon, i) => (
            <PolygonEdges
              key={PolygonToId(offsetPolygon, i)}
              points={offsetPolygon}
              lineWidthPx={styleOffset.BORDER_WIDTH}
              color={styleOffset.BORDER_COLOR}
              opacity={styleOffset.BORDER_ALPHA}
              dashSizePx={styleOffset.DASH_SIZE_PX}
              gapSizePx={styleOffset.GAP_SIZE_PX}
              dashed
              y={0}
              depthTest={true}
            />
          ))}
        </Cluster>
        <Cluster visible={showEdges}>
          <PolygonEdges
            points={props.points}
            lineWidthPx={style.BORDER_WIDTH}
            color={style.BORDER_COLOR}
            opacity={style.BORDER_ALPHA}
            depthTest={props.depthTest}
            offsetPx={showVertexes ? vertexBorderWidth + vertexRadius : 0}
          />
        </Cluster>
        <Cluster visible={showVertexes}>
          <PolygonVertexes
            points={props.points}
            radiusPx={vertexRadius}
            fillColor={0xffffff}
            borderWidthPx={vertexBorderWidth}
            borderColor={0x58588e}
          />
        </Cluster>
      </group>
    );
  }
);

function PolygonToId(polygon: V3[], index: number) {
  return `${polygon.join()}-${index}`;
}
