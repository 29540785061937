import { useMemo } from "react";
import { type Updater, useImmer } from "use-immer";
import type { World } from "src/types/World";

interface BuildingOption {
  id: string;
  label: string;
}

interface ZoneOption {
  id: string;
  label: string;
  buildings: BuildingOption[];
}
interface Selection {
  zone: null | string;
  building: null | string;
  level: number;
  labels: boolean;
}

export interface UseControls {
  zonesOptions: ZoneOption[];
  selectedZone: ZoneOption | null;
  selectedBuilding: BuildingOption | null;
  selectedLevel: number;
  labelsVisible: boolean;
  maxLevel: number;
  updateSelection: Updater<Selection>;
}

export function useControls(world: World | null): UseControls {
  const [selection, updateSelection] = useImmer<Selection>({
    zone: null,
    building: null,
    level: 0,
    labels: false,
  });
  const { zones, maxLevel } = useZones(world);

  const selectedZone = useMemo(
    () => zones.find(({ id }) => id === selection.zone) ?? null,
    [zones, selection.zone]
  );

  const selectedBuilding = useMemo(() => {
    const buildings = selectedZone?.buildings ?? zones.flatMap(({ buildings }) => buildings);
    return buildings.find(({ id }) => id === selection.building) ?? null;
  }, [zones, selectedZone?.buildings, selection.building]);

  const selectedLevel = selection.level;
  const labelsVisible = selection.labels;

  return {
    zonesOptions: zones,
    selectedZone,
    selectedBuilding,
    selectedLevel,
    labelsVisible,
    maxLevel,
    updateSelection,
  };
}

function useZones(world: World | null) {
  return useMemo(() => {
    const zoneOptions: ZoneOption[] = [];
    let maxLevel = 0;
    if (!world) return { zones: zoneOptions, maxLevel };
    const designZones = new Map(
      world.sites.flatMap(({ zones }) => zones).map((zone) => [zone.id, zone])
    );
    const zones = world.zones;

    for (const zone of zones) {
      const buildings = zone.buildings;
      const designZone = designZones.get(zone.uuid);
      if (!designZone) throw new Error(`Zone ${zone.uuid} not found in design`);
      const zoneOption: ZoneOption = {
        id: zone.uuid,
        label: designZone.label,
        buildings: [],
      };

      for (let i = 0; i < buildings.length; i++) {
        const building = buildings[i];
        const level = building.data.levels.length;
        const buildingOption: BuildingOption = {
          id: building.uuid,
          label: building.label,
        };
        zoneOption.buildings.push(buildingOption);
        maxLevel = Math.max(maxLevel, level);
      }

      zoneOptions.push(zoneOption);
    }
    return { zones: zoneOptions, maxLevel };
  }, [world]);
}
