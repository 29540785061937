import { useParams } from "react-router-dom";
import { useActiveScenarioId } from "./useActiveScenarioId";

export const useActiveParams = () => {
  const params = useParams<{
    projectId: string | undefined;
    "*": string | undefined;
  }>();
  const designId = params["*"]?.match(/designs\/(.*)/)?.[1];
  const scenarioId = useActiveScenarioId(params.projectId ?? "");
  const { projectId } = params;
  return { projectId, scenarioId, designId };
};
