import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

type Params = {
  designId: string | number;
  scenarioId: string;
  projectId: string;
};

export function useRedirect() {
  // const push = useAsyncHistory();
  const push = useNavigate();
  const project = useCallback(
    async ({ projectId }: Pick<Params, "projectId">) => await push(`/projects/${projectId}`),
    [push]
  );
  const home = useCallback(async () => await push(`/`), [push]);
  const scenario = useCallback(
    async ({ projectId, scenarioId }: Pick<Params, "projectId" | "scenarioId">) => {
      return await push(`/projects/${projectId}/${scenarioId}`);
    },
    [push]
  );
  const signUp = useCallback(async () => await push(`/signup`), [push]);
  const login = useCallback(async () => await push(`/login`), [push]);
  const profile = useCallback(async () => await push(`/profile`), [push]);

  const scenarioCanvas = useCallback(
    async ({ projectId }: { projectId: string }) => await push(`/projects/${projectId}/canvas`),
    [push]
  );

  const projectDesign = useCallback(
    async ({ projectId, designId }: { projectId: string; designId: string }) =>
      await push(`/projects/${projectId}/designs/${designId}`),
    [push]
  );

  return useMemo(
    () => ({
      /** @deprecated */
      /** @deprecated */
      scenario,
      project,
      home,
      signUp,
      login,
      profile,
      scenarioCanvas,
      projectDesign,
    }),
    [home, login, profile, project, projectDesign, scenario, scenarioCanvas, signUp]
  );
}
