import { useMemo } from "react";
import { type V3 } from "@project-rouge/rg-core";
import { useWindowEvent } from "src/hooks/useWindowEvent";
import { useGetSnapVector } from "src/hooks/useSnapMouse";
import { IsVectorOnVertex } from "src/utils/IsVectorOnVertex";
import { useLiveRef } from "src/hooks/useLiveRef";
import { MergePolygonContinousVertexes } from "src/utils/MergePolygonVertexes";
import { ZonePolygon, ZonePolygonType } from "src/components/ZonePolygon";
import { IsVectorEqual } from "src/utils/IsVectorEqual";
import { PolygonEdges } from "src/components/PolygonEdges";
import { PolygonVertex } from "src/components/PolygonVertex";
import { Measurement } from "src/components/Measurement";
import { observer, useLocalObservable } from "mobx-react-lite";
import { observable, runInAction } from "mobx";
import type { Zone } from "src/types/Zone";
import { World } from "src/types/World";

function GetVertexesAtPosition(
  source: V3,
  localZones: Zone[]
): Array<[polygonIndex: number, vertexIndex: number]> {
  const list: Array<[number, number]> = [];
  localZones.forEach((localZone, pi) => {
    localZone.outerRing.forEach((vector, vi) => {
      if (IsVectorEqual(vector, source)) list.push([pi, vi]);
    });
  });
  return list;
}

function GetAnchorPoints(
  selectedVertexes: Array<[polygonIndex: number, vertexIndex: number]>,
  localZones: Zone[]
) {
  const anchorPoints: V3[] = [];
  for (const [polygonIndex, vertexIndex] of selectedVertexes) {
    const zone = localZones[polygonIndex];
    const startIndex = vertexIndex === 0 ? -2 : vertexIndex - 1;
    const endIndex = vertexIndex === zone.outerRing.length - 1 ? 1 : vertexIndex + 1;
    const start = zone.outerRing.at(startIndex);
    const end = zone.outerRing.at(endIndex);
    if (!start || !end) continue;
    if (anchorPoints.every((point) => !IsVectorOnVertex(start, point, 0.01))) {
      anchorPoints.push(start);
    }
    if (anchorPoints.every((point) => !IsVectorOnVertex(end, point, 0.01))) {
      anchorPoints.push(end);
    }
  }
  return anchorPoints;
}

function GetStaticAffectedPolygonLines(
  selectedVertexes: Array<[polygonIndex: number, vertexIndex: number]>,
  localZones: Zone[]
) {
  const lines: [V3, V3][] = [];
  localZones.forEach((zone, zoneIndex) => {
    if (selectedVertexes.every(([zi]) => zi !== zoneIndex)) return;
    for (let pointIndex = 0; pointIndex < zone.outerRing.length - 1; pointIndex++) {
      // check if is connected
      const connected = selectedVertexes.find(([polygonIndex, vertexIndex]) => {
        if (zoneIndex !== polygonIndex) return false;
        if (pointIndex === vertexIndex - 1) return true;
        if (pointIndex === vertexIndex) return true;
      });
      if (connected) continue;
      const a = zone.outerRing[pointIndex];
      const b = zone.outerRing[pointIndex + 1];
      lines.push([a, b]);
    }
  });
  return lines;
}

interface Data {
  draftZones: Zone[];
  selectedVertexes: [polygonIndex: number, vertexIndex: number][];
  anchorPoints: V3[];
  mouse: V3;
  staticAffectedPolygonLines: [V3, V3][];
}

export const MoveVertexMode = observer(function MoveVertexMode(props: {
  zones: Zone[];
  snapTolerance: number;
  startPosition: V3;
  updatePolygons: (polygons: Zone[]) => void;
}) {
  const getSnapVector = useGetSnapVector();
  const propsRefs = useLiveRef(props);
  const vertexRadius = 6.5 / 2;
  const vertexBorderWidth = 1.5;
  const vertexOffset = vertexBorderWidth + vertexRadius;
  const data = useLocalObservable<Data>(() => {
    const draftZones = props.zones.map((zone) => zone.clone(false));
    const selectedVertexes = GetVertexesAtPosition(props.startPosition, draftZones);
    const anchorPoints = GetAnchorPoints(selectedVertexes, draftZones);
    const staticAffectedPolygonLines = GetStaticAffectedPolygonLines(selectedVertexes, draftZones);
    return observable({
      draftZones,
      selectedVertexes,
      anchorPoints,
      mouse: props.startPosition,
      staticAffectedPolygonLines,
    });
  });

  useWindowEvent("mousemove", (evt) => {
    const snapPolygons = props.zones.map(({ outerRing }) => outerRing);
    const position = getSnapVector({
      polygons: snapPolygons,
      snapEdge: true,
      snapVertex: true,
      tolerance: propsRefs.current.snapTolerance,
      strict: false,
      evt,
    });
    runInAction(() => {
      data.draftZones.forEach(() => {
        for (const [polygonIndex, vertexIndex] of data.selectedVertexes) {
          const localZone = data.draftZones[polygonIndex];
          const ring = [...localZone.outerRing];
          ring[vertexIndex] = position;
          if (vertexIndex === 0) ring[ring.length - 1] = position;
          localZone.setOuterRing(ring);
        }
      });
      data.mouse = position;
    });
  });

  useWindowEvent("mouseup", () => {
    World.sanitizeZonesOuterRing(data.draftZones);
    const zones: Zone[] = data.draftZones;
    zones.forEach((zone) => {
      const offset = new Set(zone.offsets);
      const uniformOffset = offset.size === 1 ? zone?.offsets?.at(0) : undefined;
      zone.setOuterRing(MergePolygonContinousVertexes(zone.outerRing, 0.01));
      zone.offsets = !zone.offsets
        ? null
        : zone.outerRing.length - 1 === zone.offsets.length
        ? zone.offsets
        : !!uniformOffset
        ? Array(zone.outerRing.length - 1).fill(uniformOffset)
        : null;
    });
    propsRefs.current.updatePolygons(zones);
  });
  const annotatedPoints = useMemo(
    () => data.anchorPoints.filter((a) => !IsVectorEqual(a, props.startPosition)),
    [data.anchorPoints, props.startPosition]
  );
  return (
    <>
      {props.zones.map((zone) => (
        <ZonePolygon
          zone={zone}
          key={zone.id}
          offsets={zone.offsets}
          points={zone.outerRing}
          type={
            zone.zoneType === "buildable" ? ZonePolygonType.BUILDABLE : ZonePolygonType.EXCLUSION
          }
        />
      ))}
      {data.staticAffectedPolygonLines.map((line, i) => (
        <PolygonEdges
          key={i}
          color={0x58588e}
          lineWidthPx={2}
          offsetPx={vertexOffset}
          points={line}
        />
      ))}
      {data.anchorPoints.map((point, i) => (
        <PolygonEdges
          key={i}
          color={0x58588e}
          lineWidthPx={2}
          offsetPx={vertexOffset}
          points={[point, data.mouse]}
          dashed
          dashSizePx={3}
          gapSizePx={1}
        />
      ))}
      <PolygonEdges points={[data.mouse, props.startPosition]} color={0x9e9ed1} lineWidthPx={1.5} />
      <PolygonVertex
        position={data.mouse}
        borderColor={0xffffff}
        borderWidthPx={1.5}
        radiusPx={6.5 / 2}
        fillColor={0xbabade}
      />
      {annotatedPoints.map((anchor, i) => (
        <Measurement key={i} start={anchor} end={data.mouse} />
      ))}
    </>
  );
});
