import { useCallback, useEffect, useRef, useState } from "react";

// This is arbitary value that worked best, might need refactoring to suit future layout
const padding = [0, 0, 3, 0];

/** set the svg `viewBox` to fit all content inside parent available space */
export function useSvgContainerResizer(svgElement: SVGSVGElement | null) {
  const wrapper = useRef<SVGSVGElement>();
  const [scale, setScale] = useState(1);
  const updateScale = useCallback(() => {
    if (!svgElement) return;
    if (!svgElement.parentElement) return;
    if (!wrapper.current) return;
    const box = wrapper.current.getBBox();

    const x = box.x - padding[3];
    const y = box.y - padding[0];
    const width = box.width + padding[3] + padding[1];
    const height = box.height + padding[0] + padding[2];

    svgElement.setAttribute("viewBox", [x, y, width, height].join(" "));
    setScale(
      Math.max(
        width / svgElement.parentElement.clientWidth,
        height / svgElement.parentElement.clientHeight
      )
    );
  }, [svgElement]);

  useEffect(() => {
    if (!svgElement) return;
    if (!svgElement.parentElement) return;
    wrapper.current = svgElement.children[0] as SVGSVGElement;
    if (!wrapper.current) return;

    const observer = new ResizeObserver(updateScale);
    observer.observe(wrapper.current);

    return () => observer.disconnect();
  }, [svgElement, updateScale]);

  return { scale };
}
