import type { PropsWithChildren } from "react";
import { useRef, useState } from "react";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
  arrow,
  FloatingArrow,
} from "@floating-ui/react";

export const Tooltip = (props: PropsWithChildren<{ text: string; className?: string }>) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "top",
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        fallbackAxisSideDirection: "start",
      }),
      shift(),
    ],
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });
  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()} className={props.className}>
        {props.children}
      </div>
      <FloatingPortal>
        {isOpen && (
          <div
            className="bg-neutral-8 text-white px-2 py-0.5 rounded-sm shadow-md shadow-elevation-2 z-50"
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <pre className="text-body-2">{props.text}</pre>
            <Arrow />
          </div>
        )}
      </FloatingPortal>
    </>
  );
};

const Arrow = () => {
  const arrowRef = useRef(null);
  const { refs, floatingStyles, context } = useFloating({
    placement: "top",
    middleware: [
      arrow({
        element: arrowRef,
      }),
    ],
  });

  return (
    <>
      <div ref={refs.setReference} />
      <div ref={refs.setFloating} style={floatingStyles}>
        <FloatingArrow ref={arrowRef} context={context} />
      </div>
    </>
  );
};
