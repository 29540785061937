import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";
import { Replay } from "@sentry/browser";
import { ENV } from "./constants/env";

/**
 * Attempt to sanitise some possible values from environment variables.
 */
const sanitise = (value: string): string | undefined => {
  return value === undefined || "" ? undefined : value;
};

// These are needed like this, do not change.
// Vite does find and replace, and I need the values sanitised.
const SENTRY_DSN = sanitise(ENV.SENTRY_DSN);
const SENTRY_ENVIRONMENT = sanitise(ENV.SENTRY_ENVIRONMENT);
const SENTRY_RELEASE = sanitise(ENV.SENTRY_RELEASE);

// Sentry should only be ran when we have the correct amount of configuration.
// It should also not be ran on local machines, this will pollute the event logs.
// Instead, locally use your console, for other environments we should have these variables.
// The release variable is not to be checked.
if (SENTRY_DSN !== undefined && SENTRY_ENVIRONMENT !== undefined) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    release: SENTRY_RELEASE,

    integrations: [new BrowserTracing(), new Replay({ maskAllText: true, blockAllMedia: true })],

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // This might need refining later, but for now lets collect it all.
    tracesSampleRate: 1.0,
  });
}

export function setSentryUser(
  user: {
    id: string;
    sessionId: string | null;
  } | null
) {
  if (!user) {
    Sentry.setUser(null);
    return;
  }

  Sentry.setUser({
    id: user.id,
  });
  Sentry.setContext("session", user.sessionId ? { id: user.sessionId } : null);
}
