import { makeAutoObservable } from "mobx";
import type { RgBrief } from "@project-rouge/rg-core";
import type { ProjectScenarioHttpResource } from "@project-rouge/service-project-client/resource/project-scenario";

export interface WorldBriefConfig {
  buildingSeparation: number;
}
export class WorldBrief {
  static readonly DEFAULT_BUILDING_SEPARATION = 7;
  buildingSeparation: number;
  constructor(config?: Partial<WorldBriefConfig>) {
    this.buildingSeparation = config?.buildingSeparation ?? WorldBrief.DEFAULT_BUILDING_SEPARATION;
    makeAutoObservable(this);
  }
  setBuildingSeparation(value: number) {
    this.buildingSeparation = value;
  }
  get beBrief(): ProjectScenarioHttpResource["brief"] {
    return {
      building_separation: this.buildingSeparation,
    };
  }
  get rgWorldBrief(): Omit<RgBrief, "dataset" | "obj" | "zoneBriefs"> {
    return {
      face2FaceSeparation: this.buildingSeparation,
    };
  }

  get config(): WorldBriefConfig {
    return {
      buildingSeparation: this.buildingSeparation,
    };
  }
}
