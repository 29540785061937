import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "src/contexts/auth.context";
import { useRedirect } from "src/hooks/useRedirect";

export const SsoLoginScreen = () => {
  const auth = useAuth();
  const { adminToken, actorToken } = useParams<{ adminToken: string; actorToken: string }>();
  const redirect = useRedirect();
  useEffect(() => {
    if (!adminToken || !actorToken) return;
    auth.loginSso({ adminToken, actorToken }).then(() => {
      window.location.pathname = "";
    });
  }, [actorToken, adminToken, auth, redirect]);
  return (
    <div className="w-screen h-screen justify-center items-center flex-1 flex">
      <span>loading proxy session...</span>
    </div>
  );
};
