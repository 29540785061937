import { type V3 } from "@project-rouge/rg-core";

export function SnapVectorToVector([x1, , y1]: V3, polygons: V3[][], tolerance: number) {
  let closestPoint = { distance: Infinity, position: [0, 0, 0] as V3 };
  for (const polygon of polygons) {
    for (const [x2, , y2] of polygon) {
      const distance = GetDistance(x1, y1, x2, y2);
      if (distance < closestPoint.distance) {
        closestPoint = {
          distance,
          position: [x2, 0, y2],
        };
      }
    }
  }
  return closestPoint.distance < tolerance ? closestPoint.position : undefined;
}

function GetDistance(x1: number, y1: number, x2: number, y2: number): number {
  const deltaX = x2 - x1;
  const deltaY = y2 - y1;
  const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
  return distance;
}
