import { ToggleHeaderInput } from "./ToggleHeaderInput";
import type { SelectInputOption } from "./SelectInput";
import { SelectInput } from "./SelectInput";
import { useRef, useState } from "react";
import classNames from "classnames";
import { useEvent } from "src/hooks/useEvent";
import { observer } from "mobx-react-lite";
import type { Zone } from "src/types/Zone";

const usageOptions: SelectInputOption[] = [{ label: "Commercial", id: "commercial" }];

export const PodiumSection = observer(function PodiumSection(props: {
  zone: Zone;
  onUpdate: () => void;
}) {
  const brief = props.zone.brief;
  const podium = props.zone.brief.podium;
  const onToggle = useEvent(() => {
    props.zone.brief.togglePodium();
    props.onUpdate();
  });
  const onFloorCountChange = useEvent((value: number) => {
    if (!brief.podium) return;
    brief.podium.setFloorCount(value);
    props.onUpdate();
  });
  const onHeightChange = useEvent((value: number) => {
    if (!brief.podium) return;
    brief.podium.setHeight(value);
    props.onUpdate();
  });
  return (
    <div className="flex flex-col space-y-2">
      <ToggleHeaderInput label="Podium" checked={!!podium} onCheckedChange={onToggle} />
      {podium && (
        <div className="space-y-1">
          <SelectInput
            label="Use"
            options={usageOptions}
            selected="commercial"
            onChange={() => {}}
          />
          <NumberInput label="Levels" value={podium.floorCount} onChange={onFloorCountChange} />
          <NumberInput label="Height" value={podium.height} onChange={onHeightChange} suffix="m" />
        </div>
      )}
    </div>
  );
});

const NumberInput = observer(function NumberInput(props: {
  label: string;
  value: number;
  suffix?: string;
  onChange?: (value: number) => void;
}) {
  const [draft, setDraft] = useState<string | null>(null);
  const onSubmit = () => {
    if (draft === null) return;
    const value = Number(draft);
    if (!isNaN(value) && props.value !== value) {
      props.onChange?.(value);
    }
    setDraft(null);
  };
  const ref = useRef<HTMLInputElement>(null);
  const value = draft ?? props.value;
  return (
    <div className="flex flex-col">
      <div className="flex flex-row text-body-1 text-neutral-7 h-[24px] items-center">
        <span className="flex-1">{props.label}</span>
        <div className="rounded-sm border border-neutral-5 w-[136px] flex flex-row relative h-full items-center">
          <input
            ref={ref}
            value={value}
            onInput={(evt) => setDraft(evt.currentTarget.value)}
            className={classNames(
              "text-right w-full absolute h-full",
              props.suffix && "pr-5",
              !props.suffix && "px-[6px]"
            )}
            onBlur={onSubmit}
            onKeyDown={(evt) => {
              if (evt.key === "Enter") ref.current?.blur();
            }}
          />
          {props.suffix && (
            <span className="text-neutral-6 pr-1 absolute right-0">{props.suffix}</span>
          )}
        </div>
      </div>
    </div>
  );
});
