import { useFloating, offset, autoPlacement, autoUpdate, FloatingPortal } from "@floating-ui/react";
import { HorizontalSeparator } from "./Navigator/HorizontalSeparator";
import {
  type ReactNode,
  type PropsWithChildren,
  type FunctionComponent,
  useLayoutEffect,
} from "react";
import classNames from "classnames";
export enum Intent {
  INFO = "INFO",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
  DANGER = "DANGER",
  NONE = "NONE",
}
function Separator() {
  return (
    <div className="py-1">
      <HorizontalSeparator />
    </div>
  );
}

type RefRoot = HTMLElement | null;

function Floating(props: PropsWithChildren<{ onHide: () => void; refRoot: RefRoot }>) {
  const { refs, floatingStyles, update } = useFloating({
    placement: "right-start",
    middleware: [offset(4), autoPlacement({ alignment: "start" })],
  });
  useLayoutEffect(() => {
    refs.setReference(props.refRoot);
  }, [props.refRoot, refs]);
  useLayoutEffect(() => {
    if (!refs.reference.current || !refs.floating.current) return;
    return autoUpdate(refs.reference.current, refs.floating.current, update, {
      ancestorResize: true,
    });
  }, [refs.floating, refs.reference, update]);
  useLayoutEffect(() => {
    window.addEventListener("mousedown", props.onHide);
    return () => window.removeEventListener("mousedown", props.onHide);
  }, [props.onHide]);
  return (
    <FloatingPortal>
      <div
        ref={refs.setFloating}
        style={{ ...floatingStyles, zIndex: 1000 }}
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onMouseLeave={props.onHide}
      >
        {props.children}
      </div>
    </FloatingPortal>
  );
}

function Card(props: PropsWithChildren) {
  return (
    <div className="bg-elevation-0 text-sm shadow-md shadow-elevation-2 rounded py-1 flex flex-col">
      {props.children}
    </div>
  );
}

function Button(props: {
  label: string;
  intent?: Intent;
  onClick: (evt: React.MouseEvent) => void;
  Icon: (props: { className: string }) => ReactNode;
}) {
  return (
    <button
      onClick={props.onClick}
      className={classNames(
        "flex flex-row flex-1 items-center space-x-2 hover:bg-primary-1 py-1 pl-4 pr-6",
        "text-body-1 whitespace-nowrap",
        props.intent === Intent.DANGER ? "text-danger-7" : "text-neutral-8"
      )}
    >
      <props.Icon
        className={classNames(
          "w-4",
          props.intent === Intent.DANGER ? "text-danger-6" : "text-neutral-7"
        )}
      />
      <span>{props.label}</span>
    </button>
  );
}

export const ContextMenu: FunctionComponent<
  PropsWithChildren<{ visible: boolean; onHide: () => void; refRoot: RefRoot }>
> & {
  Separator: typeof Separator;
  Card: typeof Card;
  Button: typeof Button;
} = (props) => {
  if (!props.visible) return null;
  return (
    <Floating onHide={props.onHide} refRoot={props.refRoot}>
      {props.children}
    </Floating>
  );
};
ContextMenu.Separator = Separator;
ContextMenu.Card = Card;
ContextMenu.Button = Button;
