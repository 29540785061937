import { useQuery } from "@tanstack/react-query";
import { useApi } from "src/contexts/api.context";

export const ProjectsQueryKey = ["PROJECTS"];

export interface Project {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  notes: string | null;
  designsCount: number;
}

export function useProjectsQuery() {
  const api = useApi();
  return useQuery({
    queryKey: ProjectsQueryKey,
    queryFn: async (): Promise<Project[]> => {
      const response = await api.getProjectCollection();
      if (!response) return [];
      const beProjects = response.results;
      return beProjects.map((project) => ({
        id: project.id,
        name: project.label,
        createdAt: project.created_at,
        updatedAt: project.updated_at,
        notes: project.notes,
        designsCount: project.project_scenario_design_option_count,
      }));
    },
  });
}
