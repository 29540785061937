import { type ThreeEvent } from "@react-three/fiber";
import { ZonePolygon, ZonePolygonType } from "src/components/ZonePolygon";
import { observer } from "mobx-react-lite";
import type { Selection } from "src/types/Selection";
import type { Zone } from "src/types/Zone";

export const PreviewMode = observer(
  (props: {
    localZones: Zone[];
    showVertexes: boolean;
    hoverEnabled: boolean;
    selection?: Selection;
    selectEnabled?: boolean;
    onSelect?: (id: string, evt: ThreeEvent<PointerEvent>) => void;
  }) => {
    return (
      <>
        {props.localZones.map((localZone) => (
          <ZonePolygon
            zone={localZone}
            key={localZone.id}
            points={localZone.outerRing}
            type={
              localZone.zoneType === "buildable"
                ? ZonePolygonType.BUILDABLE
                : ZonePolygonType.EXCLUSION
            }
            showVertexes={props.showVertexes}
            hoverEnabled={props.hoverEnabled}
            selected={props.selectEnabled ? props.selection?.hasZone(localZone.id) : undefined}
            onSelect={
              props.selectEnabled ? (evt) => props.onSelect?.(localZone.id, evt) : undefined
            }
            selectEnabled={props.selectEnabled}
            depthTest={true}
            offsets={localZone.offsets}
          />
        ))}
      </>
    );
  }
);
