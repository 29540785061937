import { ViewValue } from "./ViewValue";
export enum State {
  FILLED = "FILLED",
  EMPTY = "EMPTY",
  LOADING = "LOADING",
}
export const InspectorRow = (props: {
  label: string;
  value?: number | undefined;
  unit?: string;
  state: State;
  decimalPoint?: number;
  currency?: string;
}) => {
  return (
    <div
      className="w-[248px] bg-white py-[6px] flex justify-between 
      border-b-[1px] border-neutral-3 text-body-1 text-neutral-8"
    >
      <span className=" text-neutral-6">{props.label}</span>
      <div className="text-end flex items-center">
        {props.currency && <span className=" text-neutral-6">{props.currency}</span>}
        {props.state === State.FILLED && (
          <ViewValue value={props.value} decimalPoint={props.decimalPoint} />
        )}
        {props.state === State.LOADING && (
          <div className="mx-1 w-3 h-3 border-[2px] rounded-full border-primary-6 border-t-transparent animate-spin" />
        )}
        <span className="text-neutral-6 text-body-1 text-start w-[29px]">{props.unit}</span>
      </div>
    </div>
  );
};
