import { makeAutoObservable, observable } from "mobx";
import type { World } from "./World";
import type { Zone } from "./Zone";
import type { Building } from "./Building";

export const enum SelectionType {
  BUIDING = "BUIDING",
  ZONE = "ZONE",
  NONE = "NONE",
}

export class Selection {
  private _zones: Set<string>;
  private _buildings: Set<string>;
  private world: World | null;
  constructor(scenario: World | null) {
    this.world = scenario;
    this._zones = new Set();
    this._buildings = new Set();
    makeAutoObservable<Selection, "world">(this, { world: observable.ref });
  }
  setWorld(scenario: World | null) {
    this.world = scenario;
  }
  get type(): SelectionType {
    if (this.buildings.length) return SelectionType.BUIDING;
    if (this.zones.length) return SelectionType.ZONE;
    return SelectionType.NONE;
  }
  get zones(): Zone[] {
    if (!this.world) return [];
    return this.world.zones.filter((z) => this._zones.has(z.id));
  }
  get buildings(): Building[] {
    if (!this.world) return [];
    return this.world.buildings.filter((b) => this._buildings.has(b.uuid));
  }

  /** returns either selected zones or zones from selected buildings */
  get relativeZones(): Zone[] {
    if (!this.world) return [];
    if (this.zones.length) return this.zones;
    const zones: Zone[] = [];
    for (const building of this.buildings) {
      const zone = building.parent?.parent;
      if (zone) zones.push(zone);
    }
    return zones;
  }
  hasZone(id: string) {
    return this._zones.has(id);
  }
  setZones(ids: string[]) {
    this._zones = new Set(ids);
  }
  addZone(id: string) {
    this._zones.add(id);
  }
  removeZone(id: string) {
    this._zones.delete(id);
  }
  clearZones() {
    this._zones.clear();
  }
  hasBuilding(id: string) {
    return this._buildings.has(id);
  }
  setBuildings(ids: string[]) {
    this._buildings = new Set(ids);
  }
  addBuilding(id: string) {
    this._buildings.add(id);
  }
  removeBuilding(id: string) {
    this._buildings.delete(id);
  }
  clearBuildings() {
    this._buildings.clear();
  }
}
