import { BookmarkIcon } from "@heroicons/react/24/outline";
import { useSavedDesignsList } from "./useSavedDesignsList";
import { ListLoader } from "./ListLoader";
import { DesignListItem } from "./DesignListItem";
import type { Editor } from "src/types/Editor";
import type { UseBoolean } from "src/hooks/useBoolean";

export function SavedDesigns(props: { editor: Editor; expand: UseBoolean }) {
  const designList = useSavedDesignsList();
  return (
    <ListLoader loading={designList.isLoading}>
      <div className="flex flex-col flex-1 overflow-hidden">
        <Header designsCount={designList.data.length} expand={props.expand} />
        <List editor={props.editor} expand={props.expand} />
      </div>
    </ListLoader>
  );
}

function Header(props: { designsCount: number; expand: UseBoolean }) {
  return (
    <div className="flex flex-1 pb-[12px] pt-[16px] grow-0 text-overline px-[12px] justify-center">
      {props.expand.isOn && <span className="flex-1 truncate flex">SAVED DESIGNS</span>}
      <span className="text-neutral-5">{props.designsCount}</span>
    </div>
  );
}

function List(props: { editor: Editor; expand: UseBoolean }) {
  const designList = useSavedDesignsList();
  if (!designList.data.length) return <EmptyIcon expand={props.expand} />;
  return (
    <div className="flex flex-1 flex-col overflow-y-auto scrollbar-none">
      <ul className="text-neutral-7 text-subtitle-2">
        {designList.data.map((item) => (
          <DesignListItem editor={props.editor} key={item.id} item={item} expand={props.expand} />
        ))}
      </ul>
    </div>
  );
}

function EmptyIcon(props: { expand: UseBoolean }) {
  if (props.expand.isOff) return null;
  return (
    <div className="flex flex-1 items-center pt-[60px] flex-col space-y-4">
      <BookmarkIcon className="w-[64px] h-[64px] p-5 bg-secondary-1 rounded-full text-secondary-6" />
      <span className="text-neutral-6 text-subtitle-2">No Saved Designs</span>
    </div>
  );
}
