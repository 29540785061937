/* eslint-disable no-restricted-globals */
import { RG_TYPE, type V3 } from "@project-rouge/rg-core";
import {
  V3toVector3,
  dispose,
  getAbstractMeshProps,
  updateAbstractSiteInstaMesh,
  getRgObjectsOfType,
} from "@project-rouge/rg-three";
import { type RgBuilding, type RgWorld, type RgZone } from "src/types/RgCorePackage";
import {
  Box3,
  BoxGeometry,
  type ColorRepresentation,
  DynamicDrawUsage,
  HemisphereLight,
  InstancedMesh,
  MathUtils,
  MeshLambertMaterial,
  OrthographicCamera,
  PerspectiveCamera,
  Scene,
  Vector3,
  WebGLRenderer,
} from "three";

interface SiteImageProps {
  width: number;
  height: number;
  orthographic: boolean;
  camDir: V3;
  rgWorld: RgWorld;
}

export class ThumbnailRenderer {
  private static ADD_TO_TOP = 3;

  private static FrameToRgWorld({
    rgWorld,
    camera,
    canvas,
    direction = new Vector3(1, -1, 1),
  }: {
    rgWorld: RgWorld;
    camera: PerspectiveCamera | OrthographicCamera;
    direction?: Vector3;
    canvas: HTMLCanvasElement | OffscreenCanvas;
  }) {
    const points = getRgObjectsOfType<RgZone>(rgWorld, RG_TYPE.Zone)
      .flatMap((zone) => zone.data.polygons)
      .flat()
      .map(V3toVector3);
    const height =
      ThumbnailRenderer.ADD_TO_TOP +
      Math.max(
        ...getRgObjectsOfType<RgBuilding>(rgWorld, RG_TYPE.Building)
          .flatMap((building) => building.data.levels)
          .flatMap((lvl) => lvl.floorWorldBottom)
      );
    const box = new Box3().setFromPoints(points);
    box.expandByVector(new Vector3(0, height, 0));
    const boxSize = box.getSize(new Vector3());
    const center = box.getCenter(new Vector3());

    // update camera position and look at
    if (camera instanceof OrthographicCamera) {
      camera.position.copy(center).sub(direction.clone().normalize().multiplyScalar(500));
      camera.lookAt(center);
      const screenBox = ThumbnailRenderer.CameraToScreenBox(camera, box);
      const screenBoxSize = screenBox.getSize(new Vector3());
      if (screenBoxSize.x > screenBoxSize.y) {
        camera.zoom = canvas.width / screenBoxSize.x;
      } else {
        camera.zoom = canvas.height / screenBoxSize.y;
      }
      camera.updateProjectionMatrix();
      camera.updateMatrix();
    } else {
      const halfSizeToFitOnScreen = boxSize.length() * 0.5;
      const halfFovY = MathUtils.DEG2RAD * camera.fov * 0.5;
      const distance = halfSizeToFitOnScreen / Math.tan(halfFovY);
      camera.position.copy(direction).multiplyScalar(-distance).add(center);
      camera.lookAt(center);
    }
  }

  /** convert Box3 to screen coords */
  private static CameraToScreenBox(camera: OrthographicCamera, box: Box3) {
    camera.updateWorldMatrix(true, true);
    const vertices = [
      new Vector3(box.min.x, box.min.y, box.min.z),
      new Vector3(box.min.x, box.max.y, box.min.z),
      new Vector3(box.min.x, box.min.y, box.max.z),
      new Vector3(box.min.x, box.max.y, box.max.z),
      new Vector3(box.max.x, box.max.y, box.max.z),
      new Vector3(box.max.x, box.max.y, box.min.z),
      new Vector3(box.max.x, box.min.y, box.max.z),
      new Vector3(box.max.x, box.min.y, box.min.z),
    ].map((v) => v.applyMatrix4(camera.matrixWorldInverse));

    const screenBox = new Box3().setFromPoints(vertices);
    return screenBox;
  }

  private static ResizeRenderer({
    renderer,
    width,
    height,
    camera,
  }: {
    renderer: WebGLRenderer;
    width: number;
    height: number;
    camera: PerspectiveCamera | OrthographicCamera;
  }) {
    renderer.setSize(width, height, false);

    if (camera instanceof OrthographicCamera) {
      camera.left = -width * 0.5;
      camera.right = width * 0.5;
      camera.top = height * 0.5;
      camera.bottom = -height * 0.5;
    } else {
      camera.aspect = width / height;
    }

    camera.updateProjectionMatrix();
  }
  private static CreateThree({
    canvas,
    orthographic,
    bgColor = 0xffffff,
    addLights,
    size,
  }: {
    canvas: OffscreenCanvas | HTMLCanvasElement;
    orthographic?: boolean;
    bgColor?: ColorRepresentation;
    addLights?: boolean;
    size?: [x: number, y: number];
  }) {
    if (!size) {
      if (canvas instanceof OffscreenCanvas) size = [canvas.width, canvas.height];
      else size = [canvas.clientWidth, canvas.clientHeight];
    }
    // set renderer
    const renderer = new WebGLRenderer({ antialias: true, canvas });
    renderer.setSize(size[0], size[1], false);
    renderer.setClearColor(bgColor);

    // set camera

    let camera: PerspectiveCamera | OrthographicCamera;

    if (orthographic) {
      camera = new OrthographicCamera(
        -size[0] * 0.5,
        size[0] * 0.5,
        size[1] * 0.5,
        -size[1] * 0.5,
        0.1,
        1000
      );
    } else {
      camera = new PerspectiveCamera(70, size[0] / size[1], 0.1, 1000);
    }

    // set scene
    const scene = new Scene();

    if (addLights) {
      const light1 = new HemisphereLight(0xffffff, 0x000088);
      light1.position.set(-1, 1.5, 1);
      scene.add(light1);

      const light2 = new HemisphereLight(0xffffff, 0x880000, 1);
      light2.position.set(-1, -1.5, -1);
      scene.add(light2);
    }

    return { renderer, camera, scene };
  }
  private static async blobToDataUrl(blob: Blob): Promise<string> {
    return new Promise((r) => {
      let a = new FileReader();
      a.onload = r;
      a.readAsDataURL(blob);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }).then((e: any) => e.target.result);
  }
  static async renderThumbnail(rgWorld: RgWorld): Promise<string> {
    const canvas = new OffscreenCanvas(200, 200);
    const renderer = new ThumbnailRenderer();
    renderer.init(canvas);
    await renderer.render({
      rgWorld,
      camDir: [0, -1, 0],
      height: 200,
      orthographic: true,
      width: 200,
    });

    const blobData = await renderer.canvas.convertToBlob();
    const thumbnail = await ThumbnailRenderer.blobToDataUrl(blobData);

    return thumbnail;
  }
  private renderer!: WebGLRenderer;
  private camera!: PerspectiveCamera | OrthographicCamera;
  private orthoCam!: OrthographicCamera;
  private scene!: Scene;
  private mesh!: InstancedMesh;
  canvas!: OffscreenCanvas;

  init(canvas: OffscreenCanvas) {
    const { renderer, camera, scene } = ThumbnailRenderer.CreateThree({ canvas, addLights: true });
    this.orthoCam = new OrthographicCamera();
    this.canvas = canvas;
    Object.assign(this.canvas, { style: {} });
    this.renderer = renderer;
    this.camera = camera;
    this.scene = scene;
  }

  async render(props: SiteImageProps) {
    const camera = props.orthographic ? this.orthoCam : this.camera;
    if (this.mesh) {
      this.mesh.remove();
      dispose(this.mesh);
    }
    this.canvas.width = props.width;
    this.canvas.height = props.height;

    ThumbnailRenderer.ResizeRenderer({
      renderer: this.renderer,
      width: props.width,
      height: props.height,
      camera,
    });
    this.renderer.setSize(props.width, props.height);
    const rgWorld = props.rgWorld;
    const { matrices, colors } = getAbstractMeshProps({ obj: rgWorld });

    this.mesh = new InstancedMesh(
      new BoxGeometry(1, 1, 1),
      new MeshLambertMaterial({ color: "#fff" }),
      matrices.length
    );
    this.mesh.instanceMatrix.setUsage(DynamicDrawUsage);

    updateAbstractSiteInstaMesh({ mesh: this.mesh, matrices, colors });

    ThumbnailRenderer.FrameToRgWorld({
      rgWorld,
      camera,
      canvas: this.canvas,
      direction: new Vector3().fromArray(props.camDir),
    });

    this.scene.add(this.mesh);
    this.renderer.render(this.scene, camera);
  }
}
