import classNames from "classnames";
import { useRedirect } from "src/hooks/useRedirect";
import { useActiveParams } from "src/hooks/useActiveParams";
import {
  BookmarkIcon as BookmarkIconSolid,
  EllipsisHorizontalIcon,
  PencilSquareIcon,
  TrashIcon,
  SparklesIcon,
} from "@heroicons/react/24/solid";
import type { UseBoolean } from "src/hooks/useBoolean";
import { useBoolean } from "src/hooks/useBoolean";
import { type ListItem } from "./ListItem";
import { DeleteDesignModal } from "./DeleteDesignModal";
import { Cluster } from "src/components/Cluster";
import { useState, useRef } from "react";
import { useApi } from "src/contexts/api.context";
import { useActiveScenarioId } from "src/hooks/useActiveScenarioId";
import { useQueryClient } from "@tanstack/react-query";
import { useEvent } from "src/hooks/useEvent";
import { useInitials } from "./useInitials";
import { ContextMenu, Intent } from "src/components/ContextMenu";
import { OverrideCanvasDesignModal } from "src/components/OverrideCanvasDesignModal";
import type { Editor } from "src/types/Editor";

export function DesignListItem(props: { item: ListItem; editor: Editor; expand: UseBoolean }) {
  const { designId, projectId, scenarioId } = useActiveParams();

  const redirect = useRedirect();
  const optionsVisible = useBoolean();
  const changeLabel = useBoolean();
  const deleteModal = useBoolean();

  const apartmentsQuantity = 0; // savedDesign?.architecturalSiteMetrics.ApartmentQuantityTotal;
  const apartmentsQuantityLoading = apartmentsQuantity === undefined;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const overrideCanvasDesignModal = useBoolean();
  const initials = useInitials(props.item.label);
  const api = useApi();
  const openDesign = useEvent(async () => {
    if (!projectId || !scenarioId || !designId) return;
    const scenario = await api.getSavedDesignWithDesignFile(projectId, scenarioId, designId);
    if (!scenario) return;
    await props.editor.loadFromScenarioWithDesign(scenario);
    props.editor.regenerate(false);
    redirect.scenarioCanvas({ projectId: props.editor.projectId });
    overrideCanvasDesignModal.off();
  });
  const label = changeLabel.isOn ? (
    <LabelInput label={props.item.label} onChange={changeLabel.off} designId={props.item.id} />
  ) : (
    <span className="text-subtitle-2">{props.expand.isOn ? props.item.label : initials}</span>
  );
  const options = optionsVisible.isOn ? (
    <ContextMenu visible={true} onHide={optionsVisible.off} refRoot={buttonRef.current}>
      <div className="">
        <ContextMenu.Card>
          <ContextMenu.Button
            label="Open in Canvas"
            Icon={SparklesIcon}
            onClick={(evt) => {
              evt.stopPropagation();
              evt.preventDefault();
              optionsVisible.off();
              overrideCanvasDesignModal.on();
            }}
          />
          <ContextMenu.Separator />
          <ContextMenu.Button
            label="Rename"
            Icon={PencilSquareIcon}
            onClick={(evt) => {
              evt.stopPropagation();
              evt.preventDefault();
              optionsVisible.off();
              optionsVisible.off();
              changeLabel.on();
            }}
          />
          <ContextMenu.Button
            intent={Intent.DANGER}
            label="Delete"
            Icon={TrashIcon}
            onClick={(evt) => {
              evt.stopPropagation();
              evt.preventDefault();
              optionsVisible.off();
              deleteModal.on();
            }}
          />
        </ContextMenu.Card>
      </div>
    </ContextMenu>
  ) : null;
  const details = props.expand.isOn ? (
    <div className="pl-[24px] text-body-2 flex flex-row items-center space-x-1">
      <Cluster visible={apartmentsQuantityLoading}>
        <div className="border-2 border-transparent border-t-primary-4 rounded-full w-3 h-3 animate-spin" />
      </Cluster>
      <Cluster visible={!apartmentsQuantityLoading}>
        <span className="text-neutral-7">{apartmentsQuantity}</span>
      </Cluster>
      <span className="text-neutral-6">Units</span>
    </div>
  ) : null;
  const optionsButton = props.expand.isOn ? (
    <button
      ref={buttonRef}
      className={classNames(
        "absolute right-0 p-1 rounded top-1/2 transform -translate-y-1/2 text-primary-7 hover:bg-primary-3 group-hover:flex hidden",
        !changeLabel.isOn && "group-hover:opacity-100"
      )}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        optionsVisible.on();
        props.expand.on();
      }}
    >
      <EllipsisHorizontalIcon className="w-4" />
      {options}
    </button>
  ) : null;
  const selected = designId === props.item.id;
  return (
    <li
      key={props.item.id}
      className={classNames(
        "h-[52px] min-h-[52px] flex flex-1 cursor-pointer rounded flex-col relative group",
        props.expand.isOn && "px-3",
        props.expand.isOff && "px-1"
      )}
      onClick={async () => {
        await redirect.projectDesign({
          projectId: props.editor.projectId,
          designId: props.item.id,
        });
      }}
    >
      <div
        className={classNames(
          "flex flex-1 flex-col px-2 py-[6px] rounded hover:bg-primary-1",
          optionsVisible.isOn && "bg-primary-1",
          selected && "bg-primary-3 hover:bg-primary-4"
        )}
      >
        <div className="relative">
          <div
            className={classNames(
              "flex flex-1 items-center relative text-subtitle-2",
              props.expand.isOn && "space-x-[8px] flex-row",
              !props.expand.isOn && "flex-col"
            )}
          >
            <BookmarkIconSolid
              className={classNames(
                "w-4 text-neutral-6 group-hover:text-primary-7 shrink-0",
                designId && "text-primary-7",
                optionsVisible.isOn && "text-primary-7",
                changeLabel.isOn && "text-primary-7"
              )}
            />
            {label}
          </div>
          {details}
          {optionsButton}
        </div>
      </div>

      <Cluster visible={deleteModal.isOn}>
        <DeleteDesignModal onClose={deleteModal.off} label={props.item.label} id={props.item.id} />
      </Cluster>
      <OverrideCanvasDesignModal
        isOpen={overrideCanvasDesignModal.isOn}
        designName={props.item.label}
        onCancel={overrideCanvasDesignModal.off}
        onContinue={openDesign}
      />
    </li>
  );
}

function LabelInput(props: { label: string; onChange: () => void; designId: string }) {
  const [draft, setDraft] = useState(props.label);
  const projectId = useActiveParams().projectId ?? "";
  const queryClient = useQueryClient();
  const api = useApi();
  const scenarioId = useActiveScenarioId(projectId) ?? "";
  const isLoading = useBoolean();
  const onSubmit = useEvent(async () => {
    if (draft === props.label) return props.onChange();
    isLoading.on();
    await api.patchDesignOption({
      designId: props.designId,
      projectId,
      scenarioId,
      label: draft,
    });
    await queryClient.invalidateQueries({
      predicate: ({ queryKey }) => queryKey.includes(projectId),
    });
    isLoading.off();
    props.onChange();
  });
  const ref = useRef<HTMLInputElement>(null);
  return (
    <div
      className="relative"
      onMouseDown={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
    >
      <input
        autoFocus
        ref={ref}
        className="text-body-1 text-neutral-8 border-primary-6 rounded-sm px-[6px] truncate ring-0 focus:outline-[1.5px] outline-primary-6 w-full"
        value={draft}
        onChange={(evt) => setDraft(evt.currentTarget.value)}
        onBlur={onSubmit}
        onKeyDown={(evt) => {
          switch (evt.key) {
            case "Enter":
              return ref.current?.blur();
            case "Escape":
              return props.onChange();
          }
        }}
      />
      <Cluster visible={isLoading.isOn}>
        <div className="bg-white/80 absolute w-full h-full top-0 justify-center flex items-center">
          <div className="border-2 border-transparent border-t-primary-6 rounded-full w-3 h-3 animate-spin" />
        </div>
      </Cluster>
    </div>
  );
}
