import classNames from "classnames";
import {
  forwardRef,
  type ForwardRefExoticComponent,
  type PropsWithChildren,
  type ReactElement,
  type RefAttributes,
  type ReactNode,
} from "react";

export enum ButtonState {
  ACTIVE = "rg-state-active",
  NONE = "rg-state-none",
  DISABLED = "rg-state-disabled",
}

export enum ButtonIntent {
  NONE = "rg-intent-none",
  PRIMARY = "rg-intent-primary",
  SELECT = "rg-intent-select",
  WARNING = "rg-intent-warning",
  DANGER = "rg-intent-danger",
}

export enum ButtonStyle {
  MINIMAL = "rg-style-minimal",
  ICON = "rg-style-icon",
  NONE = "rg-style-none",
}

interface ButtonProps {
  onClick?: () => void;
  onAltClick?: () => void;
  state?: ButtonState | false;
  intent?: ButtonIntent;
  Icon?: (props: { className: string }) => ReactNode;
  label?: string | ReactElement;
  title?: string;
  style?: ButtonStyle;
  isLoading?: boolean;
}

const Group = ({ children, vertical }: PropsWithChildren<{ vertical?: boolean }>) => {
  return (
    <div
      className={
        vertical ? "flex-col rg-buttons-group-vertical" : "flex-row rg-buttons-group-horizontal"
      }
    >
      {children}
    </div>
  );
};

type ButtonComponent = ForwardRefExoticComponent<ButtonProps & RefAttributes<HTMLButtonElement>> & {
  Group: typeof Group;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      state = ButtonState.NONE,
      onClick,
      onAltClick,
      intent = ButtonIntent.NONE,
      Icon,
      label,
      title,
      style = ButtonStyle.NONE,
      isLoading,
    },
    ref
  ) => (
    <button
      ref={ref}
      onClick={(evt) => {
        if (evt.altKey) {
          onAltClick?.();
        } else {
          onClick?.();
        }
      }}
      className={classNames("rg-button", intent, style, state)}
      title={title}
    >
      {isLoading && (
        <div
          className={classNames(
            "mr-2 w-2 h-2 border-transparent border-2 border-t-white rounded-full animate-spin",
            intent === ButtonIntent.NONE && "border-t-black",
            !!label && "mr-4"
          )}
        />
      )}
      {!isLoading && Icon && <Icon className={classNames("w-4 flex-shrink-0", label && "mr-2")} />}
      {label && <span className="whitespace-nowrap truncate">{label}</span>}
    </button>
  )
) as ButtonComponent;

Button.Group = Group;
