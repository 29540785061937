import classNames from "classnames";
import { type PropsWithChildren } from "react";
import { observer } from "mobx-react-lite";
import { TableContentType } from "./TableContentType";

export interface TableCellProps {
  type: TableContentType;
  width?: number;
  hover?: boolean;
  selected?: boolean;
}

export const TableCell = observer(function TableCell(props: PropsWithChildren<TableCellProps>) {
  return (
    <div
      className="w-full h-full flex"
      style={{
        minWidth: props.width,
        width: props.width,
      }}
    >
      <div className="flex justify-end w-full">
        <div
          className={classNames(
            "table-cell w-full h-full",
            props.type === TableContentType.Body && "bg-white",
            props.type === TableContentType.Canvas && "bg-neutral-2"
          )}
        >
          <div
            className={classNames(
              "flex justify-end w-full h-full truncate text-ellipsis px-2 items-center",
              props.hover && "bg-primary-1",
              props.selected && "bg-primary-3 text-primary-7"
            )}
          >
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
});
