import { Form, FormIntent, FormState } from "src/components/Form/Form";
import { useImmer } from "use-immer";
import { useAuth } from "src/contexts/auth.context";
import type { FormEventHandler } from "react";
import { useEffect, useState } from "react";
import backgroundImage from "src/assets/login-background.png";
import modulousLogo from "src/assets/tessa-logo.svg";
import { PasswordResetModal } from "src/components/PasswordResetModal";
import { useEvent } from "src/hooks/useEvent";

interface FormData {
  email: string;
  password: string;
}

export const emailRegExp =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const LoginScreen = () => {
  const [showModal, setShowResetModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [invalidLogin, setInvalidLogin] = useState(false);
  const [form, updateForm] = useImmer<FormData>({
    email: "",
    password: "",
  });
  const [emailError, setEmailError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);

  useEffect(() => {
    if (form) setInvalidLogin(false);
  }, [form]);
  const auth = useAuth();

  const onBlurHandle = useEvent(async () => {
    setEmailError(emailRegExp.test(form.email) ? null : "Invalid email address");
    setPasswordError(!form.password ? null : "");
  });

  const formState =
    !form.email || !form.password || emailError || passwordError || loading || invalidLogin;

  const onSubmit = useEvent<FormEventHandler>(async (e) => {
    e.preventDefault();
    if (loading) return;
    try {
      setLoading(true);
      await auth.login(form);
    } catch (e) {
      setInvalidLogin(true);
    }
    setLoading(false);
  });

  return (
    <>
      <div
        style={{ backgroundImage: `url(${backgroundImage})` }}
        className="flex flex-1 w-screen h-screen bg-center bg-cover bg-no-repeat"
      >
        <div className="justify-center items-center flex flex-1">
          <div className="flex flex-col w-[352px] bg-white rounded-lg shadow space-y-4 items-center">
            <div className="border-b w-full flex items-center justify-center py-6 border-neutral-5">
              <img src={modulousLogo} alt="Tessa Logo" className="w-[121px] h-[32px]" />
            </div>

            <form
              className="flex flex-col w-full px-6 pb-6 space-y-4 items-center flex-1"
              onSubmit={onSubmit}
            >
              <div className="w-full flex text-2xl font-semibold">Login</div>
              {invalidLogin && <span className="text-red-100">Invalid email or password</span>}
              <div className="w-full">
                <Form.Text
                  label="Email"
                  type="email"
                  value={form.email}
                  helperText={emailError}
                  placeholder="Enter email"
                  intent={emailError ? FormIntent.ERROR : FormIntent.NONE}
                  onChange={(v) =>
                    updateForm((draft) => {
                      draft.email = v;
                    })
                  }
                  onBlur={onBlurHandle}
                />
                <Form.Text
                  label="Password"
                  type="password"
                  value={form.password}
                  helperText={passwordError}
                  placeholder="Enter password"
                  intent={passwordError ? FormIntent.ERROR : FormIntent.NONE}
                  onChange={(v) =>
                    updateForm((draft) => {
                      draft.password = v;
                    })
                  }
                  onBlur={onBlurHandle}
                />
              </div>
              <div className="w-full">
                <button
                  className="text-primary-7 cursor-pointer"
                  onClick={() => setShowResetModal(!showModal)}
                  type="button"
                >
                  Forgot password
                </button>
              </div>

              <Form.SubmitButton
                fill={true}
                state={formState ? FormState.DISABLED : FormState.NONE}
                label="Login"
                loading={loading}
              />
            </form>
          </div>
        </div>
      </div>

      {showModal && <PasswordResetModal isActive={() => setShowResetModal(!showModal)} />}
    </>
  );
};
