import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import { Button, ButtonState } from "src/components/Button";

export function HistoryUndoButton({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled: boolean;
}) {
  return (
    <Button
      title="Undo"
      Icon={ArrowUturnLeftIcon}
      state={!disabled ? ButtonState.NONE : ButtonState.DISABLED}
      onClick={onClick}
    />
  );
}
