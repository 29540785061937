import type { ReactNode } from "react";
import { type FunctionComponent, useState, useEffect } from "react";
import { Cluster } from "src/components/Cluster";
import { useBoolean } from "src/hooks/useBoolean";
import { ExploreToolbar } from "./ExploreToolbar";
import { ProgressBar } from "./ProgressBar";
import classNames from "classnames";
import type { Editor } from "src/types/Editor";
import type { WorkspaceConfig } from "src/hooks/useWorkspaceQuery";
import { observer } from "mobx-react-lite";
import { useResize } from "src/hooks/useResize";
import { Table } from "./Table";
import { TableStore } from "./TableStore";

interface ExplorePanelProps {
  editor: Editor;
  workspaceConfig: WorkspaceConfig;
}

export const ExplorePanel: FunctionComponent<ExplorePanelProps> = observer(
  ({ editor, workspaceConfig: workspaceConfig }) => {
    const [table] = useState(() => new TableStore(editor, workspaceConfig.explorePanel));
    useEffect(() => table.setConfig(workspaceConfig.explorePanel), [workspaceConfig, table]);

    return (
      <Resizer editor={editor}>
        <ExploreToolbar editor={editor} table={table} workspaceConfig={workspaceConfig} />
        <Cluster visible={editor.view.explorePanel.visible}>
          <ProgressBar designGenerator={editor.designGenerator} />
          <Table workspaceConfig={workspaceConfig} editor={editor} table={table} />
        </Cluster>
      </Resizer>
    );
  }
);

const Resizer = observer((props: { editor: Editor; children: ReactNode }) => {
  const visible = useBoolean();
  const onResize = useResize({
    onDragStart: visible.on,
    onDragStop: visible.off,
    onDrag: (evt) => props.editor.view.explorePanel.moveBy(-evt.movementY),
  });
  return (
    <div className="relative" style={{ height: props.editor.view.explorePanel.bottomPanelHeight }}>
      <div
        onMouseDown={onResize}
        className={classNames(
          visible.isOn && "!opacity-100",
          "w-full h-1 bg-purple-100 absolute -top-1 items-end flex opacity-0 hover:opacity-100 cursor-ns-resize"
        )}
      />
      <div
        className="flex relative flex-col w-full overflow-hidden border-t border-black/20"
        style={{ height: props.editor.view.explorePanel.bottomPanelHeight }}
        onMouseDown={(evt) => evt.stopPropagation()}
      >
        {props.children}
      </div>
    </div>
  );
});
