import { type PropsWithChildren, useRef } from "react";
import { useLiveRef } from "src/hooks/useLiveRef";
import { observer } from "mobx-react-lite";
import type { RegisteredColumnId } from "./RegisteredColumnId";
import { TableHeaderButtons } from "./TableHeaderButtons";
import type { SortDirection } from "./RegisteredColumns";

export interface TableCellHeaderProps<T extends RegisteredColumnId> {
  index: number;
  onColumnDragStart: (e: React.DragEvent<HTMLDivElement>, index: number) => void;
  onColumnDragOver: (e: React.DragEvent<HTMLDivElement>) => void;
  onColumnDrop: (e: React.DragEvent<HTMLDivElement>, index: number, columnID: T) => void;
  width?: number;
  onWidthChange: (id: T, width: number, submit: boolean) => void;
  id: T;
  onColumnPin: (columnId: string) => void;
  locked: boolean;
  sorted: boolean;
  onColumSort?: (columnId: string) => void;
  sortedDirection: SortDirection;
}

export const TableCellHeader = observer(function TableCellHeader<T extends RegisteredColumnId>(
  props: PropsWithChildren<TableCellHeaderProps<T>>
) {
  const ref = useRef<HTMLDivElement>(null);

  const widthRef = useLiveRef(props.width);

  const onMouseDown = (evt: React.MouseEvent) => {
    evt.preventDefault();

    const onMouseMove = (evt: MouseEvent) => {
      evt.preventDefault();
      evt.stopPropagation();
      if (!ref.current) return;
      const width = widthRef.current ?? ref.current.getBoundingClientRect().width;
      props.onWidthChange(props.id, width + evt.movementX, false);
    };

    const onMouseUp = () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
      if (!ref.current) return;
      const width = widthRef.current ?? ref.current.getBoundingClientRect().width;
      props.onWidthChange(props.id, width + evt.movementX, true);
    };

    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
  };

  return (
    <div
      draggable
      onDragStart={(e) => props.onColumnDragStart(e, props.index)}
      onDragOver={props.onColumnDragOver}
      onDrop={(e) => props.onColumnDrop(e, props.index, props.id)}
      ref={ref}
      className="w-full h-full flex relative overflow-hidden"
      style={{
        minWidth: props.width,
        width: props.width,
      }}
    >
      <div className="table-cell w-full h-full">
        <div className="flex items-center w-full h-full truncate text-ellipsis px-2 hover:bg-neutral-2 justify-end">
          <TableHeaderButtons
            columnID={props.id}
            locked={props.locked}
            onColumnPin={props.onColumnPin}
            sorted={props.sorted}
            onColumSort={props.onColumSort}
            sortedDirection={props.sortedDirection}
          />
          {props.children}
        </div>
      </div>
      <div
        className="bg-black/20 cursor-ew-resize opacity-0 hover:opacity-100 w-[3px] h-full absolute right-0 top-0 z-10"
        onMouseDown={onMouseDown}
      />
    </div>
  );
});
