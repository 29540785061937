import { useMemo } from "react";

export function useInitials(label: string) {
  return useMemo(() => {
    if (!label) return "";

    const words = label.split(/\s/).filter((w) => !!w);
    if (!words.length) return "";
    if (words.length === 1) {
      return words[0].slice(0, 2);
    }
    return words[0][0] + words[1][0];
  }, [label]);
}
