import {
  type BuildingChildren,
  deepAddParent,
  RgChildren,
  type RgObject,
  RG_TYPE,
} from "@project-rouge/rg-core";
import { useGetSvgBox, get2dPlanObjs } from "@project-rouge/rg-three";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useEvent } from "src/hooks/useEvent";
import { type SvgStyleRecord } from "./StylePresets";
import { useSvgContainerResizer } from "./useSvgContainerResizer";
import { SvgObject } from "./SvgObject";
import { SvgScale } from "./SvgScale";
import { observer } from "mobx-react-lite";

interface SvgPlanViewerProps {
  styles: SvgStyleRecord;
  obj: RgObject;
  showScale?: boolean;
  showLabels: boolean;
  selectedLevelId: number;
  svgCallback?: (svg: string) => void;
}
export const SvgPlanViewer = observer<SvgPlanViewerProps>(
  ({ obj: original, showScale, showLabels, styles, svgCallback, selectedLevelId }) => {
    const obj = useMemo(() => {
      const obj = structuredClone(original);
      deepAddParent(obj);
      return obj;
    }, [original]);

    const svgRef = useRef<SVGSVGElement>(null);
    const contentRef = useRef<SVGSVGElement>(null);
    const scaledContainerRef = useRef<HTMLDivElement>(null);
    const rootRef = useRef<HTMLDivElement>(null);

    // set the svg `viewBox` to fit all content inside parent available space, effectively recentering and fitting
    useSvgContainerResizer(svgRef.current);
    const contentBox = useGetSvgBox({ dependencies: [obj], ref: contentRef });
    const visibleObjs = useMemo(
      () =>
        get2dPlanObjs({
          obj,
          visible: Object.keys(styles) as RG_TYPE[],
          filterBy: RgFilterByLevel(selectedLevelId),
        }),
      [obj, selectedLevelId, styles]
    );

    useEffect(() => {
      svgCallback && svgRef.current && svgCallback(svgRef.current.outerHTML);
    });

    const [scale, setScale] = useState(1);
    const [pan, setPan] = useState({ x: 0, y: 0 });
    const [rotation, setRotation] = useState(0);

    const onRootWheel = useEvent(({ deltaY }: React.WheelEvent) => {
      const min = 0.1;
      const max = 20;
      const scale = deltaY < 0 ? 1.2 : 0.8; // increase or decrease scale by 20% based on scroll direction

      setScale((v) => {
        let newScale = v + (-deltaY / 1000) * scale; // apply the scale factor to the delta

        // adjust the scale factor based on the current scale value
        const distanceToMin = Math.abs(newScale - min);
        const distanceToMax = Math.abs(newScale - max);
        const maxDistance = Math.max(distanceToMin, distanceToMax);
        const adjustedScaleFactor = (max - min) / (maxDistance + 1);

        newScale = newScale > max ? max : newScale < min ? min : newScale; // ensure newScale is within min-max range
        newScale = newScale + (newScale - v) * adjustedScaleFactor; // apply the adjusted scale factor to smooth the zoom
        return Math.min(max, Math.max(min, newScale));
      });
    });

    const onRootMouseDown = useEvent((evt: React.MouseEvent) => {
      switch (evt.button) {
        case 0: {
          evt.preventDefault();
          evt.stopPropagation();

          const mousemove = (evt: MouseEvent) => {
            setPan(({ x, y }) => ({
              x: x + evt.movementX / scale,
              y: y + evt.movementY / scale,
            }));
          };

          const mouseup = () => {
            window.removeEventListener("mousemove", mousemove);
            window.removeEventListener("mouseup", mouseup);
          };

          window.addEventListener("mousemove", mousemove);
          window.addEventListener("mouseup", mouseup);
          return;
        }
        case 2: {
          evt.preventDefault();
          evt.stopPropagation();

          const mousemove = (evt: MouseEvent) => {
            setRotation((r) => r + evt.movementX / scale);
          };

          const mouseup = () => {
            window.removeEventListener("mousemove", mousemove);
            window.removeEventListener("mouseup", mouseup);
          };

          window.addEventListener("mousemove", mousemove);
          window.addEventListener("mouseup", mouseup);
          return;
        }
      }
    });

    return (
      <div
        onMouseDown={onRootMouseDown}
        onWheel={onRootWheel}
        ref={rootRef}
        onContextMenu={(evt) => evt.preventDefault()}
        className="relative w-full h-full flex cursor-move overflow-hidden"
      >
        <div
          ref={scaledContainerRef}
          style={{
            transform: `scale(${scale}) translate(${pan.x}px, ${pan.y}px) rotate(${rotation}deg)`,
          }}
        >
          <svg
            className="block"
            ref={svgRef}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g data-name="wrapper">
              <g data-name="content" ref={contentRef}>
                {visibleObjs.map((row, x) => (
                  <Fragment key={x}>
                    {row.map((o, y) => (
                      <SvgObject
                        key={y}
                        obj={o.obj}
                        mx={o.mx}
                        showLabels={showLabels}
                        {...styles[o.obj.type]}
                        showImage={styles[o.obj.type]?.showImage ?? {}}
                        scale={1}
                        rotate={rotation}
                      />
                    ))}
                  </Fragment>
                ))}
              </g>
              {showScale && contentBox && <SvgScale box={contentBox} />}
            </g>
          </svg>
        </div>
      </div>
    );
  }
);

const RgFilterByLevel = (floor: number) => (obj: RgObject) => {
  // @todo check if this always enforced by default
  if (!RgChildren[RG_TYPE.Building].includes(obj.type)) return true;

  const buildingChildren = obj as BuildingChildren;
  return buildingChildren.data.level === floor;
};
