import { Table } from "../Table";
import { type Unit } from "../UnitSelect";
import { MathSum } from "src/utils/MathSum";
import { sqmToSqft } from "../../../hooks/meterToFeet";
import { formatOrdinals } from "../formatOrdinals";
import { useMemo } from "react";
import type { ArchitecturalMetrics } from "src/types/ArchitecturalMetrics";

interface Row {
  label: string;
  area: number;
  footprint: number;
}

function useRows(
  metrics: ArchitecturalMetrics,
  unit: Unit
): { commercialRows: Row[]; serviceRows: Row[]; amenitiesRows: Row[]; totalRow: Row } {
  return useMemo(() => {
    const commercialRows: Row[] = metrics.CommercialAreaPerLevel.filter((v) => v !== 0).map(
      (commercial, index) => ({
        label: `Commercials - ${formatOrdinals(index)} Floor`,
        area: unit === "sqm" ? commercial : sqmToSqft(commercial),
        footprint: MathSum((commercial / metrics.GEAPerLevel[index]) * 100),
      })
    );

    const serviceRows: Row[] = metrics.ServiceAreaPerLevel.filter((v) => v !== 0).map(
      (service, index) => ({
        label: `Services - ${index === 0 ? "Ground" : index} Floor`,
        area: unit === "sqm" ? service : sqmToSqft(service),
        footprint: MathSum((service / metrics.GEAPerLevel[index]) * 100),
      })
    );

    const amenitiesRows: Row[] = metrics.AmenitiesAreaPerLevel.filter((v) => v !== 0).map(
      (amenities, index) => ({
        label: `Amenities - ${index === 0 ? "Ground" : index} Floor`,
        area: unit === "sqm" ? amenities : sqmToSqft(amenities),
        footprint: MathSum((amenities / metrics.GEAPerLevel[index]) * 100),
      })
    );

    const totalRow: Row = {
      label: "Total",
      area:
        unit === "sqm"
          ? metrics.NonResidentialAreaTotal
          : sqmToSqft(metrics.NonResidentialAreaTotal),
      footprint: (metrics.NonResidentialAreaTotal / metrics.GEATotal) * 100,
    };

    return { commercialRows, serviceRows, amenitiesRows, totalRow };
  }, [
    metrics.AmenitiesAreaPerLevel,
    metrics.CommercialAreaPerLevel,
    metrics.GEAPerLevel,
    metrics.GEATotal,
    metrics.NonResidentialAreaTotal,
    metrics.ServiceAreaPerLevel,
    unit,
  ]);
}

function TableRow(props: { row: Row; variant: "cell" | "total"; footprintTitle?: string }) {
  return (
    <Table.Row key={props.row.label}>
      <Table.Col>
        <Table.Label label={props.row.label} variant={props.variant} />
      </Table.Col>
      <Table.Col>
        <Table.Cell value={props.row.area} precision={2} variant={props.variant} />
      </Table.Col>
      <Table.Col>
        <Table.Cell
          value={props.row.footprint}
          precision={1}
          variant={props.variant}
          symbol="%"
          title={props.footprintTitle}
        />
      </Table.Col>
    </Table.Row>
  );
}

export function NonResidentialAreasTable(props: { metrics: ArchitecturalMetrics; unit: Unit }) {
  const { metrics, unit } = props;
  const { amenitiesRows, commercialRows, serviceRows, totalRow } = useRows(metrics, unit);
  return (
    <div>
      <Table.Head label={"Non-residential areas"} variant="section" />
      <Table.Row>
        <Table.Head label={"Type"} variant="header" position="start" />
        <Table.Head label={"Area"} variant="header" position="end" symbol={unit} />
        <Table.Head label={"Footprint"} variant="header" position="end" />
      </Table.Row>

      {commercialRows.map((row) => (
        <TableRow key={row.label} row={row} variant="cell" />
      ))}

      {serviceRows.map((row) => (
        <TableRow key={row.label} row={row} variant="cell" />
      ))}

      {amenitiesRows.map((row) => (
        <TableRow key={row.label} row={row} variant="cell" />
      ))}

      <TableRow
        row={totalRow}
        variant="total"
        footprintTitle="Total non-residential area divided by total GEA across all floors."
      />
    </div>
  );
}
