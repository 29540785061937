import { useQuery } from "@tanstack/react-query";
import { ThumbnailRenderer } from "src/types/ThumbnailRenderer";
import type { World } from "src/types/World";

export function useWorldThumbnail(world: World | null, selectedBuildingsIds?: string[]) {
  const thumbnailQuery = useQuery({
    queryKey: ["thumbnail", world?.rgWorldHashKey, selectedBuildingsIds],
    queryFn: async () => {
      if (!world) return null;
      let payload: World;
      if (selectedBuildingsIds) {
        const clone = world?.clone();
        const buildingsIdsSet = new Set(selectedBuildingsIds);
        const buildingsForRemove = clone.buildings.filter(
          (building) => !buildingsIdsSet.has(building.id)
        );
        clone.removeBuilding(buildingsForRemove);
        clone.zones.forEach((zone) => {
          if (zone.buildings.length === 0) zone.destroy();
        });
        payload = clone;
      } else {
        payload = world;
      }
      return ThumbnailRenderer.renderThumbnail(payload.rgWorld);
    },
    enabled: !!world?.rgWorldHashKey,
    staleTime: Infinity,
  });
  return thumbnailQuery.data ?? null;
}
