import { CheckIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { observer } from "mobx-react-lite";

export const ChecboxItem = observer(function CheckboxItem({
  label,
  selected,
  onClick,
}: {
  label: string;
  selected: boolean;
  onClick: () => void;
}) {
  return (
    <li className="flex">
      <button
        onClick={onClick}
        className={classNames(
          "flex flex-1 py-1 px-2 rounded items-center space-x-2 hover:bg-[#EEE]",
          !selected && "text-gray-200",
          selected && "text-purple-400"
        )}
      >
        <div className="w-4 h-4">{selected && <CheckIcon />}</div>
        <span>{label}</span>
      </button>
    </li>
  );
});
