import { useEffect } from "react";
import { useMap } from "react-three-map";
import { useLiveRef } from "src/hooks/useLiveRef";

export function useMapCanvasEvent<K extends keyof HTMLElementEventMap>(
  type: K,
  cb: (evt: HTMLElementEventMap[K]) => void
) {
  const map = useMap();
  const cbRef = useLiveRef(cb);
  useEffect(() => {
    if (!map) return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const callback = (evt: any) => cbRef.current(evt);
    map.getCanvas()?.addEventListener(type, callback);
    return () => map.getCanvas()?.removeEventListener(type, callback);
  }, [cbRef, map, type]);
}
