import { RG_TECH_CLASS, RG_TYPE } from "@project-rouge/rg-core";
import { type SvgStyle } from "./SvgObject";
import { SvgWindow } from "./SvgWindow";

export interface LegendItem {
  label: string;
  color: string;
}

export const viableResidentialAreas: LegendItem[] = [
  { label: "1B2P", color: "#BC204B" },
  { label: "2B3P", color: "#0072CE" },
  { label: "2B4P", color: "#00965E" },
  { label: "3B5P", color: "#FFC845" },
  { label: "3B6P", color: "#FF671F" },
];
export const viableNonResidentialAreas: LegendItem[] = [
  { label: "Corridor", color: "#EAEAEA" },
  { label: "Core", color: "#AAAAAA" },
  { label: "Entrance", color: "#C2C2D3" },
  { label: "Amenity", color: "#E0E0CD" },
  { label: "Service", color: "#A9B5B4" },
  { label: "Commercial Space", color: "#C1B2AC" },
];

export type SvgStyleRecord = Partial<Record<RG_TYPE, SvgStyle>>;

export enum StylePresetsEnum {
  floorPlan = "floorPlan",
  floorPlanBW = "floorPlanBW",
}

export const StylePresets: Record<StylePresetsEnum, SvgStyleRecord> = {
  [StylePresetsEnum.floorPlanBW]: {
    [RG_TYPE.Core]: {
      showImage: { fill: "white", stroke: "black", strokeWidth: 0.03 },
    },
    [RG_TYPE.Corridor]: {
      showImage: { fill: "white", stroke: "black", strokeWidth: 0.03 },
    },
    [RG_TYPE.Floor]: {
      showImage: { fill: "white", stroke: "black", strokeWidth: 0.03 },
    },
    [RG_TYPE.Wall]: {
      showImage: { fill: "white", stroke: "black", strokeWidth: 0.03 },
    },
    [RG_TYPE.Opening]: {
      showImage: { fill: "white", stroke: "white", strokeWidth: 0.03 },
    },
    [RG_TYPE.Window]: {
      showImage: { fill: "white", stroke: "black", strokeWidth: 0.01 },
      showCustom: (props) => <SvgWindow {...props} />,
    },
    [RG_TYPE.Door]: {
      showImage: { fill: "white", stroke: "black", strokeWidth: 0.01 },
    },
    [RG_TYPE.Column]: {
      showImage: { fill: "blue", stroke: "black", strokeWidth: 0.01 },
    },
    [RG_TYPE.Room]: { showImage: {}, showText: true },
  },
  [StylePresetsEnum.floorPlan]: {
    [RG_TYPE.Core]: {
      showImage: { fill: "#AAAAAA", stroke: "#DADADA", strokeWidth: 0.001 },
      showText: true,
    },
    [RG_TYPE.Corridor]: {
      showImage: { fill: "#EAEAEA", stroke: "#DADADA", strokeWidth: 0.001 },
      showText: true,
    },
    [RG_TYPE.Entrance]: {
      showImage: { fill: "#C2C2D3", stroke: "#DADADA", strokeWidth: 0.001 },
      showText: true,
    },
    [RG_TYPE.Module]: {
      showImage: { fillOpacity: 0.3, stroke: "#858585", strokeWidth: 0.001 },
      overrideFill: {
        [RG_TECH_CLASS.Amenity]: {
          showText: true,
          showImage: { fill: "#E0E0CD", fillOpacity: 1 },
        },
        [RG_TECH_CLASS.Service]: {
          showImage: { fill: "#A9B5B4", fillOpacity: 1 },
        },
      },
    },
    [RG_TYPE.EmptySpace]: {
      showImage: { fill: "#C1B2AC", stroke: "#858585", strokeWidth: 0.01 },
    },
    [RG_TYPE.Floor]: {
      showImage: { fill: "white", fillOpacity: 0, stroke: "#858585", strokeWidth: 0.01 },
    },
    [RG_TYPE.Wall]: {
      showImage: { fill: "#5F5F5F", fillOpacity: 0.75, stroke: "#5F5F5F", strokeWidth: 0.01 },
    },
    [RG_TYPE.Facade]: {
      showImage: { fill: "#5F5F5F", stroke: "#5F5F5F", strokeWidth: 0.01 },
    },
    [RG_TYPE.Opening]: {
      showImage: { fillOpacity: 0.3, customFill: (obj) => obj.parent?.parent?.color || "" },
    },
    [RG_TYPE.Window]: {
      showImage: { fill: "white", stroke: "#95969a", strokeWidth: 0.01 },
      showCustom: (props) => <SvgWindow {...props} />,
    },
    [RG_TYPE.Door]: {
      showImage: { fill: "white", stroke: "#858585", strokeWidth: 0.01 },
    },
    [RG_TYPE.Roof]: {
      showImage: { fill: "white", stroke: "#858585", strokeWidth: 0.01 },
      showText: true,
    },
    [RG_TYPE.Apartment]: { showImage: { fillOpacity: 0 }, showText: true },
    [RG_TYPE.TechnicalSpace]: { showImage: { fillOpacity: 0 }, showText: true },
  },
};

export const GetResidentialAreaColor = (label: string): string | undefined => {
  const area = viableResidentialAreas.find((item) => item.label === label);
  if (!area) return "#FFFFFF";
  return area.color;
};
