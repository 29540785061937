import { useEffect, useId, useMemo, useState } from "react";
import { Form, FormState, FormIntent } from "src/components/Form/Form";
import { useImmer } from "use-immer";
import { useQueryClient } from "@tanstack/react-query";
import { useApi } from "src/contexts/api.context";
import { useEvent } from "src/hooks/useEvent";
import { type Profile } from "src/types/Profile";
import modulousLogo from "src/assets/tessa-logo.svg";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { Modal } from "src/components/Modal";
import { type Password } from "src/types/Password";
import backgroundImage from "src/assets/profile-background.svg";
import { PasswordResetModal } from "src/components/PasswordResetModal";
import { useRedirect } from "src/hooks/useRedirect";
import { ExclamationCircleIcon, CheckCircleIcon } from "@heroicons/react/20/solid";
import { useGetUser } from "src/hooks/useGetUser";

export const ProfileScreen = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<"General" | "Password">("General");
  const [isUnsaved, setIsUnsaved] = useState(false);
  const [showResetModal, setShowResetModal] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const api = useApi();
  const userQueryKey = useId();
  const userQuery = useGetUser();

  const [form, updateForm] = useImmer({
    firstName: "",
    lastName: "",
    companyName: "",
    companyJobTitle: "",
  });

  const [passwordForm, updatePasswordForm] = useImmer({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (!userQuery.data?.profile) return;
    updateForm((draft) => {
      const profile = userQuery.data?.profile;
      if (!profile) return;
      draft.firstName = profile.firstName;
      draft.lastName = profile.lastName;
      draft.companyName = profile.companyName;
      draft.companyJobTitle = profile.companyJobTitle;
    });
  }, [updateForm, userQuery.data?.profile]);

  const errors = useMemo(
    () => ({
      firstName: !form.firstName ? "First Name is required" : null,
      lastName: !form.lastName ? "Last Name is required" : null,
      companyName: !form.companyName ? "Company Name is required" : null,
      companyJobTitle: !form.companyJobTitle ? "Your Job Title is required" : null,
    }),
    [form.firstName, form.lastName, form.companyName, form.companyJobTitle]
  );
  // enable save password button when oldpassword has been entered and there are no errors
  const passwordErrors = useMemo(
    () => ({
      password:
        passwordForm.newPassword !== passwordForm.confirmPassword ? "Passwords must match" : null,
    }),
    [passwordForm.confirmPassword, passwordForm.newPassword]
  );

  // Check for changes
  const changes = useMemo(
    () => ({
      firstName:
        form.firstName !== userQuery.data?.profile.firstName ? "First Name is not equal" : null,
      lastName:
        form.lastName !== userQuery.data?.profile.lastName ? "First Name is not equal" : null,
      companyName:
        form.companyName !== userQuery.data?.profile.companyName
          ? "Company Name is not equal"
          : null,
      companyJobTitle:
        form.companyJobTitle !== userQuery.data?.profile.companyJobTitle
          ? "Company Job Title is not equal"
          : null,
    }),
    [
      form.firstName,
      form.lastName,
      form.companyName,
      form.companyJobTitle,
      userQuery.data?.profile.firstName,
      userQuery.data?.profile.lastName,
      userQuery.data?.profile.companyName,
      userQuery.data?.profile.companyJobTitle,
    ]
  );

  const hasErrors = useMemo(() => Object.values(errors).some((v) => v !== null), [errors]);
  const hasChanges = useMemo(() => Object.values(changes).some((v) => v !== null), [changes]);
  const hasPasswordErrors = useMemo(
    () => Object.values(passwordErrors).some((v) => v !== null),
    [passwordErrors]
  );

  const queryClient = useQueryClient();
  const patchUser = useEvent(async (profile: Profile) => {
    await api.patchUser(profile);
    queryClient.invalidateQueries([userQueryKey]);
  });

  const putNewUserPassword = useEvent(async (password: Password) => {
    try {
      await api.putNewUserPassword(password);
      queryClient.invalidateQueries([userQueryKey]);
      setShowSuccess(!showSuccess);
      resetPasswordForm();
    } catch (error) {
      setShowError(!showError);
      resetPasswordForm();
      setLoading(false);
    }
  });

  const resetForm = () => {
    if (!userQuery.data?.profile) return;
    updateForm((draft) => {
      const profile = userQuery.data?.profile;
      if (!profile) return;
      draft.firstName = profile.firstName;
      draft.lastName = profile.lastName;
      draft.companyName = profile.companyName;
      draft.companyJobTitle = profile.companyJobTitle;
    });
  };

  const resetPasswordForm = () => {
    updatePasswordForm((draft) => {
      draft.oldPassword = "";
      draft.newPassword = "";
      draft.confirmPassword = "";
    });
  };

  const redirect = useRedirect();

  return (
    <>
      {showResetModal && <PasswordResetModal isActive={() => setShowResetModal(!showResetModal)} />}

      {isUnsaved && (
        <Modal.Portal isOpen={true}>
          <Modal.Card onClose={() => setIsUnsaved(!isUnsaved)}>
            <Modal.Header label="Unsaved Changes" onClose={() => setIsUnsaved(!isUnsaved)} />
            <p>Are you sure you want to leave? Your changes will not be saved.</p>
            <Modal.Actions>
              <Modal.ActionCancelButton onClick={() => setIsUnsaved(!isUnsaved)} label="Cancel" />
              <Modal.ActionDangerButton
                onClick={() => {
                  resetForm();
                  setIsUnsaved(!isUnsaved);
                }}
                label="Leave without saving"
              />
            </Modal.Actions>
          </Modal.Card>
        </Modal.Portal>
      )}

      <div
        className="flex w-full h-screen flex-col items-center bg-gray-300 bg-cover bg-no-repeat"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="w-full h-11 bg-white border border-neutral-3 flex items-center px-2">
          <img src={modulousLogo} alt="Tessa Logo" className="w-[75.83px] h-[20px]" />
        </div>
        <div className="flex flex-col space-y-10 max-w-[1024px] w-full h-full bg-white px-[29px] py-[26px]">
          <div className="flex flex-col space-y-3">
            <div className="flex space-x-2 text-primary-6 items-center">
              <button
                className="w-full h-6 underline flex space-x-1 items-center"
                onClick={redirect.home}
              >
                <ArrowLeftIcon className="w-4" /> <span>Back</span>
              </button>
            </div>
            <h1 className="font-semibold text-header-2 text-[32px] text-neutral-8">Profile</h1>
          </div>
          <div className="flex">
            {/* Menu bar */}
            <div className="flex flex-col w-[200px] h-full space-y-6">
              <button
                className={classNames(
                  page === "General"
                    ? "text-primary-6  border-l-green-100 border-l-2"
                    : "text-neutral-6",
                  "font-semibold flex items-start px-1"
                )}
                onClick={() => setPage("General")}
              >
                General
              </button>
              <button
                className={classNames(
                  page === "Password"
                    ? "text-primary-6  border-l-green-100 border-l-2"
                    : "text-neutral-6",
                  "font-semibold flex items-start px-1"
                )}
                onClick={() => (hasChanges ? setIsUnsaved(true) : setPage("Password"))}
              >
                Password
              </button>
            </div>

            {userQuery.data && (
              <div className="flex flex-col max-w-[360px] w-full h-full">
                {page === "General" ? (
                  <>
                    <Form.Text
                      value={form.firstName}
                      label="First name"
                      type="text"
                      helperText={errors.firstName}
                      intent={errors.firstName ? FormIntent.ERROR : FormIntent.NONE}
                      onChange={(value) =>
                        updateForm((draft) => {
                          draft.firstName = value;
                        })
                      }
                    />
                    <Form.Text
                      value={form.lastName}
                      label="Last Name"
                      type="text"
                      helperText={errors.lastName}
                      intent={errors.lastName ? FormIntent.ERROR : FormIntent.NONE}
                      onChange={(value) =>
                        updateForm((draft) => {
                          draft.lastName = value;
                        })
                      }
                    />
                    <Form.Text
                      value={form.companyName}
                      label="Company Name"
                      type="text"
                      helperText={errors.companyName}
                      intent={errors.companyName ? FormIntent.ERROR : FormIntent.NONE}
                      onChange={(value) =>
                        updateForm((draft) => {
                          draft.companyName = value;
                        })
                      }
                    />
                    <Form.Text
                      value={form.companyJobTitle}
                      label="Role"
                      type="text"
                      helperText={errors.companyJobTitle}
                      intent={errors.companyJobTitle ? FormIntent.ERROR : FormIntent.NONE}
                      onChange={(value) =>
                        updateForm((draft) => {
                          draft.companyJobTitle = value;
                        })
                      }
                    />

                    <div className="flex flex-col space-y-[6px] mb-8">
                      <span className={"text-neutral-6 text-sm"}>Email</span>
                      <input
                        type="text"
                        placeholder={userQuery.data.email}
                        disabled
                        className="bg-neutral-1 ring-neutral-3 cursor-not-allowed hover:ring-neutral-3 rounded-sm ring-[1px] border-0"
                      />
                      <div className="flex space-x-1">
                        <p className="text-neutral-6 text-sm">Contact</p>
                        <a
                          className="text-primary-6 text-sm underline font-bold"
                          href="mailto:help@tessa.ai"
                        >
                          help@tessa.ai
                        </a>
                        <p className="text-neutral-6 text-sm"> to change your email address.</p>
                      </div>
                    </div>

                    <div className="flex">
                      <Form.SubmitButton
                        state={
                          hasErrors || loading || !hasChanges ? FormState.DISABLED : FormState.NONE
                        }
                        label="Save Changes"
                        onClick={async () => {
                          setLoading(true);
                          return await patchUser({
                            firstName: form.firstName,
                            lastName: form.lastName,
                            companyName: form.companyName,
                            companyJobTitle: form.companyJobTitle,
                          });
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex flex-col mb-4">
                      <Form.Text
                        value={passwordForm.oldPassword}
                        label="Old password"
                        type="password"
                        onChange={(value) =>
                          updatePasswordForm((draft) => {
                            draft.oldPassword = value;
                          })
                        }
                      />
                      <div className="flex justify-end">
                        <button
                          className="text-primary-7 cursor-pointer underline"
                          onClick={() => setShowResetModal(!showResetModal)}
                        >
                          Forgot password?
                        </button>
                      </div>
                    </div>
                    <div className="mb-8">
                      <Form.Text
                        value={passwordForm.newPassword}
                        label="New password"
                        type="password"
                        helperText={passwordErrors.password}
                        intent={passwordErrors.password ? FormIntent.ERROR : FormIntent.NONE}
                        onChange={(value) =>
                          updatePasswordForm((draft) => {
                            draft.newPassword = value;
                          })
                        }
                      />
                      <Form.Text
                        value={passwordForm.confirmPassword}
                        label="Confirm password"
                        type="password"
                        helperText={passwordErrors.password}
                        intent={passwordErrors.password ? FormIntent.ERROR : FormIntent.NONE}
                        onChange={(value) =>
                          updatePasswordForm((draft) => {
                            draft.confirmPassword = value;
                          })
                        }
                      />
                    </div>
                    <div className="flex">
                      <Form.SubmitButton
                        state={
                          !passwordForm.oldPassword ||
                          !passwordForm.newPassword ||
                          hasPasswordErrors ||
                          loading
                            ? FormState.DISABLED
                            : FormState.NONE
                        }
                        label="Save Password"
                        onClick={async () => {
                          setLoading(true);

                          putNewUserPassword({
                            oldPassword: passwordForm.oldPassword,
                            newPassword: passwordForm.newPassword,
                          });
                        }}
                      />
                    </div>
                    <div className="mt-4">
                      {showError ? (
                        <div className="flex items-center space-x-1 text-danger-5">
                          <span className=" w-5">
                            <ExclamationCircleIcon />
                          </span>
                          <span>Old password is incorrect</span>
                        </div>
                      ) : showSuccess ? (
                        <div className="flex items-center space-x-1 text-success-5">
                          <span className=" w-5">
                            <CheckCircleIcon />
                          </span>
                          <span>Password successfully updated</span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
