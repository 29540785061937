import { computed, makeAutoObservable, observable, runInAction, toJS } from "mobx";
import type { RgWorld } from "src/types/RgCorePackage";
import type {
  RgBrief,
  RgBriefDto,
  RgGeneric,
  RgObjectDto,
  RgZoneBrief,
  RougeDataSet,
  V3,
  WorldData,
  RgVersions,
  RgDataSetID,
} from "@project-rouge/rg-core";
import { RG_TYPE, RougeDataSets } from "@project-rouge/rg-core";
import {
  getRgHash,
  getRgVersions,
  rgBriefToDto,
  rgObjectToDto,
} from "@project-rouge/rg-aggregator";
import type { Zone } from "./Zone";
import { Site } from "./Site";
import type { Building } from "./Building";
import { Dataset } from "src/constants/Dataset";
import { ArchitecturalMetrics } from "./ArchitecturalMetrics";
import objectHash from "object-hash";
import type { ZoneType } from "./Zone";
import { IsVectorOnVertex } from "src/utils/IsVectorOnVertex";
import type { ZoneBriefConfig } from "src/types/ZoneBrief";
import { ZoneBrief } from "src/types/ZoneBrief";
import type { WorldBriefConfig } from "./WorldBrief";
import { WorldBrief } from "./WorldBrief";
import type { ProjectScenarioSiteHttpPayloadForCreation } from "@project-rouge/service-project-client/resource/project-scenario-site";
import type { GeoPoint } from "./GeoPoint";
import type { AggDesignOptionDto } from "./AggDesignOptionDto";
import { Compress, CompressAsync } from "src/utils/Compress";
import { IsVectorEqualV3 } from "src/utils/IsVectorEqual";
import { V3ToLngLat } from "src/utils/V3ToLngLat";

export function arePolygonEqual<T extends readonly Readonly<V3>[] | V3[]>(aPoly: T, bPoly: T) {
  if (aPoly.length !== bPoly.length) return false;
  const aPolySet = new Set(aPoly.map((point) => point.join()));
  const bPolySet = new Set(bPoly.map((point) => point.join()));
  if (aPolySet.size !== bPolySet.size) return false;
  return new Set([...aPolySet, ...bPolySet]).size === aPolySet.size;
}

export interface WorldZoneMeta {
  type: ZoneType;
  label: string;
  locked: boolean;
  offsets: number[] | null;
  brief: ZoneBriefConfig;
}

type ZonesMeta = Record<string, WorldZoneMeta>;

export interface WorldConfig {
  rgWorld?: RgWorld;
  rgVersions?: RgVersions;
  rgDatasetId?: RgDataSetID;
  label?: string;
  longitude?: number;
  latitude?: number;
  briefIndex?: number;
  brief?: Partial<WorldBriefConfig>;
  zonesMeta?: ZonesMeta;
}

export class World implements RgGeneric<RG_TYPE.World, never, Site, WorldData> {
  /**
   * This function takes an array of polygons and a tolerance value, and returns a new array of
   * polygons with their vertex positions consolidated. The function merges positions that are
   * within the specified tolerance, resulting in a cleaner set of polygon vertex positions.
   */
  static sanitizeZonesOuterRing(zones: Zone[]) {
    const tolerance = 0.01;
    const positions = zones.map((polygon) => polygon.outerRing).flat();
    const list = positions
      .map((a) => {
        return positions.map((b) => {
          const key = b.join();
          let value = b;
          if (IsVectorOnVertex(a, b, tolerance)) {
            value = a;
            b[0] = a[0];
            b[1] = a[1];
            b[2] = a[2];
          }
          return [key, value] as [string, V3];
        });
      })
      .flat();

    const map = new Map(list);
    zones.forEach((zone) => {
      zone.setOuterRing(zone.outerRing.map((v) => map.get(v.join()) as V3));
    });
  }
  alpha: number;
  children: Site[] = [];
  data: WorldData;
  name: string;
  type = RG_TYPE.World as const;
  uuid: string;
  pos: V3;
  rot: V3;
  size: V3;
  anchor: V3;
  color: string;
  hash: string | undefined;
  rg = true as const;
  modulousId: string | undefined;
  parent: undefined;
  templateUuid: string | undefined;
  briefIndex: number;
  versions: RgVersions;
  datasetId: RgDataSetID;
  longitude: number;
  latitude: number;
  createdAt = new Date().toISOString();
  brief: WorldBrief;
  label: string;

  private readonly useWorkers: boolean;
  constructor(config?: WorldConfig, useWorkers = true) {
    this.useWorkers = useWorkers;
    this.label = config?.label ?? "";
    this.briefIndex = config?.briefIndex ?? 0;
    this.versions = config?.rgVersions ?? getRgVersions();
    this.datasetId = config?.rgDatasetId ?? Dataset.id;
    this.longitude = config?.longitude ?? 0;
    this.latitude = config?.latitude ?? 0;
    this.alpha = this.rgWorld?.alpha ?? 1;
    this.data = this.rgWorld?.data ?? CreateWorldData();
    this.name = this.rgWorld?.name ?? "";
    this.type = this.rgWorld?.type ?? RG_TYPE.World;
    this.uuid = this.rgWorld?.uuid ?? crypto.randomUUID();
    this.pos = this.rgWorld?.pos ?? [0, 0, 0];
    this.rot = this.rgWorld?.rot ?? [0, 0, 0];
    this.size = this.rgWorld?.size ?? [0, 0, 0];
    this.anchor = this.rgWorld?.anchor ?? [0, 0, 0];
    this.color = this.rgWorld?.color ?? "#cccccc";
    this.modulousId = this.rgWorld?.modulousId;
    this.templateUuid = this.rgWorld?.templateUuid;
    this.brief = new WorldBrief(config?.brief);
    config?.rgWorld?.children.forEach((rgSite) => this.addSite(new Site({ rgSite })));
    this.zones.forEach((zone) => {
      const meta = config?.zonesMeta?.[zone.id];
      if (!meta) return;
      zone.setZoneType(meta.type);
      zone.setLabel(meta.label);
      zone.setLocked(meta.locked);
      zone.setOffsets(meta.offsets);
      zone.setBrief(new ZoneBrief(zone, meta.brief));
    });
    makeAutoObservable<World>(this, {
      rgWorld: computed({ keepAlive: true }),
      rgWorldHashKey: computed({ keepAlive: true }),
      briefHashKey: computed({ keepAlive: true }),
      rgWorldDto: computed({ keepAlive: true }),
      rgWorldZip: computed({ keepAlive: true }),
      data: observable.ref,
      pos: observable.ref,
      rot: observable.ref,
      size: observable.ref,
      anchor: observable.ref,
      _rgWorldZip: observable.ref,
      _rgWorldZipPromise: false,
    });
  }
  get architecturalMetrics(): ArchitecturalMetrics {
    return new ArchitecturalMetrics(this.buildings);
  }
  setGeoOrigin(geoOrigin: GeoPoint) {
    this.longitude = geoOrigin.longitude;
    this.latitude = geoOrigin.latitude;
  }

  get rgVersion() {
    return toJS(this.versions);
  }

  get zonesMeta(): ZonesMeta {
    return Object.fromEntries(
      this.zones.map((zone) => [
        zone.id,
        {
          type: zone.zoneType,
          label: zone.label,
          locked: zone.locked,
          offsets: toJS(zone.offsets),
          brief: zone.brief.config,
        },
      ])
    );
  }

  get config(): Required<WorldConfig> {
    return {
      label: this.label,
      longitude: this.longitude,
      latitude: this.latitude,
      rgWorld: this.rgWorld,
      briefIndex: this.briefIndex,
      rgVersions: this.rgVersion,
      rgDatasetId: this.datasetId,
      brief: this.brief.config,
      zonesMeta: this.zonesMeta,
    };
  }

  get siteAreaHectares() {
    const sumArray = (arr: number[]) => arr.reduce((sum, value) => sum + value, 0);

    const areas = this.zones.map((zone) => zone.siteAreaHectares) ?? [0];
    return sumArray(areas);
  }

  get centroid(): V3 {
    let sumX = 0;
    let sumY = 0;
    let sumZ = 0;
    let totalVertices = 0;

    for (const site of this.sites) {
      for (const point of site.outerRing) {
        sumX += point[0];
        sumY += point[1];
        sumZ += point[2];
        totalVertices++;
      }
    }

    const centroidX = sumX / totalVertices;
    const centroidY = sumY / totalVertices;
    const centroidZ = sumZ / totalVertices;

    return [centroidX, centroidY, centroidZ];
  }

  get dataset(): RougeDataSet {
    return RougeDataSets[this.datasetId];
  }

  get rgBrief(): RgBrief {
    const zoneBriefs: Record<string, RgZoneBrief> = {};
    for (const zone of this.zones) {
      if (!zone.rgZoneBrief) continue;
      zoneBriefs[zone.id] = zone.rgZoneBrief;
    }
    return {
      obj: toJS(this.rgWorld),
      dataset: this.dataset,
      zoneBriefs,
      face2FaceSeparation: this.brief.buildingSeparation,
      face2SideSeparation: 0,
    };
  }

  get rgBriefDto(): RgBriefDto {
    const dto = rgBriefToDto(this.rgBrief);
    return dto;
  }

  /** only zone structural changes and brief are included in the hash key. Used by generator */
  get zonesBriefsHashKey(): string {
    const key = this.zones.map((z) => [toJS(z.polygons), z.brief.config, z.locked, z.zoneType]);
    return objectHash([key, this.brief.config]);
  }

  get briefHashKey(): string {
    return objectHash(this.brief);
  }

  get zones(): Zone[] {
    return this.sites.flatMap((site) => site.zones);
  }
  get sites(): Site[] {
    return this.children;
  }
  get hasSite(): boolean {
    return this.sites.length > 0;
  }
  get hasZone(): boolean {
    return this.zones.length > 0;
  }
  get buildings(): Building[] {
    return this.zones.flatMap((zone) => zone.buildings);
  }
  get hasBuilding(): boolean {
    return this.buildings.length > 0;
  }
  get id() {
    return this.uuid;
  }
  get geoOrigin(): GeoPoint {
    return { longitude: this.longitude, latitude: this.latitude };
  }

  get rgWorldHashKey() {
    return getRgHash(this.rgWorld);
  }

  _rgWorldZip: Uint8Array | null = null;
  _rgWorldZipPromise: null | Promise<Uint8Array> = null;
  async getRgWorldZip(): Promise<Uint8Array> {
    if (this._rgWorldZipPromise) return this._rgWorldZipPromise;
    this._rgWorldZipPromise = new Promise(async (resolve) => {
      const zip = this.useWorkers
        ? await CompressAsync(this.rgWorldDto)
        : Compress(this.rgWorldDto);
      runInAction(() => {
        this._rgWorldZip = zip;
      });
      resolve(zip);
    });
    return this._rgWorldZipPromise;
  }

  get rgWorldZip(): Uint8Array | null {
    if (this._rgWorldZip) return this._rgWorldZip;
    this.getRgWorldZip();
    return this._rgWorldZip;
  }

  get hashKey(): string {
    return `${this.rgWorldHashKey}_${this.briefHashKey}`;
  }

  get rgWorldDto(): RgObjectDto<World> {
    return rgObjectToDto(this.rgWorld);
  }

  get rgWorld(): RgWorld {
    const snapshot: RgWorld = {
      parent: undefined,
      children: [],
      data: toJS(this.data),
      pos: toJS(this.pos),
      rot: toJS(this.rot),
      size: toJS(this.size),
      rg: this.rg,
      hash: this.hash,
      alpha: this.alpha,
      name: this.name,
      type: this.type,
      uuid: this.uuid,
      anchor: toJS(this.anchor),
      color: this.color,
      modulousId: this.modulousId,
      templateUuid: this.templateUuid,
    };
    snapshot.children = this.children.map((child) => {
      const v = child.rgParentlessSnapshot;
      v.parent = snapshot;
      return v;
    });
    return snapshot;
  }

  get beBrief() {
    return this.brief.beBrief;
  }

  get beSites(): ProjectScenarioSiteHttpPayloadForCreation[] {
    return this.sites.map((site) => site.beSite);
  }

  get aggDesignOptionDto(): AggDesignOptionDto {
    return {
      aggProps: null as never,
      briefDto: this.rgBriefDto,
      briefIndex: this.briefIndex,
      id: this.id,
      obj: this.rgWorldDto,
      versions: this.versions,
    };
  }

  setLabel(label: string) {
    this.label = label;
  }

  clone(): World {
    const world = new World(this.config);
    world.setRgWorldZip(this.rgWorldZip);
    return world;
  }

  setId(id: string) {
    this.uuid = id;
  }

  addSite(site: Site) {
    if (this.children.includes(site)) return;
    site.parent = this;
    this.children.push(site);
  }

  removeSite(site: Site) {
    this.children = this.sites.filter((s) => s !== site);
    site.parent = undefined;
  }

  addZones(zones: Zone[]) {
    zones.forEach((zone) => this.addZone(zone));
    const firstZone = zones[0];
    if (!IsVectorEqualV3(firstZone.outerRing[0], [0, 0, 0])) {
      const firstPoint = firstZone.outerRing[0];
      const [longitude, latitude] = V3ToLngLat(firstPoint, [
        this.geoOrigin.longitude,
        this.geoOrigin.latitude,
      ]);
      this.setGeoOrigin({ longitude, latitude });
      const offset: V3 = [-firstPoint[0], firstPoint[1], -firstPoint[2]];
      zones.forEach((zone) => zone.moveBy(offset));
    }
  }

  /** Add a zone and create site if necessary. We also going to assign a new label if a label is empty */
  addZone(zone: Zone) {
    if (this.zones.includes(zone)) return;
    const zoneId = zone.uuid;
    const existingZone = this.zones.find((zone) => zone.uuid === zoneId);
    if (existingZone) return;
    const site = new Site();
    site.addZone(zone);
    this.addSite(site);
    if (!zone.label) {
      zone.setLabel(getAvailableZoneName(this.zones));
    }
  }

  replaceZones(zones: Zone[]) {
    this.sites.forEach((site) => this.removeSite(site));
    this.addZones(zones);
  }
  removeZone(zone: Zone) {
    const sites = [...this.sites];
    sites.forEach((site) => {
      site.removeZone(zone);
      if (!site.hasZone) this.removeSite(site);
    });
  }
  removeBuilding(buildings: Building | Building[]) {
    if (!Array.isArray(buildings)) buildings = [buildings];
    this.zones.forEach((zone) => zone.removeBuilding(buildings));
  }

  /** @todo this could cause memory leak if there is already a listener for current promise */
  setRgWorldZip(zip: Uint8Array | null) {
    this._rgWorldZip = zip;
    this._rgWorldZipPromise = zip ? Promise.resolve(zip) : null;
  }

  invalidateCache() {
    this._rgWorldZip = null;
    this._rgWorldZipPromise = null;
  }
}

function CreateWorldData(): WorldData {
  return {
    coords: { latitude: 0, longitude: 0 },
    dataset: Dataset.id,
  };
}

function getAvailableZoneName(zones: Zone[]): string {
  const names = zones.map((zone) => zone.label);
  const name = "Zone";
  let index = 1;
  while (names.includes(`${name} ${index}`)) {
    index++;
  }
  return `${name} ${index}`;
}
