import { dtoToRgObject, dtoToRgBrief } from "@project-rouge/rg-core";
import { makeAutoObservable, toJS } from "mobx";
import { DecompressAggDesignDto } from "src/utils/DecompressAggDesignDto";
import type { GeneratedItem } from "src/types/GeneratedItem";
import type { RgWorld, RgWorldDto } from "./RgCorePackage";
import type { WorldConfig, WorldZoneMeta } from "./World";
import { World } from "./World";
import { Decompress } from "src/utils/Decompress";
import type { BeScenarioWithAggDesignDtoZip } from "./Api";
import type { ZoneType } from "./Zone";
import { UnitsRegister } from "src/constants/UnitsRegister";
import type { BeZoneBrief } from "./BeZoneBrief";
import type { UnitMixBriefConfig } from "./UnitMixBrief";

type NextItem = () => Promise<void>;

/**
 * WorldLoader is a queue that loads world in order to prevent browser crash due to overloading
 */
export class WorldLoader {
  private stack: NextItem[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  private running = false;

  async run() {
    if (this.running) return;
    const next = this.stack.shift();
    if (next) {
      this.running = true;
      await next();
      this.running = false;
      this.run();
    }
  }

  async loadFromRgWorld(rgWorld: RgWorld, currentWorld: World | null = null): Promise<World> {
    const config: WorldConfig = structuredClone({
      ...currentWorld?.config,
      rgWorld,
    });
    return new Promise((resolve) => {
      this.stack.push(async () => {
        const world = new World(config);
        resolve(world);
      });
      this.run();
    });
  }

  async loadFromDesignGenerator(
    item: GeneratedItem,
    currentWorld: World | null = null
  ): Promise<World> {
    const currWorld = currentWorld?.clone();
    const genItem = structuredClone(toJS(item));
    return new Promise((resolve) => {
      this.stack.push(async () => {
        if (!currWorld) throw new Error("Expecting the current World exist");
        const config: WorldConfig = {
          ...currWorld.config,
          rgWorld: dtoToRgObject<RgWorld>(Decompress<RgWorldDto>(genItem.rgWorldZip)),
        };
        const world = new World(structuredClone(config));
        world.setRgWorldZip(genItem.rgWorldZip);
        resolve(world);
      });
      this.run();
    });
  }

  async loadFromScenarioWithDesign(item: BeScenarioWithAggDesignDtoZip): Promise<World> {
    const scenario = structuredClone(item);
    return new Promise((resolve) => {
      this.stack.push(async () => {
        const dto = DecompressAggDesignDto(scenario.aggDesignDtoZip);
        const rgWorld = dtoToRgObject<RgWorld>(dto.obj);
        const rgBrief = dtoToRgBrief(dto.briefDto);

        const latitude = scenario.sites.at(0)?.region.perimeter.at(0)?.latitude ?? 0;
        const longitude = scenario.sites.at(0)?.region.perimeter.at(0)?.longitude ?? 0;

        const getUnitMixCategories = (brief: BeZoneBrief): UnitMixBriefConfig["categories"] => {
          const register = structuredClone(UnitsRegister);
          for (const regUnit of Object.values(register)) {
            regUnit.enabled = false;
          }
          for (const beMix of brief.unit_category_mix) {
            const defaultMix = register[beMix.category];
            defaultMix.value = beMix.value;
            defaultMix.apartmentNames = beMix.units;
            defaultMix.enabled = true;
          }
          return Object.values(register);
        };

        const world = new World({
          label: scenario.label,
          rgWorld,
          briefIndex: dto.briefIndex,
          rgDatasetId: rgBrief.dataset.id,
          latitude,
          longitude,
          brief: {
            buildingSeparation: rgBrief.face2FaceSeparation,
          },
          rgVersions: dto.versions,
          zonesMeta: Object.fromEntries(
            scenario.sites.flatMap((site) =>
              site.zones.map((zone) => {
                const meta: WorldZoneMeta = {
                  label: zone.label,
                  type: zone.type as ZoneType,
                  locked: zone.is_locked,
                  offsets: toJS(zone.region.offsets),
                  brief: {
                    buildingCount: zone.brief.building_count,
                    buildingHeight: zone.brief.building_height,
                    clearInternalHeight: zone.brief.clear_internal_height,
                    podium: zone.brief.podium
                      ? {
                          floorCount: zone.brief.podium.floor_count,
                          height: zone.brief.podium.height,
                          type: zone.brief.podium.type,
                        }
                      : null,
                    residentialFloorCount: zone.brief.residential_floor_count,
                    unitMix: {
                      categories: getUnitMixCategories(zone.brief),
                    },
                  },
                };
                return [zone.id, meta];
              })
            )
          ),
        });
        resolve(world);
      });
      this.run();
    });
  }
}
