import { useRedirect } from "src/hooks/useRedirect";
import { useActiveParams } from "src/hooks/useActiveParams";
import { useQueryClient } from "@tanstack/react-query";
import { useApi } from "src/contexts/api.context";
import { useActiveScenarioId } from "src/hooks/useActiveScenarioId";
import { Modal } from "src/components/Modal";
import { useEvent } from "src/hooks/useEvent";

export function DeleteDesignModal(props: { onClose: () => void; label: string; id: string }) {
  const params = useActiveParams();
  const projectId = params.projectId ?? "";
  const api = useApi();
  const scenarioId = useActiveScenarioId(projectId) ?? "";
  const redirect = useRedirect();
  const queryClient = useQueryClient();
  const deleteDesign = useEvent(async () => {
    await api.deleteDesignOption({
      designId: props.id,
      projectId,
      scenarioId,
    });
    queryClient.invalidateQueries({
      predicate: ({ queryKey }) => queryKey.includes(projectId),
    });
    redirect.scenarioCanvas({ projectId });
    props.onClose();
  });
  return (
    <Modal.Portal isOpen>
      <Modal.Card onClose={props.onClose}>
        <Modal.Header
          label={
            <span className="text-subtitle-1">
              Delete <span className="italic">{props.label}</span>?
            </span>
          }
          onClose={props.onClose}
        />

        <span className="text-body-1">
          This will delete the design permanently. This action cannot be undone.
        </span>
        <div className="flex flex-row justify-end space-x-2">
          <Modal.Actions>
            <Modal.ActionCancelButton label="Cancel" onClick={props.onClose} />
            <Modal.ActionDangerButton label="Delete" onClick={deleteDesign} />
          </Modal.Actions>
        </div>
      </Modal.Card>
    </Modal.Portal>
  );
}
