export const ViewValue = ({ value, decimalPoint }: { value?: number; decimalPoint?: number }) => {
  const formattedValue =
    value === undefined
      ? "-"
      : new Intl.NumberFormat("en-GB", {
          minimumFractionDigits: decimalPoint,
          maximumFractionDigits: decimalPoint,
        }).format(value);

  return <span className="px-1 text-body-1 text-neutral-8">{formattedValue}</span>;
};
