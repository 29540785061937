import type { SelectOption } from "./Toolbox";
import { HomeModernIcon } from "@heroicons/react/20/solid";
import { useMemo } from "react";
import { ChecboxItem } from "./CheckboxItem";
import { ListToolButton } from "./ListToolButton";
import { Select } from "./Select";
import type { FeElementKey, MapState } from "src/types/MapState";
import { ModelVisibilityPreset } from "src/types/MapState";
import { observer } from "mobx-react-lite";

export const BuildingPresetButton = observer(function BuildingPresetButton({
  mapState,
}: {
  mapState: MapState;
}) {
  const presets = useMemo(() => {
    const labels: Record<ModelVisibilityPreset, string> = {
      [ModelVisibilityPreset.Abstract]: "Abstract",
      [ModelVisibilityPreset.Architectural]: "Architectural",
      [ModelVisibilityPreset.Custom]: "Custom",
    };
    const selectOptions: SelectOption[] = mapState.modelVisibility.presetList.map((id) => ({
      id,
      label: labels[id],
    }));
    return selectOptions;
  }, [mapState.modelVisibility.presetList]);

  const elementLabels = Object.entries({
    Unit: "Unit",
    Cores: "Cores",
    Corridors: "Corridors",
    Services: "Services",
    Amenities: "Amenities",
    Entrances: "Entrances",
    Facade: "Facade",
    Roof: "Roof",
    Parapets: "Parapets",
    Floors: "Floors",
    Ceilings: "Ceilings",
    Walls: "Walls",
    Windows: "Windows",
    Doors: "Doors",
    Structure: "Structure",
    Commercials: "Commercials",
  } satisfies Record<FeElementKey, string>) as [FeElementKey, string][];

  return (
    <ListToolButton Icon={HomeModernIcon}>
      <div className="flex flex-1 justify-center py-2 items-center space-x-2  text-gray-200">
        <HomeModernIcon className="w-4 h-4" />
        <span className="font-semibold">Building Presets</span>
      </div>
      <div className="space-y-2">
        <Select
          items={presets}
          onChange={mapState.modelVisibility.updatePreset}
          selected={mapState.modelVisibility.preset}
        />
        <div className="overflow-y-auto max-h-[600px] scrollbar-none">
          <div className="space-y-2">
            <ul className="space-y-1">
              {elementLabels.map(([key, label]) => (
                <ChecboxItem
                  key={key}
                  label={label}
                  selected={mapState.modelVisibility.elements[key]}
                  onClick={() => mapState.modelVisibility.toggle(key)}
                />
              ))}
              <ChecboxItem
                label="Floor Levels"
                selected={mapState.floorLevels.isOn}
                onClick={mapState.floorLevels.toggle}
              />
            </ul>
          </div>
        </div>
      </div>
    </ListToolButton>
  );
});
