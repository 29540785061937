import Smartlook from "smartlook-client";
import { ENV } from "./constants/env";

/**
 * Attempt to sanitise some possible values from environment variables.
 */
const sanitise = (value: string | null): string | undefined => {
  return value === undefined || value === "" || value === null ? undefined : value;
};

const SMARTLOOK_PARAM_KEY = "smartlook";

const forceSmartlook =
  new URLSearchParams(window.location.search).get(SMARTLOOK_PARAM_KEY) === "true";

const SMARTLOOK_KEY = sanitise(ENV.SMARTLOOK_KEY);

/**
 * Smartlook is enabled if:
 * - The user has forced it on via the URL
 * - The build environment is production and it's not a pull request
 */
const enabled =
  forceSmartlook || (ENV.BUILD_ENVIROMENT === "production" && !ENV.BUILD_PULL_REQUEST_ID);

if (enabled && SMARTLOOK_KEY) {
  Smartlook.init(SMARTLOOK_KEY);
  Smartlook.properties({
    ENV: ENV.BUILD_ENVIROMENT,
    COMMIT_SHA: ENV.BUILD_COMMIT_SHA,
    BUILD_DATE: new Date(parseInt(ENV.BUILD_DATE)).toUTCString(),
  });
}

export const analyticsSetUser = (
  user: {
    id: string;
    name: string;
    email: string;
    admin: boolean;
  } | null
) => {
  if (!enabled || user?.admin) return;

  if (!user) {
    Smartlook.anonymize();
    return;
  }

  Smartlook.identify(user.id, {
    name: user.name,
    email: user.email,
  });
};
