export const ENV = {
  /**
   * local = true;
   * next = true;
   * prod = false */
  IS_BUILD_DEV: import.meta.env.VITE_BUILD_ENVIRONMENT === "development",
  /**
   * local = true;
   * next = false;
   * prod = false */
  IS_DEV: import.meta.env.DEV,

  MAPBOX_KEY: import.meta.env.VITE_MAPBOX_KEY,

  SERVICE_ENDPOINT: import.meta.env.VITE_HOST_SERVICE_PROJECT,
  SERVICE_COST_ENDPOINT: import.meta.env.VITE_HOST_SERVICE_COST,
  SERVICE_FILE_ENDPOINT: import.meta.env.VITE_HOST_SERVICE_FILE,
  SERVICE_USER_ENDPOINT: import.meta.env.VITE_HOST_SERVICE_USER,

  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  SENTRY_ENVIRONMENT: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  SENTRY_RELEASE: import.meta.env.VITE_SENTRY_RELEASE,

  BUILD_ID: import.meta.env.VITE_BUILD_ID,
  BUILD_REGION: import.meta.env.VITE_BUILD_REGION,
  BUILD_APP_ID: import.meta.env.VITE_BUILD_APP_ID,
  BUILD_BRANCH: import.meta.env.VITE_BUILD_BRANCH,
  BUILD_COMMIT_SHA: import.meta.env.VITE_BUILD_COMMIT_SHA,
  BUILD_COMMIT_PREVIOUS_SHA: import.meta.env.VITE_BUILD_COMMIT_PREVIOUS_SHA,
  BUILD_PULL_REQUEST_ID: import.meta.env.VITE_BUILD_PULL_REQUEST_ID,
  BUILD_DATE: import.meta.env.VITE_BUILD_DATE,
  BUILD_ENVIROMENT: import.meta.env.VITE_BUILD_ENVIRONMENT,

  SMARTLOOK_KEY: import.meta.env.VITE_SMARTLOOK_KEY,
};
