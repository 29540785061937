import { type V2 } from "@project-rouge/rg-core";
import { IsVectorOnVertexV2 } from "./IsVectorOnVertex";
import { observable, toJS } from "mobx";

export function SanitizePolygonVertexesV2(polygons: V2[][], tolerance: number): V2[][] {
  const updatedPolygons = structuredClone(toJS(observable(polygons)));
  const positions = updatedPolygons.map((polygon) => polygon).flat();
  positions.forEach((a) => {
    positions.forEach((b) => {
      if (IsVectorOnVertexV2(a, b, tolerance)) {
        b[0] = a[0];
        b[1] = a[1];
      }
    });
  });
  return updatedPolygons;
}
