import { State } from "./InspectorRow";
import { InspectorRowXL } from "./InspectorRowXL";
import { InspectorGroup } from "./InspectorGroup";

export const SustainabilityGroupLoading = () => {
  return (
    <InspectorGroup label="Sustainability">
      <InspectorRowXL state={State.LOADING} value={0} decimalPoint={3}>
        <span>Indicative form factor</span>
      </InspectorRowXL>
      <InspectorRowXL state={State.LOADING} value={0} decimalPoint={0} unit={"kg"}>
        <span>
          CO<sub>2</sub> footprint
        </span>
      </InspectorRowXL>
      <InspectorRowXL state={State.LOADING} value={0} unit={"kg/sqm"} decimalPoint={0}>
        <span>
          CO<sub>2</sub> footprint/area
        </span>
      </InspectorRowXL>

      <InspectorRowXL state={State.LOADING} value={0} unit={"kg"} decimalPoint={0}>
        <span>
          Superstructure <br />
          CO<sub>2</sub> footprint
        </span>
      </InspectorRowXL>

      <InspectorRowXL state={State.LOADING} value={0} unit={"kg/sqm"} decimalPoint={0}>
        <span>
          Superstructure <br />
          CO<sub>2</sub> footprint /area
        </span>
      </InspectorRowXL>
    </InspectorGroup>
  );
};
