import { type PropsWithChildren } from "react";
import { Canvas } from "react-three-map";
import { useActiveProject } from "src/hooks/useActiveProject";
import { useSnapshot } from "src/hooks/useSnapshot";
import { ActiveProjectService } from "src/services/useActiveProject.service";
import { SnapshotService } from "src/services/useSnapshot.service/useSnapshot.service";
import { observer } from "mobx-react-lite";

type Props = PropsWithChildren<{
  origin: { lng: number; lat: number };
}>;

export const ThreeLayer = observer(function ThreeLayer(props: Props) {
  const project = useActiveProject();
  const snapshot = useSnapshot();
  return (
    <Canvas latitude={props.origin.lat} longitude={props.origin.lng} frameloop="demand">
      <hemisphereLight args={[0xffffff, 0x60666c]} position={[1, 4.5, 3]} />
      <SnapshotService.Provider value={snapshot}>
        <ActiveProjectService.Provider value={project}>
          {props.children}
        </ActiveProjectService.Provider>
      </SnapshotService.Provider>
    </Canvas>
  );
});
