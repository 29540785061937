import { observer } from "mobx-react-lite";
import type { DesignsGenerator } from "src/types/DesignsGenerator";

export const ProgressBar = observer(function ProgressBar({
  designGenerator,
}: {
  designGenerator: DesignsGenerator;
}) {
  const width = designGenerator.progress * 100;
  const isRunning = designGenerator.isRunning;
  return (
    <div className="w-full h-1 border-[1px] border-neutral-4 bg-neutral-1 flex-grow-0">
      {isRunning && (
        <div
          style={{ width: width === 100 ? 0 : `${width}%` }}
          className={`h-1 bg-primary-5 transition-all`}
        ></div>
      )}
    </div>
  );
});
