import { makeAutoObservable } from "mobx";
import { useState } from "react";

export class BooleanState {
  static useState(defaultValue = false) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useState(() => new BooleanState(defaultValue))[0];
  }
  isOn;
  constructor(defaultValue = false) {
    this.isOn = defaultValue;
    makeAutoObservable(this, {}, { autoBind: true });
  }
  toggle() {
    this.isOn = !this.isOn;
  }
  set(value: boolean) {
    this.isOn = value;
  }
  get isOff() {
    return !this.isOn;
  }
}
