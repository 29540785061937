import { type V2, type V3 } from "@project-rouge/rg-core";
import clip from "polygon-clipping";
import { arePolygonEqual } from "src/types/World";
import type { Zone } from "src/types/Zone";
import { V3ToV2 } from "./V3ToV2";

export function ClipLocalZones(localZones: Zone[], clipPolygon: V3[]): Zone[] {
  const updatedPolygons: Zone[] = [];
  const flatClipPolygon = [clipPolygon.map(([x, , y]) => [x, y] as V2)];
  for (const localZone of localZones) {
    const flatPolygon = [V3ToV2(localZone.outerRing)];
    const cliped = clip.difference(flatPolygon, flatClipPolygon);
    if (flatPolygon.toString() === cliped.toString()) {
      updatedPolygons.push(localZone);
      continue;
    }
    for (const [clip] of cliped) {
      const boundary = clip.map(([x, y]) => [x, 0, y] as V3);
      const existingZone = localZones.find((z) => arePolygonEqual(z.outerRing, boundary));
      if (existingZone) {
        updatedPolygons.push(existingZone);
      } else {
        const zone = localZone.clone();
        zone.setOffsets(null);
        zone.setId(crypto.randomUUID());
        zone.setOuterRing(boundary);
        updatedPolygons.push(zone);
      }
    }
  }
  return updatedPolygons;
}
