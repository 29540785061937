import { useMemo } from "react";
import { SvgPlanViewer } from "./SvgPlanViewer";
import { StylePresets, StylePresetsEnum } from "./StylePresets";
import { Compass2D } from "./Compass2D";
import { Controls } from "./Controls";
import { Legend } from "./Legend";
import { useControls } from "./useControls";
import { useMergeBuildings } from "./useMergeBuildings";
import { observer } from "mobx-react-lite";
import type { World } from "src/types/World";

export const Plan2D = observer(({ world }: { world: World }) => {
  const rgWorld = world.rgWorld;
  const controls = useControls(world);

  const allSelectedBuildingsIds = useMemo(() => {
    if (controls.selectedBuilding) return [controls.selectedBuilding.id];
    if (controls.selectedZone) return controls.selectedZone.buildings.map(({ id }) => id);
    return controls.zonesOptions.flatMap(({ buildings }) => buildings.map(({ id }) => id));
  }, [controls.zonesOptions, controls.selectedBuilding, controls.selectedZone]);

  const unitBuilding = useMergeBuildings(rgWorld, allSelectedBuildingsIds);
  if (!rgWorld) return null;
  return (
    <div className="flex w-full h-full relative">
      <div className="w-full h-full relative">
        {unitBuilding && (
          <SvgPlanViewer
            selectedLevelId={controls.selectedLevel}
            obj={unitBuilding}
            showScale={false}
            showLabels={controls.labelsVisible}
            styles={StylePresets[StylePresetsEnum.floorPlan]}
          />
        )}
        <Controls world={world} controls={controls} />
        {unitBuilding && (
          <Legend selectedLevel={controls.selectedLevel} uniBuilding={unitBuilding} />
        )}
        <Compass2D />
      </div>
    </div>
  );
});
