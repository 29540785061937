import { observer } from "mobx-react-lite";
import { useLayoutEffect } from "react";
import { useMap } from "react-map-gl";
import { useDebounce } from "src/hooks/useDebounce";

export const MapUtilResizeObserver = observer(() => {
  const map = useMap();
  const rootElement = map.current?.getCanvas().parentElement;
  const debounce = useDebounce(10);
  useLayoutEffect(() => {
    if (!rootElement) return;
    const resize = () =>
      debounce(() =>
        requestAnimationFrame(() =>
          setTimeout(() => map.current?.getCanvas() && map.current?.resize(), 0)
        )
      );
    const observer = new ResizeObserver(resize);
    observer.observe(rootElement);
    return () => observer.disconnect();
  }, [debounce, map, rootElement]);
  return null;
});
