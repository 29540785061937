import { Vectors } from "@project-rouge/rg-three";
import { useThree } from "@react-three/fiber";
import { useState } from "react";
import { useEvent } from "src/hooks/useEvent";
import { Plane, Vector3 } from "three";

export function useProjectScreenToPlanePoint() {
  const { raycaster } = useThree();
  const [plane] = useState(() => new Plane(Vectors.Up));
  return useEvent(() => {
    const projected = new Vector3();
    raycaster.ray.intersectPlane(plane, projected);
    return projected;
  });
}
