import { useFloating, shift, offset, arrow, flip, autoUpdate } from "@floating-ui/react";
import { FloatingPortal } from "@floating-ui/react-dom-interactions";
import { observer } from "mobx-react-lite";
import {
  type PropsWithChildren,
  useRef,
  type MutableRefObject,
  useCallback,
  type ReactNode,
} from "react";
import { Button, ButtonIntent, ButtonState } from "src/components/Button";
import { Cluster } from "src/components/Cluster";
import { useBoolean } from "src/hooks/useBoolean";

/** @todo - this need to be refactored to support alt and non-alt click at same time */
type Props = PropsWithChildren<{
  Icon: (props: { className: string }) => ReactNode;
  onAltKey?: boolean;
}>;

export const ListToolButton = observer(function ListToolButton({ children, Icon }: Props) {
  const visible = useBoolean();
  const arrowRef = useRef<SVGSVGElement>(null);

  const {
    x,
    y,
    refs,
    strategy,
    middlewareData: { arrow: { y: arrowY } = {} },
    refs: { floating: floatingRef, reference: referenceRef },
  } = useFloating({
    middleware: [
      shift({ padding: 8 }),
      offset({ mainAxis: 12 }),
      arrow({ element: arrowRef as MutableRefObject<HTMLElement | null> }),
      flip(),
    ],
    placement: "right",
    whileElementsMounted: autoUpdate,
  });
  const handleOpen = useCallback(
    (open: boolean) => {
      if (!open) {
        visible.off();
        return;
      }
      const onMousedown = ({ target }: MouseEvent) => {
        let element = target as HTMLElement | null | undefined;
        while (element && element !== document.body) {
          if (element === floatingRef.current) return;
          if (element === referenceRef.current) return;
          element = element?.parentElement;
        }
        visible.off();
        window.removeEventListener("mousedown", onMousedown);
      };
      window.addEventListener("mousedown", onMousedown);
      visible.on();
    },
    [floatingRef, referenceRef, visible]
  );
  return (
    <>
      <Button
        ref={refs.setReference}
        Icon={Icon}
        onClick={() => handleOpen(!visible.isOn)}
        onAltClick={() => handleOpen(!visible.isOn)}
        intent={ButtonIntent.NONE}
        state={ButtonState.NONE}
      />
      <FloatingPortal>
        <Cluster visible={visible.isOn}>
          <div
            className="bg-elevation-0 text-sm p-2 shadow-md border-gray-300 border rounded z-50"
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
          >
            {children}
            <svg
              className="w-4 flex-shrink-0 absolute overflow-visible text-white"
              ref={arrowRef}
              viewBox="0 0 20 20"
              style={{ left: -15, top: arrowY }}
            >
              <path
                d="M 19,0 10,10 19,20"
                // d="M 0,0 10,10 20,0"
                fill="currentColor"
                stroke="black"
                className="stroke-gray-300"
              />
            </svg>
          </div>
        </Cluster>
      </FloatingPortal>
    </>
  );
});
