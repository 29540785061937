import { useQuery } from "@tanstack/react-query";
import { useApi } from "src/contexts/api.context";
import CostWorker from "src/workers/Cost.worker?worker";
import {
  type CostWorkerMessage_Request,
  type CostWorkerMessage_Response,
} from "src/workers/Cost.worker";
import { type ProjectCostingCalculationHttpResource } from "@project-rouge/service-cost-client/resource/project/project-costing-calculation-resource";
import type { World } from "src/types/World";
import type { Building } from "src/types/Building";
import type { Api } from "src/types/Api";

export type MetricsCalculations = ProjectCostingCalculationHttpResource;
async function runCostWorker(
  data: CostWorkerMessage_Request
): Promise<CostWorkerMessage_Response["payload"]> {
  const worker = new CostWorker();
  const message = await new Promise<CostWorkerMessage_Response["payload"]>((resolve) => {
    worker.onmessage = ({ data }: MessageEvent<CostWorkerMessage_Response>) => {
      resolve(data.payload);
    };
    worker.postMessage(data);
  });
  worker.terminate();
  return message;
}

export function useMetricsCalculationsQuery(
  projectId: string,
  world: World | null,
  selectedBuildings?: Building[]
) {
  const api = useApi();
  const selectedBuildingsId = selectedBuildings?.map((building) => building.id);
  return useQuery({
    // we need "cost-metrics" here to be able to invalidate the query when cost settings change
    queryKey: ["cost-metrics", world?.rgWorldHashKey, selectedBuildingsId],
    staleTime: Infinity,
    enabled: world?.hasBuilding && !!world.rgWorldHashKey && !!world.rgWorldZip,
    queryFn: async () =>
      world
        ? fetchMetricCalculations(
            api,
            projectId,
            world,
            selectedBuildings?.length ? selectedBuildings : world.buildings
          )
        : null,
  });
}

export async function fetchMetricCalculations(
  api: Api,
  projectId: string,
  world: World,
  buildings: Building[]
): Promise<ProjectCostingCalculationHttpResource | null> {
  if (!world?.rgWorldZip) return null;
  const payload = await runCostWorker({
    rgWorldZip: world.rgWorldZip,
    selectedBuildingsIds: buildings.map((building) => building.id),
  });
  return await api.postProjectCostingCalculation(projectId, payload);
}
