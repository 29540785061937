import type { PropsWithChildren } from "react";
import emptyState from "src/assets/empty-state.svg";

export const EmptyState = (props: PropsWithChildren<{ title: string }>) => {
  return (
    <div className="absolute w-full h-full">
      <div className="h-[90%] w-[268px] text-center items-center justify-center mx-auto grid content-center">
        <img className="h-[64px] mx-auto" src={emptyState} alt="emptyState" />
        <p className="mt-5 text-subtitle-2 text-neutral-8">{props.title}</p>
        <div className="text-body-2 text-neutral-6">{props.children}</div>
      </div>
    </div>
  );
};
