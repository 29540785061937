import { useQuery, type UseQueryResult } from "@tanstack/react-query";
import { createContext, useMemo } from "react";
import { NEW_SITE_ROUTE_KEY } from "src/constants/NewSiteRouteKey";
import { useApi } from "src/contexts/api.context";
import { useActiveParams } from "src/hooks/useActiveParams";
import { useEvent } from "src/hooks/useEvent";
import { useRedirect } from "src/hooks/useRedirect";
import type { GeoPoint } from "src/types/GeoPoint";

interface Project {
  id: string;
  name: string;
  areaOfInterest: GeoPoint | null;
  createdAt: string;
  updatedAt: string;
  notes: string | null;
  designsCount: number;
  scenarios: {
    id: string;
    name: string;
  }[];
}

export type UseActiveProjectService = Omit<UseQueryResult<Project | null, unknown>, "data"> & {
  data: Project | null;
  deleteScenario: (siteId: string) => Promise<void>;
};

export function useActiveProjectService(): UseActiveProjectService {
  const api = useApi();
  const redirect = useRedirect();
  const { projectId } = useActiveParams();
  const projectQuery = useProjectQuery();

  const deleteScenario = useEvent(async (siteId: string) => {
    if (!projectId) return;
    await api.deleteProjectSite({ projectId, siteId });
    const next = projectQuery.data?.scenarios.find(({ id }) => id !== siteId)?.id;
    await projectQuery.refetch();
    return await redirect.scenario({ projectId, scenarioId: next ?? NEW_SITE_ROUTE_KEY });
  });

  return useMemo(
    () => ({ ...projectQuery, data: projectQuery.data ?? null, deleteScenario }),
    [deleteScenario, projectQuery]
  );
}

function useProjectQuery() {
  const api = useApi();
  const { projectId } = useActiveParams();

  return useQuery({
    queryKey: [projectId],
    keepPreviousData: true,
    staleTime: Infinity,
    queryFn: async (): Promise<Project | null> => {
      if (!projectId) return null;
      const [project, sites] = await Promise.all([
        api.getProject({ projectId }),
        api.getProjectSiteCollection({ projectId }),
      ]);
      if (!project || !sites) return null;
      const { page, pages } = sites.meta;
      const sitesList =
        page >= pages
          ? sites.results
          : [
              ...sites.results,
              ...(await Promise.all(
                Array.from({ length: pages - page }).map(async (_, page) => {
                  const list = await api.getProjectSiteCollection({ projectId, page: page + 2 });
                  return list?.results ?? [];
                })
              ).then((v) => v.flat())),
            ].filter((site, index, array) => array.findIndex(({ id }) => id === site.id) === index);
      return {
        id: project.id,
        name: project.label,
        areaOfInterest: project.area_of_interest?.point ?? null,
        createdAt: project.created_at,
        updatedAt: project.updated_at,
        notes: project.notes,
        designsCount: project.project_scenario_design_option_count,
        scenarios: sitesList.map((site) => ({
          id: site.id,
          name: "location name here, am i needed?",
        })),
      };
    },
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ActiveProjectService = createContext<UseActiveProjectService>(null as any);
