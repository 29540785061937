import classNames from "classnames";

export function ElementSectionButton(props: {
  label: string;
  onClick: () => void;
  active: boolean;
}) {
  return (
    <button
      className={classNames(
        "w-[128px] h-5  rounded-sm  text-sm flex items-center justify-center hover:cursor-pointer",
        props.active === true && "bg-primary-6 border-primary-7 text-white",
        props.active === false &&
          "hover:bg-primary-6 hover:border-primary-7 hover:text-white text-neutral-6"
      )}
      onClick={props.onClick}
    >
      {props.label}
    </button>
  );
}
