import { useId } from "react";
import { Layer, Source } from "react-map-gl";
import { observer } from "mobx-react-lite";
import type { MapState } from "src/types/MapState";

export const ElevationLayer = observer((props: { mapState: MapState }) => {
  const id = useId();
  if (props.mapState.layerTopographyLines.isOff) return null;
  return (
    <Source id={id} type="vector" url="mapbox://mapbox.mapbox-terrain-v2">
      <Layer
        id={id}
        type="line"
        source-layer="contour"
        layout={{
          "line-join": "round",
          "line-cap": "round",
        }}
        paint={{
          "line-color": "rgba(66,100,251, 0.5)",
          "line-width": 2,
          "line-dasharray": [2, 2],
        }}
      />
    </Source>
  );
});
