import { type V2, type V3 } from "@project-rouge/rg-core";
import clip from "polygon-clipping";
import { GetIntersectCutLine } from "./GetIntersectCutLine";
import type { Zone } from "src/types/Zone";
import { World } from "src/types/World";
import { V3ToV2 } from "./V3ToV2";

export function CutPolygons(polygons: Zone[], cutLine: V3[]): Zone[] {
  const clippedPolygons: Zone[] = [];
  for (const polygon of polygons) {
    clippedPolygons.push(...CutSinglePolygon(polygon, cutLine));
  }
  World.sanitizeZonesOuterRing(clippedPolygons);
  return clippedPolygons;
}

// function GetOffsets(offsets: number[] | null, length: number): number[] | null {
//   if (offsets === null) return null;
//   if (new Set(offsets).size === 1) return Array(length).fill(offsets[0]);
//   return null;
// }

function CutSinglePolygon(localZone: Zone, cutLine: V3[]): Zone[] {
  const flatPolygon: V2[] = V3ToV2(localZone.outerRing);
  const flatLine: V2[] = V3ToV2(cutLine);
  const results: V3[][] = SplitPolygon(flatPolygon, flatLine, 0.001)
    .map((polygon) => MergeClosestPolygonPoints(polygon))
    .map((polygon) => polygon.map(([x, y]) => [x, 0, y]));
  if (results.length === 1) return [localZone];
  // const zone = new Zone(RgCreate(DefaultZone, Dataset.rvData), {
  //   aggZoneProps: createAggZoneProps(),
  //   rgZoneBrief: createRgZoneBrief(),
  // });
  return results.map((boundary) => {
    const zone = localZone.clone(false);
    zone.setId(crypto.randomUUID());
    zone.setOuterRing(boundary);
    return zone;
  });
  // return results.map((boundary) => ({
  //   zone,
  //   boundary,
  //   boundaryHoles: [],
  //   id: crypto.randomUUID(),
  //   type: localZone.type,
  //   offsets: GetOffsets(localZone.offsets, boundary.length - 1),
  // }));
}

function SplitPolygon(polygon: V2[], line: V2[], tolerance: number): V2[][] {
  const cutLine = GetIntersectCutLine(line, polygon);
  const segments: V2[][] = [];
  for (let i = 0; i < cutLine.length - 1; i++) {
    const lineSegmentPolygon = LineToPolygon([cutLine[i], cutLine[i + 1]], tolerance / 2);
    segments.push(lineSegmentPolygon.map(([x, y]) => [x, y]));
  }
  const linePolygon = clip.union(segments.map((s) => [s]));
  const cliped = clip.difference([polygon], linePolygon).map(([v]) => v);
  return cliped;
}

function closePolygon(points: V2[]): V2[] {
  const [x1, y1] = points[0];
  const [x2, y2] = points[points.length - 1];
  return x1 !== x2 || y1 !== y2 ? [...points, [x1, y1]] : points;
}

function LineToExtrudePolygon(
  line: V2[],
  positiveX: boolean,
  positiveY: boolean,
  extrudeOffset: number
) {
  const offsetX = extrudeOffset * (positiveX ? 1 : -1);
  const offsetY = extrudeOffset * (positiveY ? 1 : -1);
  const reverse = structuredClone(line)
    .reverse()
    .map(([x, y]) => [x + offsetX, y + offsetY] as V2);
  const points = [...structuredClone(line), ...reverse];
  const polygon = closePolygon(points);
  return polygon;
}

function LineToPolygon(line: V2[], extrudeOffset: number): V2[] {
  const union = clip.union(
    [LineToExtrudePolygon(line, true, true, extrudeOffset)],
    [LineToExtrudePolygon(line, true, false, extrudeOffset)],
    [LineToExtrudePolygon(line, false, true, extrudeOffset)],
    [LineToExtrudePolygon(line, false, false, extrudeOffset)]
  );
  return union[0][0];
}

/**
 * merge closest points (continuos only)
 */
function MergeClosestPolygonPoints(polygon: V2[], threshold = 0.01): V2[] {
  const points: V2[] = [polygon[0]];
  for (let i = 1; i < polygon.length; i++) {
    const [x1, y1] = points[points.length - 1];
    const [x2, y2] = polygon[i];
    const distance = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
    if (distance > threshold) points.push(polygon[i]);
  }
  return points;
}
