import { makeAutoObservable } from "mobx";

export interface SelectOption {
  id: string;
}

export class SelectState<T extends SelectOption> {
  private active: T["id"];
  options: readonly T[];
  constructor(options: readonly T[], active: T["id"]) {
    this.active = active;
    this.options = options;
    makeAutoObservable(this, {}, { autoBind: true });
  }
  get value(): T {
    return this.options.find((option) => option.id === this.active) ?? this.options[0];
  }
  update(value: T["id"]) {
    this.active = value;
  }
}
