import { type V3 } from "@project-rouge/rg-core";
import { Circle } from "@react-three/drei";
import { useMemo, useState } from "react";
import { useWindowEvent } from "src/hooks/useWindowEvent";
import { DoubleSide, MathUtils } from "three";
import { useGetSnapVector, usePixelToMeterDistance } from "src/hooks/useSnapMouse";
import type { Zone } from "src/types/Zone";

const ROTATION: V3 = [-MathUtils.DEG2RAD * 90, 0, 0];

export const SnapMouseCursor = (props: {
  localZones: Zone[];
  snapTolerance: number;
  radiusInPx: number;
  fillColor: number;
}) => {
  const mouse = useMouseCursor(props.localZones, props.snapTolerance, true);

  if (!mouse) return null;
  return (
    <MouseCursor
      fillColor={props.fillColor}
      radiusInPx={props.radiusInPx}
      x={mouse[0]}
      z={mouse[2]}
    />
  );
};

export function useMouseCursor(localZones: Zone[], snapTolerancePx: number, strict: boolean) {
  const getPoint = useGetSnapVector();
  const polygons = useMemo(() => GetLocalZonesPoints(localZones), [localZones]);
  const [mouse, setMouse] = useState<V3 | null>(null);
  useWindowEvent("mousemove", () => {
    setMouse(
      getPoint({
        snapEdge: true,
        snapVertex: true,
        tolerance: snapTolerancePx,
        polygons,
        strict,
      })
    );
  });
  return mouse;
}

export function MouseCursor(props: {
  radiusInPx: number;
  fillColor: number;
  x: number;
  z: number;
}) {
  const radius = usePixelToMeterDistance(props.radiusInPx);
  return (
    <Circle
      args={[radius, 12]}
      position={[props.x, 0.04, props.z]}
      rotation={ROTATION}
      renderOrder={100}
    >
      <meshBasicMaterial
        color={props.fillColor}
        side={DoubleSide}
        depthTest={true}
        transparent={false}
        toneMapped={false}
      />
    </Circle>
  );
}

function GetLocalZonesPoints(localZones: Zone[]): V3[][] {
  return localZones.map(({ outerRing }) => outerRing);
}
