import { type V3 } from "@project-rouge/rg-core";
import { useState } from "react";
import { Cluster } from "src/components/Cluster";
import { useLiveRef } from "src/hooks/useLiveRef";
import { useMapCanvasEvent } from "src/hooks/useMapCanvasEvent";
import { useMapClickOrDrag } from "src/hooks/useMapClickOrDrag";
import { usePixelToMeterDistance } from "src/hooks/useSnapMouse";
import { SnapVectorToEdge } from "src/utils/SnapVectorToEdge";
import { SnapVectorToVector } from "src/utils/SnapVectorToVector";
import { CutFaceMode } from "./CutFaceMode";
import { InsertEdgeVertexMode } from "./InsertEdgeVertex.mode";
import { MoveVertexMode } from "./MoveVertex.mode";
import { PreviewMode } from "./Preview.mode";
import { MouseCursor, useMouseCursor } from "./SnapMouseCursor";
import type { Zone } from "src/types/Zone";

enum Mode {
  INSERT = "INSERT",
  PREVIEW = "PREVIEW",
  CUT = "CUT",
  MOVE_VERTEX = "MOVE_VERTEX",
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  DEFAULT = "PREVIEW",
}
export const CutTool = (props: {
  localZones: Zone[];
  snapTolerancePx: number;
  clickTolerancePx: number;
  onChange: (localZone: Zone[]) => void;
  onToolCancel: () => void;
}) => {
  const [mode, setMode] = useState(Mode.DEFAULT);
  const modeRef = useLiveRef(mode);
  const mouse = useMouseCursor(props.localZones, props.snapTolerancePx, true);
  const [startPosition, setStartPosition] = useState<V3>([0, 0, 0]);
  const polygons = props.localZones.map(({ outerRing }) => outerRing);
  const snapTolerance = usePixelToMeterDistance(props.snapTolerancePx);

  useMapCanvasEvent("keydown", (evt) => {
    switch (true) {
      case evt.key === "Escape": {
        if (mode === Mode.DEFAULT) {
          props.onToolCancel();
        } else {
          setMode(Mode.DEFAULT);
        }
        return;
      }
    }
  });

  useMapClickOrDrag({
    clickTolerancePx: props.clickTolerancePx,
    onClick: ({ mapDownVectorPosition }) => {
      if (modeRef.current === Mode.CUT) {
        const isOnEdge = SnapVectorToEdge(mapDownVectorPosition, polygons, snapTolerance);
        if (isOnEdge) return setMode(Mode.PREVIEW);
        return;
      }
      const isOnEdge = SnapVectorToEdge(mapDownVectorPosition, polygons, snapTolerance);
      if (!isOnEdge) return;
      setMode(Mode.CUT);
      setStartPosition(isOnEdge);
      return;
    },
    onMouseDown: ({ map, mapDownVectorPosition }) => {
      if (
        SnapVectorToVector(mapDownVectorPosition, polygons, snapTolerance) ||
        SnapVectorToEdge(mapDownVectorPosition, polygons, snapTolerance)
      ) {
        map.dragRotate.disable();
        map.dragPan.disable();
      }
    },
    onMouseUp: ({ map }) => {
      map.dragRotate.enable();
      map.dragPan.enable();
    },
    onDragStart: ({ mapDownVectorPosition }) => {
      const isOnVertex = SnapVectorToVector(mapDownVectorPosition, polygons, snapTolerance);
      if (isOnVertex) {
        setStartPosition(isOnVertex);
        setMode(Mode.MOVE_VERTEX);
        return;
      }
    },
  });

  return (
    <>
      <Cluster visible={mode === Mode.PREVIEW}>
        <PreviewMode
          localZones={props.localZones}
          hoverEnabled={false}
          selectEnabled={false}
          showVertexes={true}
        />
        {mouse && <MouseCursor fillColor={0x58588e} radiusInPx={6} x={mouse[0]} z={mouse[2]} />}
      </Cluster>
      <Cluster visible={mode === Mode.CUT}>
        <CutFaceMode
          localZones={props.localZones}
          snapTolerancePx={props.snapTolerancePx}
          startPosition={startPosition}
          updatePolygons={props.onChange}
        />
      </Cluster>
      <Cluster visible={mode === Mode.INSERT}>
        <InsertEdgeVertexMode
          localZones={props.localZones}
          snapTolerance={props.snapTolerancePx}
          startPosition={startPosition}
          updatePolygons={(zones) => {
            props.onChange(zones);
            setMode(Mode.DEFAULT);
          }}
        />
      </Cluster>
      <Cluster visible={mode === Mode.MOVE_VERTEX}>
        <MoveVertexMode
          zones={props.localZones}
          snapTolerance={props.snapTolerancePx}
          startPosition={startPosition}
          updatePolygons={(zones) => {
            props.onChange(zones);
            setMode(Mode.DEFAULT);
          }}
        />
      </Cluster>
    </>
  );
};
