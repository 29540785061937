import { useQuery } from "@tanstack/react-query";
import { RegisteredColumns, SortDirection } from "src/components/ExplorePanel/RegisteredColumns";
import type { RegisteredColumnId } from "src/components/ExplorePanel/RegisteredColumnId";
import {
  RegisteredColumnId_Base,
  RegisteredColumnId_Cost,
  RegisteredColumnId_Design,
  RegisteredColumnId_Sustainability,
} from "src/components/ExplorePanel/RegisteredColumnId";
import { useApi } from "src/contexts/api.context";

export const WorkspaceQueryKey = ["WORKSPACE"];
export const defaultColumns: RegisteredColumnId[] = [
  RegisteredColumnId_Base.IMAGE,
  RegisteredColumnId_Base.NAME,
  RegisteredColumnId_Design.UNITS,
  RegisteredColumnId_Design.RESIDENTIALEFFICIENCY,
  RegisteredColumnId_Design.GIA,
  RegisteredColumnId_Design.NIA,
  RegisteredColumnId_Cost.CONSTRUCTIONCOST,
  RegisteredColumnId_Sustainability.CO2FOOTPRINT,
  RegisteredColumnId_Design.UNITMIX,
];

export interface WorkspaceConfig {
  version: number;
  explorePanel: TableConfig;
}
export interface TableConfig {
  cols: { id: RegisteredColumnId; width: number }[];
  pinnedLength: number;
  sortedColumnID: string | null;
  sortDirection: SortDirection;
}
export const CURRENT_WORKSPACE_VERSION = 3;

function getInitialState(): WorkspaceConfig {
  return {
    version: CURRENT_WORKSPACE_VERSION,
    explorePanel: {
      cols: defaultColumns.map((id) => ({
        id,
        width: RegisteredColumns[id].defaultWidth,
      })),
      pinnedLength: 2,
      sortedColumnID: null,
      sortDirection: SortDirection.Asc,
    },
  };
}
export function useWorkspaceQuery() {
  const api = useApi();
  return useQuery({
    queryKey: WorkspaceQueryKey,
    staleTime: Infinity,
    keepPreviousData: true,
    queryFn: async () => {
      const response = await api.getWorkspace();
      if (!response.version || response.version !== CURRENT_WORKSPACE_VERSION) {
        return getInitialState();
      }
      return response ?? getInitialState();
    },
  });
}
