import type { ReactNode } from "react";
import type { TableCellProps } from "./TableCell";
import { TableCell } from "./TableCell";
import type { TableCellHeaderProps } from "./TableCellHeader";
import { TableCellHeader } from "./TableCellHeader";
import { meterToFeet, perSqmToPerHectare, perSqmToPerSqft, sqmToSqft } from "src/hooks/meterToFeet";
import type { RegisteredColumnId } from "./RegisteredColumnId";
import {
  RegisteredColumnId_Base,
  RegisteredColumnId_Cost,
  RegisteredColumnId_Design,
  RegisteredColumnId_Sustainability,
} from "./RegisteredColumnId";
import { observer } from "mobx-react-lite";
import type { RowData } from "./RowData";
import { UnitMixCell } from "./UnitMixCell";
import { useActiveParams } from "src/hooks/useActiveParams";
import { UnitCategory, UnitType, unitSuffix } from "src/types/UnitType";

export const enum SortDirection {
  Asc = "ascending",
  Desc = "descending",
}

interface RenderCellProps extends TableCellProps {
  data: RowData;
  unitType: UnitType;
  hover?: boolean;
  selected?: boolean;
  unitCategory: UnitCategory | null;
  onCellSelect?: ((id: string) => void) | undefined;
}

interface RenderHeadProps<K extends RegisteredColumnId> extends TableCellHeaderProps<K> {
  unitType: UnitType;
  unitCategory: UnitCategory | null;
}

type RenderCell = (props: RenderCellProps) => ReactNode;

type RenderHead<K extends RegisteredColumnId> = (props: RenderHeadProps<K>) => ReactNode;

interface SortByProps {
  sortedColumn: SortDirection | null;
}

type SortBy = (a: RowData, b: RowData, props: SortByProps) => number;

export interface DesignColumn<K extends RegisteredColumnId = RegisteredColumnId> {
  id: K;
  label: string;
  defaultWidth: number;
  unitCategory: UnitCategory | null;
  RenderHead: RenderHead<K>;
  RenderCell: RenderCell;
  sortBy?: SortBy;
}

export const RegisteredColumns: { [K in RegisteredColumnId]: DesignColumn<K> } = {
  IMAGE: {
    label: "IMAGE",
    id: RegisteredColumnId_Base.IMAGE,
    defaultWidth: 56,
    unitCategory: UnitCategory.Area,
    RenderHead: observer((props) => {
      return <TableCellHeader {...props} />;
    }),
    RenderCell: observer((props) => {
      const { projectId, scenarioId } = useActiveParams();
      if (!props.data.thumbnail) return null;
      return (
        <TableCell {...props}>
          <div
            className="border-neutral-4 border rounded-sm bg-neutral-4 w-[32px] h-[32px]"
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              if (!projectId || !scenarioId) return;
              props.onCellSelect?.(props.data.id);
            }}
            style={{
              backgroundImage: `url("${props.data.thumbnail}")`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          />
        </TableCell>
      );
    }),
  },
  NAME: {
    label: "NAME",
    id: RegisteredColumnId_Base.NAME,
    defaultWidth: 136,
    unitCategory: null,
    RenderHead: observer((props) => {
      return <TableCellHeader {...props}>Name</TableCellHeader>;
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">{props.data.label}</div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.label),
  },
  UNITS: {
    label: "Units",
    id: RegisteredColumnId_Design.UNITS,
    defaultWidth: 120,
    unitCategory: UnitCategory.Count,
    RenderHead: observer((props) => {
      return <TableCellHeader {...props}>Units</TableCellHeader>;
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">
            {props.data.architecturalMetrics.ApartmentQuantityTotal}
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.ApartmentQuantityTotal),
  },
  RESIDENTIALEFFICIENCY: {
    label: "Residential efficiency",
    id: RegisteredColumnId_Design.RESIDENTIALEFFICIENCY,
    defaultWidth: 200,
    unitCategory: UnitCategory.Percentage,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>Residential Efficiency</span>{" "}
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>
              {(props.data.architecturalMetrics.ResidentialEfficiencyTotal * 100).toFixed(1)}
            </span>
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.ResidentialEfficiencyTotal),
  },
  GIA: {
    label: "GIA",
    id: RegisteredColumnId_Design.GIA,
    defaultWidth: 120,
    unitCategory: UnitCategory.Area,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>GIA</span>{" "}
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">
            {formatArea(props.data.architecturalMetrics.GIATotal, props.unitType, 0)}
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.GIATotal),
  },
  NIA: {
    label: "NIA",
    id: RegisteredColumnId_Design.NIA,
    defaultWidth: 120,
    unitCategory: UnitCategory.Area,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>NIA</span>
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">
            {formatArea(props.data.architecturalMetrics.NIATotal, props.unitType, 0)}
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.NIATotal),
  },
  CONSTRUCTIONCOST: {
    label: "Construction cost",
    id: RegisteredColumnId_Cost.CONSTRUCTIONCOST,
    defaultWidth: 200,
    unitCategory: UnitCategory.Cost,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>Construction Cost</span>{" "}
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <span className="text-neutral-6">{unitSuffix(props.unitType, props.unitCategory)}</span>
          {formatNumber(props.data.costMetrics.summary.construction, 0)}
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.costMetrics.summary.construction),
  },
  CO2FOOTPRINT: {
    label: "CO2 footprint",
    id: RegisteredColumnId_Sustainability.CO2FOOTPRINT,
    defaultWidth: 200,
    unitCategory: UnitCategory.Mass,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full items-center space-x-1">
            <span>
              CO<sub>2</sub> Footprint
            </span>
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          {formatNumber(props.data.sustainabilityMetrics.summary.total, 0)}
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.sustainabilityMetrics.summary.total),
  },
  UNITMIX: {
    label: "Unit mix",
    id: RegisteredColumnId_Design.UNITMIX,
    defaultWidth: 200,
    unitCategory: UnitCategory.Percentage,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>Unit Mix</span>{" "}
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <UnitMixCell
          type={props.type}
          width={props.width}
          hover={props.hover}
          selected={props.selected}
          data={props.data}
        />
      );
    }),
  },
  BUILDINGS: {
    label: "Buildings",
    id: RegisteredColumnId_Design.BUILDINGS,
    defaultWidth: 200,
    unitCategory: UnitCategory.Count,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full">Buildings</div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">
            {props.data.architecturalMetrics.buildingsCount}
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.buildingsCount),
  },
  MODULES: {
    label: "Modules",
    id: RegisteredColumnId_Design.MODULES,
    defaultWidth: 120,
    unitCategory: UnitCategory.Count,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full">Modules</div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">
            {props.data.architecturalMetrics.ApartmentModuleQuantityTotal}
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.ApartmentModuleQuantityTotal),
  },
  FLOORS: {
    label: "Floors",
    id: RegisteredColumnId_Design.FLOORS,
    defaultWidth: 120,
    unitCategory: UnitCategory.Count,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full">Floors</div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">{props.data.architecturalMetrics.FloorMax}</div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.FloorMax),
  },
  PODIUMLEVELS: {
    label: "Podium level(s)",
    id: RegisteredColumnId_Design.PODIUMLEVELS,
    defaultWidth: 150,
    unitCategory: UnitCategory.Count,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full">Podium Levels</div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">
            {props.data.architecturalMetrics.PodiumHeightSumTotal / 4}
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.PodiumHeightSumTotal),
  },
  FLOORSRESIDENTIAL: {
    label: "Floors (residential)",
    id: RegisteredColumnId_Design.FLOORSRESIDENTIAL,
    defaultWidth: 150,
    unitCategory: UnitCategory.Count,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full">Floors (residential)</div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">
            {props.data.architecturalMetrics.ResidentialLevelCountMax}
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.FloorMax),
  },
  ENTRANCES: {
    label: "Entrances",
    id: RegisteredColumnId_Design.ENTRANCES,
    defaultWidth: 150,
    unitCategory: UnitCategory.Count,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full">Entrances</div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">
            {props.data.architecturalMetrics.EntrancesTotal}
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.EntrancesTotal),
  },
  CORES: {
    label: "Cores",
    id: RegisteredColumnId_Design.CORES,
    defaultWidth: 120,
    unitCategory: UnitCategory.Count,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full">Cores</div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">
            {props.data.architecturalMetrics.CoresTotal}
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.CoresTotal),
  },
  FIREFIGHTINGCORES: {
    label: "Firefighting cores",
    id: RegisteredColumnId_Design.FIREFIGHTINGCORES,
    defaultWidth: 150,
    unitCategory: UnitCategory.Count,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full">Firefighting Cores</div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">
            {props.data.architecturalMetrics.FireCoresTotal}
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.FireCoresTotal),
  },
  LIFTCORES: {
    label: "Lift cores",
    id: RegisteredColumnId_Design.LIFTCORES,
    defaultWidth: 150,
    unitCategory: UnitCategory.Count,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full">Lift Cores</div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">
            {props.data.architecturalMetrics.LiftCoresTotal}
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.LiftCoresTotal),
  },
  BUILDINGHEIGHT: {
    label: "Building height",
    id: RegisteredColumnId_Design.BUILDINGHEIGHT,
    defaultWidth: 200,
    unitCategory: UnitCategory.Length,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>Building Height</span>{" "}
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">
            {formatLength(props.data.architecturalMetrics.BuildingHeightMax, props.unitType, 2)}
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.BuildingHeightMax),
  },
  FFL: {
    label: "FFL",
    id: RegisteredColumnId_Design.FFL,
    defaultWidth: 120,
    unitCategory: UnitCategory.Length,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>FFL</span>{" "}
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">
            {formatLength(props.data.architecturalMetrics.FFLMax, props.unitType, 2)}
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.FFLMax),
  },
  GEA: {
    label: "GEA",
    id: RegisteredColumnId_Design.GEA,
    defaultWidth: 120,
    unitCategory: UnitCategory.Area,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>GEA</span>
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">
            {formatArea(props.data.architecturalMetrics.GEATotal, props.unitType, 0)}
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.GEATotal),
  },
  BUILDINGFOOTPRINT: {
    label: "Building footprint",
    id: RegisteredColumnId_Design.BUILDINGFOOTPRINT,
    defaultWidth: 200,
    unitCategory: UnitCategory.Area,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>Building Footprint</span>{" "}
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">
            {formatArea(props.data.architecturalMetrics.FootPrintTotal, props.unitType, 0)}
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.FootPrintTotal),
  },
  COMMERCIALAREA: {
    label: "Commercial area",
    id: RegisteredColumnId_Design.COMMERCIALAREA,
    defaultWidth: 200,
    unitCategory: UnitCategory.Area,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>Commercial Area</span>{" "}
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">
            {formatArea(
              props.data.architecturalMetrics.CommercialAreaPerLevel.at(0) ?? 0,
              props.unitType,
              0
            )}
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.CommercialAreaPerLevel.at(0) ?? 0),
  },
  NONRESIDENTIALAREA: {
    label: "Non-residential area",
    id: RegisteredColumnId_Design.NONRESIDENTIALAREA,
    defaultWidth: 200,
    unitCategory: UnitCategory.Area,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>Non Residential Area</span>
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">
            {formatArea(props.data.architecturalMetrics.NonResidentialAreaTotal, props.unitType, 0)}
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.NonResidentialAreaTotal),
  },
  COREANDCORRIDOR: {
    label: "Core & corridor area",
    id: RegisteredColumnId_Design.COREANDCORRIDOR,
    defaultWidth: 200,
    unitCategory: UnitCategory.Area,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>Core & Corridor Area</span>{" "}
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">
            {formatArea(
              props.data.architecturalMetrics.CoreAndCorridorAreaTotal,
              props.unitType,
              0
            )}
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.CoreAndCorridorAreaTotal),
  },
  SERVICEAREA: {
    label: "Services area",
    id: RegisteredColumnId_Design.SERVICEAREA,
    defaultWidth: 200,
    unitCategory: UnitCategory.Area,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>Service Area</span>{" "}
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">
            {formatArea(
              props.data.architecturalMetrics.ServiceAreaPerLevel.at(0) ?? 0,
              props.unitType,
              0
            )}
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.ServiceAreaPerLevel.at(0) ?? 0),
  },
  AMENITIESAREA: {
    label: "Amenities area",
    id: RegisteredColumnId_Design.AMENITIESAREA,
    defaultWidth: 150,
    unitCategory: UnitCategory.Area,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>Amenities Area</span>{" "}
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">
            {formatArea(props.data.architecturalMetrics.AmenitiesAreaTotal, props.unitType, 0)}
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.AmenitiesAreaTotal),
  },
  GROSSFACADEAREA: {
    label: "Gross façade area",
    id: RegisteredColumnId_Design.GROSSFACADEAREA,
    defaultWidth: 200,
    unitCategory: UnitCategory.Area,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>Gross Façade Area</span>{" "}
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">
            {formatArea(props.data.architecturalMetrics.FacadeTotal, props.unitType, 0)}
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.FacadeTotal),
  },
  GLAZEDFACADEAREA: {
    label: "Glazed façade area",
    id: RegisteredColumnId_Design.GLAZEDFACADEAREA,
    defaultWidth: 200,
    unitCategory: UnitCategory.Area,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>Glazed Façade Area</span>{" "}
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">
            {formatArea(props.data.architecturalMetrics.GlazedFacade, props.unitType, 0)}
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.GlazedFacade),
  },
  NETFACADEAREA: {
    label: "Net façade area",
    id: RegisteredColumnId_Design.NETFACADEAREA,
    defaultWidth: 200,
    unitCategory: UnitCategory.Area,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>Net Façade Area</span>{" "}
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">
            {formatArea(props.data.architecturalMetrics.FacadePerLevel, props.unitType, 0)}
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.FacadePerLevel),
  },
  ROOFAREA: {
    label: "Roof area",
    id: RegisteredColumnId_Design.ROOFAREA,
    defaultWidth: 150,
    unitCategory: UnitCategory.Area,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>Roof Area</span>{" "}
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">
            {formatArea(props.data.architecturalMetrics.RoofAreaTotal, props.unitType, 0)}
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.RoofAreaTotal),
  },
  DENSITY: {
    label: "Density",
    id: RegisteredColumnId_Design.DENSITY,
    defaultWidth: 120,
    unitCategory: UnitCategory.CountPerHectare,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>Density</span>{" "}
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">
            {formatSqmPerHectare(props.data.architecturalMetrics.DensityUnitsTotal, 2)}
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.DensityUnitsTotal),
  },
  DENSITY_HABITABLEROOMS: {
    label: "Density (habitable rooms)",
    id: RegisteredColumnId_Design.DENSITY_HABITABLEROOMS,
    defaultWidth: 250,
    unitCategory: UnitCategory.CountPerHectare,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>Density (habitable rooms)</span>{" "}
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">
            {formatSqmPerHectare(props.data.architecturalMetrics.DensityHabitableRoomsTotal, 2)}
          </div>
        </TableCell>
      );
    }),
    sortBy: (a, b, props) => {
      if (!props.sortedColumn) return 0;
      const first = props.sortedColumn === SortDirection.Asc ? a : b;
      const second = first === a ? b : a;
      return first.architecturalMetrics.NIATotal - second.architecturalMetrics.NIATotal;
    },
  },
  BUILDINGEFFICIENCY: {
    label: "Building efficiency",
    id: RegisteredColumnId_Design.BUILDINGEFFICIENCY,
    defaultWidth: 150,
    unitCategory: UnitCategory.Percentage,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>Building Efficiency</span>{" "}
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>
              {(props.data.architecturalMetrics.BuildingEfficiencyTotal * 100).toFixed(1)}
            </span>
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.BuildingEfficiencyTotal),
  },
  LANDCOVERAGE: {
    label: "Land coverage",
    id: RegisteredColumnId_Design.LANDCOVERAGE,
    defaultWidth: 150,
    unitCategory: UnitCategory.Percentage,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>Land Coverage</span>{" "}
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>{(props.data.architecturalMetrics.LandCoverageTotal * 100).toFixed(1)}</span>
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.LandCoverageTotal),
  },
  NORTHFACINGUNITS: {
    label: "North facing units",
    id: RegisteredColumnId_Design.NORTHFACINGUNITS,
    defaultWidth: 200,
    unitCategory: UnitCategory.Count,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full">North Facing Units</div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>{props.data.architecturalMetrics.NorthFacingUnitsTotal}</span>
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.NorthFacingUnitsTotal),
  },
  PARTMCOMPLIANTUNIT: {
    label: "(*) Part M compliant units",
    id: RegisteredColumnId_Design.PARTMCOMPLIANTUNIT,
    defaultWidth: 200,
    unitCategory: UnitCategory.Count,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full">Part M Compliant Unit (*)</div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">**</div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.NorthFacingUnitsTotal),
  },
  UNITSPERCORE: {
    label: "Unit per core",
    id: RegisteredColumnId_Design.UNITSPERCORE,
    defaultWidth: 150,
    unitCategory: UnitCategory.Count,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full">Units per Core</div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>{props.data.architecturalMetrics.UnitToLiftCoreRatio.toFixed(0)}</span>
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.UnitToLiftCoreRatio),
  },
  DUALASPECT: {
    label: "(*) Dual aspect units",
    id: RegisteredColumnId_Design.DUALASPECT,
    defaultWidth: 150,
    unitCategory: UnitCategory.Count,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full">Dual Aspect (*)</div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">**</div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.UnitToLiftCoreRatio),
  },

  MODULESCOST: {
    label: "Modules",
    id: RegisteredColumnId_Cost.MODULESCOST,
    defaultWidth: 120,
    unitCategory: UnitCategory.Cost,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>Modules</span>{" "}
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <span className="text-neutral-6">{unitSuffix(props.unitType, props.unitCategory)}</span>
          {formatNumber(props.data.costMetrics.summary.constructionModules, 0)}
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.costMetrics.summary.constructionModules),
  },
  FITOUT: {
    label: "Fit-out",
    id: RegisteredColumnId_Cost.FITOUT,
    defaultWidth: 120,
    unitCategory: UnitCategory.Cost,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>Fit-out</span>{" "}
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <span className="text-neutral-6">{unitSuffix(props.unitType, props.unitCategory)}</span>
          {formatNumber(props.data.costMetrics.summary.constructionFitOut, 0)}
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.costMetrics.summary.constructionFitOut),
  },
  BASEBUILD: {
    label: "Base build",
    id: RegisteredColumnId_Cost.BASEBUILD,
    defaultWidth: 200,
    unitCategory: UnitCategory.Cost,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>Base Build</span>{" "}
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <span className="text-neutral-6">{unitSuffix(props.unitType, props.unitCategory)}</span>
          {formatNumber(props.data.costMetrics.summary.constructionBaseBuild, 0)}
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.costMetrics.summary.constructionBaseBuild),
  },
  PRELIMS: {
    label: "Prelims",
    id: RegisteredColumnId_Cost.PRELIMS,
    defaultWidth: 120,
    unitCategory: UnitCategory.Cost,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>Prelims</span>{" "}
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <span className="text-neutral-6">{unitSuffix(props.unitType, props.unitCategory)}</span>
          {formatNumber(props.data.costMetrics.summary.preliminaries, 0)}
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.costMetrics.summary.preliminaries),
  },
  TOTALCOST: {
    label: "Total cost",
    id: RegisteredColumnId_Cost.TOTALCOST,
    defaultWidth: 150,
    unitCategory: UnitCategory.Cost,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>Total Cost</span>{" "}
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <span className="text-neutral-6">{unitSuffix(props.unitType, props.unitCategory)}</span>
          {formatNumber(props.data.costMetrics.summary.total, 0)}
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.costMetrics.summary.total),
  },
  CONTINGENCY: {
    label: "Contingency",
    id: RegisteredColumnId_Cost.CONTINGENCY,
    defaultWidth: 150,
    unitCategory: UnitCategory.Cost,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>Contingency</span>{" "}
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <span className="text-neutral-6">{unitSuffix(props.unitType, props.unitCategory)}</span>
          {formatNumber(props.data.costMetrics.summary.contingencies, 0)}
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.costMetrics.summary.contingencies),
  },
  OHAPALLOWANCE: {
    label: "OH&P allowance",
    id: RegisteredColumnId_Cost.OHAPALLOWANCE,
    defaultWidth: 200,
    unitCategory: UnitCategory.Cost,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>OH & P Allowance</span>{" "}
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <span className="text-neutral-6">{unitSuffix(props.unitType, props.unitCategory)}</span>
          {formatNumber(props.data.costMetrics.summary.overheadsAndProfits, 0)}
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.costMetrics.summary.overheadsAndProfits),
  },
  CONSTRUCTIONSQFT: {
    label: "Construction /area",
    id: RegisteredColumnId_Cost.CONSTRUCTIONSQFT,
    defaultWidth: 200,
    unitCategory: UnitCategory.CostPerArea,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>Construction /area</span>{" "}
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <span className="text-neutral-6">£</span>
          {formatPerSqmPerSqft(
            props.data.costMetrics.perMetrics.constructionPerArea,
            props.unitType,
            0
          )}
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.costMetrics.perMetrics.constructionPerArea),
  },
  CONSTRUCTIONUNIT: {
    label: "Construction /unit",
    id: RegisteredColumnId_Cost.CONSTRUCTIONUNIT,
    defaultWidth: 200,
    unitCategory: UnitCategory.CostPerUnit,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>Construction /unit</span>{" "}
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <span className="text-neutral-6">£</span>
          {formatNumber(props.data.costMetrics.perMetrics.constructionPerUnit, 0)}
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.costMetrics.perMetrics.constructionPerUnit),
  },
  INDICATIVEFORMFACTOR: {
    label: "Indicative form factor",
    id: RegisteredColumnId_Design.INDICATIVEFORMFACTOR,
    defaultWidth: 200,
    unitCategory: null,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full">Indicative form factor</div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">
            {props.data.architecturalMetrics.IndicativeFormFactorTotal.toFixed(3)}
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.IndicativeFormFactorTotal),
  },
  WALLTOFLOORRATIO: {
    label: "Wall to floor ratio",
    id: RegisteredColumnId_Design.WALLTOFLOORRATIO,
    defaultWidth: 200,
    unitCategory: null,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full">Wall to floor ratio</div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">
            {formatArea(props.data.architecturalMetrics.WallToFloorRatioTotal, props.unitType, 2)}
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.WallToFloorRatioTotal),
  },
  CONCAVEFACADECORNER: {
    label: "Concave façade corner length",
    id: RegisteredColumnId_Design.CONCAVEFACADECORNER,
    defaultWidth: 200,
    unitCategory: UnitCategory.Length,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>Concave Façade Corner Length</span>{" "}
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>
              {formatLength(
                props.data.architecturalMetrics.FacadeInternalCornerLength,
                props.unitType,
                2
              )}
            </span>
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.FacadeInternalCornerLength),
  },
  CONVEXFACADECORNER: {
    label: "Convex façade corner length",
    id: RegisteredColumnId_Design.CONVEXFACADECORNER,
    defaultWidth: 200,
    unitCategory: UnitCategory.Length,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>Convex Façade Corner Length</span>
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>
              {formatLength(
                props.data.architecturalMetrics.FacadeExternalCornerLength,
                props.unitType,
                2
              )}
            </span>
          </div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.FacadeExternalCornerLength),
  },
  SUBSTRUCTURECO2FOOTPRINT: {
    label: "(*) Substructure CO2 footprint /area",
    id: RegisteredColumnId_Sustainability.SUBSTRUCTURECO2FOOTPRINT,
    defaultWidth: 200,
    unitCategory: UnitCategory.MassPerArea,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end space-x-1">
            <span>
              Substructure CO<sub>2</sub> Footprint /area
            </span>
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
            <span>(*)</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">**</div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.architecturalMetrics.FacadeExternalCornerLength),
  },
  SUPERSTRUCTURECO2FOOTPRINT: {
    label: "Superstructure CO2 footprint /area",
    id: RegisteredColumnId_Sustainability.SUPERSTRUCTURECO2FOOTPRINT,
    defaultWidth: 200,
    unitCategory: UnitCategory.MassPerArea,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>
              Superstructure CO<sub>2</sub> Footprint /area
            </span>
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          {formatArea(
            props.data.sustainabilityMetrics.perMetrics.superstructure,
            props.unitType,
            0
          )}
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.sustainabilityMetrics.perMetrics.superstructure),
  },
  STEELCO2FOOTPRINT: {
    label: "(*) Steel CO2 footprint /area ",
    id: RegisteredColumnId_Sustainability.STEELCO2FOOTPRINT,
    defaultWidth: 200,
    unitCategory: UnitCategory.MassPerArea,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>
              Steel CO<sub>2</sub> Footprint /area
            </span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">**</div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.sustainabilityMetrics.perMetrics.superstructure),
  },
  STEELCO2FOOTPRINT2: {
    label: "(*) Steel CO2 footprint ",
    id: RegisteredColumnId_Sustainability.STEELCO2FOOTPRINT2,
    defaultWidth: 200,
    unitCategory: UnitCategory.Mass,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>
              Steel CO<sub>2</sub> Footprint
            </span>
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
            <span>(*)</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">**</div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.sustainabilityMetrics.perMetrics.superstructure),
  },
  CONCRETECO2FOOTPRINT: {
    label: "(*) Concrete CO2 footprint /area",
    id: RegisteredColumnId_Sustainability.CONCRETECO2FOOTPRINT,
    defaultWidth: 200,
    unitCategory: UnitCategory.MassPerArea,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>
              Concrete CO<sub>2</sub>
            </span>
            Footprint /area
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
            <span>(*)</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">**</div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.sustainabilityMetrics.perMetrics.superstructure),
  },
  CONCRETECO2FOOTPRINT2: {
    label: "(*) Concrete CO2 footprint",
    id: RegisteredColumnId_Sustainability.CONCRETECO2FOOTPRINT2,
    defaultWidth: 200,
    unitCategory: UnitCategory.Mass,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>
              Concrete CO<sub>2</sub> Footprint
            </span>
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
            <span>(*)</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">**</div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.sustainabilityMetrics.perMetrics.superstructure),
  },
  SUBSTRUCTURECO2FOOTPRINT2: {
    label: "(*) Substructure CO2 footprint",
    id: RegisteredColumnId_Sustainability.SUBSTRUCTURECO2FOOTPRINT2,
    defaultWidth: 200,
    unitCategory: UnitCategory.Mass,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>
              Substructure CO<sub>2</sub> Footprint
            </span>
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
            <span>(*)</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          <div className="flex justify-end w-full">**</div>
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.sustainabilityMetrics.perMetrics.superstructure),
  },
  SUPERSTRUCTURECO2FOOTPRINT2: {
    label: "Superstructure CO2 footprint",
    id: RegisteredColumnId_Sustainability.SUPERSTRUCTURECO2FOOTPRINT2,
    defaultWidth: 200,
    unitCategory: UnitCategory.Mass,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>
              Super Structure CO<sub>2</sub>Footprint
            </span>
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          {formatNumber(props.data.sustainabilityMetrics.summary.superstructure, 0)}
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.sustainabilityMetrics.summary.superstructure),
  },
  CO2FOOTPRINTSQFT: {
    label: "CO2 footprint /area",
    id: RegisteredColumnId_Sustainability.CO2FOOTPRINTSQFT,
    defaultWidth: 200,
    unitCategory: UnitCategory.MassPerArea,
    RenderHead: observer((props) => {
      return (
        <TableCellHeader {...props}>
          <div className="flex justify-end w-full space-x-1">
            <span>
              CO<sub>2</sub> Footprint /area
            </span>
            <span className="text-body-1">{unitSuffix(props.unitType, props.unitCategory)}</span>
          </div>
        </TableCellHeader>
      );
    }),
    RenderCell: observer((props) => {
      return (
        <TableCell {...props}>
          {formatPerSqmPerSqft(
            props.data.sustainabilityMetrics.perMetrics.total,
            props.unitType,
            0
          )}
        </TableCell>
      );
    }),
    sortBy: sort((row) => row.sustainabilityMetrics.perMetrics.total),
  },
};

function formatSqmPerHectare(num: number, fractionDigits: number) {
  return perSqmToPerHectare(num).toLocaleString("en", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
}

function formatNumber(num: number, fractionDigits: number) {
  return num.toLocaleString("en", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
}

function formatLength(num: number, lengthUnits: UnitType, fractionDigits: number) {
  const value = lengthUnits === UnitType.Metric ? num : meterToFeet(num);
  return value.toLocaleString("en", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
}

function formatArea(num: number, areaUnits: UnitType, fractionDigits: number) {
  const value = areaUnits === UnitType.Metric ? num : sqmToSqft(num);
  return value.toLocaleString("en", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
}
function formatPerSqmPerSqft(num: number, areaUnits: UnitType, fractionDigits: number) {
  const value = areaUnits === UnitType.Metric ? num : perSqmToPerSqft(num);
  return value.toLocaleString("en", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
}

function sort<T extends number | string>(getValue: (row: RowData) => T): SortBy {
  return (a, b, props) => {
    if (!props.sortedColumn) return 0;
    const first = props.sortedColumn === SortDirection.Asc ? a : b;
    const second = first === a ? b : a;
    return compareStrings(getValue(first), getValue(second));
  };
}

// Define a custom comparison function that respects embedded numbers.
function compareStrings(a: string | number, b: string | number) {
  if (typeof a === "number" && typeof b === "number") return a - b;
  if (typeof a === "number") a = a.toString();
  if (typeof b === "number") b = b.toString();
  const regex = /\d+/g;
  const aNumbers = a.match(regex)?.map(Number) || [];
  const bNumbers = b.match(regex)?.map(Number) || [];
  for (let i = 0; i < Math.max(aNumbers.length, bNumbers.length); i++) {
    const numA = aNumbers[i] || 0;
    const numB = bNumbers[i] || 0;
    if (numA !== numB) {
      return numA - numB;
    }
  }
  return a.localeCompare(b); // If numbers are equal, compare alphabetically.
}
