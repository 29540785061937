import { LockClosedIcon, LockOpenIcon } from "@heroicons/react/20/solid";
import { useId, useRef, useState } from "react";
import { clamp } from "./clamp";
import { useEvent } from "src/hooks/useEvent";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import type { Zone } from "src/types/Zone";
import type { UnitMixBrief } from "src/types/UnitMixBrief";

export const UnitMixSection = observer(function UnitMixSection(props: {
  zone: Zone;
  onUpdate: () => void;
}) {
  const [draft, setDraft] = useState<null | UnitMixBrief>(null);
  const unitMix = draft ?? props.zone.brief.unitMix;
  const onValueChange = useEvent((index: number, value: number, submit: boolean) => {
    const unitMix = draft ?? props.zone.brief.unitMix.clone();
    unitMix.updateUnit(index, value);
    if (!draft) setDraft(unitMix);
    if (!submit) return;
    props.zone.brief.setUnitMix(unitMix);
    setDraft(null);
    props.onUpdate();
  });
  const onLockedToggle = useEvent((index: number) => {
    unitMix.toggleUnitLock(index);
    props.onUpdate();
  });
  const onEnabledToggle = useEvent((index: number) => {
    unitMix.toggleUnitEnabled(index);
    props.onUpdate();
  });
  return (
    <div className="flex flex-col space-y-[2px]">
      {unitMix.categories.map((unit, i) => (
        <UnitInput
          index={i}
          key={unit.id}
          label={unit.label}
          unitColor={unit.unitColor}
          textColor={unit.textColor}
          value={unit.value}
          enabled={unit.enabled}
          onValueChange={onValueChange}
          onLockedToggle={onLockedToggle}
          locked={unit.locked}
          onEnabledToggle={onEnabledToggle}
        />
      ))}
    </div>
  );
});

const UnitInput = observer(function UnitInput(props: {
  label: string;
  index: number;
  value: number;
  unitColor: string;
  textColor: string;
  enabled: boolean;
  locked: boolean;
  onEnabledToggle: (index: number) => void;
  onValueChange: (index: number, value: number, submit: boolean) => void;
  onLockedToggle: (index: number) => void;
}) {
  const onSliderChange = useEvent((value: number) => {
    props.onValueChange(props.index, value, false);
  });
  const onNumberInputChange = useEvent((value: number) => {
    props.onValueChange(props.index, value, true);
  });
  const onLockedToggle = useEvent(() => {
    props.onLockedToggle(props.index);
  });
  const onEnabledToggle = useEvent(() => {
    props.onEnabledToggle(props.index);
  });
  const onSubmit = useEvent((value: number) => {
    props.onValueChange(props.index, value, true);
  });
  return (
    <div className="flex flex-row h-[24px] min-h-[24px] space-x-1 items-center">
      <div
        style={{ backgroundColor: props.enabled ? props.unitColor : "#DDD", width: 59, height: 20 }}
        className="pl-[2px] rounded-sm flex flex-row items-center cursor-pointer"
        onClick={onEnabledToggle}
      >
        <Checkbox checked={props.enabled} />
        <span
          className="px-1 text-body-1"
          style={{ color: props.enabled ? props.textColor : "#888" }}
        >
          {props.label}
        </span>
      </div>
      <Slider
        value={props.value}
        disabled={!props.enabled}
        locked={props.locked}
        onChange={onSliderChange}
        onSubmit={onSubmit}
      />
      <NumberInput
        disabled={!props.enabled}
        locked={props.locked}
        onLockedToggle={onLockedToggle}
        onValueChange={onNumberInputChange}
        value={props.value}
      />
    </div>
  );
});

const Checkbox = observer(function Checkbox(props: { checked: boolean }) {
  const id = useId();
  const id2 = useId();
  return props.checked ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath={`url(#${id2})`}>
        <rect width="16" height="16" rx="3" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.666656 3.33329C0.666656 1.86053 1.86056 0.666626 3.33332 0.666626H12.6667C14.1394 0.666626 15.3333 1.86053 15.3333 3.33329V12.6666C15.3333 14.1394 14.1394 15.3333 12.6667 15.3333H3.33332C1.86056 15.3333 0.666656 14.1394 0.666656 12.6666V3.33329ZM3.33332 1.99996H12.6667C13.403 1.99996 14 2.59691 14 3.33329V12.6666C14 13.403 13.403 14 12.6667 14H3.33332C2.59694 14 1.99999 13.403 1.99999 12.6666V3.33329C1.99999 2.59691 2.59694 1.99996 3.33332 1.99996Z"
          fill="#12736E"
        />
        <path
          d="M6.75421 11.468C6.62404 11.5981 6.41298 11.5981 6.28281 11.468L3.04968 8.23483C2.91983 8.10498 2.91945 7.89457 3.04884 7.76426L3.62355 7.18548C3.75359 7.05452 3.96529 7.05414 4.09579 7.18464L6.28281 9.37167C6.41298 9.50184 6.62404 9.50184 6.75421 9.37166L11.9042 4.22168C12.0347 4.09118 12.2464 4.09155 12.3764 4.22252L12.9511 4.8013C13.0805 4.9316 13.0802 5.14202 12.9503 5.27187L6.75421 11.468ZM13.1852 1.33329H2.8148C1.99258 1.33329 1.33332 1.99255 1.33332 2.81477V13.1851C1.33332 13.5781 1.48941 13.9549 1.76724 14.2327C2.04507 14.5105 2.42189 14.6666 2.8148 14.6666H13.1852C13.5781 14.6666 13.9549 14.5105 14.2327 14.2327C14.5106 13.9549 14.6667 13.5781 14.6667 13.1851V2.81477C14.6667 1.99255 14 1.33329 13.1852 1.33329Z"
          fill="#12736E"
        />
      </g>
      <defs>
        <clipPath id={`${id2}`}>
          <rect width="16" height="16" rx="3" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath={`url(#${id})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.666656 3.33329C0.666656 1.86053 1.86056 0.666626 3.33332 0.666626H12.6667C14.1394 0.666626 15.3333 1.86053 15.3333 3.33329V12.6666C15.3333 14.1394 14.1394 15.3333 12.6667 15.3333H3.33332C1.86056 15.3333 0.666656 14.1394 0.666656 12.6666V3.33329ZM3.33332 1.99996H12.6667C13.403 1.99996 14 2.59691 14 3.33329V12.6666C14 13.403 13.403 14 12.6667 14H3.33332C2.59694 14 1.99999 13.403 1.99999 12.6666V3.33329C1.99999 2.59691 2.59694 1.99996 3.33332 1.99996Z"
          fill="#33383C"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
});

const NumberInput = observer(function NumberInput(props: {
  disabled: boolean;
  value: number;
  locked: boolean;
  onLockedToggle: () => void;
  onValueChange: (value: number) => void;
}) {
  const [draft, setDraft] = useState<null | string>(null);
  const onSubmit = useEvent(() => {
    if (props.disabled) return;
    if (draft === null) return;
    const value = Number(draft);
    setDraft(null);
    if (isNaN(value)) return;
    props.onValueChange(clamp(value / 100, 0, 1));
  });
  const ref = useRef<HTMLInputElement>(null);
  const value = draft ?? Math.round(props.value * 100);

  return (
    <div
      style={{ width: 79 }}
      className={classNames(
        "flex flex-row border border-neutral-5 rounded-x rounded-sm items-center bg-white",
        props.disabled && "text-neutral-5"
      )}
    >
      <input
        ref={ref}
        className={classNames("flex flex-1 text-body-1 text-right")}
        style={{ width: 20 }}
        value={value}
        onBlur={onSubmit}
        disabled={props.disabled || props.locked}
        onKeyDown={(evt) => {
          if (evt.key === "Enter") ref.current?.blur();
        }}
        onInput={(evt) => !props.disabled && setDraft(evt.currentTarget.value)}
      />
      <span className="text-caption px-1">%</span>
      <LockIcon
        locked={props.locked}
        disabled={props.disabled}
        onLockedToggle={props.onLockedToggle}
      />
    </div>
  );
});

const LockIcon = observer(function LockIcon(props: {
  locked: boolean;
  disabled: boolean;
  onLockedToggle: () => void;
}) {
  const lockIcon = !props.locked ? (
    <LockOpenIcon className="w-[10px] text-white" />
  ) : (
    <LockClosedIcon className="w-[10px] text-white" />
  );
  return (
    <div
      onClick={props.onLockedToggle}
      className={classNames(
        "p-[6px] rounded-r-[1px] border-l border-neutral-5 h-full",
        (props.locked || props.disabled) && "bg-neutral-5",
        !(props.locked || props.disabled) && "bg-primary-6 hover:bg-primary-5 ",
        !props.disabled && props.locked && "hover:bg-neutral-4 cursor-default",
        !props.disabled && "cursor-pointer"
      )}
    >
      {lockIcon}
    </div>
  );
});

function roundToDecimal(number: number, decimalPlaces: number) {
  return parseFloat(number.toFixed(decimalPlaces));
}

const Slider = observer(function Slider(props: {
  value: number;
  disabled: boolean;
  locked: boolean;
  onChange?: (value: number) => void;
  onSubmit: (value: number) => void;
}) {
  const handleDrag = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    const getValue = (evt: MouseEvent) => {
      const trackRect = trackRef.current?.getBoundingClientRect();
      if (!trackRect) return 0;
      const x = evt.clientX - trackRect.left;
      const value = Math.max(0, Math.min(1, (x - thumbSize / 2) / trackRect.width));
      return roundToDecimal(value, 2);
    };
    props.onChange?.(getValue(e.nativeEvent));
    const onUp = (evt: MouseEvent) => {
      window.removeEventListener("mouseup", onUp);
      window.removeEventListener("mousemove", onMove);
      props.onSubmit(getValue(evt));
    };
    const onMove = (evt: MouseEvent) => {
      evt.stopPropagation();
      evt.preventDefault();
      props.onChange?.(getValue(evt));
    };
    window.addEventListener("mousemove", onMove);
    window.addEventListener("mouseup", onUp);
  };
  const thumbSize = 12;
  const trackRef = useRef<HTMLDivElement>(null);
  return (
    <div
      className={classNames(
        "w-full flex flex-1 h-full relative justify-start items-center",
        (props.disabled || props.locked) && "cursor-default",
        !(props.disabled || props.locked) && "cursor-col-resize hover:bg-primary-1"
      )}
      onMouseDown={props.disabled || props.locked ? undefined : handleDrag}
    >
      <div className="w-full h-1 bg-neutral-4 absolute rounded items-center flex" />
      <div
        className="w-full h-1 bg-primary-6 absolute rounded items-center flex"
        style={{ width: `${props.value * 100}%` }}
      />
      <div
        ref={trackRef}
        style={{
          width: `calc(100% - ${thumbSize}px)`,
        }}
        className="relative w-full aboslute items-center flex"
      >
        <div
          style={{ width: thumbSize, height: thumbSize, left: `${props.value * 100}%` }}
          className="rounded-full bg-white absolute border-primary-6 border"
        />
      </div>
    </div>
  );
});
