import type {
  ValueGroupItem,
  ValueGroup,
} from "@project-rouge/service-cost-client/data/value-breakdown";
import { COST_GROUP_ELEMENT_REFERENCE_GROUP_INDEX_LABELS } from "src/types/CostElementReference";
import { type CostElementReferenceGroupIndex } from "@project-rouge/service-cost-client/data/cost-element-reference";
import {
  createCostingViewForBuilding,
  createCostingViewForTotal,
} from "@project-rouge/service-cost-client/utility/custom-view";
import type { Building } from "src/types/Building";
import type { World } from "src/types/World";
import type { MetricsCalculations } from "src/hooks/useMetricsCalculationsQuery";

type Category = ValueGroup<number>;

interface ElementTableRow {
  label: string;
  totalCost: number;
  buildingCost: number;
  id: string;
}

export interface ElementTable {
  id: string;
  label: string;
  rows: ElementTableRow[];
  subTotal: ElementTableRow;
}

function GetSummaryGroupLabel(index: string) {
  return `${index} ${
    COST_GROUP_ELEMENT_REFERENCE_GROUP_INDEX_LABELS[index as CostElementReferenceGroupIndex] ??
    "Unknown"
  }`;
}

function GetRow(
  item: ValueGroupItem<number>,
  totalCostMap: Map<string, number>,
  buildingCostMap: Map<string, number>
): ElementTableRow {
  return {
    id: item.index,
    label: GetSummaryGroupLabel(item.index),
    totalCost: totalCostMap.get(item.index) ?? 0,
    buildingCost: buildingCostMap.get(item.index) ?? 0,
  };
}

function GetTable(totalCategory: Category, buildingCategory?: Category): ElementTable {
  const buildingCostMap = new Map(buildingCategory?.items.map((v) => [v.index, v.value]));
  const totalCostMap = new Map(totalCategory.items.map((v) => [v.index, v.value]));
  const rows = totalCategory.items.map((item) => GetRow(item, totalCostMap, buildingCostMap));

  const subTotal: ElementTableRow = {
    id: totalCategory.index,
    label: `Sub Total ${totalCategory.index}`,
    totalCost: totalCategory.total,
    buildingCost: buildingCategory?.total ?? 0,
  };

  const table: ElementTable = {
    id: totalCategory.index,
    label: GetSummaryGroupLabel(totalCategory.index),
    rows,
    subTotal,
  };

  return table;
}

export function GetTables(
  world: World,
  selectedBuilding: Building | null,
  metricsCalculations: MetricsCalculations,
  isBaseBuildElements: boolean
): ElementTable[] {
  type CategoryMap = Map<string, Category>;

  const building = selectedBuilding
    ? createCostingViewForBuilding(metricsCalculations, world.buildings.indexOf(selectedBuilding))
    : null;

  const costingView = createCostingViewForTotal(metricsCalculations);

  const totalBasebuild = costingView.element.basebuild;
  const totalModular = costingView.element.modular;
  const totalSelected = isBaseBuildElements ? totalBasebuild : totalModular;
  const totalCategories: CategoryMap = new Map(totalSelected.map((v) => [v.index, v]));

  const buildingBasebuild = building?.element.basebuild;
  const buildingModular = building?.element.modular;
  const buildingSelected = isBaseBuildElements ? buildingBasebuild : buildingModular;
  const buildingCategories: CategoryMap = new Map(buildingSelected?.map((v) => [v.index, v]));

  const selectedCategory = buildingSelected ?? totalSelected;

  const tabels = selectedCategory.map((category) => {
    const totalCategory = totalCategories.get(category.index);
    if (!totalCategory) throw new Error(`Category ${category.index} not found`);
    const buildingCategory = buildingCategories.get(category.index);
    return GetTable(totalCategory, buildingCategory);
  });

  return tabels;
}

// export function useElementsTables(
//   selectedBuilding: Building | null,
//   costing: CostMetrics,
//   isBaseBuildElements: boolean
// ) {
//   return useMemo(
//     () => GetTables(selectedBuilding, costing, isBaseBuildElements),
//     [costing, isBaseBuildElements, selectedBuilding]
//   );
// }
