import { type V3 } from "@project-rouge/rg-core";
import { Vector3 } from "three";

export function ProjectToCanvas(position: V3, camera: THREE.Camera, canvas: HTMLCanvasElement) {
  const canvasWidth = canvas.width;
  const canvasHeight = canvas.height;
  const projected = new Vector3(...position).project(camera);
  const canvasX = ((projected.x + 1) * canvasWidth) / 2 / window.devicePixelRatio;
  const canvasY = ((-projected.y + 1) * canvasHeight) / 2 / window.devicePixelRatio;
  return [canvasX, canvasY] as [number, number];
}
