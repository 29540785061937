import type { RPoly } from "@project-rouge/rg-core";
import { type V2, type V3 } from "@project-rouge/rg-core";

export function V3ToV2(items: V3 | Readonly<V3>): V2;
export function V3ToV2(items: V3[] | Readonly<V3[]>): V2[];
export function V3ToV2(items: RPoly): V2[];
export function V3ToV2(items: V3 | V3[] | RPoly | Readonly<V3> | Readonly<V3[]>) {
  return Array.isArray(items[0])
    ? (items as V3[]).map(([x, , y]) => [x, y] as V2)
    : ([items[0], items[2]] as V2);
}
