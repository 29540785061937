import classNames from "classnames";
import { type PropsWithChildren } from "react";
import { observer } from "mobx-react-lite";
import { TableContentType } from "./TableContentType";

export const TableRow = observer(function TableRow(
  props: PropsWithChildren<{
    type: TableContentType;
    selected?: boolean;
    onRowSelect?: (id: string) => void;
    onHover?: (id: string, hovered: boolean) => void;
    hover?: boolean;
    rowId: string;
  }>
) {
  return (
    <div
      className={classNames(
        "flex overflow-hidden border-b border-neutral-4",
        props.type === TableContentType.Header &&
          "sticky top-0 left-0 cursor-move bg-neutral-1 text-neutral-6 text-subtitle-2 h-[32px] z-10 ",
        props.type === TableContentType.Canvas &&
          "sticky top-8 left-0 bg-neutral-2 text-neutral-8 text-body-1 h-[36px] z-10 ",
        props.type === TableContentType.Body &&
          "cursor-pointer text-neutral-8 text-body-1 h-[36px]",
        props.selected && "bg-primary-3 text-primary-7",
        props.hover && "bg-primary-1"
      )}
      onClick={() => props.onRowSelect?.(props.rowId)}
      onMouseOver={() => props.onHover?.(props.rowId, true)}
      onMouseOut={() => props.onHover?.(props.rowId, false)}
    >
      {props.children}
    </div>
  );
});
