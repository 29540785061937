import { type PropsWithChildren } from "react";

export function Wrapper(props: PropsWithChildren) {
  return (
    <div
      className="absolute left-2 top-24"
      onMouseDown={(evt) => {
        evt.stopPropagation();
      }}
    >
      <div className="flex flex-col space-y-2">{props.children}</div>
    </div>
  );
}
