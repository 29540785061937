import { useState } from "react";
import { BlockTags } from "../BlockTags";
import { EfficiencyTable } from "./EfficiencyTable";
import { UnitMixTable } from "./UnitMixTable";
import { NonResidentialAreasTable } from "./NonResidentialAreasTable";
import { ALL_BLOCKS, type BlockId } from "../BlockId";
import { Unit, UnitSelector } from "../UnitSelect";
import { AreaMeasurementsTable } from "../AreaMeasurementsTable";
import type { World } from "src/types/World";

export function DesignMetricsSection(props: { design: World }) {
  const [blockId, setBlockId] = useState<BlockId>(ALL_BLOCKS);
  const [unit, setUnit] = useState(Unit.sqm);
  const buildings = props.design.buildings;
  const selectedBuilding = (blockId === ALL_BLOCKS ? null : buildings[blockId]) ?? null;

  const metrics = !selectedBuilding ? props.design.architecturalMetrics : selectedBuilding.metrics;

  if (!props.design) return null;

  return (
    <div className="w-full h-full overflow-hidden flex flex-col py-3">
      <BlockTags blockId={blockId} design={props.design} onChange={setBlockId} />
      <UnitSelector onUnitChange={setUnit} unit={unit} />
      <div className="flex flex-1 flex-col scrollbar-none overflow-scroll">
        <AreaMeasurementsTable metrics={metrics} unit={unit} />
        <EfficiencyTable metrics={metrics} />
        <UnitMixTable metrics={metrics} unit={unit} />
        <NonResidentialAreasTable metrics={metrics} unit={unit} />
      </div>
    </div>
  );
}
