import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import type { PropsWithChildren } from "react";
import { useBoolean } from "src/hooks/useBoolean";

export const InspectorGroup = observer(
  ({ children, label }: PropsWithChildren<{ label: string }>) => {
    const collapsed = useBoolean();
    return (
      <div className="flex flex-col w-[272px] bg-white border-t-[1px] border-b-[1px] border-neutral-4 my-1">
        <div
          className={classNames(
            "flex flex-row items-center border-neutral-4 border-b-[1px]",
            collapsed.value && "border-none"
          )}
        >
          <div
            className="flex justify-between w-full overflow-hidden items-center cursor-pointer h-10 px-3"
            onClick={collapsed.toggle}
          >
            <span className="text-subtitle-2 truncate text-neutral-8">{label}</span>
            <button className="text-primary-6">
              {collapsed.value ? (
                <IconChevronUp className="stroke-[1.5px]" />
              ) : (
                <IconChevronDown className="stroke-[1.5px]" />
              )}
            </button>
          </div>
        </div>
        {children && !collapsed.value && (
          <div className="mx-3 pb-2">{!collapsed.value && children}</div>
        )}
      </div>
    );
  }
);
