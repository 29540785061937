import { observer } from "mobx-react-lite";
import type { UnitType } from "../../types/UnitType";
import { InspectorGroup } from "./InspectorGroup";
import { RoomUnitGroup } from "./RoomUnitGroup";
import { InspectorRow, State } from "./InspectorRow";
import { SelectionType, type Selection } from "src/types/Selection";
import { meterToFeet, perSqmToPerHectare, sqmToSqft } from "src/hooks/meterToFeet";
import type { World } from "src/types/World";

export const ArchitecturalMetricsInspector = observer(
  (props: { world: World; unit: UnitType; selection: Selection }) => {
    const buildingMetric = props.selection.buildings.at(0)?.metrics;
    const zoneMetrics = props.selection.zones.at(0)?.metrics;
    const siteMetrics = props.world.architecturalMetrics;

    const metrics = buildingMetric ?? zoneMetrics ?? siteMetrics;

    return (
      <InspectorGroup label="Design">
        <RoomUnitGroup world={props.world} selection={props.selection} />
        <InspectorRow
          state={State.FILLED}
          label={"Residential efficiency"}
          decimalPoint={1}
          value={metrics.ResidentialEfficiencyTotal * 100}
          unit={"%"}
        />
        <InspectorRow
          state={State.FILLED}
          label={"Building efficiency"}
          decimalPoint={1}
          value={metrics.BuildingEfficiencyTotal * 100}
          unit={"%"}
        />
        <InspectorRow state={State.FILLED} label={"Floors"} value={metrics.FloorMax} />
        <InspectorRow
          state={State.FILLED}
          label={"Building height"}
          value={
            props.unit === "Metric"
              ? metrics.BuildingHeightMax
              : meterToFeet(metrics.BuildingHeightMax)
          }
          unit={props.unit === "Metric" ? "m" : "ft"}
          decimalPoint={1}
        />
        <InspectorRow
          state={State.FILLED}
          label={"FFL"}
          value={props.unit === "Metric" ? metrics.FFLMax : meterToFeet(metrics.FFLMax)}
          unit={props.unit === "Metric" ? "m" : "ft"}
          decimalPoint={1}
        />
        <InspectorRow
          state={State.FILLED}
          label={"Podium height"}
          value={
            metrics.PodiumHeightSumTotal === 0
              ? undefined
              : props.unit === "Metric"
              ? metrics.PodiumHeightSumTotal
              : meterToFeet(metrics.PodiumHeightSumTotal)
          }
          unit={props.unit === "Metric" ? "m" : "ft"}
          decimalPoint={1}
        />
        <InspectorRow
          state={State.FILLED}
          label={"GIA"}
          value={props.unit === "Metric" ? metrics.GIATotal : sqmToSqft(metrics.GIATotal)}
          unit={props.unit === "Metric" ? "sqm" : "sqft"}
          decimalPoint={0}
        />
        <InspectorRow
          state={State.FILLED}
          label={"NIA"}
          value={props.unit === "Metric" ? metrics.NIATotal : sqmToSqft(metrics.NIATotal)}
          unit={props.unit === "Metric" ? "sqm" : "sqft"}
          decimalPoint={0}
        />
        <InspectorRow
          state={State.FILLED}
          label={"Building footprint"}
          value={
            props.unit === "Metric" ? metrics.FootPrintTotal : sqmToSqft(metrics.FootPrintTotal)
          }
          unit={props.unit === "Metric" ? "sqm" : "sqft"}
          decimalPoint={0}
        />
        <InspectorRow
          state={State.FILLED}
          label={"Density (units)"}
          value={
            props.selection.type === SelectionType.BUIDING
              ? undefined
              : perSqmToPerHectare(metrics.DensityUnitsTotal)
          }
          decimalPoint={1}
        />
        <InspectorRow
          state={State.FILLED}
          label={"Land coverage"}
          value={
            props.selection.type === SelectionType.BUIDING
              ? undefined
              : metrics.LandCoverageTotal * 100
          }
          unit={"%"}
          decimalPoint={1}
        />
        <InspectorRow
          state={State.FILLED}
          label={"North facing units"}
          value={metrics.NorthFacingUnitsTotal}
          decimalPoint={0}
        />
        <InspectorRow
          state={State.FILLED}
          label={"Gross façade area"}
          value={props.unit === "Metric" ? metrics.FacadeTotal : sqmToSqft(metrics.FacadeTotal)}
          decimalPoint={0}
          unit={props.unit === "Metric" ? "sqm" : "sqft"}
        />
        <InspectorRow
          state={State.FILLED}
          label={"Commercial area"}
          value={
            metrics.CommercialAreaPerLevel[0] === 0
              ? undefined
              : props.unit === "Metric"
              ? metrics.CommercialAreaPerLevel[0]
              : sqmToSqft(metrics.CommercialAreaPerLevel[0])
          }
          decimalPoint={0}
          unit={props.unit === "Metric" ? "sqm" : "sqft"}
        />
        <InspectorRow
          state={State.FILLED}
          label={"Amenities area"}
          value={
            props.unit === "Metric"
              ? metrics.AmenitiesAreaTotal
              : sqmToSqft(metrics.AmenitiesAreaTotal)
          }
          decimalPoint={0}
          unit={props.unit === "Metric" ? "sqm" : "sqft"}
        />
        <InspectorRow
          state={State.FILLED}
          label={"Services area"}
          value={
            props.unit === "Metric"
              ? metrics.ServiceAreaPerLevel[0]
              : sqmToSqft(metrics.ServiceAreaPerLevel[0])
          }
          decimalPoint={0}
          unit={props.unit === "Metric" ? "sqm" : "sqft"}
        />
      </InspectorGroup>
    );
  }
);
