import { ZoneInspector } from "./Inspector/ZoneInspector";
import { ArchitecturalMetricsInspector } from "./Inspector/ArchitecturalMetricsInspector";
import { CostMetricsInspector } from "./Inspector/CostMetricsInspector";
import { SustainabilityMetricsInspector } from "./Inspector/SustainabilityMetricsInspector";
import { observer } from "mobx-react-lite";
import type { Editor } from "src/types/Editor";
import { useState } from "react";
import { MetricsButton } from "./Inspector/MetricsButton";
import { UnitType } from "../types/UnitType";
import { InspectorsGroup } from "./Inspector/InspectorsGroup";
import { AreaView } from "./Inspector/AreaView";
import { InspectorGroup } from "./Inspector/InspectorGroup";
import { useEvent } from "src/hooks/useEvent";

interface Props {
  editor: Editor;
}

export const EditorInspector = observer(function EditorInspector(props: Props) {
  const world = props.editor.world;
  const [unit, setUnit] = useState(UnitType.Metric);
  const selection = props.editor.view.selection;
  const onZoneUpdate = useEvent(async () => props.editor.regenerate(false));
  const zoneInspector = !selection.buildings.length ? (
    <ZoneInspector
      world={props.editor.world}
      readonly={false}
      selection={props.editor.view.selection}
      onUpdate={onZoneUpdate}
    />
  ) : null;

  const buildingInspector = !!selection.buildings.length ? (
    <InspectorGroup label="Block">
      {selection.buildings.map((building) => (
        <div className="pt-2" key={building.id}>
          {building.label}
        </div>
      ))}
    </InspectorGroup>
  ) : !!selection.zones.length ? (
    <InspectorGroup label="Zone">
      <div className="flex">
        {selection.zones.at(0)?.buildings.map((building) => (
          <div className="pt-2 pr-2" key={building.id}>
            {building.label}
          </div>
        ))}
      </div>
    </InspectorGroup>
  ) : null;

  return (
    <InspectorsGroup>
      <AreaView onUnitChange={setUnit} world={world} unit={unit} />

      {zoneInspector}
      {buildingInspector}
      {world && (
        <ArchitecturalMetricsInspector
          world={world}
          unit={unit}
          selection={props.editor.view.selection}
        />
      )}
      {world && (
        <CostMetricsInspector world={world} unit={unit} selection={props.editor.view.selection} />
      )}
      {world && (
        <SustainabilityMetricsInspector
          world={world}
          unit={unit}
          selection={props.editor.view.selection}
        />
      )}
      {world && <MetricsButton world={world} />}
    </InspectorsGroup>
  );
});
