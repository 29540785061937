import { IconAdjustmentsHorizontal, IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useBoolean } from "src/hooks/useBoolean";
import { BriefPanel } from "../BriefPanel/BriefPanel";
import { FloatingPortal } from "@floating-ui/react";
import { useMap } from "react-map-gl";
import { useLayoutEffect, useState } from "react";
import { MAP_ID } from "../Map/Map";
import type { Editor } from "src/types/Editor";

export const BriefButton = observer(function BriefButton(props: { editor: Editor }) {
  const checked = useBoolean();
  const caret = checked.isOn ? (
    <IconChevronUp className="w-4" />
  ) : (
    <IconChevronDown className="w-4" />
  );
  return (
    <>
      <button
        onClick={checked.toggle}
        className={classNames(
          "relative text-button px-[6px] h-7 mx-2 py-1 space-x-1 border flex flex-row justify-center items-center rounded",
          checked.isOn && "bg-primary-6 text-white border-primary-6",
          checked.isOff && "bg-white text-neutral-7 border-neutral-5 hover:bg-primary-1"
        )}
      >
        <IconAdjustmentsHorizontal className="w-4" />
        <span>Brief</span>
        {caret}
      </button>
      {checked.isOn && props.editor.world && <BriefPopup editor={props.editor} />}
    </>
  );
});

interface Rect {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
  x: number;
  y: number;
}

const defaultCanvasRect: Rect = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0,
};

const BriefPopup = observer(function BriefPopup(props: { editor: Editor }) {
  const mapRef = useMap();
  const [canvasRect, setCanvasRect] = useState<Rect>(defaultCanvasRect);
  useLayoutEffect(() => {
    const canvas = document.getElementById(MAP_ID);
    if (!canvas) return;
    const observer = new ResizeObserver(() => setCanvasRect(canvas.getBoundingClientRect()));
    observer.observe(canvas);
    return () => {
      observer.disconnect();
    };
  }, [mapRef]);
  const marginTop = 52;
  const marginBottom = 2;
  const marginRight = 12;
  const width = 250;
  return (
    <FloatingPortal>
      <div
        style={{
          width,
          height: canvasRect.height - marginTop - marginBottom,
          top: canvasRect.top + marginTop,
          left: canvasRect.right - width - marginRight,
        }}
        className="fixed flex justify-end items-end z-20 pointer-events-none overflow-hidden"
      >
        <div className="flex mt-[52px] right-0 overflow-hidden h-full pointer-events-none">
          <div className="flex flex-col flex-1 overflow-hidden justify-end pointer-events-none">
            <BriefPanel editor={props.editor} />
          </div>
        </div>
      </div>
    </FloatingPortal>
  );
});
