import { type FunctionComponent, useMemo, useState } from "react";
import { useImmer } from "use-immer";
import { Form, FormIntent, FormState } from "src/components/Form/Form";
import modulousLogo from "src/assets/tessa-logo.svg";
import { XMarkIcon, ArrowLeftIcon } from "@heroicons/react/20/solid";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { useApi } from "src/contexts/api.context";
import { useEvent } from "src/hooks/useEvent";
import { emailRegExp } from "src/screens/[login].screen";
import { useRedirect } from "src/hooks/useRedirect";

interface ResetFormData {
  email: string;
}

type PasswordResetModalProps = {
  isActive: (state: boolean) => void;
};

export const PasswordResetModal: FunctionComponent<PasswordResetModalProps> = ({ isActive }) => {
  const [loading, setLoading] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [showResetSuccess, setShowResetSuccess] = useState<boolean>(false);
  const redirect = useRedirect();

  const api = useApi();

  const [form, updateForm] = useImmer<ResetFormData>({
    email: "",
  });

  const errors = useMemo(
    () => ({
      email: emailRegExp.test(form.email) || form.email === "" ? null : "invalid email address",
    }),
    [form.email]
  );
  const hasErrors = useMemo(() => Object.values(errors).some((v) => v !== null), [errors]);

  const passwordReset = useEvent(async (payload: { email: string }) => {
    const result = await api.postUserPasswordReset(payload);

    return result;
  });

  return (
    <div className="w-screen h-screen flex z-[9999] bg-neutral-8/30 absolute top-0 left-0 items-center justify-center">
      {!showResetSuccess ? (
        <div className="w-[364px] bg-white rounded-[4px] p-4 space-y-4">
          <div className="w-full flex items-center justify-between">
            <div className="text-neutral-8 font-semibold">Reset password</div>
            <XMarkIcon
              className="w-6 text-neutral-8 cursor-pointer"
              onClick={() => isActive(false)}
            />
          </div>
          <div className="text-neutral-7 text-sm">
            Enter your email address and we will send you instructions to reset your password.
          </div>
          <Form.Text
            label="Email address"
            type="email"
            value={form.email}
            helperText={errors.email}
            placeholder="Enter email"
            intent={errors.email ? FormIntent.ERROR : FormIntent.NONE}
            onChange={(v) =>
              updateForm((draft) => {
                draft.email = v;
              })
            }
          />
          <div className="flex justify-end">
            <Form.SubmitButton
              state={hasErrors || loading || invalidEmail ? FormState.DISABLED : FormState.NONE}
              label="Send Reset Link"
              onClick={async () => {
                try {
                  setLoading(true);
                  await passwordReset({ email: form.email });
                  isActive(false);
                  setShowResetSuccess(true);
                } catch (e) {
                  setInvalidEmail(true);
                }
                setLoading(false);
              }}
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-col w-[352px] h-[418px] bg-white rounded-lg shadow space-y-4 items-center">
          <div className="border-b w-full flex items-center justify-center py-6 border-neutral-5">
            <img src={modulousLogo} alt="Tessa Logo" className="w-[121px] h-[32px]" />
          </div>
          <div className="w-full flex justify-center flex-col items-center pt-10 space-y-4 px-4">
            <div className="bg-secondary-1 w-16 h-16 rounded-full flex items-center justify-center">
              <EnvelopeIcon className="text-secondary-5 fill-white w-[22.75px]" />
            </div>
            <div className="text-neutral-8 font-semibold text-xl">Check your email</div>
            <div className="text-neutral-7 text-center">
              Weve sent instructions to reset your password.
            </div>
            <button
              className="w-[150px] h-8 bg-primary-6 text-white flex items-center justify-center rounded"
              onClick={async () => await redirect.home()}
            >
              <ArrowLeftIcon className="w-5" />
              Back to login
            </button>

            <Form.CancelButton
              state={hasErrors || loading || invalidEmail ? FormState.DISABLED : FormState.NONE}
              label="Resend the email"
              onClick={async () => {
                try {
                  setLoading(true);
                  await passwordReset({ email: form.email });
                  isActive(false);
                } catch (e) {
                  setInvalidEmail(true);
                }
                setLoading(false);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
