import { type V3 } from "@project-rouge/rg-core";

export function IsVectorOnEdge(
  vector: V3,
  [lineStart, lineEnd]: [V3, V3],
  tolerance: number
): boolean {
  const [x1, , y1] = lineStart;
  const [x2, , y2] = lineEnd;
  const [x, , y] = vector;
  const dotProduct = (x - x1) * (x2 - x1) + (y - y1) * (y2 - y1);
  const segmentLengthSquared = Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2);

  if (dotProduct < 0 || dotProduct > segmentLengthSquared) {
    return false;
  }

  const distanceToLine =
    Math.abs((y2 - y1) * x - (x2 - x1) * y + x2 * y1 - y2 * x1) / Math.sqrt(segmentLengthSquared);

  return distanceToLine <= tolerance;
}
