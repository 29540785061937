import { InspectorRow, State } from "./InspectorRow";
import { InspectorGroup } from "./InspectorGroup";

export const CostGroupLoading = () => {
  return (
    <InspectorGroup label="Cost">
      <InspectorRow
        currency="£"
        state={State.LOADING}
        label={"Modules"}
        value={0}
        decimalPoint={0}
      />
      <InspectorRow
        currency="£"
        state={State.LOADING}
        label={"Fit-out"}
        value={0}
        decimalPoint={0}
      />
      <InspectorRow
        currency="£"
        state={State.LOADING}
        label={"Base build"}
        value={0}
        decimalPoint={0}
      />
      <InspectorRow
        currency="£"
        state={State.LOADING}
        label={"Construction"}
        value={0}
        decimalPoint={0}
      />
      <InspectorRow
        currency="£"
        state={State.LOADING}
        label={"Construction /unit"}
        value={0}
        decimalPoint={0}
        unit={"/unit"}
      />
      <InspectorRow
        currency="£"
        state={State.LOADING}
        label={"Construction /area"}
        value={0}
        decimalPoint={0}
        unit={"/sqm"}
      />
    </InspectorGroup>
  );
};
