import {
  type QueueItem,
  type SiteImageManagerWorkerInitRequest,
  type SiteImageManagerWorkerRequest,
  type SiteImageManagerWorkerResponse,
} from "./types";
import Worker from "src/workers/Renderer.worker?worker";

export class SiteImageManager {
  private activeQueueItem: QueueItem | null = null;

  private readonly canvas: HTMLCanvasElement;
  private readonly worker: Worker;
  private readonly queue = new Map<Uint8Array, QueueItem>();

  constructor() {
    this.worker = new Worker();
    this.canvas = document.createElement("canvas");
    const offscreenCanvas = this.canvas.transferControlToOffscreen();
    const listener = async ({ data }: MessageEvent<SiteImageManagerWorkerResponse>) => {
      if (data.type !== "SITE_IMAGE_RESPONSE") return;
      if (data.messageId !== this.activeQueueItem?.props.messageId) return;
      await new Promise((r) => setTimeout(r, 5));
      this.activeQueueItem.resolve(this.canvas.toDataURL());
      this.queue.delete(this.activeQueueItem.props.rgDesignZip);
      this.activeQueueItem = null;
      this.run();
    };
    this.worker.addEventListener("message", listener);
    const initRequest: SiteImageManagerWorkerInitRequest = {
      offscreenCanvas,
      type: "SITE_IMAGE_REQUEST_INIT",
    };
    this.worker.postMessage(initRequest, [offscreenCanvas]);
  }

  private run() {
    if (this.activeQueueItem || !this.queue.size) return;
    const item: QueueItem = this.queue.values().next().value;
    this.activeQueueItem = item;
    this.worker.postMessage(item.props);
  }

  async render(
    rgDesignZip: Uint8Array,
    params: SiteImageManagerWorkerRequest["params"]
  ): Promise<string> {
    const zip = this.queue.get(rgDesignZip);
    if (zip) return await zip.promise;
    const messageId = crypto.randomUUID();
    let resolve: (imageDataURL: string) => void = () => {};
    const props: SiteImageManagerWorkerRequest = {
      type: "SITE_IMAGE_REQUEST",
      messageId,
      rgDesignZip,
      params,
    };
    const promise = new Promise<string>((r) => (resolve = r));
    const queueItem: QueueItem = {
      promise,
      resolve,
      props,
    };
    this.queue.set(rgDesignZip, queueItem);
    this.run();
    return await promise;
  }

  destroy() {
    this.worker.terminate();
  }
}
