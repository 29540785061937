import { makeAutoObservable, toJS } from "mobx";
import type { ApiConfig } from "./Api";
import { Api } from "./Api";
import deepEqual from "deep-equal";

interface JwtToken {
  uid: string;
  sid: string;
  v: number;
  iat: number;
  exp: number;
  iss: string;
  sub: string;
}

export type AuthState = {
  id: string;
  tokenActor: string;
  tokenAdmin: string | null;
  expiredAt: string;
  createdAt: string;
};

export class Auth {
  private static localStorageKey = "auth";

  private static GetCachedAuth(): AuthState | null {
    const value = window.localStorage.getItem(this.localStorageKey);
    if (!value) return null;
    if (value === "undefined") return null;
    if (!/^{.*}$/.test(value)) return null;
    return JSON.parse(value);
  }

  private static SetCachedAuth(auth: AuthState | null) {
    window.localStorage.setItem(this.localStorageKey, JSON.stringify(auth));
  }

  private static DecodeJwt(token: string): JwtToken {
    return JSON.parse(window.atob(token.split(".")[1]));
  }

  private static DecodeTokens(actorToken: string | null, adminToken: string | null) {
    if (!actorToken) return null;
    const decodedAdminToken = adminToken ? this.DecodeJwt(adminToken) : null;
    const decodedActorToken = this.DecodeJwt(actorToken);
    const createAtTime = (decodedAdminToken?.iat ?? decodedActorToken.iat) * 1000;
    const expiredAtTime = (decodedAdminToken?.exp ?? decodedActorToken.exp) * 1000;
    const createdAt = new Date(createAtTime).toISOString();
    const expiredAt = new Date(expiredAtTime).toISOString();
    const id = decodedAdminToken?.sid ?? decodedActorToken.sid;
    const auth: AuthState = {
      createdAt,
      expiredAt,
      id,
      tokenActor: actorToken,
      tokenAdmin: adminToken,
    };
    return auth;
  }

  readonly api: Api;
  private state: AuthState | null = null;
  private removeLoginTimeout = () => {};
  constructor(apiConfig: Omit<ApiConfig, "onSessionExpired">) {
    this.api = new Api({
      ...apiConfig,
      onSessionExpired: async () => this.logout(),
    });
    const state = Auth.GetCachedAuth();
    this.updateToken(state?.tokenActor ?? null, state?.tokenAdmin ?? null);
    makeAutoObservable(this, {}, { autoBind: true });
    window.addEventListener("storage", this.onStorageListener);
  }
  get isAuthorized() {
    return this.state !== null;
  }
  get sessionId() {
    return this.state?.id || null;
  }
  get actor() {
    return this.state?.tokenActor || null;
  }
  get admin() {
    return this.state?.tokenAdmin || null;
  }
  private onStorageListener(e: StorageEvent) {
    if (e.key !== Auth.localStorageKey) return;
    const newState = Auth.GetCachedAuth();
    if (deepEqual(newState, toJS(this.state))) return;
    this.updateState(newState);
  }
  private updateState(auth: AuthState | null) {
    if (!auth) return this.clear();
    const timeout = new Date(auth.expiredAt).valueOf() - new Date().valueOf();
    if (timeout < 0) {
      this.clear();
      return;
    }
    Auth.SetCachedAuth(auth);
    const timeoutInt = window.setTimeout(this.clear, timeout);
    this.removeLoginTimeout = () => window.clearTimeout(timeoutInt);
    this.api.setTokens(auth.tokenActor, auth.tokenAdmin);
    this.state = auth;
  }
  private updateToken(actorToken: string | null, adminToken: string | null) {
    const auth = Auth.DecodeTokens(actorToken, adminToken);
    this.removeLoginTimeout();
    this.updateState(auth);
  }
  /**
   * this method is used to clear the auth state
   */
  private clear() {
    this.removeLoginTimeout();
    Auth.SetCachedAuth(null);
    this.api.setTokens("", null);
    this.state = null;
  }

  async login(creds: { email: string; password: string }) {
    const session = await Api.PostSession(this.api, creds);
    if (!session) return this.clear();
    this.updateToken(session.tokens.actor ?? null, session.tokens.admin ?? null);
  }

  async logout() {
    this.clear();
  }

  async loginSso(data: { adminToken: string; actorToken: string }) {
    if (this.state?.tokenActor === data.actorToken && this.state?.tokenAdmin === data.adminToken)
      return;
    this.updateToken(window.atob(data.actorToken), window.atob(data.adminToken));
  }
}
