import { type V3 } from "@project-rouge/rg-core";

export function IsVectorOnFace(point: V3, polygon: V3[]): boolean {
  let counter = 0;
  const [x, , y] = point;

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const [xi, , yi] = polygon[i];
    const [xj, , yj] = polygon[j];

    // Ignore following as preittier doesn't respect eslintno-mixed-operators rule
    // prettier-ignore
    const intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);

    if (intersect) {
      counter++;
    }
  }

  return counter % 2 === 1;
}
