import { type V3 } from "@project-rouge/rg-core";
import { destination } from "@turf/turf";
import type { GeoPoint } from "src/types/GeoPoint";
import { MathUtils, Vector3 } from "three";

type LngLat = [number, number];

export function V3ToLngLat(v3Position: V3, origin: LngLat): LngLat {
  const v3 = new Vector3(...v3Position);
  const dist = v3.length();
  const angle = (90 - Math.atan2(-v3.z, v3.x) * MathUtils.RAD2DEG + 360) % 360;

  const originCoords: LngLat = [origin[0], origin[1]];
  const targetCoords: LngLat = destination(originCoords, dist, angle, { units: "meters" }).geometry
    .coordinates as LngLat;

  return [targetCoords[0], targetCoords[1]];
}

export function V3ToGeoPoints(positions: V3[], origin: GeoPoint): GeoPoint[] {
  const points: GeoPoint[] = [];
  const latLngOrigin: LngLat = [origin.longitude, origin.latitude];
  for (const pos of positions) {
    const [longitude, latitude] = V3ToLngLat(pos, latLngOrigin);
    points.push({ longitude, latitude });
  }
  return points;
}
