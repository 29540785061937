import { type PropsWithChildren, useEffect, useState } from "react";
import { type Root, createRoot } from "react-dom/client";

export function MapPortal(props: PropsWithChildren<{ position: [number, number] }>) {
  const [rootData, setRoot] = useState<{
    root: Root;
    rootElement: HTMLDivElement;
    containerElement: HTMLElement;
  } | null>(null);

  // create the root element on mount
  useEffect(() => {
    const containerElement = document.getElementById("tessa-map");
    if (!containerElement) return;
    const rootElement = document.createElement("div");
    rootElement.style.position = "absolute";
    rootElement.style.top = "0";
    rootElement.style.left = "0";
    containerElement.appendChild(rootElement);
    setRoot({ root: createRoot(rootElement), rootElement, containerElement });
    return () => {
      containerElement.removeChild(rootElement);
    };
  }, []);

  const [left, top] = props.position;
  useEffect(() => {
    rootData?.root.render(<div style={{ position: "absolute", left, top }}>{props.children}</div>);
  }, [left, props.children, rootData, top]);
  return null;
}
