import { useQuery } from "@tanstack/react-query";
import { useApi } from "src/contexts/api.context";
import { type CostSettings } from "src/types/CostSettings";

export function useCostSettings(projectId?: string) {
  const api = useApi();
  return useQuery({
    // we need "cost-metrics" here to be able to invalidate the query when cost settings change
    queryKey: [projectId, "cost-metrics", "cost-settings"],
    staleTime: Infinity,
    queryFn: async () => {
      if (!projectId) return null;

      const settings: CostSettings = await api.getCostingSettings(projectId);
      return settings;
    },
    enabled: !!projectId,
  });
}
