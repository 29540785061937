import { observer } from "mobx-react-lite";
import { useEvent } from "src/hooks/useEvent";
import { TableContentType } from "src/components/ExplorePanel/TableContentType";
import { TableBody } from "./TableBody";
import { TableRow } from "./TableRow";
import type { RowData } from "./RowData";
import { type RegisteredColumnId } from "./RegisteredColumnId";
import type { Column, TableStore } from "./TableStore";

export const ColumnsGroup = observer(function ColumnsGroup(props: {
  columns: Column[];
  table: TableStore;
  canvasRowData: RowData;
  onConfigSubmit: () => void;
}) {
  const onColumnDrop = useEvent(
    (
      e: React.DragEvent<HTMLDivElement>,
      destinationIndex: number,
      destinationID: RegisteredColumnId
    ) => {
      props.table.onColumnDrop(e, destinationIndex, destinationID);
      props.onConfigSubmit();
    }
  );
  const onColumnPin = useEvent((columnId: string) => {
    props.table.onColumnPin(columnId);
    props.onConfigSubmit();
  });
  const onColumnSort = useEvent((columnId: string) => {
    props.table.onColumnSort(columnId);
    props.onConfigSubmit();
  });
  const onColumnWidthChange = useEvent((columnId: string, width: number, submit: boolean) => {
    props.table.setColumnWidth(columnId, width);
    if (submit) {
      props.onConfigSubmit();
    }
  });
  return (
    <TableBody>
      <TableRow type={TableContentType.Header} rowId="header">
        {props.columns.map((column, index) => {
          return (
            <column.RenderHead
              key={column.id}
              index={index}
              onColumnDragStart={props.table.onColumnDragStart}
              onColumnDragOver={props.table.onColumnDragOver}
              onColumnDrop={onColumnDrop}
              width={props.table.columnsById.get(column.id)?.width ?? column.defaultWidth}
              onWidthChange={onColumnWidthChange}
              id={column.id}
              locked={false}
              onColumnPin={onColumnPin}
              sorted={false}
              onColumSort={column.sortBy ? onColumnSort : undefined}
              sortedDirection={props.table.sortDirection}
              unitType={props.table.unitType}
              unitCategory={column.unitCategory}
            />
          );
        })}
      </TableRow>
      {props.table.canvasWorld && (
        <TableRow type={TableContentType.Canvas} rowId="canvas">
          {props.columns.map((column) => {
            return (
              <column.RenderCell
                type={TableContentType.Canvas}
                key={column.id}
                data={props.canvasRowData}
                width={props.table.columnsById.get(column.id)?.width ?? column.defaultWidth}
                unitType={props.table.unitType}
                unitCategory={column.unitCategory}
              />
            );
          })}
        </TableRow>
      )}
      {props.table.rows.map((row) => (
        <TableRow
          rowId={row.id}
          key={row.id}
          type={TableContentType.Body}
          selected={props.table.selectedHashKey === row.rgWorldHashKey}
          onRowSelect={props.table.selectRowById}
          onHover={props.table.setHoverById}
          hover={props.table.hoveredHashKey === row.rgWorldHashKey}
        >
          {props.columns.map((column) => {
            return (
              <column.RenderCell
                type={TableContentType.Body}
                key={column.id}
                data={row}
                unitType={props.table.unitType}
                width={props.table.columnsById.get(column.id)?.width ?? column.defaultWidth}
                hover={props.table.hoveredHashKey === row.rgWorldHashKey}
                selected={props.table.selectedHashKey === row.rgWorldHashKey}
                onCellSelect={props.table.selectRowById}
                unitCategory={column.unitCategory}
              />
            );
          })}
        </TableRow>
      ))}
    </TableBody>
  );
});
