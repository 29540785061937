import { IconInfoCircleFilled } from "@tabler/icons-react";
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useListNavigation,
  useInteractions,
  FloatingFocusManager,
  useTypeahead,
  flip,
  size,
  autoUpdate,
  FloatingPortal,
} from "@floating-ui/react";
import { useState, useRef } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import fibreCement from "src/assets/facadeTexture/fibre-cement.jpg";
import mechanicalBrickSlip from "src/assets/facadeTexture/mechanical-brickSlip.jpg";
import metalCladding from "src/assets/facadeTexture/metal-cladding.jpg";
import precastConcrete from "src/assets/facadeTexture/precast-concrete-panels.jpg";
import brickwork from "src/assets/facadeTexture/traditional-brickwork.jpg";
import classNames from "classnames";

interface Facade {
  id: number;
  price: number;
  currency: string;
  unit: AreaUnits;
  label: string;
  image: string;
}
const enum AreaUnits {
  Sqm = "sqm",
  Sqft = "sqft",
}
export function FacadeFinishMaterialSection() {
  return (
    <div className="space-y-2">
      <div className="text-body-1 text-neutral-7 flex items-center justify-between">
        Façade Finish Material <IconInfoCircleFilled className="w-3 text-neutral-5" />
      </div>
      <FacadeSelection />
    </div>
  );
}

const options: Facade[] = [
  {
    id: 0,
    price: 495,
    currency: "£",
    unit: AreaUnits.Sqm,
    label: "Traditional Brickwork",
    image: brickwork,
  },
  {
    id: 1,
    price: 695,
    currency: "£",
    unit: AreaUnits.Sqm,
    label: "Precast Concrete Panels",
    image: precastConcrete,
  },
  {
    id: 2,
    price: 695,
    currency: "£",
    unit: AreaUnits.Sqm,
    label: "Fibre/Cement Cladding System",
    image: fibreCement,
  },
  {
    id: 3,
    price: 695,
    currency: "£",
    unit: AreaUnits.Sqm,
    label: "Mechanical BrickSlip System",
    image: mechanicalBrickSlip,
  },
  {
    id: 4,
    price: 1000,
    currency: "£",
    unit: AreaUnits.Sqm,
    label: "Metal Cladding System",
    image: metalCladding,
  },
];
const listOfLabel = options.map((obj) => obj.label);
function FacadeSelection() {
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const { refs, floatingStyles, context } = useFloating({
    placement: "bottom-start",
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      flip({ padding: 10 }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width + 100}px`,
          });
        },
      }),
    ],
  });

  const listRef = useRef<Array<HTMLElement | null>>([]);
  const listContentRef = useRef(listOfLabel);
  const isTypingRef = useRef(false);

  const click = useClick(context, { event: "mousedown" });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "listbox" });
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
    // This is a large list, allow looping.
    loop: true,
  });
  const typeahead = useTypeahead(context, {
    listRef: listContentRef,
    activeIndex,
    selectedIndex,
    onMatch: isOpen ? setActiveIndex : setSelectedIndex,
    onTypingChange(isTyping) {
      isTypingRef.current = isTyping;
    },
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    dismiss,
    role,
    listNav,
    typeahead,
    click,
  ]);

  const handleSelect = (index: number) => {
    setSelectedIndex(index);
    setIsOpen(false);
  };

  const selectedItem = selectedIndex !== null ? options[selectedIndex] : options[0];

  return (
    <>
      <div
        tabIndex={0}
        ref={refs.setReference}
        aria-labelledby="select-label"
        aria-autocomplete="none"
        className={classNames(
          "w-full border border-neutral-5 text-body-1 rounded-sm",
          " h-8 items-center flex space-x-2 px-2 cursor-pointer",
          isOpen && "border-primary-6 "
        )}
        {...getReferenceProps()}
      >
        <img
          src={selectedItem.image}
          alt={selectedItem.label}
          className="w-6 h-6 border border-neutral-5 rounded-sm "
        />
        <span className="truncate w-[95px] text-neutral-8">{selectedItem.label}</span>

        <div className="flex text-neutral-6 w-auto">
          <span>{selectedItem.currency}</span>
          <span>{selectedItem.price.toLocaleString("en")}</span>
          <span>/</span>
          <span>{selectedItem.unit}</span>
        </div>
        {isOpen ? (
          <ChevronUpIcon className="w-3 stroke-2 stroke-neutral-7" />
        ) : (
          <ChevronDownIcon className="w-3 stroke-2 stroke-neutral-7" />
        )}
      </div>
      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div
              className="z-50 shadow shadow-elevation-2"
              ref={refs.setFloating}
              style={{
                ...floatingStyles,
                overflowY: "auto",
                outline: 0,
              }}
              {...getFloatingProps()}
            >
              {options.map((item, i) => (
                <div
                  key={item.id}
                  ref={(node) => {
                    listRef.current[i] = node;
                  }}
                  role="option"
                  tabIndex={i === activeIndex ? 0 : -1}
                  aria-selected={i === selectedIndex && i === activeIndex}
                  className="bg-white"
                  {...getItemProps({
                    // Handle pointer select.
                    onClick() {
                      handleSelect(i);
                    },
                    // Handle keyboard select.
                    onKeyDown(event) {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        handleSelect(i);
                      }

                      if (event.key === " " && !isTypingRef.current) {
                        event.preventDefault();
                        handleSelect(i);
                      }
                    },
                  })}
                >
                  <div
                    className={classNames(
                      "flex w-auto text-body-1 h-8 items-center px-2 cursor-pointer rounded-none",
                      i === selectedIndex && "bg-primary-3",
                      i === activeIndex && "bg-primary-1"
                    )}
                  >
                    <img
                      src={item.image}
                      alt="Tessa Logo"
                      className="w-6 h-6 border border-neutral-5 rounded-sm mr-2 "
                    />
                    <span className="w-[230px] text-neutral-8">{item.label}</span>
                    <div className="flex text-neutral-6 w-[60px] justify-end">
                      <span>{item.currency}</span>
                      <span>{item.price.toLocaleString("en")}</span>
                      <span>/</span>
                      <span> {item.unit}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
}
