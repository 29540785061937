import { makeAutoObservable } from "mobx";
import type { World } from "./World";
import { Selection } from "./Selection";
import { ExplorePanel } from "./ExplorePanel";
export class View {
  selection: Selection;
  explorePanel = new ExplorePanel();
  constructor(world: World | null) {
    this.selection = new Selection(world);
    makeAutoObservable(this, {}, { autoBind: true });
  }
}
