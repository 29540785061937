import { Canvas } from "@react-three/fiber";
import { observer } from "mobx-react-lite";
import { PerspectiveCamera, Html } from "@react-three/drei";
import type { MapState } from "src/types/MapState";

const Compass = observer((props: { mapState: MapState }) => {
  const width = 0.0185;
  const length = 0.35;
  const { bearing, pitch } = props.mapState;
  return (
    <>
      <ambientLight />
      <PerspectiveCamera makeDefault position={[0, 0, 8]} fov={10} />
      <group rotation-x={pitch.value} rotation-y={bearing.value}>
        <mesh position={[length, 0, 0]} rotation={[0, 0, (-90 * Math.PI) / 180]}>
          <coneGeometry args={[width * 2.25, 0.1, 10]} />
          <meshStandardMaterial color={[0, 0, 0]} />
        </mesh>
        <mesh scale={[length, width, width]} position={[length / 2, 0, 0]}>
          <boxGeometry />
          <meshStandardMaterial color={[0, 0, 0]} />
        </mesh>
        <mesh position={[0, length, 0]} rotation={[0, (-90 * Math.PI) / 180, 0]}>
          <coneGeometry args={[width * 2.25, 0.1, 10]} />
          <meshStandardMaterial color={[0, 0, 0]} />
        </mesh>
        <mesh scale={[width, length, width]} position={[0, length / 2, 0]}>
          <boxGeometry />
          <meshStandardMaterial color={[0, 0, 0]} />
        </mesh>
        <mesh position={[0, 0, -length]} rotation={[(-90 * Math.PI) / 180, 0, 0]}>
          <coneGeometry args={[width * 2.25, 0.1, 10]} />
          <meshPhongMaterial color={[0.14, 0.56, 0.45]} />
        </mesh>
        <mesh scale={[width, width, -length]} position={[0, 0, -length / 2]}>
          <boxGeometry />
          <meshPhongMaterial color={[0.14, 0.56, 0.45]} />
        </mesh>
        <Html position={[0.1, 0.04, -length - 0.15]} center sprite>
          <h1 className="bg-red-100/0 text-[9px] text-dark-green-100 font-semibold">N</h1>
        </Html>
      </group>
    </>
  );
});

export const Compass3D = observer(({ mapState }: { mapState: MapState }) => {
  return (
    <div className="absolute w-16 h-16 z-10 overflow-visible top-2 right-2 rounded-full bg-white/75 border-black/40 border">
      <div className="w-[64px] h-[64px] bg-white/0 rounded-full overflow-hidden">
        <Canvas linear>
          <Compass mapState={mapState} />
        </Canvas>
      </div>
    </div>
  );
});
