import { enableMapSet } from "immer";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { ENV } from "./constants/env";
import "./index.scss";
import "./sentry";
import "./smartlook";

enableMapSet();

if (ENV.IS_BUILD_DEV && !ENV.IS_DEV) {
  console.log("%cTessa", "color: #269786; font-weight: bold; font-size:1rem");
  console.log("%cBuild ID:", "color: #269786", parseInt(ENV.BUILD_ID));
  console.log("%cAPP ID:", "color: #269786", ENV.BUILD_APP_ID);
  console.log("%cLast update:", "color: #269786", new Date(parseInt(ENV.BUILD_DATE)).toUTCString());
  console.log("%cVersion (commit SHA):", "color: #269786", ENV.BUILD_COMMIT_SHA);
  console.log(
    "%cPull Request:",
    "color: #269786",
    ENV.BUILD_PULL_REQUEST_ID
      ? `https://github.com/Project-Rouge/rg-app-beta/pull/${ENV.BUILD_PULL_REQUEST_ID}`
      : "MISSING"
  );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(<App />);
