import { type V2 } from "@project-rouge/rg-core";

/** Returns a line including intersection points.
 * Additionally all extra points which are outside of start/end of intersection will be removed
 * */
export function GetIntersectCutLine(cutLine: V2[], polygon: V2[]): V2[] {
  const points: { position: V2; isIntersect: boolean }[] = [];
  for (let i = 0; i < cutLine.length - 1; i++) {
    const x1 = cutLine[i][0];
    const y1 = cutLine[i][1];
    const x2 = cutLine[i + 1][0];
    const y2 = cutLine[i + 1][1];
    // do not include a point if sits outside before we registered point on the edge
    // if (!!points.length) points.push(cutLine[i]);
    points.push({ position: cutLine[i], isIntersect: false });
    for (let n = 0; n < polygon.length - 1; n++) {
      const x3 = polygon[n][0];
      const y3 = polygon[n][1];
      const x4 = polygon[n + 1][0];
      const y4 = polygon[n + 1][1];
      const value = GetIntersection(x1, y1, x2, y2, x3, y3, x4, y4);
      if (!!value) points.push({ position: [value.x, value.y], isIntersect: true });
    }
  }
  // points.push({position: cutLine[cutLine.length -1], isIntersect: })
  const firstIntersectIndex = points.findIndex((point) => point.isIntersect);
  const lastIntersectIndex = findLastIndex(points, (point) => point.isIntersect);
  if (firstIntersectIndex === -1 || lastIntersectIndex === -1) return cutLine;
  return points.slice(firstIntersectIndex, lastIntersectIndex + 1).map(({ position }) => position);
}

// line intercept math by Paul Bourke http://paulbourke.net/geometry/pointlineplane/
// Determine the intersection point of two line segments
// Return FALSE if the lines don't intersect
function GetIntersection(
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  x3: number,
  y3: number,
  x4: number,
  y4: number,
  tolerance: number = 0.001
) {
  // Check if none of the lines are of length 0
  if (
    (Math.abs(x1 - x2) < tolerance && Math.abs(y1 - y2) < tolerance) ||
    (Math.abs(x3 - x4) < tolerance && Math.abs(y3 - y4) < tolerance)
  ) {
    return false;
  }

  const denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);

  // Lines are parallel
  if (Math.abs(denominator) < tolerance) {
    return false;
  }

  let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
  let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;

  // is the intersection along the segments
  if (ua < -tolerance || ua > 1 + tolerance || ub < -tolerance || ub > 1 + tolerance) {
    return false;
  }

  // Return an object with the x and y coordinates of the intersection
  let x = x1 + ua * (x2 - x1);
  let y = y1 + ua * (y2 - y1);

  return { x, y };
}

function findLastIndex<T extends unknown[]>(
  arr: T,
  predicate: (item: T[number]) => boolean
): number {
  for (let i = arr.length - 1; i >= 0; i--) {
    if (predicate(arr[i])) return i;
  }
  return -1;
}
