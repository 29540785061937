import { UnitType } from "src/types/UnitType";
import { formatOrdinals } from "src/components/MetricsDrawer/formatOrdinals";
import type { World } from "src/types/World";
import type { ArchitecturalMetrics } from "src/types/ArchitecturalMetrics";
import { meterToFeet, perSqmToPerSqft, sqmToSqft } from "src/hooks/meterToFeet";
import {
  RegisteredColumnId_Cost,
  RegisteredColumnId_Design,
  RegisteredColumnId_Sustainability,
} from "src/components/ExplorePanel/RegisteredColumnId";
import type { CostingMetrics, SustainabilityMetrics } from "src/components/ExplorePanel/RowData";
import { calculateValuePerMetrics } from "@project-rouge/service-cost-client/data/value-per-metric";
import type { csvMetricData } from "src/components/Toolbox/ExportButton";
import type { metricOptions } from "src/components/ExportModal";

export const metricsToCSV = async (
  world: World,
  csvMetric: csvMetricData,
  selectedMetric: metricOptions,
  selectedUnit: UnitType
) => {
  const worldMetric = architectureColumn(csvMetric.architecturalMetrics, selectedUnit);
  const worldCostMetrics = csvMetric.costMetrics.world;
  const worldSustainabilityMetrics = csvMetric.sustainabilityMetrics.world;

  const construction = calculateValuePerMetrics(
    worldCostMetrics.summary.construction,
    world.architecturalMetrics.ApartmentModuleQuantityTotal,
    world.architecturalMetrics.GIATotal
  );

  const constructionPerArea = construction.sqm;

  const constructionPerUnit = construction.unit;
  let costMetrics: CostingMetrics = {
    ...worldCostMetrics,
    perMetrics: {
      constructionPerArea,
      constructionPerUnit,
    },
  };
  const superstructureValuePerMetric = calculateValuePerMetrics(
    worldSustainabilityMetrics.summary.superstructure,
    world.architecturalMetrics.ModuleQuantityTotal,
    world.architecturalMetrics.GIATotal
  );
  const superstructure = superstructureValuePerMetric.sqm;
  const summaryTotalValuePerMetric = calculateValuePerMetrics(
    worldSustainabilityMetrics.summary.total,
    world.architecturalMetrics.ModuleQuantityTotal,
    world.architecturalMetrics.GIATotal
  );
  const total = summaryTotalValuePerMetric.sqm;
  let sustainabilityMetrics: SustainabilityMetrics = {
    ...worldSustainabilityMetrics,
    perMetrics: {
      superstructure,
      total,
    },
  };

  const costWorldMetric = costColumn(costMetrics, selectedUnit);
  const sustainabilityWorldMetrics = sustainabilityColumn(sustainabilityMetrics, selectedUnit);

  // header
  const unitMixs = worldMetric.UNITMIX.value.map((unit) => unit.name);
  const generalHeaders = ["Zone name", "Block name", "Level"];
  const designHeaders = [
    worldMetric.BUILDINGS.label,
    worldMetric.MODULES.label,
    worldMetric.FLOORS.label,
    worldMetric.PODIUMLEVELS.label,
    worldMetric.FLOORSRESIDENTIAL.label,
    worldMetric.ENTRANCES.label,
    worldMetric.CORES.label,
    worldMetric.FIREFIGHTINGCORES.label,
    worldMetric.LIFTCORES.label,
    worldMetric.NORTHFACINGUNITS.label,
    worldMetric.PARTMCOMPLIANTUNIT.label,
    worldMetric.DUALASPECT.label,
    `${worldMetric.BUILDINGHEIGHT.label} ${worldMetric.BUILDINGHEIGHT.unit}`,
    `${worldMetric.FFL.label} ${worldMetric.FFL.unit}`,
    `${worldMetric.GEA.label} ${worldMetric.GEA.unit}`,
    `${worldMetric.GIA.label} ${worldMetric.GIA.unit}`,
    `${worldMetric.NIA.label} ${worldMetric.NIA.unit}`,
    `${worldMetric.BUILDINGFOOTPRINT.label} ${worldMetric.BUILDINGFOOTPRINT.unit}`,
    `${worldMetric.COMMERCIALAREA.label} ${worldMetric.COMMERCIALAREA.unit}`,
    `${worldMetric.NONRESIDENTIALAREA.label} ${worldMetric.NONRESIDENTIALAREA.unit}`,
    `${worldMetric.COREANDCORRIDOR.label} ${worldMetric.COREANDCORRIDOR.unit}`,
    `${worldMetric.SERVICEAREA.label} ${worldMetric.SERVICEAREA.unit}`,
    `${worldMetric.AMENITIESAREA.label} ${worldMetric.AMENITIESAREA.unit}`,
    `${worldMetric.GLAZEDFACADEAREA.label} ${worldMetric.GLAZEDFACADEAREA.unit}`,
    `${worldMetric.GROSSFACADEAREA.label} ${worldMetric.GROSSFACADEAREA.unit}`,
    `${worldMetric.NETFACADEAREA.label} ${worldMetric.NETFACADEAREA.unit}`,
    `${worldMetric.ROOFAREA.label} ${worldMetric.ROOFAREA.unit}`,
    `${worldMetric.CONVEXFACADECORNER.label} ${worldMetric.CONVEXFACADECORNER.unit}`,
    `${worldMetric.CONCAVEFACADECORNER.label} ${worldMetric.CONCAVEFACADECORNER.unit}`,
    `${worldMetric.DENSITY.label} no/ha`,
    `${worldMetric.DENSITY_HABITABLEROOMS.label} no/ha`,
    `${worldMetric.RESIDENTIALEFFICIENCY.label} %`,
    `${worldMetric.BUILDINGEFFICIENCY.label} %`,
    `${worldMetric.LANDCOVERAGE.label} %`,
    worldMetric.UNITSPERCORE.label,
    worldMetric.WALLTOFLOORRATIO.label,
    worldMetric.UNITS.label,
    ...unitMixs,
  ];
  const costHeaders = [
    `${costWorldMetric.MODULESCOST.label} £`,
    `${costWorldMetric.CONSTRUCTIONCOST.label} £`,
    `${costWorldMetric.FITOUT.label} £`,
    `${costWorldMetric.BASEBUILD.label} £`,
    `${costWorldMetric.PRELIMS.label} £`,
    `${costWorldMetric.TOTALCOST.label} £`,
    `${costWorldMetric.CONTINGENCY.label} £`,
    `${costWorldMetric.OHAPALLOWANCE.label} £`,
    `${costWorldMetric.CONSTRUCTIONSQFT.label} £`,
    `${costWorldMetric.CONSTRUCTIONUNIT.label} £`,
  ];
  const sustainabilityHeader = [
    `${sustainabilityWorldMetrics.CO2FOOTPRINTSQFT.label} ${sustainabilityWorldMetrics.CO2FOOTPRINTSQFT.unit}`,
    `${sustainabilityWorldMetrics.CO2FOOTPRINT.label} kg`,
    `${sustainabilityWorldMetrics.SUPERSTRUCTURECO2FOOTPRINTSQFT.label} ${sustainabilityWorldMetrics.SUPERSTRUCTURECO2FOOTPRINTSQFT.unit}`,
    `${sustainabilityWorldMetrics.SUPERSTRUCTURECO2FOOTPRINT.label} kg`,
    worldMetric.INDICATIVEFORMFACTOR.label,
  ];

  let headerList = [...generalHeaders];
  if (selectedMetric.design) {
    headerList = headerList.concat(designHeaders);
  }
  if (selectedMetric.cost) {
    headerList = headerList.concat(costHeaders);
  }
  if (selectedMetric.sustainability) {
    headerList = headerList.concat(sustainabilityHeader);
  }

  // world metrics

  const generalWorldValue = { zones: "All zones", blocks: "All blocks", level: "" };

  let worldMetricRow = [];
  let worldMetricObject = Object.assign(generalWorldValue);
  if (selectedMetric.design) {
    const worldMetricValue = {
      buildings: worldMetric.BUILDINGS.value,
      modules: worldMetric.MODULES.value,
      floors: worldMetric.FLOORS.value,
      podium: worldMetric.PODIUMLEVELS.value,
      floorsResidential: worldMetric.FLOORSRESIDENTIAL.value,
      entrances: worldMetric.ENTRANCES.value,
      cores: worldMetric.CORES.value,
      firefightingCores: worldMetric.FIREFIGHTINGCORES.value,
      liftCores: worldMetric.LIFTCORES.value,
      northFacingUnits: worldMetric.NORTHFACINGUNITS.value,
      partMCompliantUnits: worldMetric.PARTMCOMPLIANTUNIT.value,
      dualAspectUits: worldMetric.DUALASPECT.value,
      buildingHeight: worldMetric.BUILDINGHEIGHT.value,
      FFL: worldMetric.FFL.value,
      GEA: worldMetric.GEA.value,
      GIA: worldMetric.GIA.value,
      NIA: worldMetric.NIA.value,
      buildingFootprint: worldMetric.BUILDINGFOOTPRINT.value,
      commercial: worldMetric.COMMERCIALAREA.value,
      nonResidential: worldMetric.NONRESIDENTIALAREA.value,
      coreandCorridor: worldMetric.COREANDCORRIDOR.value,
      serviceArea: worldMetric.SERVICEAREA.value,
      amenitiesArea: worldMetric.AMENITIESAREA.value,
      glazedFacadeArea: worldMetric.GLAZEDFACADEAREA.value,
      grossFacadeArea: worldMetric.GROSSFACADEAREA.value,
      netFacadeArea: worldMetric.NETFACADEAREA.value,
      roofArea: worldMetric.ROOFAREA.value,
      convex: worldMetric.CONVEXFACADECORNER.value,
      concave: worldMetric.CONCAVEFACADECORNER.value,
      density: worldMetric.DENSITY.value,
      densityHabitabeleRoom: worldMetric.DENSITY_HABITABLEROOMS.value,
      residentialEfficiency: worldMetric.RESIDENTIALEFFICIENCY.value,
      buildingEfficiency: worldMetric.BUILDINGEFFICIENCY.value,
      landCoverage: worldMetric.LANDCOVERAGE.value,
      unitPerCore: worldMetric.UNITSPERCORE.value,
      wall2FloorRatio: worldMetric.WALLTOFLOORRATIO.value,
      units: worldMetric.UNITS.value,
    };
    const unitList = worldMetric.UNITMIX.value.map((item) => ({
      [item.name]: item.count,
    }));
    worldMetricObject = Object.assign(worldMetricObject, worldMetricValue, ...unitList);
  }
  if (selectedMetric.cost) {
    const worldCostMetricValue = {
      moduleCost: costWorldMetric.MODULESCOST.value,
      constructionCost: costWorldMetric.CONSTRUCTIONCOST.value,
      fitout: costWorldMetric.FITOUT.value,
      baseBuild: costWorldMetric.BASEBUILD.value,
      prelims: costWorldMetric.PRELIMS.value,
      totalCost: costWorldMetric.TOTALCOST.value,
      contingency: costWorldMetric.CONTINGENCY.value,
      ohpAllowance: costWorldMetric.OHAPALLOWANCE.value,
      constructionPerArea: costWorldMetric.CONSTRUCTIONSQFT.value,
      constructionPerUnit: costWorldMetric.CONSTRUCTIONUNIT.value,
    };
    worldMetricObject = { ...worldMetricObject, ...worldCostMetricValue };
  }
  if (selectedMetric.sustainability) {
    const worldSustainabilityMetricValue = {
      co2FootprintSqft: sustainabilityWorldMetrics.CO2FOOTPRINTSQFT.value,
      co2Footprint: sustainabilityWorldMetrics.CO2FOOTPRINT.value,
      superstructureCo2FootprintSqft:
        sustainabilityWorldMetrics.SUPERSTRUCTURECO2FOOTPRINTSQFT.value,
      superstructureCo2Footprint: sustainabilityWorldMetrics.SUPERSTRUCTURECO2FOOTPRINT.value,
      indicativeFormFactor: worldMetric.INDICATIVEFORMFACTOR.value,
    };
    worldMetricObject = { ...worldMetricObject, ...worldSustainabilityMetricValue };
  }
  worldMetricRow.push(worldMetricObject);

  // zone metrics
  const zoneCostMetrics = csvMetric.costMetrics.zones;
  const zoneSustainabilityMetrics = csvMetric.sustainabilityMetrics.zones;
  world.zones.forEach((element, index) => {
    const zone = architectureColumn(element.metrics, selectedUnit);
    const unitList = zone.UNITMIX.value.map((item) => ({
      [item.name]: item.count,
    }));
    const zoneMetric = {
      zones: world?.zones[index].label ?? "",
      blocks: "",
      level: "",
      buildings: zone.BUILDINGS.value,
      modules: zone.MODULES.value,
      floors: zone.FLOORS.value,
      podium: zone.PODIUMLEVELS.value,
      floorsResidential: zone.FLOORSRESIDENTIAL.value,
      entrances: zone.ENTRANCES.value,
      cores: zone.CORES.value,
      firefightingCores: zone.FIREFIGHTINGCORES.value,
      liftCores: zone.LIFTCORES.value,
      northFacingUnits: zone.NORTHFACINGUNITS.value,
      partMCompliantUnits: zone.PARTMCOMPLIANTUNIT.value,
      dualAspectUits: zone.DUALASPECT.value,
      buildingHeight: zone.BUILDINGHEIGHT.value,
      FFL: zone.FFL.value,
      GEA: zone.GEA.value,
      GIA: zone.GIA.value,
      NIA: zone.NIA.value,
      buildingFootprint: zone.BUILDINGFOOTPRINT.value,
      commercial: zone.COMMERCIALAREA.value,
      nonResidential: zone.NONRESIDENTIALAREA.value,
      coreandCorridor: zone.COREANDCORRIDOR.value,
      serviceArea: zone.SERVICEAREA.value,
      amenitiesArea: zone.AMENITIESAREA.value,
      glazedFacadeArea: zone.GLAZEDFACADEAREA.value,
      grossFacadeArea: zone.GROSSFACADEAREA.value,
      netFacadeArea: zone.NETFACADEAREA.value,
      roofArea: zone.ROOFAREA.value,
      convex: zone.CONVEXFACADECORNER.value,
      concave: zone.CONCAVEFACADECORNER.value,
      density: zone.DENSITY.value,
      densityHabitabeleRoom: zone.DENSITY_HABITABLEROOMS.value,
      residentialEfficiency: zone.RESIDENTIALEFFICIENCY.value,
      buildingEfficiency: zone.BUILDINGEFFICIENCY.value,
      landCoverage: zone.LANDCOVERAGE.value,
      unitPerCore: zone.UNITSPERCORE.value,
      wall2FloorRatio: zone.WALLTOFLOORRATIO.value,
      units: zone.UNITS.value,
    };
    const construction = calculateValuePerMetrics(
      zoneCostMetrics[index].summary.construction,
      zone.UNITS.value,
      zone.GIA.value
    );

    const constructionPerArea = construction.sqm;
    const constructionPerUnit = construction.unit;
    let zoneCostMetric: CostingMetrics = {
      ...zoneCostMetrics[index],
      perMetrics: {
        constructionPerArea,
        constructionPerUnit,
      },
    };
    const zoneCostMetricCsv = costColumn(zoneCostMetric, selectedUnit);

    const superstructureValuePerMetric = calculateValuePerMetrics(
      zoneSustainabilityMetrics[index].summary.superstructure,
      zone.MODULES.value,
      zone.GIA.value
    );
    const superstructure = superstructureValuePerMetric.sqm;
    const summaryTotalValuePerMetric = calculateValuePerMetrics(
      zoneSustainabilityMetrics[index].summary.total,
      world.architecturalMetrics.ModuleQuantityTotal,
      world.architecturalMetrics.GIATotal
    );
    const total = summaryTotalValuePerMetric.sqm;
    let sustainabilityMetrics: SustainabilityMetrics = {
      ...worldSustainabilityMetrics,
      perMetrics: {
        superstructure,
        total,
      },
    };
    const zoneSustainabilityMetricCsv = sustainabilityColumn(sustainabilityMetrics, selectedUnit);
    const zoneCostMetricValue = {
      moduleCost: zoneCostMetricCsv.MODULESCOST.value,
      constructionCost: zoneCostMetricCsv.CONSTRUCTIONCOST.value,
      fitout: zoneCostMetricCsv.FITOUT.value,
      baseBuild: zoneCostMetricCsv.BASEBUILD.value,
      prelims: zoneCostMetricCsv.PRELIMS.value,
      totalCost: zoneCostMetricCsv.TOTALCOST.value,
      contingency: zoneCostMetricCsv.CONTINGENCY.value,
      ohpAllowance: zoneCostMetricCsv.OHAPALLOWANCE.value,
      constructionPerArea: zoneCostMetricCsv.CONSTRUCTIONSQFT.value,
      constructionPerUnit: zoneCostMetricCsv.CONSTRUCTIONUNIT.value,
    };
    const zoneSustainabilityMetricValue = {
      co2FootprintSqft: zoneSustainabilityMetricCsv.CO2FOOTPRINTSQFT.value,
      co2Footprint: zoneSustainabilityMetricCsv.CO2FOOTPRINT.value,
      superstructureCo2FootprintSqft:
        zoneSustainabilityMetricCsv.SUPERSTRUCTURECO2FOOTPRINTSQFT.value,
      superstructureCo2Footprint: zoneSustainabilityMetricCsv.SUPERSTRUCTURECO2FOOTPRINT.value,
      indicativeFormFactor: zone.INDICATIVEFORMFACTOR.value,
    };

    const zoneMetricRow = Object.assign(
      zoneMetric,
      ...unitList,
      zoneCostMetricValue,
      zoneSustainabilityMetricValue
    );
    worldMetricRow.push(zoneMetricRow);
  });

  // building Metric
  const buildingCostMetrics = csvMetric.costMetrics.buildings;
  const buildingSustainabilityMetrics = csvMetric.sustainabilityMetrics.buildings;
  world.buildings.forEach((element, index) => {
    const buildingIndex = index;
    const building = architectureColumn(element.metrics, selectedUnit);

    const unitList = building.UNITMIX.value.map((item) => ({
      [item.name]: item.count,
    }));

    const buildingMetric = {
      zones: world?.buildings[index].parent?.parent?.label ?? "",
      blocks: world?.buildings[index].label,
      level: "",
      buildings: building.BUILDINGS.value,
      modules: building.MODULES.value,
      floors: building.FLOORS.value,
      podium: building.PODIUMLEVELS.value,
      floorsResidential: building.FLOORSRESIDENTIAL.value,
      entrances: building.ENTRANCES.value,
      cores: building.CORES.value,
      firefightingCores: building.FIREFIGHTINGCORES.value,
      liftCores: building.LIFTCORES.value,
      northFacingUnits: building.NORTHFACINGUNITS.value,
      partMCompliantUnits: building.PARTMCOMPLIANTUNIT.value,
      dualAspectUits: building.DUALASPECT.value,
      buildingHeight: building.BUILDINGHEIGHT.value,
      FFL: building.FFL.value,
      GEA: building.GEA.value,
      GIA: building.GIA.value,
      NIA: building.NIA.value,
      buildingFootprint: building.BUILDINGFOOTPRINT.value,
      commercial: building.COMMERCIALAREA.value,
      nonResidential: building.NONRESIDENTIALAREA.value,
      coreandCorridor: building.COREANDCORRIDOR.value,
      serviceArea: building.SERVICEAREA.value,
      amenitiesArea: building.AMENITIESAREA.value,
      glazedFacadeArea: building.GLAZEDFACADEAREA.value,
      grossFacadeArea: building.GROSSFACADEAREA.value,
      netFacadeArea: building.NETFACADEAREA.value,
      roofArea: building.ROOFAREA.value,
      convex: building.CONVEXFACADECORNER.value,
      concave: building.CONCAVEFACADECORNER.value,
      density: building.DENSITY.value,
      densityHabitabeleRoom: building.DENSITY_HABITABLEROOMS.value,
      residentialEfficiency: building.RESIDENTIALEFFICIENCY.value,
      buildingEfficiency: building.BUILDINGEFFICIENCY.value,
      landCoverage: building.LANDCOVERAGE.value,
      unitPerCore: building.UNITSPERCORE.value,
      wall2FloorRatio: building.WALLTOFLOORRATIO.value,
      units: building.UNITS.value,
    };

    const construction = calculateValuePerMetrics(
      buildingCostMetrics[index].summary.construction,
      building.UNITS.value,
      building.GIA.value
    );

    const constructionPerArea = construction.sqm;
    const constructionPerUnit = construction.unit;
    let buildingCostMetric: CostingMetrics = {
      ...buildingCostMetrics[index],
      perMetrics: {
        constructionPerArea,
        constructionPerUnit,
      },
    };
    const buildingCostMetricCsv = costColumn(buildingCostMetric, selectedUnit);

    const superstructureValuePerMetric = calculateValuePerMetrics(
      buildingSustainabilityMetrics[index].summary.superstructure,
      building.MODULES.value,
      building.GIA.value
    );
    const superstructure = superstructureValuePerMetric.sqm;
    const summaryTotalValuePerMetric = calculateValuePerMetrics(
      buildingSustainabilityMetrics[index].summary.total,
      world.architecturalMetrics.ModuleQuantityTotal,
      world.architecturalMetrics.GIATotal
    );
    const total = summaryTotalValuePerMetric.sqm;
    let sustainabilityMetrics: SustainabilityMetrics = {
      ...worldSustainabilityMetrics,
      perMetrics: {
        superstructure,
        total,
      },
    };
    const buildingSustainabilityMetricCsv = sustainabilityColumn(
      sustainabilityMetrics,
      selectedUnit
    );
    const buildingCostMetricValue = {
      moduleCost: buildingCostMetricCsv.MODULESCOST.value,
      constructionCost: buildingCostMetricCsv.CONSTRUCTIONCOST.value,
      fitout: buildingCostMetricCsv.FITOUT.value,
      baseBuild: buildingCostMetricCsv.BASEBUILD.value,
      prelims: buildingCostMetricCsv.PRELIMS.value,
      totalCost: buildingCostMetricCsv.TOTALCOST.value,
      contingency: buildingCostMetricCsv.CONTINGENCY.value,
      ohpAllowance: buildingCostMetricCsv.OHAPALLOWANCE.value,
      constructionPerArea: buildingCostMetricCsv.CONSTRUCTIONSQFT.value,
      constructionPerUnit: buildingCostMetricCsv.CONSTRUCTIONUNIT.value,
    };
    const buildingSustainabilityMetricValue = {
      co2FootprintSqft: buildingSustainabilityMetricCsv.CO2FOOTPRINTSQFT.value,
      co2Footprint: buildingSustainabilityMetricCsv.CO2FOOTPRINT.value,
      superstructureCo2FootprintSqft:
        buildingSustainabilityMetricCsv.SUPERSTRUCTURECO2FOOTPRINTSQFT.value,
      superstructureCo2Footprint: buildingSustainabilityMetricCsv.SUPERSTRUCTURECO2FOOTPRINT.value,
      indicativeFormFactor: building.INDICATIVEFORMFACTOR.value,
    };

    const buildingMetricRow = Object.assign(
      buildingMetric,
      ...unitList,
      buildingCostMetricValue,
      buildingSustainabilityMetricValue
    );
    worldMetricRow.push(buildingMetricRow);

    // level Metric
    building.RESIDENTIALEFFICIENCY.perLevel.forEach((level, index) => {
      if (!building.UNITMIX.perLevel) return;
      const unitMixPerLevel = building.UNITMIX.perLevel[index];
      const unitList = unitMixPerLevel.map((item: { name: string; count: number }) => ({
        [item.name]: item.count,
      }));
      const levelMetric = {
        zones: world?.buildings[buildingIndex].parent?.parent?.label ?? "",
        blocks: world?.buildings[buildingIndex].label,
        level: `${formatOrdinals(index)} Floor`,
        buildings: "",
        modules: "",
        floors: "",
        podium: "",
        floorsResidential: "",
        entrances: "",
        cores: "",
        firefightingCores: "",
        liftCores: "",
        northFacingUnits: "",
        partMCompliantUnits: "",
        dualAspectUits: "",
        buildingHeight: "",
        FFL: building.FFL.perLevel[index],
        GEA: building.GEA.perLevel[index],
        GIA: building.GIA.perLevel[index],
        NIA: building.NIA.perLevel[index],
        buildingFootprint: "",
        commercial: building.COMMERCIALAREA.perLevel[index],
        nonResidential: building.NONRESIDENTIALAREA.perLevel[index],
        coreandCorridor: building.COREANDCORRIDOR.perLevel[index],
        serviceArea: building.SERVICEAREA.perLevel[index],
        amenitiesArea: building.AMENITIESAREA.perLevel[index],
        glazedFacadeArea: "",
        grossFacadeArea: "",
        netFacadeArea: "",
        roofArea: building.ROOFAREA.perLevel[index],
        convex: "",
        concave: "",
        density: "",
        densityHabitabeleRoom: "",
        residentialEfficiency: level,
        buildingEfficiency: "",
        landCoverage: "",
        unitPerCore: "",
        wall2FloorRatio: "",
        units: building.UNITS.perLevel[index],
      };
      const levelMetricRow = Object.assign(levelMetric, ...unitList);

      worldMetricRow.push(levelMetricRow);
    });
  });

  let sortedResult = worldMetricRow.sort((a, b) => a.zones.localeCompare(b.zones));

  const replacer = (_key: string, value: string | number | null) => value ?? "";
  const header = Object.keys(sortedResult[0]);
  const rowItems = sortedResult.map((row) =>
    header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(",")
  );

  const csv = [headerList, ...rowItems].join("\n");
  const BOM = "\uFEFF";
  const csvData = BOM + csv;
  const blob = new Blob([csvData], { type: "text/csv;charset=UTF-8" });
  return blob;
};

const architectureColumn = (metric: ArchitecturalMetrics, unit: UnitType) => {
  return {
    UNITS: {
      label: "Apartment modules",
      id: RegisteredColumnId_Design.UNITS,
      value: metric.ApartmentModuleQuantityTotal,
      perLevel: metric.ApartmentModuleQuantityPerLevel,
    },
    UNITMIX: {
      label: "Unit mix",
      id: RegisteredColumnId_Design.UNITMIX,
      value: metric.UnitCategoryMixPerCategory,
      perLevel:
        metric.UnitCategoryMixPerLevel !== 0 &&
        metric.UnitCategoryMixPerLevel.map((item) => item.mix),
    },
    RESIDENTIALEFFICIENCY: {
      label: "Residential efficiency",
      id: RegisteredColumnId_Design.RESIDENTIALEFFICIENCY,
      value: metric.ResidentialEfficiencyTotal,
      perLevel: metric.ResidentialEfficiencyPerLevel,
    },
    GIA: {
      unit: unit === UnitType.Metric ? "sqm" : "sqft",
      label: "GIA",
      id: RegisteredColumnId_Design.GIA,
      value: formatArea(metric.GIATotal, unit),
      perLevel: metric.GIAPerLevel.map((value) => formatArea(value, unit)),
    },
    NIA: {
      unit: unit === UnitType.Metric ? "sqm" : "sqft",
      label: "NIA",
      id: RegisteredColumnId_Design.NIA,
      value: formatArea(metric.NIATotal, unit),
      perLevel: metric.NIAPerLevel.map((value) => formatArea(value, unit)),
    },
    BUILDINGS: {
      label: "Buildings",
      id: RegisteredColumnId_Design.BUILDINGS,
      value: metric.buildingsCount,
    },
    MODULES: {
      label: "Modules",
      id: RegisteredColumnId_Design.MODULES,
      value: metric.ModuleQuantityTotal,
    },
    FLOORS: {
      label: "Floors",
      id: RegisteredColumnId_Design.FLOORS,
      value: metric.FloorMax,
    },
    PODIUMLEVELS: {
      label: "Podium height",
      id: RegisteredColumnId_Design.PODIUMLEVELS,
      value: metric.PodiumHeightSumTotal,
    },
    FLOORSRESIDENTIAL: {
      label: "Floors (residential)",
      id: RegisteredColumnId_Design.FLOORSRESIDENTIAL,
      value: metric.PodiumHeightSumTotal > 0 ? metric.FloorMax - 1 : metric.FloorMax,
    },
    ENTRANCES: {
      label: "Entrances",
      id: RegisteredColumnId_Design.ENTRANCES,
      value: metric.EntrancesTotal,
    },
    CORES: {
      label: "Cores",
      id: RegisteredColumnId_Design.CORES,
      value: metric.CoresTotal,
    },
    FIREFIGHTINGCORES: {
      label: "Firefighting cores",
      id: RegisteredColumnId_Design.FIREFIGHTINGCORES,
      value: metric.FireCoresTotal,
    },
    LIFTCORES: {
      label: "Lift cores",
      id: RegisteredColumnId_Design.LIFTCORES,
      value: metric.LiftCoresTotal,
    },
    BUILDINGHEIGHT: {
      unit: unit === UnitType.Metric ? "m" : "ft",
      label: "Building height",
      id: RegisteredColumnId_Design.BUILDINGHEIGHT,
      value: formatLength(metric.BuildingHeightMax, unit),
    },
    FFL: {
      unit: unit === UnitType.Metric ? "m" : "ft",
      label: "FFL",
      id: RegisteredColumnId_Design.FFL,
      value: formatLength(metric.FFLMax, unit),
      perLevel: metric.FFLPerLevel.map((value) => formatLength(value, unit)),
    },
    GEA: {
      unit: unit === UnitType.Metric ? "sqm" : "sqft",
      label: "GEA",
      id: RegisteredColumnId_Design.GEA,
      value: formatArea(metric.GEATotal, unit),
      perLevel: metric.GEAPerLevel.map((value) => formatArea(value, unit)),
    },
    BUILDINGFOOTPRINT: {
      unit: unit === UnitType.Metric ? "sqm" : "sqft",
      label: "Building footprint",
      id: RegisteredColumnId_Design.BUILDINGFOOTPRINT,
      value: formatArea(metric.FootPrintTotal, unit),
    },
    COMMERCIALAREA: {
      unit: unit === UnitType.Metric ? "sqm" : "sqft",
      label: "Commercial area",
      id: RegisteredColumnId_Design.COMMERCIALAREA,
      value: formatArea(metric.CommercialAreaTotal, unit),
      perLevel: metric.CommercialAreaPerLevel.map((value) => formatArea(value, unit)),
    },
    NONRESIDENTIALAREA: {
      unit: unit === UnitType.Metric ? "sqm" : "sqft",
      label: "Non-residential area",
      id: RegisteredColumnId_Design.NONRESIDENTIALAREA,
      value: formatArea(metric.NonResidentialAreaTotal, unit),
      perLevel: metric.NonResidentialAreaPerLevel.map((value) => formatArea(value, unit)),
    },
    COREANDCORRIDOR: {
      unit: unit === UnitType.Metric ? "sqm" : "sqft",
      label: "Core & corridor area",
      id: RegisteredColumnId_Design.COREANDCORRIDOR,
      value: formatArea(metric.CoreAndCorridorAreaTotal, unit),
      perLevel: metric.CoreAndCorridorAreaPerLevel.map((value) => formatArea(value, unit)),
    },
    SERVICEAREA: {
      unit: unit === UnitType.Metric ? "sqm" : "sqft",
      label: "Services area",
      id: RegisteredColumnId_Design.SERVICEAREA,
      value: formatArea(metric.ServiceAreaTotal, unit),
      perLevel: metric.ServiceAreaPerLevel.map((value) => formatArea(value, unit)),
    },
    AMENITIESAREA: {
      unit: unit === UnitType.Metric ? "sqm" : "sqft",
      label: "Amenities area",
      id: RegisteredColumnId_Design.AMENITIESAREA,
      value: formatArea(metric.AmenitiesAreaTotal, unit),
      perLevel: metric.AmenitiesAreaPerLevel.map((value) => formatArea(value, unit)),
    },
    GROSSFACADEAREA: {
      unit: unit === UnitType.Metric ? "sqm" : "sqft",
      label: "Gross facade area",
      id: RegisteredColumnId_Design.GROSSFACADEAREA,
      value: formatArea(metric.FacadeTotal, unit),
    },
    GLAZEDFACADEAREA: {
      unit: unit === UnitType.Metric ? "sqm" : "sqft",
      label: "Glazed facade area",
      id: RegisteredColumnId_Design.GLAZEDFACADEAREA,
      value: metric.GlazedFacade,
    },
    NETFACADEAREA: {
      unit: unit === UnitType.Metric ? "sqm" : "sqft",
      label: "Net facade area",
      id: RegisteredColumnId_Design.NETFACADEAREA,
      value: formatArea(metric.FacadePerLevel, unit),
    },
    ROOFAREA: {
      unit: unit === UnitType.Metric ? "sqm" : "sqft",
      label: "Roof area",
      id: RegisteredColumnId_Design.ROOFAREA,
      value: metric.RoofAreaTotal,
      perLevel: metric.RoofAreaPerLevel,
    },
    DENSITY: {
      label: "Density",
      id: RegisteredColumnId_Design.DENSITY,
      // not sure why we need to multiply by 10000
      value: metric.DensityUnitsTotal * 10000,
    },
    DENSITY_HABITABLEROOMS: {
      label: "Density (habitable rooms)",
      id: RegisteredColumnId_Design.DENSITY_HABITABLEROOMS,
      // not sure why we need to multiply by 10000
      value: metric.DensityHabitableRoomsTotal * 10000,
    },
    BUILDINGEFFICIENCY: {
      label: "Building efficiency",
      id: RegisteredColumnId_Design.BUILDINGEFFICIENCY,
      value: metric.DensityHabitableRoomsTotal,
    },
    LANDCOVERAGE: {
      label: "Land coverage",
      id: RegisteredColumnId_Design.LANDCOVERAGE,
      value: metric.LandCoverageTotal,
    },
    NORTHFACINGUNITS: {
      label: "North facing units",
      id: RegisteredColumnId_Design.NORTHFACINGUNITS,
      value: metric.NorthFacingUnitsTotal,
      perLevel: metric.NorthFacingUnitsPerLevel,
    },
    PARTMCOMPLIANTUNIT: {
      label: "(*) Part M compliant units",
      id: RegisteredColumnId_Design.PARTMCOMPLIANTUNIT,
      value: "",
    },
    UNITSPERCORE: {
      label: "Unit per core",
      id: RegisteredColumnId_Design.UNITSPERCORE,
      value: metric.UnitToLiftCoreRatio,
    },
    DUALASPECT: {
      label: "(*) Dual aspect units",
      id: RegisteredColumnId_Design.DUALASPECT,
      value: "",
    },
    CONCAVEFACADECORNER: {
      unit: unit === UnitType.Metric ? "m" : "ft",
      label: "Concave facade corner length",
      id: RegisteredColumnId_Design.CONCAVEFACADECORNER,
      value: formatLength(metric.FacadeInternalCornerLength, unit),
    },
    CONVEXFACADECORNER: {
      unit: unit === UnitType.Metric ? "m" : "ft",
      label: "Convex facade corner length",
      id: RegisteredColumnId_Design.CONVEXFACADECORNER,
      value: formatLength(metric.FacadeExternalCornerLength, unit),
    },
    WALLTOFLOORRATIO: {
      label: "Wall to floor ratio",
      id: RegisteredColumnId_Design.WALLTOFLOORRATIO,
      value: formatArea(metric.WallToFloorRatioTotal, unit),
    },
    INDICATIVEFORMFACTOR: {
      label: "Indicative form factor",
      id: RegisteredColumnId_Design.INDICATIVEFORMFACTOR,
      value: metric.IndicativeFormFactorTotal,
    },
  };
};

const costColumn = (costingMetrics: CostingMetrics, unit: UnitType) => {
  return {
    FITOUT: {
      label: "Fit-out",
      id: RegisteredColumnId_Cost.FITOUT,
      value: costingMetrics.summary.constructionFitOut,
    },
    MODULESCOST: {
      label: "Modules",
      id: RegisteredColumnId_Cost.MODULESCOST,
      value: costingMetrics.summary.constructionModules,
    },
    CONSTRUCTIONCOST: {
      label: "Construction cost",
      id: RegisteredColumnId_Cost.CONSTRUCTIONCOST,
      value: costingMetrics.summary.construction,
    },
    BASEBUILD: {
      label: "Base build",
      id: RegisteredColumnId_Cost.BASEBUILD,
      value: costingMetrics.summary.constructionBaseBuild,
    },
    PRELIMS: {
      label: "Prelims",
      id: RegisteredColumnId_Cost.PRELIMS,
      value: costingMetrics.summary.preliminaries,
    },
    TOTALCOST: {
      label: "Total cost",
      id: RegisteredColumnId_Cost.TOTALCOST,
      value: costingMetrics.summary.total,
    },
    CONTINGENCY: {
      label: "Contingency",
      id: RegisteredColumnId_Cost.CONTINGENCY,
      value: costingMetrics.summary.contingencies,
    },
    OHAPALLOWANCE: {
      label: "OH&P allowance",
      id: RegisteredColumnId_Cost.OHAPALLOWANCE,
      value: costingMetrics.summary.overheadsAndProfits,
    },
    CONSTRUCTIONSQFT: {
      unit: unit === UnitType.Metric ? "£/sqm" : "£/ft",
      label: "Construction /area",
      id: RegisteredColumnId_Cost.CONSTRUCTIONSQFT,
      value: formatPerArea(costingMetrics.perMetrics.constructionPerArea, unit),
    },
    CONSTRUCTIONUNIT: {
      label: "Construction /unit",
      id: RegisteredColumnId_Cost.CONSTRUCTIONUNIT,
      value: costingMetrics.perMetrics.constructionPerUnit,
    },
  };
};
const sustainabilityColumn = (sustainabilityMetrics: SustainabilityMetrics, unit: UnitType) => {
  return {
    CO2FOOTPRINTSQFT: {
      unit: unit === UnitType.Metric ? "kg/sqm" : "kg/ft",
      label: "CO2 footprint /area",
      id: RegisteredColumnId_Sustainability.CO2FOOTPRINTSQFT,
      value: formatPerArea(sustainabilityMetrics.perMetrics.total, unit),
    },
    CO2FOOTPRINT: {
      label: "CO2 footprint",
      id: RegisteredColumnId_Sustainability.CO2FOOTPRINT,
      value: sustainabilityMetrics.summary.total,
    },
    SUBSTRUCTURECO2FOOTPRINTSQFT: {
      unit: unit === UnitType.Metric ? "kg/sqm" : "kg/ft",
      label: "(*) Substructure CO2 footprint /area",
      id: RegisteredColumnId_Sustainability.SUBSTRUCTURECO2FOOTPRINT,
      value: "",
    },
    SUBSTRUCTURECO2FOOTPRINT: {
      label: "(*) Substructure CO2 footprint",
      id: RegisteredColumnId_Sustainability.SUBSTRUCTURECO2FOOTPRINT2,
      value: "",
    },
    SUPERSTRUCTURECO2FOOTPRINT: {
      label: "Superstructure CO2 footprint",
      id: RegisteredColumnId_Sustainability.SUPERSTRUCTURECO2FOOTPRINT2,
      value: sustainabilityMetrics.summary.superstructure,
    },
    SUPERSTRUCTURECO2FOOTPRINTSQFT: {
      unit: unit === UnitType.Metric ? "kg/sqm" : "kg/ft",
      label: "Superstructure CO2 footprint/ area",
      id: RegisteredColumnId_Sustainability.SUPERSTRUCTURECO2FOOTPRINT,
      value: sustainabilityMetrics.perMetrics.superstructure,
    },
  };
};
function formatLength(num: number, unit: UnitType) {
  const value = unit === UnitType.Metric ? num : meterToFeet(num);
  return value;
}

function formatArea(num: number, unit: UnitType) {
  const value = unit === UnitType.Metric ? num : sqmToSqft(num);
  return value;
}

function formatPerArea(num: number, unit: UnitType) {
  const value = unit === UnitType.Metric ? num : perSqmToPerSqft(num);
  return value;
}
