import { useMetricsCalculationsQuery } from "src/hooks/useMetricsCalculationsQuery";
import { calculateValuePerMetrics } from "@project-rouge/service-cost-client/data/value-per-metric";
import { observer } from "mobx-react-lite";
import type { UnitType } from "../../types/UnitType";
import { InspectorGroup } from "./InspectorGroup";
import { State } from "./InspectorRow";
import { SustainabilityGroupLoading } from "./SustainabilityGroupLoading";
import { InspectorRowXL } from "./InspectorRowXL";
import { ZoneType } from "src/types/Zone";
import type { Selection } from "src/types/Selection";
import { useActiveParams } from "src/hooks/useActiveParams";
import type { World } from "src/types/World";
import { createCarbonViewForTotal } from "@project-rouge/service-cost-client/utility/custom-view";
import { perSqmToPerSqft } from "src/hooks/meterToFeet";

export const SustainabilityMetricsInspector = observer(
  (props: { world: World; unit: UnitType; selection: Selection }) => {
    // const selectedBuildings = computed(() => {
    //   const buildings = new Set<string>();
    //   if (!props.scenario) return buildings;
    //   if (props.selection.buildings.length > 0)
    //     return new Set(props.selection.buildings.map((b) => b.uuid));
    //   return new Set(
    //     getZoneBuildingsIds(
    //       props.scenario,
    //       props.selection.zones.map((z) => z.id)
    //     )
    //   );
    // }).get();

    // const metrics = computed(() => {
    //   return selectedBuildings.size > 0
    //     ? new ArchitecturalSiteMetrics(props.scenario, selectedBuildings)
    //     : props.scenario.architecturalSiteMetrics;
    // }).get();
    const buildingMetric = props.selection.buildings.at(0)?.metrics;
    const zoneMetrics = props.selection.zones.at(0)?.metrics;
    const siteMetrics = props.world.architecturalMetrics;
    const selectedBuildings = props.selection.zones.at(0)?.buildings
      ? props.selection.zones.at(0)?.buildings
      : props.selection.buildings;

    const metrics = buildingMetric ? buildingMetric : zoneMetrics ? zoneMetrics : siteMetrics;
    const projectId = useActiveParams().projectId ?? "";
    const metricsCalculations = useMetricsCalculationsQuery(
      projectId,
      props.world,
      selectedBuildings
    );

    if (!metricsCalculations.data) return <SustainabilityGroupLoading />;
    const totalCarbon = createCarbonViewForTotal(metricsCalculations.data);

    const superstructureValuePerMetric = calculateValuePerMetrics(
      totalCarbon.summary.superstructure,
      metrics.ModuleQuantityTotal,
      metrics.GIATotal
    );

    const summaryTotalValuePerMetric = calculateValuePerMetrics(
      totalCarbon.summary.total,
      metrics.ModuleQuantityTotal,
      metrics.GIATotal
    );
    if (
      props.selection.zones.length > 0 &&
      props.selection.zones.every((zone) => zone.zoneType === ZoneType.exclusion)
    )
      return null;
    return (
      <InspectorGroup label="Sustainability">
        <InspectorRowXL
          state={State.FILLED}
          value={metrics.IndicativeFormFactorTotal}
          decimalPoint={3}
        >
          <span>Indicative form factor</span>
        </InspectorRowXL>
        <InspectorRowXL
          state={State.FILLED}
          value={totalCarbon.summary.total}
          decimalPoint={0}
          unit={"kg"}
        >
          <span>
            CO<sub>2</sub> footprint
          </span>
        </InspectorRowXL>
        <InspectorRowXL
          state={State.FILLED}
          value={
            props.unit === "Metric"
              ? summaryTotalValuePerMetric.sqm
              : perSqmToPerSqft(summaryTotalValuePerMetric.sqm)
          }
          unit={props.unit === "Metric" ? "kg/sqm" : "kg/sqft"}
          decimalPoint={0}
        >
          <span>
            CO<sub>2</sub> footprint /area
          </span>
        </InspectorRowXL>
        <InspectorRowXL
          state={State.FILLED}
          value={totalCarbon.summary.superstructure}
          unit={"kg"}
          decimalPoint={0}
        >
          <span>
            Superstructure <br />
            CO<sub>2</sub> footprint
          </span>
        </InspectorRowXL>
        <InspectorRowXL
          state={State.FILLED}
          value={
            props.unit === "Metric"
              ? superstructureValuePerMetric.sqm
              : perSqmToPerSqft(superstructureValuePerMetric.sqm)
          }
          unit={props.unit === "Metric" ? "kg/sqm" : "kg/sqft"}
          decimalPoint={0}
        >
          <span>
            Superstructure <br />
            CO<sub>2</sub> footprint /area
          </span>
        </InspectorRowXL>
      </InspectorGroup>
    );
  }
);
