import { useMemo } from "react";
import { Square3Stack3DIcon } from "@heroicons/react/20/solid";
import { type Option, StepperSelect } from "../StepperSelect";
import { type UseControls } from "./useControls";
import { useEvent } from "src/hooks/useEvent";
import { formatOrdinals } from "./formatOrdinals";
import { Toggle } from "./Toggle";
import { observer } from "mobx-react-lite";
import type { World } from "src/types/World";
import { useWorldThumbnail } from "src/hooks/useWorldThumbnail";

interface ControlsProps {
  controls: UseControls;
  world: World;
}

export const Controls = observer(({ controls, world }: ControlsProps) => {
  const visibleBuildings = useMemo(
    () =>
      controls.selectedZone?.buildings ??
      controls.zonesOptions.flatMap(({ buildings }) => buildings),
    [controls.zonesOptions, controls.selectedZone?.buildings]
  );
  const thumbnail = useWorldThumbnail(world);
  const zonesOptions: Option[] = useMemo(() => {
    const options = controls.zonesOptions.map((zone) => ({
      id: zone.id,
      label: zone.label,
    }));
    const allOption: Option = {
      id: "ALL",
      label: "All zones",
    };
    return [allOption, ...options];
  }, [controls.zonesOptions]);

  const buildingsOptions: Option[] = useMemo(() => {
    const options = visibleBuildings.map((building) => ({
      id: building.id,
      label: building.label,
    }));
    const allOption: Option = {
      id: "ALL",
      label: "All blocks",
    };
    return [allOption, ...options];
  }, [visibleBuildings]);

  const levelsOptions: Option[] = useMemo(() => {
    const maxLevel = controls.maxLevel;
    return Array.from({ length: maxLevel }).map((_, index) => ({
      id: `${index}`,
      label: formatOrdinals(index, maxLevel - 1),
    }));
  }, [controls.maxLevel]);

  const onZoneChange = useEvent((id: string) => {
    controls.updateSelection((draft) => {
      draft.zone = id === "ALL" ? null : id;
      draft.building = null;
    });
  });

  const onBuildingChange = useEvent((id: string) => {
    controls.updateSelection((draft) => {
      draft.building = id === "ALL" ? null : id;
    });
  });

  const onLevelChange = useEvent((level: string) => {
    controls.updateSelection((draft) => {
      draft.level = parseInt(level);
    });
  });

  const toggleLabels = useEvent((value: boolean) => {
    controls.updateSelection((draft) => {
      draft.labels = value;
    });
  });

  return (
    <div>
      <div className="absolute left-0 py-16 px-2 h-fit overflow-hidden align-middle top-0">
        <div className="overflow-y-auto h-fit align-middle border border-neutral-5 rounded-md bg-white px-2 shadow-md ">
          <div
            onMouseDown={(evt) => {
              evt.preventDefault();
              evt.stopPropagation();
            }}
            className="py-3 flex flex-1 justify-left items-center space-x-2 text-gray-200"
          >
            <Square3Stack3DIcon className="w-4 h-4" />
            <span className="font-semibold">2D Block View</span>
          </div>
          <div
            className="h-[110px] w-[190px] rounded-md border border-black/10"
            style={{
              backgroundImage: `url("${thumbnail}")`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          />

          <div className="p-3 space-y-3">
            <StepperSelect
              options={zonesOptions}
              selected={controls.selectedZone?.id ?? "ALL"}
              onChange={onZoneChange}
            />

            <StepperSelect
              options={buildingsOptions}
              selected={controls.selectedBuilding?.id ?? "ALL"}
              onChange={onBuildingChange}
            />

            <StepperSelect
              options={levelsOptions}
              selected={`${controls.selectedLevel}`}
              stepper
              onChange={onLevelChange}
            />
            <Toggle label="Labels" value={controls.labelsVisible} onChange={toggleLabels} />
          </div>
        </div>
      </div>
    </div>
  );
});
