import classNames from "classnames";
import { IconPinFilled, IconArrowUp, IconArrowDown } from "@tabler/icons-react";
import { Cluster } from "../Cluster";
import { observer } from "mobx-react-lite";
import { SortDirection } from "./RegisteredColumns";

export const TableHeaderButtons = observer(function TableHeaderButtons(props: {
  onColumnPin: (columnId: string) => void;
  columnID: string;
  locked: boolean;
  sorted: boolean;
  onColumSort?: (columnId: string) => void;
  sortedDirection: SortDirection;
}) {
  const onColumnSort = props.onColumSort ? (
    <button
      title="Sort"
      className={classNames(
        "w-[24px] h-[24px] flex justify-center items-center bg-white rounded-md hover:bg-primary-1 cursor-pointer group-hover:opacity-100",
        props.sorted && "opacity-100",
        !props.sorted && "opacity-0"
      )}
      onClick={() => props.onColumSort?.(props.columnID)}
    >
      <Cluster visible={props.sortedDirection === SortDirection.Desc}>
        <IconArrowDown className="w-[12px]" />
      </Cluster>
      <Cluster visible={props.sortedDirection === SortDirection.Asc}>
        <IconArrowUp className="w-[12px] " />
      </Cluster>
    </button>
  ) : null;
  return (
    <div className="group w-full flex space-x-2 absolute px-4 z-10">
      <button
        title="Pin"
        className={classNames(
          "w-[24px] h-[24px] flex justify-center items-center bg-white rounded-md hover:bg-primary-1 cursor-pointer group-hover:opacity-100",
          props.locked && "opacity-100",
          !props.locked && "opacity-0"
        )}
        onClick={() => props.onColumnPin(props.columnID)}
      >
        <IconPinFilled className="w-[12px] " />
      </button>

      {onColumnSort}
    </div>
  );
});
