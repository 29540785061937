import { Modal } from "./Modal";
import { observer } from "mobx-react-lite";

interface OverrideCanvasDesignModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onContinue: () => Promise<void>;
  designName: string;
}

export const OverrideCanvasDesignModal = observer((props: OverrideCanvasDesignModalProps) => {
  return (
    <Modal.Portal isOpen={props.isOpen}>
      <Modal.Card onClose={props.onCancel}>
        <Modal.Header label="Open in Canvas" onClose={props.onCancel} />
        <div className="max-w-[428px]">
          This will replace the zones and design brief in the Canvas with those from{" "}
          <span className="font-semibold italic">{props.designName}</span>. Do you wish to continue?
        </div>
        <Modal.Actions>
          <Modal.ActionCancelButton onClick={props.onCancel} label="Cancel" />
          <Modal.ActionPrimaryButton onClick={props.onContinue} label="Continue" />
        </Modal.Actions>
      </Modal.Card>
    </Modal.Portal>
  );
});
