export function ToggleHeaderInput(props: {
  label: string;
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
}) {
  const radio = props.checked ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="12" viewBox="0 0 21 12" fill="none">
      <rect width="21" height="12" rx="6" fill="#12736E" />
      <rect x="10.5" y="1.5" width="9" height="9" rx="4.5" fill="white" />
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="12" viewBox="0 0 21 12" fill="none">
      <rect width="21" height="12" rx="6" fill="#AFB7BF" />
      <rect x="1.5" y="1.5" width="9" height="9" rx="4.5" fill="white" />
    </svg>
  );
  return (
    <button
      className="flex flex-row text-body-1 text-neutral-8 h-[24px] items-center justify-between"
      onClick={() => props.onCheckedChange(!props.checked)}
    >
      <span>{props.label}</span>
      {radio}
    </button>
  );
}
