import { type V2, type V3 } from "@project-rouge/rg-core";

export function IsVectorEqual([x1, , y1]: V3, [x2, , y2]: V3) {
  return x1 === x2 && y1 === y2;
}
export function IsVectorEqualV3([x1, y1, z1]: V3, [x2, y2, z2]: V3) {
  return x1 === x2 && y1 === y2 && z1 === z2;
}

export function IsVectorEqualV2([x1, y1]: V2, [x2, y2]: V2) {
  return x1 === x2 && y1 === y2;
}
