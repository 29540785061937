import { makeAutoObservable, observable, toJS } from "mobx";
import type { RgSite } from "./RgCorePackage";
import type { RgGeneric, SiteData, V3 } from "@project-rouge/rg-core";
import { RG_TYPE } from "@project-rouge/rg-core";
import { Zone } from "./Zone";
import type { World } from "./World";
import { GetPolygonCentroid } from "src/utils/GetPolygonCentroid";
import { Dataset } from "src/constants/Dataset";
import type { ProjectScenarioSiteHttpPayloadForCreation } from "@project-rouge/service-project-client/resource/project-scenario-site";
import type { GeoPoint } from "./GeoPoint";
import { V3ToV2 } from "src/utils/V3ToV2";
import clip from "polygon-clipping";
import type { Building } from "./Building";
import { MathSum } from "src/utils/MathSum";
interface SiteConfig {
  rgSite?: RgSite;
}
export class Site implements RgGeneric<RG_TYPE.Site, World, Zone, SiteData> {
  alpha: number;
  children: Zone[] = [];
  data: SiteData;
  name: string;
  type = RG_TYPE.Site as const;
  uuid: string;
  pos: V3;
  rot: V3;
  size: V3;
  anchor: V3;
  color: string;
  hash?: string | undefined;
  rg = true as const;
  parent: World | undefined;
  modulousId: string | undefined;
  templateUuid: string | undefined;
  constructor(config?: SiteConfig) {
    this.alpha = config?.rgSite?.alpha ?? 1;
    config?.rgSite?.children.forEach((rgZone) => {
      return this.addZone(new Zone({ rgZone }));
    });
    this.data = config?.rgSite?.data ?? CreateDefaultData();
    this.name = config?.rgSite?.name ?? "";
    this.type = config?.rgSite?.type ?? RG_TYPE.Site;
    this.uuid = config?.rgSite?.uuid ?? crypto.randomUUID();
    this.pos = config?.rgSite?.pos ?? [0, 0, 0];
    this.rot = config?.rgSite?.rot ?? [0, 0, 0];
    this.size = config?.rgSite?.size ?? [0, 0, 0];
    this.anchor = config?.rgSite?.anchor ?? [0, 0, 0];
    this.color = config?.rgSite?.color ?? "#cccccc";
    this.modulousId = config?.rgSite?.modulousId;
    this.templateUuid = config?.rgSite?.templateUuid;
    config?.rgSite?.children.forEach((rgZone) => new Zone({ rgZone }));
    makeAutoObservable(this, {
      data: observable.ref,
      pos: observable.ref,
      rot: observable.ref,
      size: observable.ref,
      anchor: observable.ref,
    });
  }
  get world(): World | undefined {
    return this.parent;
  }
  get buildings(): Building[] {
    return this.zones.flatMap((zone) => zone.buildings);
  }
  get siteAreaHectares() {
    return MathSum(this.zones.map((zone) => zone.siteAreaHectares));
  }

  get id() {
    return this.uuid;
  }
  get outerRing(): V3[] {
    const forUnion = this.zones.map((zone) => [zone.outerRing.map((v) => V3ToV2(v))]);
    const rest = clip.union(forUnion);
    if (rest.length === 0) return [];
    if (rest.length > 1) throw new Error("Union of polygons should only return one polygon");
    const [multipolygon] = rest;
    const [outerRingV2] = multipolygon;
    return outerRingV2.map((v) => {
      return [v[0], 0, v[1]] as V3;
    });
  }
  get geoOuterRing(): GeoPoint[] {
    if (!this.world) return [];
    return this.zones.at(0)?.geoOuterRing ?? [];
  }
  get centroid() {
    return GetPolygonCentroid(this.outerRing);
  }
  get zones(): Zone[] {
    return this.children;
  }
  /** DONT use it directly. use `world.addZone` */
  addZone(zone: Zone): void {
    if (this.children.includes(zone)) return;
    zone.parent = this;
    this.children.push(zone);
  }
  removeZone(zone: Zone): void {
    const children = this.children.filter((z) => z !== zone);
    if (children.length === this.children.length) return;
    zone.parent = undefined;
    this.children = children;
  }

  hasZoneOverlap(zone: Zone) {
    return this.zones.some((z) => z.hasZoneOverlap(zone));
  }

  get hasZone(): boolean {
    return this.children.length > 0;
  }
  get rgParentlessSnapshot(): RgSite {
    const snapshot: RgSite = {
      parent: undefined,
      data: toJS(this.data),
      pos: toJS(this.pos),
      rot: toJS(this.rot),
      size: toJS(this.size),
      children: [],
      rg: this.rg,
      hash: this.hash,
      alpha: this.alpha,
      name: this.name,
      type: this.type,
      uuid: this.uuid,
      anchor: toJS(this.anchor),
      color: this.color,
      modulousId: this.modulousId,
      templateUuid: this.templateUuid,
    };
    snapshot.children = this.children.map((child) => {
      const v = child.rgZone;
      v.parent = snapshot;
      return v;
    });
    return snapshot;
  }

  get beSite(): ProjectScenarioSiteHttpPayloadForCreation {
    return {
      id: this.uuid,
      region: {
        perimeter: this.geoOuterRing,
      },
      zones: this.zones.map((zone) => zone.beZone),
    };
  }
}

function CreateDefaultData(): SiteData {
  return {
    polygon: [
      [0, 0, 0],
      [10, 0, 0],
      [10, 0, 10],
      [0, 0, 10],
      [0, 0, 0],
    ],
    coords: { latitude: 0, longitude: 0 },
    dataset: Dataset.id,
  };
}
