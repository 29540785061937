import { type PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useThree } from "@react-three/fiber";
import { type Root, createRoot } from "react-dom/client";
import { type V3 } from "@project-rouge/rg-core";
import { Vector3 } from "three";
import { useLiveRef } from "src/hooks/useLiveRef";
import { IsVectorEqual } from "src/utils/IsVectorEqual";
/**
 * Renders a React component into the map from 3D space as native HTML
 * @TODO - listen for container resize events and update the position
 */
export const MapAnnotation = (props: PropsWithChildren<{ position: V3 }>) => {
  const [rootData, setRoot] = useState<{
    root: Root;
    rootElement: HTMLDivElement;
    containerElement: HTMLElement;
  } | null>(null);
  const camera = useThree().raycaster.camera;
  const [projectedPosition, setProjectedPosition] = useState<V3>([0, 0, 0]);

  const posRef = useLiveRef(props.position);

  // update the projected position on every frame
  // @TODO - optimize the render loop
  useEffect(() => {
    const render = () => {
      const position = new Vector3(...posRef.current).project(camera).toArray();
      if (!IsVectorEqual(position, posRef.current)) setProjectedPosition(position);
      requestAnimationFrame(render);
    };
    render();
  }, [camera, posRef]);

  // create the root element on mount
  useEffect(() => {
    const containerElement = document.getElementById("tessa-map");
    if (!containerElement) return;
    const rootElement = document.createElement("div");
    rootElement.style.position = "absolute";
    rootElement.style.top = "0";
    rootElement.style.left = "0";
    containerElement.appendChild(rootElement);
    setRoot({ root: createRoot(rootElement), rootElement, containerElement });
    return () => {
      containerElement.removeChild(rootElement);
    };
  }, []);

  const position = useMemo(() => {
    if (!rootData) return null;
    const [x, y] = projectedPosition;
    const { width, height } = rootData.containerElement.getBoundingClientRect();
    const left = ((x + 1) * width) / 2;
    const top = ((-y + 1) * height) / 2;
    return { top, left };
  }, [projectedPosition, rootData]);

  // render the children into the DOM
  useEffect(() => {
    rootData?.root.render(
      position ? (
        <div style={{ position: "absolute", left: position.left, top: position.top }}>
          {props.children}
        </div>
      ) : null
    );
  }, [position, props.children, rootData]);
  return null;
};
