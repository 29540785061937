import { useLayoutEffect, useRef } from "react";
import type { UseBoolean } from "src/hooks/useBoolean";
import {
  UserCircleIcon,
  QuestionMarkCircleIcon,
  ChevronRightIcon,
  ArrowRightOnRectangleIcon,
} from "@heroicons/react/20/solid";
import { useRedirect } from "src/hooks/useRedirect";
import { useAuth } from "src/contexts/auth.context";
import { useBoolean } from "src/hooks/useBoolean";
import { Cluster } from "../Cluster";
import { useGetUser } from "src/hooks/useGetUser";
import classNames from "classnames";
import { ContextMenu } from "../ContextMenu";

function useUserName() {
  const user = useGetUser();
  const firstName = user.data?.profile.firstName ?? "";
  const lastName = user.data?.profile.lastName ?? "";
  return `${firstName} ${lastName}`;
}

export function ProfileButton(props: { expand: UseBoolean }) {
  const userName = useUserName();
  const optionsVisible = useBoolean();
  const auth = useAuth();
  const redirect = useRedirect();
  useLayoutEffect(() => {
    window.addEventListener("mousedown", optionsVisible.off);
    return () => window.removeEventListener("mousedown", optionsVisible.off);
  }, [optionsVisible.off]);
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <div className="flex h-[40px] p-1">
      <button
        ref={buttonRef}
        onClick={optionsVisible.on}
        className={classNames(
          "flex flex-row items-center flex-1 p-1 overflow-hidden space-x-2 rounded text-subtitle-2 text-neutral-7 hover:text-primary-7 hover:bg-primary-1",
          props.expand.isOff && "justify-center",
          props.expand.isOn && "justify-start"
        )}
      >
        <UserCircleIcon className="w-4 flex-shrink-0" />
        <Cluster visible={props.expand.isOn}>
          <span className="truncate flex flex-1">{userName}</span>
          <ChevronRightIcon className="w-4" />
        </Cluster>
      </button>
      <ContextMenu
        visible={optionsVisible.isOn}
        onHide={optionsVisible.off}
        refRoot={buttonRef.current}
      >
        <ContextMenu.Card>
          <ContextMenu.Button
            label="Profile"
            Icon={UserCircleIcon}
            onClick={async () => await redirect.profile()}
          />
          <ContextMenu.Button
            label="Logout"
            Icon={ArrowRightOnRectangleIcon}
            onClick={auth.logout}
          />
          <ContextMenu.Separator />

          <ContextMenu.Button
            label="Help Centre"
            Icon={QuestionMarkCircleIcon}
            onClick={() => {
              optionsVisible.off();
              window.open("https://help.tessa.ai", "_blank");
            }}
          />
        </ContextMenu.Card>
      </ContextMenu>
    </div>
  );
}
