import { ArrowUturnRightIcon } from "@heroicons/react/20/solid";
import { Button, ButtonState } from "src/components/Button";

export function HistoryRedoButton({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled: boolean;
}) {
  return (
    <Button
      title="Redo"
      Icon={ArrowUturnRightIcon}
      state={!disabled ? ButtonState.NONE : ButtonState.DISABLED}
      onClick={onClick}
    />
  );
}
