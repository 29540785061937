import { type Brief } from "src/types/Brief";
import { getDefaultMix } from "@project-rouge/rg-aggregator";
import { Dataset } from "src/constants/Dataset";

export function CreateBrief(overrides?: Partial<Brief>): Brief {
  return {
    buildingCount: 100,
    buildingHeight: {
      minimum: 0,
      maximum: 100,
    },
    residentialFloorCount: 6,
    clearInternalHeight: 2.45,
    unitCategoryMix: GetDefaultUnitDistribution(),
    podium: null,
    ...overrides,
  };
}

function GetDefaultUnitDistribution() {
  return getDefaultMix(Dataset).map((unit) => ({
    category: unit.label,
    units: unit.apartmentNames,
    value: unit.ratio,
  }));
}
