import { ProfileButton } from "./ProfileButton";
import { SettingsButton } from "./SettingsButton";
import { Logo } from "./Logo";
import type { UseBoolean } from "src/hooks/useBoolean";
import { useBoolean } from "src/hooks/useBoolean";
import { HorizontalSeparator } from "./HorizontalSeparator";
import { ProjectName } from "./ProjectName";
import { SavedDesigns } from "./SavedDesigns";
import { CanvasButton } from "./CanvasButton";
import { observer } from "mobx-react-lite";
import type { AutoSave } from "src/types/AutoSave";
import type { Editor } from "src/types/Editor";
import { useEffect } from "react";
import classNames from "classnames";

export const Navigator = observer(function Navigator(props: { editor: Editor }) {
  const expand = useBoolean();
  return (
    <div className="flex flex-col relative bg-white w-[40px] shrink-0">
      <div
        className={classNames(
          "transition-all duration-200 ease-out h-full bg-white flex flex-col z-50 border-black/20 border-r shadow-elevation-2",
          expand.isOff && "w-[41px]",
          expand.isOn && "w-[237px]"
        )}
        onMouseEnter={expand.on}
        onMouseLeave={expand.off}
      >
        <Header expand={expand} />
        <SavedDesigns editor={props.editor} expand={expand} />
        <AutoSaveIndicator autoSave={props.editor.autoSave} />
        <HorizontalSeparator />
        <ProfileButton expand={expand} />
      </div>
    </div>
  );
});

function Header(props: { expand: UseBoolean }) {
  return (
    <div className="flex flex-col items-center justify-center space-y-[12px] py-[12px] border-b border-neutral-4">
      <Logo expand={props.expand} />
      <ProjectName expanded={props.expand} />
      <div
        className={classNames(
          "flex flex-col space-y-1 w-full",
          props.expand.isOn && "px-[8px]",
          props.expand.isOff && "px-[6px]"
        )}
      >
        <CanvasButton expand={props.expand} />
        <SettingsButton expand={props.expand} />
      </div>
    </div>
  );
}

const AutoSaveIndicator = observer(function AutoSave(props: { autoSave: AutoSave }) {
  useEffect(() => {
    const beforeUnload = function (e: BeforeUnloadEvent) {
      if (!props.autoSave.isSaving) return;
      e.preventDefault();
      e.returnValue = "";
    };
    window.addEventListener("beforeunload", beforeUnload);
    return () => window.removeEventListener("beforeunload", beforeUnload);
  }, [props.autoSave.isSaving]);
  if (!props.autoSave.isSaving) return null;
  return (
    <div className="p-2">
      <div className="w-full h-full overflow-hidden flex flex-col items-center justify-center">
        <div className="animate-spin inline-block w-4 h-4 border-[2px] border-l-neutral-6 border-t-transparent border-b-neutral-6/60 border-r-neutral-6/0 text-blue-600 rounded-full" />
      </div>
    </div>
  );
});
