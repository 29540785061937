/**
 * Api service is responsible to include all endpoints and do all snake_case to camelCase conversion.
 * Keep the schema same as result from a backend endpoint.
 */

import type { Profile } from "src/types/Profile";
import type { Password } from "src/types/Password";
import type { AuthenticationForAnyCase } from "@project-rouge/service-core/authentication/header";
import type { ScenarioConfiguration } from "@project-rouge/service-cost-client/data/building";
import type { ProjectCostingCalculationHttpResource } from "@project-rouge/service-cost-client/resource/project/project-costing-calculation-resource";
import type { ProjectScenarioHttpPayloadForReplacement } from "@project-rouge/service-project-client/resource/project-scenario";
import type { UserSession } from "src/types/UserSession";
import type { Client } from "@project-rouge/service-core/http/client";
import { factory } from "@project-rouge/service-core/http/client";
import { createDefaultAggDtoZip } from "src/utils/createDefaultAggDtoZip";
import { request as getProjectScenarioCollection } from "@project-rouge/service-project-client/endpoint/project-scenario/get-project-scenario-collection";
import { request as postProjectScenarioResource } from "@project-rouge/service-project-client/endpoint/project-scenario/post-project-scenario-resource";
import { request as putProjectScenarioResource } from "@project-rouge/service-project-client/endpoint/project-scenario/put-project-scenario-resource";
import { request as getProjectCollection } from "@project-rouge/service-project-client/endpoint/project/get-project-collection";
import { request as getProjectResource } from "@project-rouge/service-project-client/endpoint/project/get-project-resource";
import { request as postProjectResource } from "@project-rouge/service-project-client/endpoint/project/post-project-resource";
import { request as deleteProjectResource } from "@project-rouge/service-project-client/endpoint/project/delete-project-resource";
import { request as patchProjectResource } from "@project-rouge/service-project-client/endpoint/project/patch-project-resource";
import { request as getUserResource } from "@project-rouge/service-user-client/endpoint/user/get-user";
import { request as postSessionResource } from "@project-rouge/service-user-client/endpoint/session/post-session";
import { request as patchUserResourceProfile } from "@project-rouge/service-user-client/endpoint/user/patch-user-profile";
import { request as putUserNewPassword } from "@project-rouge/service-user-client/endpoint/user/put-user-password";
import { request as postResetToken } from "@project-rouge/service-user-client/endpoint/reset-token/post-reset-token";
import { request as putResetTokenPassword } from "@project-rouge/service-user-client/endpoint/reset-token/put-reset-token-password";
import { request as getResetToken } from "@project-rouge/service-user-client/endpoint/reset-token/get-reset-token";
import { request as getProjectScenarioDesignOptions } from "@project-rouge/service-project-client/endpoint/project-scenario-design-option/get-project-scenario-design-options";
import { request as postProjectCostingCalculationResource } from "@project-rouge/service-cost-client/endpoint/project/post-project-costing-calculation-resource";
import { request as getProjectCostingResource } from "@project-rouge/service-cost-client/endpoint/project/get-project-costing-resource";
import { request as patchProjectCostingResource } from "@project-rouge/service-cost-client/endpoint/project/patch-project-costing-resource";
import { request as postFileResource } from "@project-rouge/service-file-client/endpoint/file/post-file";
import { request as putFileResourceStatePending } from "@project-rouge/service-file-client/endpoint/file/put-file-state-pending";
import { request as postDesignOption } from "@project-rouge/service-project-client/endpoint/project-scenario-design-option/post-project-scenario-design-option";
import { request as patchDesignOption } from "@project-rouge/service-project-client/endpoint/project-scenario-design-option/patch-project-scenario-design-option";
import { request as deleteDesignOption } from "@project-rouge/service-project-client/endpoint/project-scenario-design-option/delete-project-scenario-design-option";
import { request as getDesign } from "@project-rouge/service-project-client/endpoint/project-scenario-design-option/get-project-scenario-design-option";
import { request as GetWorkspaceLayoutResource } from "@project-rouge/service-project-client/endpoint/workspace-layout/get-workspace-layout-resource";
import { request as PutWorkspaceLayoutResource } from "@project-rouge/service-project-client/endpoint/workspace-layout/put-workspace-layout-resource";
import type { WorkspaceConfig } from "src/hooks/useWorkspaceQuery";
import { request as putProjectScenarioDesignOptionExport } from "@project-rouge/service-project-client/endpoint/project-scenario-design-option/put-project-scenario-design-option-export";
import type { World } from "./World";
import { Compress } from "src/utils/Compress";
import type { ProjectScenarioDesignOptionSiteHttpResource } from "@project-rouge/service-project-client/resource/project-scenario-design-option-site";
import { makeAutoObservable } from "mobx";

export interface BeScenarioWithAggDesignDtoZip {
  aggDesignDtoZip: Uint8Array;
  sites: ProjectScenarioDesignOptionSiteHttpResource[];
  label: string;
}
export interface ApiConfig {
  endpoint: string;
  costEndpoint: string;
  fileEndpoint: string;
  userEndpoint: string;
  onSessionExpired: () => void;
}

export class Api {
  static async PostSession(
    api: Api,
    creds: { email: string; password: string }
  ): Promise<UserSession | null> {
    const session = await api.postSession(creds);
    if (!session) return null;
    return {
      createdAt: session.created_at,
      expiresAt: session.expires_at,
      id: session.id,
      tokens: {
        actor: session.tokens.actor,
        admin: session.tokens.admin,
      },
    };
  }

  static async postProjectCostingCalculation(
    client = factory(fetch),
    hostname: string,
    projectId: string,
    payload: ScenarioConfiguration
  ) {
    const { body } = await postProjectCostingCalculationResource(client, {
      hostname,
      path: {
        // @question why do we need to pass projectId here?
        projectId,
      },

      payload,
    });
    return body;
  }

  private hostname: string;
  private hostnameCost: string;
  private hostnameFile: string;
  private hostnameUser: string;
  actor: string = "";
  admin: string | undefined = undefined;
  private client: Client;

  constructor(config: ApiConfig) {
    if (config.endpoint === undefined || config.endpoint === "") {
      throw new Error(
        "Missing environment based configuration, please update your environment with required variables"
      );
    }

    this.client = factory(async (...args) => {
      const res = await fetch(...args);
      if (res.headers.get("api-authoriser") === "session:expired") config.onSessionExpired();
      return res;
    });

    this.hostname = config.endpoint;
    this.hostnameCost = config.costEndpoint;
    this.hostnameFile = config.fileEndpoint;
    this.hostnameUser = config.userEndpoint;
    makeAutoObservable(this);
  }

  onFetchError(e: Error) {
    console.log(e);
  }

  setTokens(actor: string, admin: string | null) {
    this.actor = actor;
    this.admin = admin ?? undefined;
  }

  get credentials(): AuthenticationForAnyCase {
    return { actor: this.actor, admin: this.admin };
  }

  // Check if the token has been updated
  hasToken() {
    return !!this.actor;
  }

  async getScenarioWithDesignFile(
    projectId: string
  ): Promise<BeScenarioWithAggDesignDtoZip | null> {
    const { body } = await getProjectScenarioCollection(this.client, {
      credentials: this.credentials,
      hostname: this.hostname,
      path: { projectId },
    });
    if (!body) return null;
    const [beScenario] = body.results;
    const fileUrl = beScenario.editor.aggregation.file?.url ?? null;
    const designSnapshot = fileUrl ? await this.fetchDesignFile(fileUrl) : null;
    const aggDesignDtoZip = designSnapshot?.aggDesignDtoZip ?? createDefaultAggDtoZip(beScenario);
    return {
      label: "",
      ...beScenario,
      aggDesignDtoZip,
    };
  }

  async getSavedDesignWithDesignFile(
    projectId: string,
    scenarioId: string,
    designId: string
  ): Promise<BeScenarioWithAggDesignDtoZip | null> {
    const body = await this.getDesignOption({ projectId, scenarioId, designId });
    if (!body) return null;
    const fileUrl = body.aggregation.file?.url ?? null;
    const designSnapshot = fileUrl ? await this.fetchDesignFile(fileUrl) : null;
    const aggDesignDtoZip = designSnapshot?.aggDesignDtoZip;
    if (!aggDesignDtoZip) return null;
    return {
      ...body,
      aggDesignDtoZip,
    };
  }

  private async saveWorldFile(world: World): Promise<{ fileId: string }> {
    const file = {
      aggDesignDtoZip: Array.from(Compress(world.aggDesignOptionDto)),
    };
    const { body: fileResource } = await postFileResource(this.client, {
      hostname: this.hostnameFile,
      payload: {
        meta: {
          mimetype: "application/json",
          bytes: JSON.stringify(file).length,
        },
        type: "binary",
      },
    });
    await fetch(fileResource.upload.url, {
      method: "PUT",
      body: JSON.stringify(file),
    });
    await this.putFileResourceStatePending({ fileId: fileResource.id });
    return { fileId: fileResource.id };
  }

  private async fetchDesignFile(url: string) {
    const res = await fetch(url);
    const design: { aggDesignDtoZip: Uint8Array } = await res.json();
    design.aggDesignDtoZip = new Uint8Array(design.aggDesignDtoZip);
    return design ?? null;
  }

  async putWorld(projectId: string, scenarioId: string, world: World) {
    const { fileId } = await this.saveWorldFile(world);
    const payload: ProjectScenarioHttpPayloadForReplacement = {
      brief: world.beBrief,
      sites: world.beSites,
      editor: {
        aggregation: { file: { id: fileId } },
      },
    };
    await putProjectScenarioResource(this.client, {
      credentials: this.credentials,
      hostname: this.hostname,
      path: { projectId, projectScenarioId: scenarioId },
      payload,
    });
  }

  async saveWorld(
    projectId: string,
    scenarioId: string,
    world: World
  ): Promise<string | undefined> {
    const { fileId } = await this.saveWorldFile(world);
    let saveId = "";
    const res = await postDesignOption(this.client, {
      hostname: this.hostname,
      path: { projectId, projectScenarioId: scenarioId },
      payload: {
        aggregation: { file: { id: fileId } },
        label: world.label,
      },
      credentials: { actor: this.actor },
    });
    if (!res.body) return;
    saveId = res.body.id;
    return saveId;
  }

  async GetScenario({ projectId }: { projectId: string }) {
    const { body } = await getProjectScenarioCollection(this.client, {
      credentials: this.credentials,
      hostname: this.hostname,
      path: { projectId },
    });
    if (!body) return null;
    const [scenario] = body.results;
    return scenario;
  }

  async PostScenario({ projectId }: { projectId: string }) {
    const { body } = await postProjectScenarioResource(this.client, {
      credentials: this.credentials,
      hostname: this.hostname,
      path: { projectId },
      payload: {
        brief: { building_separation: 7 }, // @todo fixme
        sites: [],
      },
    });
    return body ?? null;
  }

  async PutScenario({
    scenario,
    projectId,
    scenarioId,
  }: {
    scenario: ProjectScenarioHttpPayloadForReplacement;
    scenarioId: string;
    projectId: string;
  }) {
    await putProjectScenarioResource(this.client, {
      credentials: this.credentials,
      hostname: this.hostname,
      path: { projectId, projectScenarioId: scenarioId },
      payload: scenario,
    });
  }

  // auth

  async patchUser(profile: Profile) {
    const { body } = await patchUserResourceProfile(this.client, {
      hostname: this.hostnameUser,
      credentials: {
        actor: this.actor,
      },
      path: {
        userId: "me",
      },
      payload: {
        name_first: profile.firstName,
        name_last: profile.lastName,
        company_name: profile.companyName,
        company_job_title: profile.companyJobTitle,
      },
    });
    return body;
  }

  async putNewUserPassword(password: Password) {
    const { body, status } = await putUserNewPassword(this.client, {
      hostname: this.hostnameUser,
      credentials: {
        actor: this.actor,
      },
      path: {
        userId: "me",
      },
      payload: {
        old_password: password.oldPassword,
        new_password: password.newPassword,
      },
    });

    if (status >= 400) {
      const errorMessage = "Password Error";
      throw new Error(errorMessage);
    }

    return body;
  }

  async getUser({ userId }: { userId: string }) {
    const { body } = await getUserResource(this.client, {
      hostname: this.hostnameUser,
      path: {
        userId,
      },
      credentials: {
        actor: this.actor,
      },
    });
    return body;
  }

  async postSession({ email, password }: { email: string; password: string }) {
    const { body, status } = await postSessionResource(this.client, {
      hostname: this.hostnameUser,
      payload: {
        email,
        password,
      },
    });
    if (status === 404) {
      throw new Error("ERR");
    }
    return body;
  }

  // file

  async postFileResource(data: unknown) {
    const { body } = await postFileResource(this.client, {
      hostname: this.hostnameFile,
      payload: {
        meta: {
          mimetype: "application/json",
          bytes: JSON.stringify(data).length,
        },
        type: "binary",
      },
    });
    return body;
  }

  async putFileResourceStatePending({ fileId }: { fileId: string }) {
    const { body } = await putFileResourceStatePending(this.client, {
      hostname: this.hostnameFile,
      path: { fileId },
    });
    return body;
  }

  // project

  async getProjectCollection() {
    const { body } = await getProjectCollection(this.client, {
      hostname: this.hostname,
      credentials: this.credentials,
      pagination: {
        limit: 100,
      },
    });

    return body;
  }

  async getProject({ projectId }: { projectId: string }) {
    const { body } = await getProjectResource(this.client, {
      hostname: this.hostname,
      credentials: this.credentials,
      path: { projectId },
    });

    return body;
  }

  async PostProject({ label }: { label: string }) {
    const { body } = await postProjectResource(this.client, {
      hostname: this.hostname,
      payload: { label },
      credentials: this.credentials,
    });

    return body;
  }

  async deleteProject({ projectId }: { projectId: string }): Promise<void> {
    await deleteProjectResource(this.client, {
      hostname: this.hostname,
      path: { projectId },
      credentials: this.credentials,
    });
  }

  async patchProject({
    projectId,
    label,
    notes,
  }: {
    projectId: string;
    label: string;
    notes: string;
  }) {
    const { body } = await patchProjectResource(this.client, {
      hostname: this.hostname,
      credentials: this.credentials,
      path: { projectId },
      payload: {
        label,
        notes,
      },
    });

    return body;
  }

  // site

  async getProjectSiteCollection({ projectId }: { projectId: string; page?: number }) {
    const { body } = await getProjectScenarioCollection(this.client, {
      hostname: this.hostname,
      path: { projectId },
      credentials: this.credentials,
    });

    return body;
  }

  /** @deprecated */
  async deleteProjectSite({}: { projectId: string; siteId: string }): Promise<void> {
    // @todo
    return await Promise.resolve();
  }

  // design option

  async deleteDesignOption(props: { projectId: string; scenarioId: string; designId: string }) {
    await deleteDesignOption(this.client, {
      hostname: this.hostname,
      credentials: this.credentials,
      path: {
        projectId: props.projectId,
        projectScenarioId: props.scenarioId,
        projectScenarioDesignOptionId: props.designId,
      },
    });
  }

  async getProjectDesignOptionCollection({
    scenarioId,
    projectId,
  }: {
    scenarioId: string;
    projectId: string;
  }) {
    const { body } = await getProjectScenarioDesignOptions(this.client, {
      credentials: this.credentials,
      hostname: this.hostname,
      pagination: { limit: 100 },
      path: { projectId, projectScenarioId: scenarioId },
    });
    return body;
  }

  async getDesignOption({
    designId,
    projectId,
    scenarioId,
  }: {
    designId: string;
    projectId: string;
    scenarioId: string;
  }) {
    const { body } = await getDesign(this.client, {
      hostname: this.hostname,
      credentials: { ...this.credentials, actor: this.actor },
      path: { projectId, projectScenarioDesignOptionId: designId, projectScenarioId: scenarioId },
    });
    return body || null;
  }

  async postDesignOption({
    projectId,
    scenarioId,
    name,
    aggSiteFileId,
  }: {
    projectId: string;
    scenarioId: string;
    name: string;
    aggSiteFileId: string;
  }) {
    await postDesignOption(this.client, {
      hostname: this.hostname,
      path: { projectId, projectScenarioId: scenarioId },
      payload: {
        aggregation: { file: { id: aggSiteFileId } },
        label: name,
      },
      credentials: { actor: this.actor },
    });
    return;
  }

  async patchDesignOption(params: {
    projectId: string;
    designId: string;
    label: string;
    scenarioId: string;
  }) {
    await patchDesignOption(this.client, {
      hostname: this.hostname,
      path: {
        projectId: params.projectId,
        projectScenarioDesignOptionId: params.designId,
        projectScenarioId: params.scenarioId,
      },
      credentials: { actor: this.actor },
      payload: {
        label: params.label,
      },
    });
    return;
  }

  // Costing
  async postProjectCostingCalculation(
    projectId: string,
    payload: ScenarioConfiguration
  ): Promise<ProjectCostingCalculationHttpResource> {
    const { body } = await postProjectCostingCalculationResource(this.client, {
      hostname: this.hostnameCost,

      path: {
        // @question why do we need to pass projectId here?
        projectId,
      },

      payload,
    });

    return body;
  }

  // Costing Settings
  async getCostingSettings(projectId: string) {
    const { body } = await getProjectCostingResource(this.client, {
      hostname: this.hostnameCost,
      path: {
        projectId,
      },
    });

    return {
      contingency: body.on_costs.contingencies_percentage.value,
      ohp: body.on_costs.overheads_and_profits_percentage.value,
      prelims: body.on_costs.preliminiaries_percentage.value,
    };
  }

  async patchCostingSettings(
    projectId: string,
    payload: {
      contingency: number;
      ohp: number;
      prelims: number;
    }
  ) {
    const { body, status } = await patchProjectCostingResource(this.client, {
      hostname: this.hostnameCost,

      path: {
        projectId,
      },

      payload: {
        on_costs: {
          contingencies_percentage: payload.contingency,
          overheads_and_profits_percentage: payload.ohp,
          preliminiaries_percentage: payload.prelims,
        },
      },
    });

    if (status !== 200) {
      return undefined;
    }

    return {
      contingency: body.on_costs.contingencies_percentage.value,
      ohp: body.on_costs.overheads_and_profits_percentage.value,
      prelims: body.on_costs.preliminiaries_percentage.value,
    };
  }

  // Reset Password
  async postUserPasswordReset(payload: { email: string }) {
    const { body } = await postResetToken(this.client, {
      hostname: this.hostnameUser,
      payload: {
        email: payload.email,
      },
    });

    return body;
  }

  async putUserNewPassword(resetToken: string, payload: { password: string }) {
    const { body } = await putResetTokenPassword(this.client, {
      hostname: this.hostnameUser,
      path: {
        resetToken,
      },

      payload: {
        password: payload.password,
      },
    });

    return body;
  }

  async isUserPasswordResetTokenValid(path: { resetToken: string }) {
    const { status } = await getResetToken(this.client, {
      hostname: this.hostnameUser,
      path: {
        resetToken: path.resetToken,
      },
    });

    return status === 200;
  }

  // Column Settings
  async getWorkspace(): Promise<WorkspaceConfig> {
    const { body } = await GetWorkspaceLayoutResource(this.client, {
      credentials: this.credentials,
      hostname: this.hostname,
    });
    return body?.blob as unknown as WorkspaceConfig;
  }

  async postWorkspace(payload: WorkspaceConfig) {
    const { body } = await PutWorkspaceLayoutResource(this.client, {
      credentials: this.credentials,
      hostname: this.hostname,
      payload: {
        blob: payload as never,
      },
    });
    return body;
  }

  // export bim file
  async putDesignExport(payload: {
    projectId: string;
    scenarioId: string;
    designId: string;
    buildingsIds: string[];
    unitsCount: number;
  }) {
    const res = await putProjectScenarioDesignOptionExport(this.client, {
      credentials: this.credentials,
      hostname: this.hostname,
      path: {
        projectId: payload.projectId,
        projectScenarioDesignOptionId: payload.designId,
        projectScenarioId: payload.scenarioId,
      },
      payload: {
        aggregation_building_ids: payload.buildingsIds,
        reserve_credits: payload.unitsCount,
      },
    });
    return res;
  }
}
