import { ViewValue } from "./ViewValue";
import { UnitType } from "../../types/UnitType";
import { UnitSelector } from "./UnitSelector";
import type { World } from "src/types/World";

export function AreaView(props: {
  world: World | null;
  unit: UnitType;
  onUnitChange: (unit: UnitType) => void;
}) {
  return (
    <div className="flex flex-col space-y-1 px-3 py-2 bg-white border-b border-b-neutral-4">
      <div className="flex text-body-1 text-neutral-6">
        <span>Area:</span>
        <ViewValue
          value={
            !props.world
              ? 0
              : props.unit === UnitType.Metric
              ? props.world.architecturalMetrics.siteAreaAcres
              : props.world.architecturalMetrics.siteAreaAcres
          }
          decimalPoint={2}
        />
        <span>{props.unit === UnitType.Metric ? "ha" : "ac"}</span>
      </div>
      <div className="w-[248px]">
        <UnitSelector onUnitChange={props.onUnitChange} unit={props.unit} />
      </div>
    </div>
  );
}
