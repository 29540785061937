import { useMemo } from "react";
import { useActiveParams } from "src/hooks/useActiveParams";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "src/contexts/api.context";
import { useActiveScenarioId } from "src/hooks/useActiveScenarioId";
import { type ListItem } from "./ListItem";

export function getSavedDesignListQueryKey(projectId: string) {
  return ["saved-designs", projectId];
}

export function useSavedDesignsList() {
  const projectId = useActiveParams().projectId ?? "";
  const scenarioId = useActiveScenarioId(projectId) ?? "";
  const api = useApi();
  const queryKey = getSavedDesignListQueryKey(projectId);
  const savedDesignsQuery = useQuery({
    queryKey,
    queryFn: async () => await api.getProjectDesignOptionCollection({ projectId, scenarioId }),
    enabled: !!scenarioId,
  });
  const savedDesigns = useMemo(
    () =>
      savedDesignsQuery.data?.results.map((beDesign) => {
        const item: ListItem = {
          id: beDesign.id,
          label: beDesign.label,
        };
        return item;
      }) || [],
    [savedDesignsQuery.data?.results]
  );
  return { data: savedDesigns, isLoading: savedDesignsQuery.isLoading };
}

export type useSavedDesignsList = ReturnType<typeof useSavedDesignsList>;
