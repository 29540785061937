import classNames from "classnames";
import { useState } from "react";
import {
  ChevronDoubleDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ChevronDoubleUpIcon,
  ChevronDownIcon,
} from "@heroicons/react/20/solid";
import { observer } from "mobx-react-lite";
import { UnitType } from "../../types/UnitType";
import type { Editor } from "src/types/Editor";
import { BriefButton } from "./BriefButton";
import { IconSettings } from "@tabler/icons-react";
import type { ExplorePanel } from "src/types/ExplorePanel";
import { ExplorePanelSettings } from "./ExplorePanelSettings";
import { useBoolean } from "src/hooks/useBoolean";
import { useEvent } from "src/hooks/useEvent";
import type { WorkspaceConfig } from "src/hooks/useWorkspaceQuery";
import {
  CURRENT_WORKSPACE_VERSION,
  WorkspaceQueryKey,
  defaultColumns,
} from "src/hooks/useWorkspaceQuery";
import type { TableStore } from "./TableStore";
import { useQueryClient } from "@tanstack/react-query";
import { useApi } from "src/contexts/api.context";
import type { RegisteredColumnId } from "./RegisteredColumnId";
import { RegisteredColumns } from "./RegisteredColumns";

interface ExploreToolbarProps {
  editor: Editor;
  table: TableStore;
  workspaceConfig: WorkspaceConfig;
}
export const ExploreToolbar = observer(function ExploreToolbar({
  editor,
  table,
  workspaceConfig,
}: ExploreToolbarProps) {
  return (
    <div className="flex items-center w-full bg-white">
      <div className="w-full h-10 items-center flex justify-start px-3 overflow-hidden gap-3">
        <span className="font-semibold text-neutral-8 truncate text-overline ">Design Options</span>
        <div className="flex gap-1 items-center">
          <button
            onClick={() => {
              const index = table.selectedRowIndex - 1;
              const updated = Math.max(index, 0);
              if (updated === table.selectedRowIndex) return;
              table.selectRowByIndex(updated);
            }}
            className="w-6 flex items-center justify-center"
          >
            <ChevronLeftIcon className="w-4 text-primary-6" />
          </button>

          <span className="font-semibold text-neutral-8 text-overline text-sm w-5 flex items-center justify-center">
            {editor.designGenerator.listSize ? table.selectedRowIndex + 1 : 0}
          </span>
          <span className="text-sm w-[14px] flex items-center justify-center">of</span>
          <span className="font-semibold text-neutral-8 text-overline text-sm w-5 flex items-center justify-center">
            {editor.designGenerator.isRunning
              ? editor.designGenerator.listSize
              : editor.designGenerator.completedSize}
          </span>

          <button
            onClick={() => {
              const index = table.selectedRowIndex + 1;
              const updated = Math.min(index, editor.designGenerator.list.length - 1);
              if (updated === table.selectedRowIndex) return;
              table.selectRowByIndex(updated);
            }}
            className="w-6 flex items-center justify-center"
          >
            <ChevronRightIcon className="w-4 text-primary-6" />
          </button>
        </div>
      </div>
      <div className="flex items-center justify-end w-full">
        <UnitSelector table={table} />
        <div className="border-l border-neutral-4 w-1 h-7" />
        <TableSettingsButton table={table} workspaceConfig={workspaceConfig} />
        <div className="border-l border-neutral-4 w-1 h-7" />
        <BriefButton editor={editor} />
        <ToggleVisibilityButton explorePanel={editor.view.explorePanel} />
      </div>
    </div>
  );
});

const ToggleVisibilityButton = observer(function ToggleVisibilityButton(props: {
  explorePanel: ExplorePanel;
}) {
  const icon = props.explorePanel.visible ? (
    <ChevronDoubleDownIcon className="w-4" />
  ) : (
    <ChevronDoubleUpIcon className="w-4" />
  );
  return (
    <button
      onClick={() => props.explorePanel.toggleVisible()}
      className="text-primary-6 hover:bg-primary-1 h-10 w-10 flex items-center justify-center border-l border-neutral-4 ml-1"
    >
      {icon}
    </button>
  );
});

const UnitSelector = observer(function UnitSelector(props: { table: TableStore }) {
  const [unitHidden, isUnitHidden] = useState<boolean>(true);
  return (
    <div className="flex items-center px-3 gap-2">
      <div className="relative inline-block w-[87px] ">
        <button
          className="w-full flex items-center justify-between border-neutral-4 border bg-white text-sm text-neutral-7 h-6 rounded-sm px-2 hover:bg-primary-1"
          onClick={unitHidden === true ? () => isUnitHidden(false) : () => isUnitHidden(true)}
        >
          {props.table.unitType === UnitType.Metric ? UnitType.Metric : UnitType.Imperial}
          {!unitHidden ? <ChevronUpIcon className="w-4" /> : <ChevronDownIcon className="w-4" />}
        </button>
        <button
          className={classNames(
            unitHidden === true && "hidden",
            unitHidden === false && "block",
            "z-[999] absolute w-[87px] text-left px-2 h-6 border-neutral-4 border bg-white text-sm hover:bg-neutral-4 text-neutral-7"
          )}
          onClick={() => {
            unitHidden ? isUnitHidden(false) : isUnitHidden(true);
            props.table.unitType === UnitType.Metric
              ? props.table.setUnit(UnitType.Imperial)
              : props.table.setUnit(UnitType.Metric);
          }}
        >
          {props.table.unitType === UnitType.Metric ? UnitType.Imperial : UnitType.Metric}
        </button>
      </div>
    </div>
  );
});

const TableSettingsButton = observer(function TableSettingsButton(props: {
  workspaceConfig: WorkspaceConfig;
  table: TableStore;
}) {
  const exploreSettingsModal = useBoolean();
  const queryClient = useQueryClient();
  const api = useApi();
  const onSettingsChange = useEvent(async (keys: string[]) => {
    const workspace: WorkspaceConfig = {
      ...props.workspaceConfig,
      explorePanel: {
        ...props.workspaceConfig.explorePanel,
        cols: keys.map((id) => ({
          id: id as RegisteredColumnId,
          width:
            props.workspaceConfig.explorePanel.cols.find((item) => item.id === id)?.width ??
            RegisteredColumns[id as RegisteredColumnId].defaultWidth,
        })),
      },
      version: CURRENT_WORKSPACE_VERSION,
    };
    queryClient.setQueryData(WorkspaceQueryKey, () => workspace);
    await api.postWorkspace(workspace);
    queryClient.invalidateQueries(WorkspaceQueryKey);
    exploreSettingsModal.off();
  });

  const settingsModal = exploreSettingsModal.isOff ? null : (
    <ExplorePanelSettings
      onClose={exploreSettingsModal.off}
      onChange={onSettingsChange}
      currentSelectedTags={props.table.config.cols.map((item) => item.id)}
      defaultColumns={defaultColumns}
      unitType={props.table.unitType}
    />
  );
  return (
    <>
      <button
        onClick={exploreSettingsModal.toggle}
        className="relative text-button px-[6px] h-7 mx-2 py-1 space-x-1 flex flex-row justify-center items-center rounded bg-white text-neutral-7 hover:bg-primary-1 hover:text-primary-7"
      >
        <IconSettings className="w-4" />
        <span>Columns</span>
      </button>
      {settingsModal}
    </>
  );
});
