import { BookmarkIcon } from "@heroicons/react/24/outline";
import { observer } from "mobx-react-lite";
import { useBoolean } from "src/hooks/useBoolean";
import { Button } from "src/components/Button";
import { SaveModal } from "src/components/SaveModal";
import type { World } from "src/types/World";

export const SaveButton = observer(function ExportButton(props: { world: World | null }) {
  const showModal = useBoolean();

  return (
    <>
      <Button Icon={BookmarkIcon} onClick={showModal.on} />
      {showModal.isOn && props.world && <SaveModal onClose={showModal.off} world={props.world} />}
    </>
  );
});
