import { ArrowRightOnRectangleIcon } from "@heroicons/react/20/solid";
import { Button, ButtonIntent, ButtonState } from "src/components/Button";

export function EdgeOffsetButton({
  onClick,
  disabled,
  active,
}: {
  onClick: () => void;
  disabled: boolean;
  active: boolean;
}) {
  return (
    <Button
      title="Edge Offset"
      Icon={ArrowRightOnRectangleIcon}
      intent={ButtonIntent.SELECT}
      state={disabled ? ButtonState.DISABLED : active ? ButtonState.ACTIVE : ButtonState.NONE}
      onClick={onClick}
    />
  );
}
