import ChevronLeftIcon from "@heroicons/react/20/solid/ChevronLeftIcon";
import ChevronRightIcon from "@heroicons/react/20/solid/ChevronRightIcon";
import classNames from "classnames";

import { useEvent } from "src/hooks/useEvent";
import { Cluster } from "./Cluster";
import { observer } from "mobx-react-lite";

interface StepperSelectProps<T extends Options> {
  options: T;
  selected: string;
  stepper?: boolean;
  onChange?: (selected: T[number]["id"]) => void;
}

export interface Option {
  id: string;
  label: string;
}

type Options = Option[];

export const StepperSelect = observer(function Select<T extends Options>({
  options,
  selected,
  stepper,
  onChange,
}: StepperSelectProps<T>) {
  const currentIndex = options.findIndex((option) => option.id === selected);
  const isLastIndex = currentIndex === options.length - 1;
  const handleStepperClick = useEvent((step: number) => {
    const newIndex = currentIndex + step;
    const value = options.at(newIndex)?.id;
    if (value) onChange?.(value);
  });

  return (
    <div className="flex flex-row flex-1 border-neutral-5 border rounded h-[28px] relative">
      <Cluster visible={!!stepper}>
        <button
          className={classNames(
            `hover:bg-primary-1 rounded basis-[32px] justify-center items-center flex`,
            currentIndex === 0 && "hover:bg-neutral-1 opacity-50 cursor-default"
          )}
          disabled={currentIndex === 0}
          onClick={() => handleStepperClick(-1)}
        >
          <ChevronLeftIcon className="w-4" />
        </button>
      </Cluster>
      <select
        className="bg-none block w-full h-full p-0 border-none appearance-none bg-transparent focus:ring-0 text-center"
        value={selected}
        onChange={(event) => onChange?.(event.target.value)}
      >
        {options.map((option) => (
          <option key={option.id} value={option.id} className="hover:bg-primary-1 hover:text-black">
            {option.label}
          </option>
        ))}
      </select>
      <Cluster visible={!!stepper}>
        <button
          className={classNames(
            `hover:bg-primary-1 rounded basis-[32px] justify-center items-center flex`,
            isLastIndex && "hover:bg-neutral-1 opacity-50 cursor-default"
          )}
          disabled={isLastIndex}
          onClick={() => handleStepperClick(1)}
        >
          <ChevronRightIcon className="w-4" />
        </button>
      </Cluster>
    </div>
  );
});
