import { Router } from "src/components/Router";
import { QueryClientProvider, QueryClient, type QueryClientConfig } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { AuthContext, useAuth } from "./contexts/auth.context";
import * as Sentry from "@sentry/react";
import { ENV } from "./constants/env";
import { ApiContext } from "./contexts/api.context";
import { useGetUser } from "./hooks/useGetUser";
import { analyticsSetUser } from "./smartlook";
import { observer } from "mobx-react-lite";
import { Toaster, ToasterContext, useToaster } from "./contexts/toaster.context";
import { ToastFC } from "./components/Toast";
import { createPortal } from "react-dom";
import { setSentryUser } from "./sentry";
import { Auth } from "./types/Auth";
import { autorun } from "mobx";

const DefaultQueryClientConfig: QueryClientConfig = {
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
};

export const App = observer(() => {
  const queryClient = useMemo(() => new QueryClient(DefaultQueryClientConfig), []);
  const [auth] = useState(
    () =>
      new Auth({
        endpoint: ENV.SERVICE_ENDPOINT as string,
        costEndpoint: ENV.SERVICE_COST_ENDPOINT as string,
        fileEndpoint: ENV.SERVICE_FILE_ENDPOINT as string,
        userEndpoint: ENV.SERVICE_USER_ENDPOINT as string,
      })
  );

  const [toaster] = useState(() => new Toaster());

  return (
    <div className="App" data-sl="canvas-hq">
      <ApiContext.Provider value={auth.api}>
        <Sentry.ErrorBoundary>
          <AuthContext.Provider value={auth}>
            <QueryClientProvider client={queryClient}>
              <ToasterContext.Provider value={toaster}>
                <ToasterFC />
                <Analytics />
                <Router />
              </ToasterContext.Provider>
            </QueryClientProvider>
          </AuthContext.Provider>
        </Sentry.ErrorBoundary>
      </ApiContext.Provider>
    </div>
  );
});
const ToasterFC = observer(() => {
  const toaster = useToaster();
  const [root, setRoot] = useState<null | HTMLDivElement>(null);

  useEffect(() => {
    const root = document.createElement("div");
    document.body.appendChild(root);
    setRoot(root);
    return () => {
      document.body.removeChild(root);
    };
  }, []);

  if (!root) return null;
  return createPortal(
    <div>
      {toaster.toasts.map((toast) => (
        <ToastFC key={toast.id} toast={toast} />
      ))}
    </div>,
    root
  );
});

const Analytics = observer(function Analytics() {
  const userQuery = useGetUser();
  const auth = useAuth();

  useEffect(
    () =>
      autorun(() => {
        const user = userQuery.data;
        const name = `${user?.profile.firstName} ${user?.profile.lastName}`;
        const props = !user
          ? null
          : {
              id: user.id,
              name,
              email: user.email,
              admin: !!auth.admin,
              sessionId: auth.sessionId,
            };

        analyticsSetUser(props);
        setSentryUser(props);
      }),
    [auth.admin, auth.sessionId, userQuery.data]
  );
  return null;
});
