export enum RegisteredColumnId_Base {
  "IMAGE" = "IMAGE",
  "NAME" = "NAME",
}

export enum RegisteredColumnId_Design {
  "AMENITIESAREA" = "AMENITIESAREA",
  "BUILDINGEFFICIENCY" = "BUILDINGEFFICIENCY",
  "BUILDINGFOOTPRINT" = "BUILDINGFOOTPRINT",
  "BUILDINGS" = "BUILDINGS",
  "BUILDINGHEIGHT" = "BUILDINGHEIGHT",
  "COMMERCIALAREA" = "COMMERCIALAREA",
  "CONCAVEFACADECORNER" = "CONCAVEFACADECORNER",
  "UNITS" = "UNITS",
  "UNITMIX" = "UNITMIX",
  "UNITSPERCORE" = "UNITSPERCORE",
  "WALLTOFLOORRATIO" = "WALLTOFLOORRATIO",
  "RESIDENTIALEFFICIENCY" = "RESIDENTIALEFFICIENCY",
  "GIA" = "GIA",
  "GEA" = "GEA",
  "GROSSFACADEAREA" = "GROSSFACADEAREA",
  "GLAZEDFACADEAREA" = "GLAZEDFACADEAREA",
  "INDICATIVEFORMFACTOR" = "INDICATIVEFORMFACTOR",
  "LANDCOVERAGE" = "LANDCOVERAGE",
  "LIFTCORES" = "LIFTCORES",
  "MODULES" = "MODULES",
  "NETFACADEAREA" = "NETFACADEAREA",
  "NIA" = "NIA",
  "CONVEXFACADECORNER" = "CONVEXFACADECORNER",
  "COREANDCORRIDOR" = "COREANDCORRIDOR",
  "CORES" = "CORES",
  "DENSITY" = "DENSITY",
  "DENSITY_HABITABLEROOMS" = "DENSITY_HABITABLEROOMS",
  "DUALASPECT" = "DUALASPECT",
  "ENTRANCES" = "ENTRANCES",
  "FFL" = "FFL",
  "FIREFIGHTINGCORES" = "FIREFIGHTINGCORES",
  "FLOORS" = "FLOORS",
  "FLOORSRESIDENTIAL" = "FLOORSRESIDENTIAL",

  "NONRESIDENTIALAREA" = "NONRESIDENTIALAREA",
  "NORTHFACINGUNITS" = "NORTHFACINGUNITS",
  "PARTMCOMPLIANTUNIT" = "PARTMCOMPLIANTUNIT",
  "PODIUMLEVELS" = "PODIUMLEVELS",
  "ROOFAREA" = "ROOFAREA",
  "SERVICEAREA" = "SERVICEAREA",
}

export enum RegisteredColumnId_Sustainability {
  "CO2FOOTPRINT" = "CO2FOOTPRINT",
  "CO2FOOTPRINTSQFT" = "CO2FOOTPRINTSQFT",
  "CONCRETECO2FOOTPRINT" = "CONCRETECO2FOOTPRINT",
  "CONCRETECO2FOOTPRINT2" = "CONCRETECO2FOOTPRINT2",
  "STEELCO2FOOTPRINT" = "STEELCO2FOOTPRINT",
  "STEELCO2FOOTPRINT2" = "STEELCO2FOOTPRINT2",
  "SUBSTRUCTURECO2FOOTPRINT" = "SUBSTRUCTURECO2FOOTPRINT",
  "SUBSTRUCTURECO2FOOTPRINT2" = "SUBSTRUCTURECO2FOOTPRINT2",
  "SUPERSTRUCTURECO2FOOTPRINT" = "SUPERSTRUCTURECO2FOOTPRINT",
  "SUPERSTRUCTURECO2FOOTPRINT2" = "SUPERSTRUCTURECO2FOOTPRINT2",
}

export enum RegisteredColumnId_Cost {
  "BASEBUILD" = "BASEBUILD",
  "CONSTRUCTIONCOST" = "CONSTRUCTIONCOST",
  "CONSTRUCTIONUNIT" = "CONSTRUCTIONUNIT",
  "CONSTRUCTIONSQFT" = "CONSTRUCTIONSQFT",
  "MODULESCOST" = "MODULESCOST",
  "TOTALCOST" = "TOTALCOST",
  "FITOUT" = "FITOUT",
  "PRELIMS" = "PRELIMS",
  "CONTINGENCY" = "CONTINGENCY",
  "OHAPALLOWANCE" = "OHAPALLOWANCE",
}

export type RegisteredColumnId =
  | RegisteredColumnId_Base
  | RegisteredColumnId_Design
  | RegisteredColumnId_Sustainability
  | RegisteredColumnId_Cost;
