import { observer } from "mobx-react-lite";
import { useWorldThumbnail } from "src/hooks/useWorldThumbnail";
import type { ReactNode } from "react";
import type { World } from "src/types/World";

interface ThumbnailProps {
  world: World;
  children: ReactNode;
}

export const Thumbnail = observer(function BlocksContainer({ children, world }: ThumbnailProps) {
  const thumbnail = useWorldThumbnail(world);

  return (
    <div className="bg-white basis-5 w-full flex">
      <div className="pb-3">
        <div className="px-3 border-r border-neutral-4">
          <div
            className=" border-neutral-4 border rounded-sm bg-neutral-4 w-[64px] h-[64px]"
            style={{
              backgroundImage: `url("${thumbnail}")`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          />
        </div>
      </div>
      <div className="max-h-20 px-3 scrollbar">
        <div className="flex gap-2 flex-wrap">{children}</div>
      </div>
    </div>
  );
});
