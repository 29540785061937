import type { UseBoolean } from "src/hooks/useBoolean";
import { ALL_BLOCKS, type BlockId } from "../BlockId";
import { useState } from "react";
import type { MetricsCalculations } from "src/hooks/useMetricsCalculationsQuery";
import { useMetricsCalculationsQuery } from "src/hooks/useMetricsCalculationsQuery";
import { SummaryTable } from "./SummaryTable";
import { useBoolean } from "src/hooks/useBoolean";

import { ElementGroupTable } from "./ElementGroupTable";
import { observer } from "mobx-react-lite";
import { Unit, UnitSelector } from "../UnitSelect";
import { BlockTags } from "../BlockTags";
import { BlocksInfo } from "../BlocksInfo";
import { Table } from "../Table";
import { ElementSection } from "../ElementSection";
import { ElementSectionButton } from "../ElementSectionButton";
import type { World } from "src/types/World";
import { GetTables } from "./useElementsTables";

export const CostMetricsSection = observer(
  ({ design, projectId }: { design: World; projectId: string }) => {
    const costing = useMetricsCalculationsQuery(projectId, design);

    if (costing.isLoading) {
      return (
        <div className="w-full h-full overflow-hidden flex flex-col items-center justify-center">
          <div className="animate-spin inline-block w-11 h-11 border-[3px] border-current border-t-transparent text-blue-600 rounded-full" />
        </div>
      );
    }

    if (!costing.data) return null;

    return <Content design={design} metricsCalculations={costing.data} />;
  }
);

function Content(props: { design: World; metricsCalculations: MetricsCalculations }) {
  const [blockId, setBlockId] = useState<BlockId>(ALL_BLOCKS);
  const [unit, setUnit] = useState(Unit.sqm);

  const buildings = props.design.buildings;

  const selectedBuilding = (blockId === ALL_BLOCKS ? null : buildings[blockId]) ?? null;
  const isBaseBuildElements = useBoolean(true);
  const tables = GetTables(
    props.design,
    selectedBuilding,
    props.metricsCalculations,
    isBaseBuildElements.isOn
  );
  return (
    <div className="w-full h-full overflow-hidden flex flex-col pt-3">
      <BlockTags blockId={blockId} onChange={setBlockId} design={props.design} />
      <UnitSelector onUnitChange={setUnit} unit={unit} />
      <div className="flex flex-1 flex-col scrollbar-none overflow-scroll">
        <BlocksInfo design={props.design} unit={unit} selectedBuilding={selectedBuilding} />
        <SummaryTable
          metricsCalculations={props.metricsCalculations}
          unit={unit}
          design={props.design}
          selectedBuilding={selectedBuilding}
        />
        <TypeSwitch isBaseBuildElements={isBaseBuildElements} />
        {tables.map((table) => (
          <ElementGroupTable key={table.id} table={table} selectedBuilding={selectedBuilding} />
        ))}
      </div>
    </div>
  );
}

function TypeSwitch(props: { isBaseBuildElements: UseBoolean }) {
  return (
    <Table.Sticky zIndex={1}>
      <ElementSection>
        <ElementSectionButton
          onClick={props.isBaseBuildElements.on}
          label="Base Build"
          active={props.isBaseBuildElements.isOn}
        />

        <ElementSectionButton
          onClick={props.isBaseBuildElements.off}
          label="Module element"
          active={props.isBaseBuildElements.isOff}
        />
      </ElementSection>
    </Table.Sticky>
  );
}
