import { type PropsWithChildren } from "react";

export function ElementSection({ children }: PropsWithChildren) {
  return (
    <div className="flex items-center w-full p-4 h-9 bg-neutral-3 border-t border-neutral-4 font-semibold justify-center">
      <div className="flex bg-white border-neutral-5 border h-6 items-center p-[2px] rounded-sm">
        {children}
      </div>
    </div>
  );
}
