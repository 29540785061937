import { IconFileAnalytics } from "@tabler/icons-react";
import { observer } from "mobx-react-lite";
import { MetricsDrawer } from "../MetricsDrawer/MetricsDrawer";
import { useBoolean } from "src/hooks/useBoolean";
import classNames from "classnames";
import type { World } from "src/types/World";

export const MetricsButton = observer(function MetricsButton(props: { world: World }) {
  const isDrawerOpen = useBoolean();
  const hasSite = props.world.hasSite;
  return (
    <div className="bg-white border-t-[1px] border-neutral-4 mt-1 h-[56px] sticky bottom-0 p-3">
      <button
        className={classNames(
          // base
          "text-button border-[1px] border-neutral-5 rounded w-full",
          "text-neutral-7 flex items-center h-8 justify-center",
          // hover
          "hover:text-primary-7 hover:bg-primary-1 hover:border-[1px] hover:border-neutral-7",
          // active
          "active:text-primary-6 active:border-neutral-6 active:border-[1px]",
          // disable
          !hasSite && "!text-neutral-6 !bg-neutral-3 !border-neutral-4"
        )}
        disabled={!hasSite}
        onClick={isDrawerOpen.on}
        title="See all metrics"
      >
        <IconFileAnalytics className="w-4 h-4 mr-1 stroke-[1.5px]" />
        See all metrics
      </button>
      {props.world && isDrawerOpen.isOn && (
        <MetricsDrawer world={props.world} onClose={isDrawerOpen.off} />
      )}
    </div>
  );
});
