import {
  CostElementReferenceGroupItemIndex,
  CostElementReferenceGroupIndex,
} from "@project-rouge/service-cost-client/data/cost-element-reference";

export const COST_GROUP_ELEMENT_REFERENCE_GROUP_INDEX_LABELS: Record<
  CostElementReferenceGroupIndex | CostElementReferenceGroupItemIndex,
  string
> = {
  [CostElementReferenceGroupIndex.FacilitatingWorks]: "Demolition and Alterations",
  [CostElementReferenceGroupIndex.Substructure]: "Substructure",
  [CostElementReferenceGroupIndex.Superstructure]: "Structure",
  [CostElementReferenceGroupIndex.InternalFinishes]: "Internal Finishes",
  [CostElementReferenceGroupIndex.FittingsFurnitureAndEquipment]: "Fittings and Furniture",
  [CostElementReferenceGroupIndex.Services]: "Services",
  [CostElementReferenceGroupIndex.PrefabricatedBuildingAndBuildingUnits]:
    "Pre-fabricated Components",
  [CostElementReferenceGroupIndex.ExternalWorks]: "External Works",
  [CostElementReferenceGroupItemIndex.FacilitatingWorksSiteClearance]: "Site Clearance",
  [CostElementReferenceGroupItemIndex.Substructure]: "Substructure",
  [CostElementReferenceGroupItemIndex.SuperstructureFrame]: "Frame",
  [CostElementReferenceGroupItemIndex.SuperstructureUpperFloors]: "Upper Floors",
  [CostElementReferenceGroupItemIndex.SuperstructureRoof]: "Roofs",
  [CostElementReferenceGroupItemIndex.SuperstructureStairsAndRamps]: "Stairs",
  [CostElementReferenceGroupItemIndex.SuperstructureExternalWalls]: "External Walls",
  [CostElementReferenceGroupItemIndex.SuperstructureWindowsAndExternalDoors]:
    "Windows and External Doors",
  [CostElementReferenceGroupItemIndex.SuperstructureInternalWallsAndPartitions]:
    "Internal Walls and Partitions",
  [CostElementReferenceGroupItemIndex.SuperstructureInternalDoors]: "Internal Doors",
  [CostElementReferenceGroupItemIndex.InternalFinishesWallFinishes]: "Wall Finishes",
  [CostElementReferenceGroupItemIndex.InternalFinishesFloorFinishes]: "Floor Finishes",
  [CostElementReferenceGroupItemIndex.InternalFinishesCeilingFinishes]: "Ceiling Finishes",
  [CostElementReferenceGroupItemIndex.FittingsFurnitureAndEquipment]: "Fittings and Furniture",
  [CostElementReferenceGroupItemIndex.ServicesSanitaryAppliances]: "Sanitary Appliances",
  [CostElementReferenceGroupItemIndex.ServicesServicesEquipment]: "Service Equipment",
  [CostElementReferenceGroupItemIndex.ServicesDisposalInstallations]: "Disposal Installations",
  [CostElementReferenceGroupItemIndex.ServicesWaterInstallations]: "Water Installations",
  [CostElementReferenceGroupItemIndex.ServicesHeatSource]: "Heat Source",
  [CostElementReferenceGroupItemIndex.ServicesSpaceHeatingAndAirConditioning]:
    "Space Heating and Air Conditioning",
  [CostElementReferenceGroupItemIndex.ServicesVentilation]: "Ventilation Systems",
  [CostElementReferenceGroupItemIndex.ServicesElectricalInstallations]: "Electrical Installations",
  [CostElementReferenceGroupItemIndex.ServicesFuelInstallations]: "Fuel Installations",
  [CostElementReferenceGroupItemIndex.ServicesLiftsAndConveyorInstallations]:
    "Lifts and Escalators",
  [CostElementReferenceGroupItemIndex.ServicesFireAndLightningProtection]:
    "Protective Installations",
  [CostElementReferenceGroupItemIndex.ServicesCommunicationSecurityAndControlSystems]:
    "Fire Alarms, Communications and Security",
  [CostElementReferenceGroupItemIndex.ServicesSpecialistInstallations]: "Specialist Installations",
  [CostElementReferenceGroupItemIndex.ServicesBuildersWorkInConnectionWithServices]:
    "Builders Work in Connection",
  [CostElementReferenceGroupItemIndex.PrefabricatedBuildingAndBuildingUnits]:
    "Prefabricated Buildings And Units",
  [CostElementReferenceGroupItemIndex.PrefabricatedBuildingAndBuildingUnitsOffSitePanelManufactureAndAssembly]:
    "Off Site Panel Manufacture and Assembly",
  [CostElementReferenceGroupItemIndex.PrefabricatedBuildingAndBuildingUnitsOnSiteDeploymentOfModules]:
    "On Site Deployment of Modules",
  [CostElementReferenceGroupItemIndex.ExternalWorksSiteWorks]: "Site Works",
  [CostElementReferenceGroupItemIndex.ExternalWorksRoadsPathsAndPavings]: "Roads Paths And Pavings",
  [CostElementReferenceGroupItemIndex.ExternalWorksSoftLandscapesPlatingAndIrrigation]:
    "Soft Landscapes Planting And Irrigation",
  [CostElementReferenceGroupItemIndex.ExternalWorksFencingRailsAndWalls]: "Fencing Rails And Walls",
  [CostElementReferenceGroupItemIndex.ExternalWorksExternalFixtures]: "External Fixtures",
  [CostElementReferenceGroupItemIndex.ExternalWorksDrainage]: "Drainag",
  [CostElementReferenceGroupItemIndex.ExternalWorksExternalServices]: "External Services",
};
