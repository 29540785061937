import { type V2, type V3 } from "@project-rouge/rg-core";
import { GetVectorDistance, GetVectorDistanceV2 } from "./GetVectorDistance";

export function IsVectorOnVertex(a: V3, b: V3, tolerance: number): boolean {
  if (tolerance === 0) return a[0] === b[0] && a[1] === b[1] && a[2] === b[2];
  const distance = GetVectorDistance(a, b);
  return distance <= tolerance;
}

export function IsVectorOnVertexV2(a: V2, b: V2, tolerance: number): boolean {
  if (tolerance === 0) return a[0] === b[0] && a[1] === b[1];
  const distance = GetVectorDistanceV2(a, b);
  return distance <= tolerance;
}
