import { calculateValuePerMetrics } from "@project-rouge/service-cost-client/data/value-per-metric";
import { Table } from "../Table";
import {
  type CarbonView,
  createCarbonViewForBuilding,
  createCarbonViewForTotal,
} from "@project-rouge/service-cost-client/utility/custom-view";
import { type Unit } from "../UnitSelect";
import type { Building } from "src/types/Building";
import type { World } from "src/types/World";
import type { MetricsCalculations } from "src/hooks/useMetricsCalculationsQuery";

export function SummaryTable(props: {
  selectedBuilding: Building | null;
  metricsCalculations: MetricsCalculations;
  unit: Unit;
  design: World;
}) {
  const selectedBuilding = props.selectedBuilding
    ? createCarbonViewForBuilding(
        props.metricsCalculations,
        props.design.buildings.indexOf(props.selectedBuilding)
      )
    : null;
  const selectedCategory: CarbonView =
    selectedBuilding ?? createCarbonViewForTotal(props.metricsCalculations);

  const summaryTotalValue = selectedCategory.summary.total;

  const unitCount =
    props.selectedBuilding?.metrics.ApartmentModuleQuantityTotal ??
    props.design.architecturalMetrics.ApartmentModuleQuantityTotal;
  const gia =
    props.selectedBuilding?.metrics.GIATotal ?? props.design.architecturalMetrics.GIATotal;
  const summaryTotalValuePerMetric = calculateValuePerMetrics(summaryTotalValue, unitCount, gia);
  const superstructureValue = selectedCategory.summary.superstructure;
  const superstructureValuePerMetric = calculateValuePerMetrics(
    superstructureValue,
    unitCount,
    gia
  );

  const otherFinishesValue = selectedCategory.summary.other;

  const othertotalPerMetric = calculateValuePerMetrics(otherFinishesValue, unitCount, gia);
  return (
    <div>
      <Table.Sticky>
        <Table.Head label="Summary" variant="section" />
        <Table.Row>
          <Table.Head label="" variant="header" position="start" />
          <Table.Head subLabel="CO2" label="footprint" variant="header" position="end" />
          <Table.Head
            subLabel="CO2"
            label={`footprint per ${props.unit}`}
            variant="header"
            position="end"
          />
        </Table.Row>
      </Table.Sticky>
      <Row label="Substructure" variant="cell" info footprint={0} footprintPerMetric={0} />
      <Row
        label="Superstructure"
        variant="cell"
        symbol="kg"
        footprint={superstructureValue}
        footprintPerMetric={
          props.unit === "sqm"
            ? superstructureValuePerMetric.sqm
            : superstructureValuePerMetric.sqft
        }
      />
      <Row
        label="Other (Internal finishes & furniture; Fittings, furnishings & equipment; Services.)"
        variant="cell"
        symbol="kg"
        footprint={otherFinishesValue}
        footprintPerMetric={
          props.unit === "sqm" ? othertotalPerMetric.sqm : othertotalPerMetric.sqft
        }
      />

      <Row
        label="Total"
        variant="subtotal"
        symbol="kg"
        footprint={summaryTotalValue}
        footprintPerMetric={
          props.unit === "sqm" ? summaryTotalValuePerMetric.sqm : summaryTotalValuePerMetric.sqft
        }
      />
    </div>
  );
}

function Row(props: {
  label: string;
  footprint: number;
  footprintPerMetric: number;
  variant: "cell" | "subtotal" | "total";
  symbol?: string;
  info?: boolean;
}) {
  return (
    <Table.Row>
      <Table.Col>
        <Table.Label label={props.label} variant={props.variant} />
      </Table.Col>
      <Table.Col>
        <Table.Cell
          value={props.footprint}
          variant={props.variant}
          symbol={props.symbol}
          info={props.info}
        />
      </Table.Col>
      <Table.Col>
        <Table.Cell
          value={props.footprintPerMetric}
          variant="cell"
          symbol={props.symbol}
          info={props.info}
        />
      </Table.Col>
    </Table.Row>
  );
}
