import {
  type Apartment,
  type RgObject,
  RG_TYPE,
  type TechnicalSpace,
} from "@project-rouge/rg-core";

import { getHorizontalArea } from "@project-rouge/rg-three";
import { observer } from "mobx-react-lite";

export const SvgObjectText = observer<{ obj: RgObject; scale: number }>(({ obj }) => {
  let line1, line2, line3;
  const label = GenerateLabel(obj);
  if (label.split(" ").length === 1) {
    line2 = label;
  } else {
    line1 = label
      .split(" ")
      .slice(0, label.split(" ").length / 2)
      .join(" ");
    line2 = label
      .split(" ")
      .slice(label.split(" ").length / 2, label.split(" ").length)
      .join(" ");
  }
  line3 = getHorizontalArea(obj).toFixed(1) + " sqm";

  return (
    <text
      name={`${obj.name}-label`}
      fontSize={0.6}
      fontWeight={"bold"}
      alignmentBaseline="middle"
      textAnchor="middle"
      style={{ width: "2", textAlign: "center" }}
    >
      <tspan y="-1em" x="0">
        {line1}
      </tspan>
      <tspan y=".0em" x="0">
        {line2}
      </tspan>
      <tspan y="1em" x="0">
        {line3}
      </tspan>
    </text>
  );
});

function GenerateLabel(obj: RgObject): string {
  let label: string = "";
  switch (obj.type) {
    case RG_TYPE.Apartment:
      return (obj as Apartment).data?.category;
    case RG_TYPE.Core:
      return "Core";
    case RG_TYPE.Corridor: {
      label = "Corridor";
      break;
    }
    case RG_TYPE.EmptySpace: {
      label = "Commercial Space";
      break;
    }
    case RG_TYPE.Entrance: {
      label = "Entrance";
      break;
    }
    case RG_TYPE.TechnicalSpace: {
      label = "";
      if (obj.type === RG_TYPE.TechnicalSpace) {
        if (!(obj as TechnicalSpace).data.instance.label)
          return (obj as TechnicalSpace).data.instance.name.split(" ").slice(0, 5).join(" ");
        return (obj as TechnicalSpace).data.instance.label?.split(" ").slice(0, 5).join(" ") ?? "";
      }
      break;
    }
    default: {
      label = "Default";
      break;
    }
  }

  return label;
}
