import { Button, ButtonStyle } from "./Button";
import { Modal } from "./Modal";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useBoolean } from "src/hooks/useBoolean";
import { useWindowEvent } from "src/hooks/useWindowEvent";
import { useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { useImmer } from "use-immer";
import { CircleStackIcon, EnvelopeIcon } from "@heroicons/react/20/solid";
import { useEvent } from "src/hooks/useEvent";
import { IconInfoCircleFilled } from "@tabler/icons-react";
import { useGetUser } from "src/hooks/useGetUser";
import type { User } from "src/types/User";
import type { World } from "src/types/World";
import { useWorldThumbnail } from "src/hooks/useWorldThumbnail";
import { UnitSelector } from "./Inspector/UnitSelector";
import { UnitType } from "../types/UnitType";
import { observer } from "mobx-react-lite";
import { getCountOfType, getRgObjectsOfType } from "@project-rouge/rg-three";
import type { RgBuilding } from "src/types/RgCorePackage";
import { RG_TYPE } from "@project-rouge/rg-core";
export interface ExportOptions {
  drawings:
    | false
    | {
        lables: boolean;
      };
  metrics: false | metricOptions;
  bim: boolean;
  scenarioName: string;
  unit: UnitType;
}
export interface metricOptions {
  design: boolean;
  cost: boolean;
  sustainability: boolean;
}
export const ExportModal = observer(function ExportModal(props: {
  isEditMode: boolean;
  onClose: () => void;
  onExport: (data: { options: ExportOptions; zoneIds: string[]; buildingIds: string[] }) => void;
  world: World;
}) {
  const { zones } = useSelector(props.world);
  const [selectedZoneId, setSelectedZone] = useState("all");
  const [selectedBuildingId, setSelectedBuilding] = useState("all");

  const selectedZone = useMemo(() => {
    const selectedZone = zones.find((z) => z.id === selectedZoneId);
    if (!selectedZone) throw new Error("Zone not found");
    return selectedZone;
  }, [selectedZoneId, zones]);

  const selectedBuildingIdsMap = useMemo(() => {
    if (selectedBuildingId === "all") {
      const [, ...buildings] = selectedZone.buildings;
      return buildings.map((b) => b.id);
    } else {
      return [selectedBuildingId];
    }
  }, [selectedBuildingId, selectedZone.buildings]);

  const thumbnail = useWorldThumbnail(props.world, selectedBuildingIdsMap);
  const [exportOptions, updateExportOptions] = useImmer<ExportOptions>({
    drawings: false,
    metrics: false,
    bim: false,
    scenarioName: props.world.label,
    unit: UnitType.Metric,
  });
  const isLoading = useBoolean();

  const onExport = useEvent(() => {
    isLoading.on();
    const zoneIds = new Set<string>();
    for (const buildingId of selectedBuildingIdsMap) {
      const building = props.world.buildings.find((b) => b.id === buildingId);
      const id = building?.parent?.parent?.id;
      if (!id) continue;
      zoneIds.add(id);
    }
    props.onExport({
      options: exportOptions,
      zoneIds: [...zoneIds.values()],
      buildingIds: selectedBuildingIdsMap,
    });
  });
  const ignoreClose = useRef(false);
  return (
    <Modal.Portal isOpen>
      <div
        className="w-full h-full justify-center flex items-start py-3"
        onMouseUp={() => {
          if (!ignoreClose.current) props.onClose();
          ignoreClose.current = false;
        }}
      >
        <div
          className="inline-flex bg-white rounded-md shadow flex-col max-h-full min-w-[400px] max-w-[400px]"
          onMouseDown={() => (ignoreClose.current = true)}
          onMouseUp={(ev) => {
            ev.stopPropagation();
          }}
        >
          <div className="border-b border-neutral-4 px-4 pt-4 pb-3 space-y-3">
            <Modal.Header label="Export" onClose={props.onClose} />
            <AreaSection
              selectedBuildingsSize={selectedBuildingIdsMap.length}
              zoneList={zones}
              selectedZone={selectedZoneId}
              setSelectedZone={(id) => {
                setSelectedBuilding("all");
                setSelectedZone(id);
              }}
              buildingList={selectedZone.buildings}
              selectedBuilding={selectedBuildingId}
              setSelectedBuilding={setSelectedBuilding}
              imageUri={thumbnail}
            />
          </div>
          <div className="px-4 flex flex-1 flex-col overflow-y-auto overflow-x-hidden pt-4">
            <OptionMetrics options={exportOptions} updateOptions={updateExportOptions} />
            <OptionBim
              selectedBuildingIdsMap={selectedBuildingIdsMap}
              world={props.world}
              options={exportOptions}
              updateOptions={updateExportOptions}
              label={props.world.name}
              isEditMode={props.isEditMode}
            />
          </div>
          <div className="flex flex-1 p-4 space-x-3 justify-end items-center border-t border-neutral-4">
            <button className="px-4 py-1 hover:bg-primary-1 h-full rounded" onClick={props.onClose}>
              Cancel
            </button>
            <Modal.ActionPrimaryButton
              loading={isLoading.isOn}
              onClick={onExport}
              label={props.isEditMode ? "Save & Export" : "Export"}
              disabled={!exportOptions.bim && !exportOptions.drawings && !exportOptions.metrics}
            />
          </div>
        </div>
      </div>
    </Modal.Portal>
  );
});

interface ZoneOption {
  id: string;
  label: string;
  buildings: { id: string; label: string }[];
}

function useSelector(world: World): {
  zones: ZoneOption[];
} {
  const zones = useMemo<ZoneOption[]>(() => {
    const zones: ZoneOption[] = world.zones.map((z) => ({
      id: z.id,
      label: z.label,
      buildings: [
        { id: "all", label: "All buildings" },
        ...world.buildings.filter((b) => b.parent?.parent?.id === z.id),
      ],
    }));
    const allZones: ZoneOption = {
      id: "all",
      label: "All zones",
      buildings: [
        { id: "all", label: "All buildings" },
        ...world.buildings.map((b) => ({ id: b.id, label: b.label })),
      ],
    };
    return [allZones, ...zones];
  }, [world.buildings, world.zones]);
  return { zones };
}

function OptionBim(props: {
  world: World;
  selectedBuildingIdsMap: string[];
  isEditMode: boolean;
  label: string;
  options: ExportOptions;
  updateOptions: (draft: (draft: ExportOptions) => void) => void;
}) {
  const unitsCount = useMemo(() => {
    const selectedBuildingsSet = new Set<string>(props.selectedBuildingIdsMap);
    const rgBuildings = getRgObjectsOfType<RgBuilding>(
      props.world.rgWorld,
      RG_TYPE.Building
    ).filter((b) => selectedBuildingsSet.has(b.uuid));
    return getCountOfType(rgBuildings, RG_TYPE.Apartment);
  }, [props.selectedBuildingIdsMap, props.world.rgWorld]);

  const userQuery = useGetUser();
  const userData = userQuery.data;
  return (
    <div className="py-4">
      <Checkbox
        label="BIM Revit 2021 package"
        suffix="(.rvt)"
        checked={props.options.bim}
        onChange={(value) =>
          props.updateOptions((draft) => {
            draft.bim = value;
          })
        }
      />
      {!!props.options.bim && (
        <div className="flex flex-col flex-1 items-center space-y-3 pl-2 border-l border-neutral-4 mt-2">
          {props.isEditMode && (
            <div className="flex-col flex-1 w-full">
              <div className="text-subtitle-2 text-neutral-8 flex w-full items-center">
                <IconInfoCircleFilled className="w-3 text-neutral-7 mr-[6px]" />
                You must save this design to export
              </div>
              <div className="flex flex-col space-y-1">
                <span className="text-neutral-8 text-caption">Name</span>
                <input
                  className={classNames(
                    // base
                    "rounded-sm ring-neutral-4 ring-[1px] border-0 text-body-1",
                    // hover
                    "hover:ring-[1.5px] hover:ring-primary-6",
                    // focus
                    "focus:ring-primary-6 ring-[1.5px]"
                  )}
                  type="text"
                  value={props.options.scenarioName}
                  onChange={(evt) =>
                    props.updateOptions((draft) => {
                      draft.scenarioName = evt.target.value;
                    })
                  }
                />
              </div>
            </div>
          )}

          <Note intent={NoteIntent.NONE} icon={CircleStackIcon}>
            <div className="flex flex-col flex-1 w-full">
              <span>
                Exporting this file requires{" "}
                <span className="text-subtitle-2">{unitsCount} credits.</span>
                <br />
                You currently have
                <span className="text-subtitle-2"> 500</span> credits.
              </span>
              <span className="text-body-2 text-neutral-6">1 unit = 1 credit.</span>
            </div>
          </Note>
          <Note intent={NoteIntent.NONE} icon={EnvelopeIcon}>
            We will email {hideEmail(userData)} with a link to download your files when they are
            ready.
          </Note>
          {/* <Note intent={NoteIntent.ERROR} icon={ExclamationCircleIcon}>
            <div className="flex flex-col">
              <span className="text-danger-7 text-subtitle-2">You dont have enough credits</span>
              <a className="text-info-6 underline">Contact support</a>
            </div>
          </Note> */}
        </div>
      )}
    </div>
  );
}
function hideEmail(userData: User | null | undefined): string {
  if (!userData) return "";
  const [username, domain] = userData.email.split("@");
  const headerUsername = username.slice(0, 3);
  const hiddenUsername = "*".repeat(username.length - 3);
  const maskedEmail = `${headerUsername}${hiddenUsername}@${domain}`;

  return maskedEmail;
}
const enum NoteIntent {
  NONE = "NONE",
  ERROR = "ERROR",
}
function Note(props: {
  intent: NoteIntent;
  icon: (props: { className: string }) => React.ReactNode;
  children: React.ReactNode;
}) {
  return (
    <div
      className={classNames(
        "flex flex-row flex-1 w-full items-start space-x-2 p-2 rounded-md text-neutral-8 text-body-1",
        props.intent === NoteIntent.NONE && "bg-neutral-2",
        props.intent === NoteIntent.ERROR && "bg-danger-2"
      )}
    >
      <props.icon
        className={classNames(
          "w-4 py-1",
          props.intent === NoteIntent.ERROR && "fill-danger-6",
          props.intent === NoteIntent.NONE && "fill-neutral-7"
        )}
      />
      <div className="flex flex-1 flex-col space-y-2">{props.children}</div>
    </div>
  );
}

const OptionMetrics = observer(function OptionMetrics(props: {
  options: ExportOptions;
  updateOptions: (draft: (draft: ExportOptions) => void) => void;
}) {
  return (
    <div className="pb-4 border-b border-neutral-4">
      <Checkbox
        label="Metrics"
        suffix="(.csv)"
        checked={!!props.options.metrics}
        onChange={(value) =>
          props.updateOptions((draft) => {
            draft.metrics = value ? { design: true, cost: true, sustainability: true } : false;
          })
        }
      />
      {!!props.options.metrics && (
        <div className=" pl-2 border-l border-neutral-4 mt-2 space-y-2">
          <div className="w-[100px]">
            <UnitSelector
              unit={props.options.unit}
              onUnitChange={(value) =>
                props.updateOptions((draft) => {
                  draft.unit = value;
                })
              }
            />
          </div>

          <div className="flex flex-row space-x-4 ">
            <Checkbox
              label="Design"
              suffix=""
              checked={props.options.metrics.design}
              onChange={(value) =>
                props.updateOptions((draft) => {
                  if (!draft.metrics) return;
                  draft.metrics.design = value;
                  if (
                    !draft.metrics.cost &&
                    !draft.metrics.sustainability &&
                    !draft.metrics.design
                  ) {
                    draft.metrics = false;
                  }
                })
              }
            />
            <Checkbox
              label="Cost"
              suffix=""
              checked={props.options.metrics.cost}
              onChange={(value) =>
                props.updateOptions((draft) => {
                  if (!draft.metrics) return;
                  draft.metrics.cost = value;
                  if (
                    !draft.metrics.cost &&
                    !draft.metrics.sustainability &&
                    !draft.metrics.design
                  ) {
                    draft.metrics = false;
                  }
                })
              }
            />
            <Checkbox
              label="Sustainability"
              suffix=""
              checked={props.options.metrics.sustainability}
              onChange={(value) =>
                props.updateOptions((draft) => {
                  if (!draft.metrics) return;
                  draft.metrics.sustainability = value;
                  if (
                    !draft.metrics.cost &&
                    !draft.metrics.sustainability &&
                    !draft.metrics.design
                  ) {
                    draft.metrics = false;
                  }
                })
              }
            />
          </div>
        </div>
      )}
    </div>
  );
});

function Checkbox(props: {
  label: string;
  checked: boolean;
  suffix: string;
  onChange: (checked: boolean) => void;
}) {
  return (
    <div className="flex flex-row items-center space-x-2">
      <input
        type="checkbox"
        checked={props.checked}
        onChange={(evt) => props.onChange(evt.target.checked)}
        className={classNames(
          "rounded",
          props.checked && `text-primary-6`,
          !props.checked && `text-white`
        )}
      />
      <div className="space-x-1">
        <span className="text-body-1 text-neutral-8">{props.label}</span>
        <span className="text-body-2 text-neutral-6">{props.suffix}</span>
      </div>
    </div>
  );
}

const AreaSection = observer(function AreaSection(props: {
  zoneList: Option[];
  selectedZone: string;
  setSelectedZone: (id: string) => void;
  buildingList: Option[];
  selectedBuilding: string;
  setSelectedBuilding: (id: string) => void;
  imageUri: string | null;
  selectedBuildingsSize: number;
}) {
  return (
    <div className="flex flex-col space-y-2">
      <div className="flex flex-1 flex-row justify-between">
        <span className="text-subtitle-2 text-neutral-8">Design option #1</span>
        <span className="text-caption text-neutral-6">
          <span className="text-neutral-8">{props.selectedBuildingsSize}</span> blocks selected
        </span>
      </div>
      <div className="flex flex-row space-x-2 flex-1">
        <DropDown
          options={props.zoneList}
          selected={props.selectedZone}
          onChange={props.setSelectedZone}
        />
        <DropDown
          options={props.buildingList}
          selected={props.selectedBuilding}
          onChange={props.setSelectedBuilding}
        />
      </div>
      <Preview imageUri={props.imageUri} />
    </div>
  );
});

function Preview(props: { imageUri: string | null }) {
  return (
    <div
      className="flex flex-1 h-[112px] min-h-[112px] max-h-[112px] border border-neutral-4 rounded-sm"
      style={{
        backgroundImage: `url("${props.imageUri}")`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    />
  );
}

interface Option {
  label: string;
  id: string;
}

function DropDown(props: { options: Option[]; selected: string; onChange: (id: string) => void }) {
  const label = props.options.find((o) => o.id === props.selected)?.label ?? props.options[0].label;
  const showOptions = useBoolean();
  const options = showOptions.isOn ? (
    <div className="absolute top-0 left-0 bg-white border border-neutral-4 rounded-sm w-full shadow flex flex-col items-start justify-center flex-1">
      {props.options.map((o) => (
        <button
          key={o.id}
          className="py-1 px-4 hover:bg-primary-1 flex flex-1 w-full"
          onClick={(evt) => {
            evt.stopPropagation();
            showOptions.off();
            props.onChange(o.id);
          }}
        >
          {o.label}
        </button>
      ))}
    </div>
  ) : null;
  const rootRef = useRef<HTMLDivElement>(null);
  useWindowEvent("click", (evt) => {
    if (!rootRef.current) return;
    let parent = evt.target as HTMLElement | null | undefined;
    while (parent && parent !== document.body) {
      if (parent === rootRef.current) return;
      parent = parent?.parentElement;
    }
    showOptions.off();
  });
  return (
    <div
      ref={rootRef}
      className="relative h-[32px] flex flex-1 border border-neutral-5 rounded-sm py-[6px] px-2 cursor-pointer"
      onClick={showOptions.on}
    >
      <div className="flex flex-1 flex-row justify-between items-center">
        <span>{label}</span>
        <Button Icon={ChevronDownIcon} style={ButtonStyle.ICON} />
      </div>
      {options}
    </div>
  );
}
