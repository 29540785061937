import { makeAutoObservable } from "mobx";
import { createContext, useContext } from "react";
import type { ToastType } from "src/components/Toast";

export interface Toast {
  text: string;
  type: ToastType;
  autoHide: boolean;
  id: string;
}

export class Toaster {
  toasts: Toast[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  show(toast: Toast) {
    this.toasts.push(toast);
    return () => {
      this.toasts = this.toasts.filter((t) => t !== toast);
    };
  }
  close() {
    this.toasts = [];
  }
}

export const ToasterContext = createContext<Toaster>(null as never);

export const useToaster = () => useContext(ToasterContext);
