import { makeAutoObservable } from "mobx";

export class NumberState {
  value: number;
  constructor(defaultValue = 0) {
    this.value = defaultValue;
    makeAutoObservable(this, {}, { autoBind: true });
  }
  set(value: number) {
    this.value = value;
  }
}
