import { useRedirect } from "src/hooks/useRedirect";
import modulousLogo from "src/assets/tessa-logo.svg";
import modulousFavicon from "src/assets/tessa-favicon.svg";
import classNames from "classnames";
import type { UseBoolean } from "src/hooks/useBoolean";

export function Logo(props: { expand: UseBoolean }) {
  const redirect = useRedirect();
  const logo = props.expand.isOff ? (
    <img className="h-full" src={modulousFavicon} alt="logo" />
  ) : (
    <img className="h-full w-[100px] min-w-[100px]" src={modulousLogo} alt="logo" />
  );
  return (
    <button
      className={classNames(
        "bg-no-repeat bg-contain h-[24px] w-full flex transition-all",
        props.expand.isOn && "px-[8px] justify-start",
        props.expand.isOff && "justify-center"
      )}
      onClick={redirect.home}
    >
      {logo}
    </button>
  );
}
