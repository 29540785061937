import { type Poly, type RgObject, RG_TYPE, type TechnicalSpace } from "@project-rouge/rg-core";
import { get2dRgObjectValues } from "@project-rouge/rg-three";
import { type FC, type SVGAttributes, useMemo } from "react";
import { type Matrix4Tuple } from "three";
import { SvgObjectText } from "./SvgObjectText";
import { GetResidentialAreaColor } from "./StylePresets";
import { observer } from "mobx-react-lite";

interface RgSVGAttributes extends SVGAttributes<SVGRectElement> {
  customFill?: (obj: RgObject) => string;
}

export type SvgStyle = {
  /** showImage is the basic rectangle that represents the basic module */
  showImage: RgSVGAttributes;
  /** showCustom accepts special graphic component, @SvgWindow */
  showCustom?: FC<{ obj: RgObject; scale: number }>;
  /** showText = label text */
  showText?: boolean | FC<{ obj: RgObject; scale: number }>;
  /** used for openings and doors */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  overrideFill?: undefined | Partial<Record<any, SvgStyle>>;
};

interface SvgObjectProps extends SvgStyle {
  obj: RgObject;
  scale: number;
  mx: Matrix4Tuple;
  showLabels: boolean;
  rotate: number;
}

export const SvgObject = observer<SvgObjectProps>(
  ({ obj, scale, showImage, showCustom, showText, overrideFill, mx, showLabels, rotate }) => {
    const { pos, size, rotation, anchor, angle } = useMemo(
      () => get2dRgObjectValues({ obj, mx }),
      [obj, mx]
    );
    const { customFill, ...style } =
      showText && typeof showImage === "boolean" ? { customFill: undefined } : showImage;

    if (!obj) return null;
    let fill;
    if (style && !customFill) fill = style.fill !== undefined ? style.fill : obj.color;
    else if (customFill) {
      fill = customFill(obj);
      if (obj.parent?.type === RG_TYPE.Facade) style.opacity = 0; // give Facade openings white color
    }

    if (obj.type === RG_TYPE.Module && obj.parent && obj.parent.type === RG_TYPE.Apartment) {
      const label = obj.parent.name.slice(-6).slice(0, -2);
      fill = GetResidentialAreaColor(label);
    }

    if (obj.parent && overrideFill) {
      if (obj.parent.type === RG_TYPE.TechnicalSpace) {
        const techSpaceClass: string =
          (obj.parent as TechnicalSpace).data.instance.spaceClass ?? "";
        if (techSpaceClass) {
          if (Object.keys(overrideFill).includes(techSpaceClass))
            fill = overrideFill[techSpaceClass]?.showImage.fill ?? "";
        }
      }
    }
    let polygonPoints: string = "";
    let hasPolygon = false;

    if (obj.data && obj.type !== RG_TYPE.TechnicalSpace && `polygon` in obj.data) {
      const polygon: Poly = obj.data.polygon as Poly;
      hasPolygon = true;
      for (let i = 0; i < polygon.length; i++) {
        polygonPoints += `${polygon[i][0]}","${polygon[i][2]}" "`;
      }
    }

    return (
      <g data-name={obj.name} transform={[`translate(${pos.join(",")})`].join(" ")}>
        {
          <g
            data-name="wrapper"
            transform={[
              `translate(${!hasPolygon ? anchor.join(",") : [0, 0]})`,
              `rotate(${rotation.join(" ")})`,
            ].join(" ")}
          >
            {showImage && customFill && style && !hasPolygon && (
              <rect width={size[0]} height={size[1]} opacity={1} fill={"white"} /> // Background color as base in order to overlay the fill color properly
            )}

            {showImage && style && !hasPolygon && (
              <rect width={size[0]} height={size[1]} {...style} fill={fill} />
            )}

            {showImage && style && hasPolygon && <polygon points={polygonPoints} fill={fill} />}

            {showCustom && (
              <g
                data-name="center"
                transform={[
                  `translate(${size[0] * 0.5},${size[1] * 0.5})`,
                  `rotate(${Math.abs(angle % 360) > 90 ? 180 : 0})`,
                ].join(",")}
              >
                {showCustom && showCustom({ obj, scale })}
              </g>
            )}
            {showLabels && showText && (
              <g
                data-name="center"
                transform={[
                  `translate(${size[0] * 0.5},${size[1] * 0.5})`,
                  `rotate(${Math.abs((angle + rotate) % 360) > 90 ? 180 : 0})`,
                ].join(",")}
              >
                {showText && (
                  <>
                    {typeof showText === "boolean" && <SvgObjectText obj={obj} scale={scale} />}
                    {typeof showText !== "boolean" && showText({ obj, scale })}
                  </>
                )}
              </g>
            )}
          </g>
        }
      </g>
    );
  }
);
