import { RG_TYPE } from "@project-rouge/rg-core";
import type { RgBuilding } from "./RgCorePackage";

export function GetAreaPerApartmentType(buildings: RgBuilding[]): number[] {
  type ApartmentName = string;
  const mix = new Map<ApartmentName, { name: string; apartmentNia: number }>();

  for (const building of buildings) {
    for (const child of building.children) {
      if (child.type === RG_TYPE.Apartment)
        mix.set(child.name, {
          name: child.name,
          apartmentNia: child.data.nia,
        });
    }
  }
  return [...mix.values()]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ apartmentNia }) => apartmentNia);
}
