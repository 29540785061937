import { createContext, useEffect, useState } from "react";
import { SiteImageManager } from "./SiteImageManager";

type UseSnapshotService = SiteImageManager;

export function useSnapshotService(): UseSnapshotService {
  const [siteImageManager] = useState(() => new SiteImageManager());
  useEffect(() => () => siteImageManager.destroy(), [siteImageManager]);
  return siteImageManager;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SnapshotService = createContext<UseSnapshotService>(null as any);
