import { usePixelToMeterDistance } from "src/hooks/useSnapMouse";
import { IsVectorOnVertex } from "src/utils/IsVectorOnVertex";
import { type V3 } from "@project-rouge/rg-core";
import { Line } from "@react-three/drei";
import { Vector3 } from "three";
import { observer } from "mobx-react-lite";

const EDGE_POS_Y = 0.02;

export const PolygonEdges = observer(
  (props: {
    points: V3[];
    offsetPx?: number;
    lineWidthPx?: number;
    color?: number;
    opacity?: number;
    dashed?: boolean;
    dashSizePx?: number;
    gapSizePx?: number;
    depthTest?: boolean;
    y?: number;
  }) => {
    const dashSize = usePixelToMeterDistance(props.dashSizePx ?? 0);
    const gapSize = usePixelToMeterDistance(props.gapSizePx ?? 0);
    const offset = usePixelToMeterDistance(props.offsetPx ?? 0);
    const lines = getLines(props.points, offset);
    const depthTest = props.depthTest ?? false;
    const position = getPosition(props.y);
    return (
      <group position={position}>
        {lines.map((points, i) => (
          <Line
            key={i}
            points={points}
            opacity={props.opacity}
            lineWidth={props.lineWidthPx}
            color={props.color}
            depthTest={depthTest}
            dashed={props.dashed}
            dashSize={dashSize}
            gapSize={gapSize}
          />
        ))}
      </group>
    );
  }
);

function getPosition(y: number = EDGE_POS_Y) {
  return [0, y, 0] as V3;
}

function getLines(points: V3[], offset: number): [V3, V3][] {
  const lines: [V3, V3][] = [];
  for (let i = 0; i < points.length - 1; i++) {
    const start = points[i];
    const end = points[i + 1];
    if (IsVectorOnVertex(start, end, offset)) continue;
    const a = Lerp(start, end, offset);
    const b = Lerp(end, start, offset);
    lines.push([a, b]);
  }
  return lines;
}

function Lerp(start: V3, end: V3, offsetFromStart: number) {
  const startVector = new Vector3(...start);
  const endVector = new Vector3(...end);
  startVector.lerp(endVector, offsetFromStart / startVector.distanceTo(endVector));
  return startVector.toArray();
}
