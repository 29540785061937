import { useQuery } from "@tanstack/react-query";
import { useApi } from "src/contexts/api.context";

/** @deprecated - use `useActiveParams()` */
export function useActiveScenarioId(projectId: string) {
  const api = useApi();

  const project = useQuery({
    queryKey: ["new", projectId],
    enabled: !!projectId,
    queryFn: async () => {
      if (!projectId) return null;
      return await api.getProjectSiteCollection({ projectId });
    },
  });
  return project.data?.results.at(0)?.id;
}

export const GetScenarioQueryKey = (scenarioId: string | undefined) => [
  "scenario-query",
  scenarioId,
];
