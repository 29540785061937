import type { RgZoneBrief } from "@project-rouge/rg-core";
import { makeAutoObservable } from "mobx";
import type { BeZoneBrief } from "./BeZoneBrief";

export interface PodiumBriefConfig {
  floorCount: number;
  height: number;
  type: "commercial";
}

export class PodiumBrief {
  floorCount: number;
  height: number;
  type: "commercial";
  constructor(podium?: Partial<PodiumBriefConfig>) {
    this.floorCount = podium?.floorCount ?? 1;
    this.height = podium?.height ?? 4;
    this.type = "commercial";
    makeAutoObservable(this, {}, { autoBind: true });
  }
  get rgPodium(): RgZoneBrief["podium"] {
    return {
      replaceLevels: this.floorCount,
      levelHeight: this.height,
    };
  }
  get config(): PodiumBriefConfig {
    return {
      floorCount: this.floorCount,
      height: this.height,
      type: this.type,
    };
  }
  get bePodiumBrief(): BeZoneBrief["podium"] {
    return {
      floor_count: this.floorCount,
      height: this.height,
      type: this.type,
    };
  }
  setFloorCount(value: number) {
    this.floorCount = Math.max(value, 1);
  }
  setHeight(value: number) {
    this.height = value;
  }
}
