import { observer } from "mobx-react-lite";
import { useEvent } from "src/hooks/useEvent";
import { TableContentType } from "src/components/ExplorePanel/TableContentType";
import { TableBody } from "./TableBody";
import { TableLoadingCell } from "./TableLoadingCell";
import { TableRow } from "./TableRow";
import type { RowData } from "./RowData";
import type { WorkspaceConfig } from "src/hooks/useWorkspaceQuery";
import { CURRENT_WORKSPACE_VERSION, WorkspaceQueryKey } from "src/hooks/useWorkspaceQuery";
import { useQueryClient } from "@tanstack/react-query";
import { useApi } from "src/contexts/api.context";
import type { Editor } from "src/types/Editor";
import { useMetricsCalculationsQuery } from "src/hooks/useMetricsCalculationsQuery";
import { TableStore } from "./TableStore";
import { useWorldThumbnail } from "src/hooks/useWorldThumbnail";
import type {
  CarbonView,
  CostingView,
} from "@project-rouge/service-cost-client/utility/custom-view";
import {
  createCarbonViewForTotal,
  createCostingViewForTotal,
} from "@project-rouge/service-cost-client/utility/custom-view";
import { ColumnsGroup } from "./ColumnsGroup";
import type { ReactNode } from "react";
import { toJS } from "mobx";

interface Props {
  workspaceConfig: WorkspaceConfig;
  editor: Editor;
  table: TableStore;
}

export const Table = observer(function Table(props: Props) {
  const queryClient = useQueryClient();
  const cavnasRowData = useCanvasRowData(props.editor);
  const api = useApi();

  const putTableConfig = useEvent(async () => {
    const workspace: WorkspaceConfig = {
      ...props.workspaceConfig,
      explorePanel: toJS(props.table.config),
      version: CURRENT_WORKSPACE_VERSION,
    };
    queryClient.setQueryData(WorkspaceQueryKey, () => workspace);
    await api.postWorkspace(workspace);
    queryClient.invalidateQueries(WorkspaceQueryKey);
  });

  if (!cavnasRowData) return null;

  return (
    <div className="overflow-auto w-full flex flex-col relative">
      <div className="flex w-full">
        <PinColumns>
          <ColumnsGroup
            columns={props.table.pinnedColumns}
            table={props.table}
            canvasRowData={cavnasRowData}
            onConfigSubmit={putTableConfig}
          />
        </PinColumns>
        <ColumnsGroup
          columns={props.table.unpinnedColumns}
          table={props.table}
          canvasRowData={cavnasRowData}
          onConfigSubmit={putTableConfig}
        />
      </div>
      <LoadingRows table={props.table} />
    </div>
  );
});

const PinColumns = observer(function PinColumns(props: { children: ReactNode }) {
  return (
    <div className="border-r border-neutral-4 sticky left-0 flex z-[11]">{props.children}</div>
  );
});

const LoadingRows = observer(function LoadingRows(props: { table: TableStore }) {
  return (
    <TableBody>
      {new Array(props.table.loadingRowsSize).fill(null).map((_, index) => {
        return (
          <TableRow key={index} type={TableContentType.Body} rowId={`${index}`}>
            <TableLoadingCell />
          </TableRow>
        );
      })}
    </TableBody>
  );
});

function useCanvasRowData(editor: Editor): RowData | null {
  const calculation = useMetricsCalculationsQuery(editor.projectId, editor.world);
  const thumbnail = useWorldThumbnail(editor.world) ?? "";
  if (!editor.world) return null;
  const costingView: CostingView = calculation.data
    ? createCostingViewForTotal(calculation.data)
    : {
        element: {
          modular: [],
          basebuild: [],
        },
        summary: {
          construction: 0,
          constructionBaseBuild: 0,
          constructionFitOut: 0,
          constructionModules: 0,
          contingencies: 0,
          overheadsAndProfits: 0,
          preliminaries: 0,
          total: 0,
        },
      };
  const sustainabilityView: CarbonView = calculation.data
    ? createCarbonViewForTotal(calculation.data)
    : { element: [], summary: { total: 0, substructure: 0, other: 0, superstructure: 0 } };
  if (!costingView || !sustainabilityView) return null;
  const row = TableStore.createRowData(
    -1,
    {
      architecturalMetrics: editor.world.architecturalMetrics,
      costMetrics: costingView,
      sustainabilityMetrics: sustainabilityView,
      thumbnail,
      rgWorldHashKey: editor.world.rgWorldHashKey,
      rgWorldZip: new Uint8Array(),
    },
    "Canvas"
  );
  return row;
}
