import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Button, ButtonIntent, ButtonState } from "src/components/Button";
import { observer } from "mobx-react-lite";

interface Props {
  onClick: () => void;
}

export const SearchButton = observer((props: Props) => {
  return (
    <div>
      <div className="rounded-[0.45rem] shadow-sm">
        <Button
          Icon={MagnifyingGlassIcon}
          onClick={() => {
            props.onClick();
          }}
          intent={ButtonIntent.NONE}
          state={ButtonState.NONE}
        />
      </div>
    </div>
  );
});
