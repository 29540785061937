// export function MathSum(numbs: number[]): number;
// export function MathSum(...nums: number[]): number;
// export function MathSum(...args: unknown[]): number {
//   const nums = Array.isArray(args[0]) ? args[0] : args;
//   // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
//   return nums.reduce((c, v) => c + v, 0);
// }

export function MathSum(numbs: number[]): number;
export function MathSum(...nums: number[]): number;
export function MathSum(...args: unknown[]): number {
  const nums = Array.isArray(args[0]) ? args[0] : args;
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  const decimalPlaces = Math.max(...nums.map(countDecimalPlaces));
  if (decimalPlaces === 0) return nums.reduce((c, v) => c + v, 0);
  return nums.reduce((c, v) => c + v * 10 * decimalPlaces, 0) / (10 * decimalPlaces);
}

function countDecimalPlaces(number: number) {
  if (Math.floor(number) === number) {
    return 0; // No decimal places if it's an integer
  }

  const exponent = Math.floor(Math.log10(Math.abs(number)));
  return Math.max(0, -exponent);
}
