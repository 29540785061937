import { useMemo, useRef, useState } from "react";
import { LinkIcon } from "@heroicons/react/24/outline";
import { useEvent } from "src/hooks/useEvent";
import { Cluster } from "src/components/Cluster";
import classNames from "classnames";

export function OffsetInput(props: {
  value: number;
  enabledEvents: boolean;
  disabled?: boolean;
  synced: boolean;
  onChange?: (value: number) => void;
  onSyncClick: () => void;
}) {
  const { value, enabledEvents, disabled, onChange, synced, onSyncClick } = props;
  const [draftOffset, setDraftOffset] = useState<string | null>(null);
  const submitOffset = useEvent((rawValue: string) => {
    const value = Number(rawValue);
    if (isNaN(value)) {
      setDraftOffset(null);
    } else {
      const updatedValue = parseFloat(value.toFixed(1));
      setDraftOffset(null);
      onChange?.(Math.max(updatedValue, 0));
    }
  });
  // const offset = draftOffset ?? value;
  const inputRef = useRef<HTMLInputElement>(null);
  const onInputKeyDown = useEvent((e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "ArrowUp": {
        e.stopPropagation();
        e.preventDefault();
        const increment = e.shiftKey ? 10 : 1;
        const nextValue = (parseFloat(draftOffset ?? value.toString()) + increment).toString();
        return submitOffset(nextValue);
      }
      case "ArrowDown": {
        e.stopPropagation();
        e.preventDefault();
        const increment = e.shiftKey ? 10 : 1;
        const nextValue = (parseFloat(draftOffset ?? value.toString()) - increment).toString();
        return submitOffset(nextValue);
      }
      case "Enter": {
        e.stopPropagation();
        e.preventDefault();
        if (!draftOffset) return;
        return submitOffset(draftOffset);
      }
    }
  });
  const handleChange = useEvent((e: React.ChangeEvent<HTMLInputElement>) => {
    setDraftOffset(e.target.value);
  });
  const offsetValue = useMemo(() => {
    if (draftOffset !== null) return draftOffset;
    return value.toFixed(1);
  }, [draftOffset, value]);

  return (
    <div
      className="relative top-[-12px]"
      style={{ pointerEvents: enabledEvents && !disabled ? "all" : "none" }}
    >
      <div
        className={classNames(
          "absolute bottom-1 -translate-x-1/2 shadow-md flex",
          "border border-neutral-5 rounded-sm bg-white"
        )}
      >
        <input
          ref={inputRef}
          className={classNames(
            disabled && "bg-neutral-2 text-neutral-6",
            !disabled && "text-neutral-8",
            // base
            "ring-transparent",
            "rounded-sm text-body-1 text-end pr-10 w-20",
            // focus
            "focus:ring-primary-6 ring-[1px] focus-visible:outline-none"
          )}
          value={offsetValue}
          onChange={handleChange}
          onBlur={() => {
            if (!draftOffset) return;
            submitOffset(draftOffset);
          }}
          onKeyDown={onInputKeyDown}
        />
        <span className="absolute right-4 flex items-center pr-2 text-body-1 text-neutral-6">
          m
        </span>
        <div
          className={classNames(
            !disabled && synced && "bg-primary-6",
            !disabled && !synced && "bg-neutral-3 text-neutral-6",
            disabled && "bg-transparent",
            "p-1 cursor-pointer rounded-r-sm",
            "absolute right-0 flex items-cente"
          )}
          onClick={onSyncClick}
        >
          <div className="w-3">
            <Cluster visible={!disabled}>
              <LinkIcon
                className={classNames(
                  synced && "bg-primary-6 text-white",
                  !synced && "bg-neutral-3 text-neutral-6"
                )}
              />
            </Cluster>
          </div>
        </div>
      </div>
    </div>
  );
}
