import { type PropsWithChildren } from "react";
import { Button } from "src/components/Button";
import { Cluster } from "src/components/Cluster";

export function Group(props: PropsWithChildren<{ visible?: boolean }>) {
  return (
    <Cluster visible={props.visible ?? true}>
      <Button.Group vertical>{props.children}</Button.Group>
    </Cluster>
  );
}
