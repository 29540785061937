import { type V2, type V3 } from "@project-rouge/rg-core";
import { lineString, rewind } from "@turf/turf";
import { V3ToV2 } from "src/utils/V3ToV2";
import { V2ToV3 } from "src/utils/V2ToV3";

export function RewindPolygonAnticlockwise(polygon: V3[]): V3[] {
  return V2ToV3(
    rewind(lineString(V3ToV2(polygon)), { reverse: true }).geometry.coordinates as V2[]
  );
}
