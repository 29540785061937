import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import classNames from "classnames";
import type { FunctionComponent } from "react";
import { useState } from "react";
import { UnitType } from "../../types/UnitType";

type UnitSelectorProps = {
  unit: string;
  onUnitChange: (selectedUnit: UnitType) => void;
};

export const UnitSelector: FunctionComponent<UnitSelectorProps> = ({ unit, onUnitChange }) => {
  const [hidden, isHidden] = useState<boolean>(true);

  return (
    <div className="flex items-center w-full">
      <div className="relative inline-block w-full">
        <button
          className="flex items-center justify-between px-2 w-full h-6 rounded-sm border-neutral-5 border bg-white text-sm text-neutral-8"
          onClick={hidden === true ? () => isHidden(false) : () => isHidden(true)}
        >
          {unit === "Metric" ? "Metric" : "Imperial"}
          {!hidden ? (
            <IconChevronUp className="w-4 text-neutral-7" />
          ) : (
            <IconChevronDown className="w-4 text-neutral-7" />
          )}
        </button>
        <button
          className={classNames(
            hidden === true && "hidden",
            hidden === false && "block",
            "z-[999] absolute w-full text-left px-2 h-6 border-neutral-4 border bg-white text-body-1 hover:bg-neutral-4 text-neutral-8"
          )}
          onClick={() => {
            hidden ? isHidden(false) : isHidden(true);
            unit === "Metric" ? onUnitChange(UnitType.Imperial) : onUnitChange(UnitType.Metric);
          }}
        >
          {unit === "Metric" ? "Imperial" : "Metric"}
        </button>
      </div>
    </div>
  );
};
