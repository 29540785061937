import { type V3 } from "@project-rouge/rg-core";
import { IsVectorOnVertex } from "./IsVectorOnVertex";

export function MergePolygonContinousVertexes(polygon: V3[], tolerance: number): V3[] {
  const points: V3[] = [];
  for (const point of polygon) {
    if (points.length === 0) {
      points.push(point);
      continue;
    }
    const lastPoint = points[points.length - 1];
    const isNear = IsVectorOnVertex(lastPoint, point, tolerance);
    if (!isNear) points.push(point);
  }
  points[points.length - 1] = points[0];
  return points;
}
