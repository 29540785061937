import type { Api, BeScenarioWithAggDesignDtoZip } from "src/types/Api";
import type { GeneratedItem } from "src/types/GeneratedItem";
import type { RgWorld } from "./RgCorePackage";
import { makeAutoObservable, reaction, runInAction, toJS } from "mobx";
import { DesignsGenerator } from "./DesignsGenerator";
import { CreateBrief } from "src/utils/CreateBrief";
import { AutoSave } from "./AutoSave";
import { View } from "./View";
import { History } from "./History";
import { WorldLoader } from "./WorldLoader";
import type { World } from "./World";
import { MapState } from "./MapState";
export class Editor {
  private readonly history = new History();
  private lastModifiedZoneBrief = CreateBrief();
  readonly designGenerator: DesignsGenerator;
  readonly autoSave: AutoSave;
  readonly projectId: string;
  readonly scenarioId: string;
  readonly view: View;
  readonly loader = new WorldLoader();
  readonly mapState = new MapState(null);

  private onDestroyListeners: (() => void)[] = [];

  constructor(api: Api, projectId: string, scenarioId: string) {
    this.projectId = projectId;
    this.scenarioId = scenarioId;
    this.designGenerator = new DesignsGenerator({
      projectId,
      listSize: 10,
      tokenActor: api.actor,
      tokenAdmin: api.admin ?? null,
    });
    this.autoSave = new AutoSave(this.projectId, this.scenarioId, api);
    this.view = new View(null);
    makeAutoObservable(this, {}, { autoBind: true });
    // watch for world change and update selection
    this.onDestroyListeners.push(
      reaction(
        () => this.world,
        (world) => this.view.selection.setWorld(world)
      )
    );
    // watch the world and auto save
    this.onDestroyListeners.push(
      reaction(
        () => this.world?.config,
        (curr, prev) => !!prev && this.world && this.autoSave.save(this.world)
      )
    );
    // watch the world and update map state
    this.onDestroyListeners.push(
      reaction(
        () => this.world,
        (world) => this.mapState.setWorld(world)
      )
    );
  }

  get world() {
    return this.history.world;
  }

  destory() {
    this.history.destory();
    this.onDestroyListeners.forEach((cb) => cb());
    this.onDestroyListeners = [];
  }

  async loadFromWorld(world: World) {
    runInAction(() => {
      this.history.setActiveEntry(world);
      this.designGenerator.generate(world);
    });
  }

  async loadFromRgWorld(rgWorld: RgWorld) {
    const world = await this.loader.loadFromRgWorld(rgWorld, this.world);
    runInAction(() => {
      this.history.setActiveEntry(world);
      this.designGenerator.generate(world);
    });
  }

  async loadFromDesignGenerator(item: GeneratedItem) {
    const world = await this.loader.loadFromDesignGenerator(item, this.world);
    runInAction(() => {
      this.history.setActiveEntry(world);
    });
  }

  async loadFromScenarioWithDesign(item: BeScenarioWithAggDesignDtoZip) {
    const world = await this.loader.loadFromScenarioWithDesign(item);
    runInAction(() => {
      this.history.setActiveEntry(world);
      this.designGenerator.generate(world);
    });
  }

  private regenerateId = 0;
  /**
   * run generator against current world and autoselect the first result
   */
  async regenerate(autoSelect: boolean) {
    const id = this.regenerateId;
    this.regenerateId = id;
    if (autoSelect) {
      const dereg = reaction(
        () => this.designGenerator.list.at(0),
        (curr, prev) => {
          if (!curr) {
            dereg();
            return;
          }
          if (!prev && curr) {
            if (id === this.regenerateId) this.loadFromDesignGenerator(curr);
            dereg();
            return;
          }
        }
      );
    }
    this.designGenerator.generate(this.world);
  }

  get hasHistoryBack() {
    return this.history.hasHistoryBack;
  }

  get hasHistoryForward() {
    return this.history.hasHistoryForward;
  }

  async historyBack() {
    await this.history.historyBack();
    this.regenerate(false);
  }

  async historyForward() {
    await this.history.historyForward();
    this.regenerate(false);
  }

  get defaultBrief() {
    return structuredClone(toJS(this.lastModifiedZoneBrief));
  }
}
