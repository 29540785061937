import { type V3 } from "@project-rouge/rg-core";
import { Line } from "@react-three/drei";
import { type ThreeEvent } from "@react-three/fiber";
import { useMemo } from "react";
import { usePixelToMeterDistance } from "src/hooks/useSnapMouse";
import { MathUtils, Shape, Vector2, DoubleSide } from "three";

export const PolygonVertexSquare = (props: {
  sizePx: number;
  clickTargetSizePx?: number;
  borderWidthPx?: number;
  borderColor?: number;
  position: V3;
  fillColor: number | `#${string}`;
  opacity: number;
  y: number;
  rotation: number;
  onPointerEnter?: (evt: ThreeEvent<PointerEvent>) => void;
  onPointerLeave?: (evt: ThreeEvent<PointerEvent>) => void;
  onPointerDown?: (event: ThreeEvent<PointerEvent>) => void;
}) => {
  const size = usePixelToMeterDistance(props.sizePx);
  const clickTargetSize = usePixelToMeterDistance(props.clickTargetSizePx ?? 0);
  const points = useMemo(
    () => [
      new Vector2(-size / 2, -size / 2),
      new Vector2(size / 2, -size / 2),
      new Vector2(size / 2, size / 2),
      new Vector2(-size / 2, size / 2),
      new Vector2(-size / 2, -size / 2),
    ],
    [size]
  );
  const shape = useMemo(() => {
    return new Shape(points);
  }, [points]);

  const args = useMemo(() => [shape] as [Shape], [shape]);
  const position = useMemo(
    () => [props.position[0], props.y, props.position[2]] as V3,
    [props.position, props.y]
  );
  const rotation = useMemo(
    () => [MathUtils.DEG2RAD * 90, 0, props.rotation] as V3,
    [props.rotation]
  );

  return (
    <group
      rotation={rotation}
      position={position}
      onPointerEnter={props.onPointerEnter}
      onPointerLeave={props.onPointerLeave}
      onPointerDown={props.onPointerDown}
    >
      <mesh>
        <shapeGeometry attach="geometry" args={args} />
        <meshBasicMaterial
          color={props.fillColor}
          side={DoubleSide}
          opacity={props.opacity}
          transparent={props.opacity !== 1}
          toneMapped={false}
          depthTest={true}
        />
      </mesh>
      <mesh position={[0, 0, -0.001]}>
        <planeGeometry attach="geometry" args={[clickTargetSize, clickTargetSize]}></planeGeometry>
        <meshBasicMaterial
          color={0x000000}
          side={DoubleSide}
          opacity={0}
          transparent={true}
          toneMapped={false}
          depthTest={false}
        />
      </mesh>
      {props.borderWidthPx && (
        <group position={[0, 0, props.y - 0.03]}>
          <Line
            points={points}
            opacity={props.opacity}
            lineWidth={props.borderWidthPx}
            color={props.borderColor}
            depthTest={true}
          />
        </group>
      )}
    </group>
  );
};
