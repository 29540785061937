import type { RgZoneBrief } from "@project-rouge/rg-core";
import { makeAutoObservable, toJS } from "mobx";
import type { BeZoneBrief } from "./BeZoneBrief";
import type { Zone } from "./Zone";
import type { PodiumBriefConfig } from "./PodiumBrief";
import { PodiumBrief } from "./PodiumBrief";
import type { UnitMixBriefConfig } from "./UnitMixBrief";
import { UnitMixBrief } from "./UnitMixBrief";

interface Range {
  minimum: number;
  maximum: number;
}

export interface ZoneBriefConfig {
  buildingCount: number;
  buildingHeight: Range;
  residentialFloorCount: number;
  clearInternalHeight: number;
  unitMix: UnitMixBriefConfig;
  podium: null | PodiumBriefConfig;
}

export class ZoneBrief {
  static readonly roofHeight = 1;
  static readonly residentialFloorHeight = 3;
  static readonly maximumFloorCount = 25;
  buildingCount: number;
  buildingHeight: Range;
  residentialFloorCount: number;
  clearInternalHeight: number;
  unitMix: UnitMixBrief;
  podium: null | PodiumBrief;
  private zone: Zone;

  constructor(zone: Zone, config?: Partial<ZoneBriefConfig>) {
    this.zone = zone;
    this.buildingCount = config?.buildingCount ?? 100;
    this.buildingHeight = { minimum: 0, maximum: 100 };
    this.residentialFloorCount = config?.residentialFloorCount ?? 6;
    this.clearInternalHeight = 2.45;
    this.unitMix = new UnitMixBrief(config?.unitMix);
    this.podium = config?.podium ? new PodiumBrief(config?.podium) : null;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get locked() {
    return this.zone.locked;
  }

  get rgZoneBrief(): RgZoneBrief {
    return {
      buildings: this.buildingCount,
      floors: this.residentialFloorCount + this.podiumFloorCount,
      frozen: this.locked,
      unitMix: this.unitMix.rgUnitMix,
      technicalSpaces: {},
      podium: this.podium?.rgPodium,
    };
  }

  get beZoneBrief(): BeZoneBrief {
    return {
      building_count: this.buildingCount,
      building_height: this.buildingHeight,
      clear_internal_height: this.clearInternalHeight,
      podium: this.podium?.bePodiumBrief ?? null,
      residential_floor_count: this.residentialFloorCount,
      unit_category_mix: this.unitMix.beUnitMix,
    };
  }

  get config(): ZoneBriefConfig {
    return {
      buildingCount: this.buildingCount,
      buildingHeight: toJS(this.buildingHeight),
      clearInternalHeight: this.clearInternalHeight,
      residentialFloorCount: this.residentialFloorCount,
      unitMix: this.unitMix.config,
      podium: this.podium?.config ?? null,
    };
  }

  get totalBuildingHeight(): number {
    const podiumHeight = this.podium?.height || 0;
    const podiumFloorCount = this.podiumFloorCount;
    const residentialFloorCount = this.residentialFloorCount;
    if (!podiumFloorCount && !residentialFloorCount) return 0;
    return (
      podiumHeight * podiumFloorCount +
      ZoneBrief.residentialFloorHeight * residentialFloorCount +
      ZoneBrief.roofHeight
    );
  }

  get totalFloorCount(): number {
    return this.residentialFloorCount + this.podiumFloorCount;
  }

  get podiumFloorCount(): number {
    return this.podium?.floorCount ?? 0;
  }

  setResidentialFloorCount(value: number) {
    this.residentialFloorCount = Math.min(value, ZoneBrief.maximumFloorCount);
  }

  togglePodium() {
    this.podium = this.podium ? null : new PodiumBrief();
  }
  setUnitMix(unitMix: UnitMixBrief) {
    this.unitMix = unitMix;
  }
}
