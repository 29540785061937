import { FloatingPortal } from "@floating-ui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useLayoutEffect, useState } from "react";
import { CostMetricsSection } from "./CostMetrics/CostMetricsSection";
import { DesignMetricsSection } from "./DesignMetrics/DesignMetricsSection";
import { SustainabilityMetricsSection } from "./SustainabilityMetrics/SustainabilityMetricsSection";
import { useActiveParams } from "src/hooks/useActiveParams";
import type { World } from "src/types/World";

export const MetricsDrawer = observer(function MetricsDrawer(props: {
  world: World;
  onClose: () => void;
}) {
  return (
    <FloatingPortal>
      <Background onClick={props.onClose}>
        <Card>
          <Header onClose={props.onClose} world={props.world} />
          <Tabs scenario={props.world} />
        </Card>
      </Background>
    </FloatingPortal>
  );
});

const enum TabType {
  DESIGN = "design",
  COST = "cost",
  SUSTAINABILITY = "sustainability",
}

function Tabs(props: { scenario: World }) {
  const [selectedTab, setSelectedTab] = useState<TabType>(TabType.DESIGN);
  const projectId = useActiveParams().projectId ?? "";
  return (
    <div className="flex flex-col overflow-hidden">
      <div className="flex flex-row">
        <Tab
          label="Design"
          onClick={() => setSelectedTab(TabType.DESIGN)}
          selected={selectedTab === TabType.DESIGN}
        />
        <Tab
          label="Cost"
          onClick={() => setSelectedTab(TabType.COST)}
          selected={selectedTab === TabType.COST}
        />
        <Tab
          label="Sustainability"
          onClick={() => setSelectedTab(TabType.SUSTAINABILITY)}
          selected={selectedTab === TabType.SUSTAINABILITY}
        />
        <div className="border-b-2 border-neutral-5 flex-1" />
      </div>
      <div className="overflow-hidden">
        {selectedTab === TabType.DESIGN && <DesignMetricsSection design={props.scenario} />}
        {selectedTab === TabType.COST && (
          <CostMetricsSection design={props.scenario} projectId={projectId} />
        )}
        {selectedTab === TabType.SUSTAINABILITY && (
          <SustainabilityMetricsSection design={props.scenario} />
        )}
      </div>
    </div>
  );
}

function Tab(props: { label: string; onClick: () => void; selected: boolean }) {
  return (
    <button
      className={classNames(
        "px-4 border-b-2 border-neutral-5 py-2 text-button text-neutral-6",
        props.selected && "border-primary-7 bg-primary-3 text-primary-7",
        !props.selected && "hover:bg-primary-1"
      )}
      onClick={props.onClick}
    >
      {props.label}
    </button>
  );
}

function Card(props: { children: React.ReactNode }) {
  const [isMounted, setIsMounted] = useState(false);
  useLayoutEffect(() => {
    const int = setTimeout(() => setIsMounted(true), 0);
    return () => clearTimeout(int);
  }, []);
  const width = 704;
  const right = isMounted ? 0 : -width;
  return (
    <div
      style={{ width, right }}
      className="bg-white h-full absolute flex flex-col transition-all duration-300"
    >
      {props.children}
    </div>
  );
}

function Background(props: { children: React.ReactNode; onClick: () => void }) {
  const [isMounted, setIsMounted] = useState(false);
  useLayoutEffect(() => {
    const int = setTimeout(() => setIsMounted(true), 0);
    return () => clearTimeout(int);
  }, []);
  const opacity = isMounted ? 0.3 : 0;
  return (
    <div
      onClick={props.onClick}
      style={{ backgroundColor: `rgba(0,0,0, ${opacity})` }}
      className="w-full h-full transition-all fixed top-0 left-0 z-50"
    >
      <div
        onClick={(evt) => {
          evt.stopPropagation();
        }}
      >
        {props.children}
      </div>
    </div>
  );
}

function Header(props: { world: World; onClose: () => void }) {
  return (
    <div className="flex flex-row justify-between px-3 items-center py-3">
      <div className="flex flex-row space-x-2 items-center">
        <span className="text-subtitle-1 text-neutral-8">Metrics</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
          <circle cx="2" cy="2" r="2" fill="#AFB7BF" />
        </svg>
        <span>{props.world.label}</span>
      </div>
      <XMarkIcon className="w-5 text-neutral-8 cursor-pointer" onClick={props.onClose} />
    </div>
  );
}
