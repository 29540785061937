import { type PropsWithChildren } from "react";

export function ListLoader({ children, loading }: PropsWithChildren<{ loading: boolean }>) {
  if (loading)
    return (
      <div className="w-full h-full flex flex-1 items-start p-4">
        <div className="w-full h-2 bg-black/10 animate-pulse rounded-full" />
      </div>
    );
  return <>{children}</>;
}
