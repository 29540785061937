import type { Editor } from "src/types/Editor";
import type { Place } from "./MapSearch";
import { useEffect, useState } from "react";
import { Map as TessaMap } from "./Map/Map";
import { ElevationLayer } from "./Map/Layers/Elevation.layer";
import { ExtrusionLayer } from "./Map/Layers/Extrusion";
import { ScaleControl, useMap } from "react-map-gl";
import { Toolbox } from "./Toolbox/Toolbox";
import { Compass3D } from "./CompassWrapper";
import { MapUtilAreaOfInterestCenter } from "./MapUtilAreaOfInterestCenter";
import { MapUtilResizeObserver } from "./MapUtilResizeObserver";
import { observer } from "mobx-react-lite";
import { useEvent } from "src/hooks/useEvent";
import { ThreeLayer } from "./Map/Layers/three-layer/Three.layer";
import { WorldEditor } from "./WorldEditor";
import { SitesBoundaries } from "./Map/Layers/three-layer/components/SitesBoundaries";
import { ZoneEditor } from "./Map/Layers/three-layer/ZoneEditor";
import { MapSearch } from "./MapSearch";
import type { MapState } from "src/types/MapState";
import { ToolSelection } from "src/types/MapState";
import { WorldUpdateType } from "./Map/Layers/three-layer/WorldUpdateType";

interface Props {
  editor: Editor;
}

export const EditorMap = observer(function EditorMap(props: Props) {
  const [placesHistory, setPlacesHistory] = useState<Place[]>([]);
  const onWorldUpdate = useEvent((type?: WorldUpdateType) => {
    props.editor.regenerate(true);
    if (type === WorldUpdateType.CREATE && props.editor.world?.zones.length === 1) {
      props.editor.view.explorePanel.toggleVisible(true);
    }
  });
  const onSelectedToolCancel = useEvent(() => {
    props.editor.mapState.selectedTool.update(ToolSelection.SELECT);
  });
  if (!props.editor.world) return null;
  const worldEditor = props.editor.mapState.selectedTool.value.id === ToolSelection.SELECT && (
    <WorldEditor
      readonly={false}
      design={props.editor.world}
      onDesignChange={props.editor.loadFromRgWorld}
      selection={props.editor.view.selection}
      mapState={props.editor.mapState}
    />
  );
  const siteBoundariesLayer = props.editor.mapState.layerSiteBoundary.isOn && <SitesBoundaries />;
  const mapSearch = props.editor.mapState.selectedTool.value.id === ToolSelection.SEARCH_PLACE && (
    <MapSearch
      mapState={props.editor.mapState}
      onPlacesHistoryChange={setPlacesHistory}
      placesHistory={placesHistory}
    />
  );
  const geoOrigin = { lng: props.editor.world.longitude, lat: props.editor.world.latitude };

  return (
    <div className="w-full h-full relative">
      <TessaMap mapState={props.editor.mapState}>
        <KeyboardShortcuts editor={props.editor} />
        <MapUtilAreaOfInterestCenter />
        <MapUtilResizeObserver />
        <ElevationLayer mapState={props.editor.mapState} />
        <ExtrusionLayer mapState={props.editor.mapState} />
        <ThreeLayer origin={geoOrigin}>
          <ZoneEditor
            mapState={props.editor.mapState}
            defaultBrief={props.editor.defaultBrief}
            onWorldUpdate={onWorldUpdate}
            world={props.editor.world}
            onSelectedToolCancel={onSelectedToolCancel}
            selectedTool={props.editor.mapState.selectedTool.value.id}
            geoOrigin={geoOrigin}
            selection={props.editor.view.selection}
          />
          {worldEditor}
          {siteBoundariesLayer}
        </ThreeLayer>
        {mapSearch}
        <ScaleControl />
      </TessaMap>
      <ToolboxPanel editor={props.editor} mapState={props.editor.mapState} />
      <Compass3D mapState={props.editor.mapState} />
    </div>
  );
});

const ToolboxPanel = observer(function ToolboxPanel(props: { editor: Editor; mapState: MapState }) {
  return (
    <Toolbox.Wrapper>
      <Toolbox.SearchButton
        onClick={() => props.mapState.selectedTool.update(ToolSelection.SEARCH_PLACE)}
      />

      <Toolbox.Group>
        <Toolbox.SelectButton mapState={props.mapState} />
      </Toolbox.Group>

      <Toolbox.Group>
        <Toolbox.CreateButton
          mapState={props.mapState}
          onClick={() => {
            props.editor.view.selection.clearZones();
            props.mapState.selectedTool.update(ToolSelection.CREATE);
          }}
        />
        <Toolbox.CutButton mapState={props.mapState} />

        <Toolbox.EdgeOffsetButton
          active={props.mapState.selectedTool.value.id === ToolSelection.EDGE_OFFSET}
          disabled={false}
          onClick={() => props.mapState.selectedTool.update(ToolSelection.EDGE_OFFSET)}
        />

        <Toolbox.MergeButton editor={props.editor} />
        <Toolbox.DeleteButton editor={props.editor} />
      </Toolbox.Group>

      <Toolbox.Group>
        <Toolbox.MapViewButton mapState={props.mapState} />
        <Toolbox.BuildingPresetButton mapState={props.mapState} />
      </Toolbox.Group>

      <Toolbox.Group>
        <Toolbox.SaveButton world={props.editor.world} />
      </Toolbox.Group>

      <Toolbox.Group>
        <Toolbox.ExportButton world={props.editor.world} />
      </Toolbox.Group>

      <Toolbox.Group>
        <Toolbox.HistoryUndoButton
          onClick={async () => props.editor.historyBack()}
          disabled={!props.editor.hasHistoryBack}
        />

        <Toolbox.HistoryRedoButton
          onClick={async () => props.editor.historyForward()}
          disabled={!props.editor.hasHistoryForward}
        />
      </Toolbox.Group>
    </Toolbox.Wrapper>
  );
});

const KeyboardShortcuts = observer(function KeyboardShortcuts(props: { editor: Editor }) {
  const mapRef = useMap();
  const onKeydown = useEvent(async (event: KeyboardEvent) => {
    switch (true) {
      case event.key === "z" && event.metaKey && !event.shiftKey:
        return props.editor.historyBack();
      case event.key === "z" && event.metaKey && event.shiftKey:
        return props.editor.historyForward();
      case event.key === "f": {
        throw new Error("Not implemented");
        // const selected = props.editor.view.selection.zones
        //   .map(
        //     (zone) =>
        //       props.editor.scenario?.sites.flatMap((s) => s.zones).find((z) => z.id === zone.id)
        //   )
        //   .filter(Boolean)
        //   .map((z) => z?.region.perimeter as GeographicPoint[])
        //   .flat();
        // const bounds = GetBounds(selected);
        // if (!bounds) return;
        // return mapRef.current?.fitBounds(bounds, {
        //   padding: 100,
        //   pitch: mapRef.current.getPitch(),
        //   bearing: mapRef.current.getBearing(),
        // });
      }
    }
  });
  useEffect(() => {
    if (!mapRef.current) return;
    const map = mapRef.current.getMap().getCanvas();
    const keydown = async (event: KeyboardEvent) => onKeydown(event);
    map.addEventListener("keydown", keydown);
    return () => {
      map.removeEventListener("keydown", keydown);
    };
  }, [mapRef, onKeydown]);
  return null;
});
