import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useApi } from "src/contexts/api.context";
import { useEvent } from "src/hooks/useEvent";
import { getSavedDesignListQueryKey } from "./Navigator/useSavedDesignsList";
import { Modal } from "./Modal";
import { Form } from "./Form/Form";
import { observer } from "mobx-react-lite";
import type { World } from "src/types/World";
import { useActiveParams } from "src/hooks/useActiveParams";

export const SaveModal = observer(function SaveModal(props: { onClose: () => void; world: World }) {
  const api = useApi();
  const { scenarioId, projectId } = useActiveParams();
  const queryClient = useQueryClient();
  const [label, setLabel] = useState(props.world.name);
  const postDesign = useEvent(async () => {
    if (!props.world || !projectId || !scenarioId) return;
    const clone = props.world.clone();
    clone.setLabel(label);
    await api.saveWorld(projectId, scenarioId, clone);
    queryClient.invalidateQueries(getSavedDesignListQueryKey(projectId));
    props.onClose();
  });

  return (
    <Modal.Portal isOpen>
      <Modal.Card onClose={props.onClose}>
        <Modal.Header label="Save Design" onClose={props.onClose} />
        <Form.Text type="text" label="Name" value={label} onChange={setLabel} autoFocus />
        <Modal.Actions>
          <Modal.ActionCancelButton onClick={props.onClose} label="Cancel" />
          <Modal.ActionPrimaryButton onClick={postDesign} label="Save" />
        </Modal.Actions>
      </Modal.Card>
    </Modal.Portal>
  );
});
