import { makeAutoObservable, observable, toJS } from "mobx";
import type { RgZone } from "./RgCorePackage";
import type { RPoly, RgGeneric, RgZoneBrief, V3, ZoneData } from "@project-rouge/rg-core";
import { RG_TYPE } from "@project-rouge/rg-core";
import { Plot } from "./Plot";
import { booleanPointInPolygon, polygon as Turfpolygon } from "@turf/turf";
import { V3ToV2 } from "src/utils/V3ToV2";
import type { Site } from "./Site";
import type { Building } from "./Building";
import { GetPolygonCentroid } from "src/utils/GetPolygonCentroid";
import { GetOffsetPolygons } from "src/utils/GetOffsetPolygons";
import { V2ToV3 } from "src/utils/V2ToV3";
import type { BeZoneBrief } from "./BeZoneBrief";
import type { ZoneBriefConfig } from "./ZoneBrief";
import { ZoneBrief } from "./ZoneBrief";
import { ArchitecturalMetrics } from "./ArchitecturalMetrics";
import type { World } from "./World";
import type { ProjectScenarioSiteZoneHttpPayloadForCreation } from "@project-rouge/service-project-client/resource/project-scenario-site-zone";
import { V3ToGeoPoints } from "src/utils/V3ToLngLat";
import type { GeoPoint } from "./GeoPoint";
import { IsVectorEqualV3 } from "src/utils/IsVectorEqual";
import clip from "polygon-clipping";
interface ZoneConfig {
  rgZone?: RgZone;
  zoneType?: ZoneType;
  offsets?: number[] | null;
  brief?: ZoneBriefConfig;
  label?: string;
  locked?: boolean;
}

class Data implements ZoneData {
  holes: V3[][] = [];
  polygons: V3[][] = [];
  realPoly: V3[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  setHoles(holes: V3[][] | readonly Readonly<RPoly>[]): void {
    this.holes = holes.map((hole) => hole.map(([x, y, z]) => [x, y, z]));
  }

  setPolygons(polygons: V3[][] | readonly RPoly[]): void {
    this.polygons = polygons.map((polygon) => polygon.map(([x, y, z]) => [x, y, z]));
  }

  setRealPoly(realPoly: V3[] | RPoly): void {
    this.realPoly = realPoly.map(([x, y, z]) => [x, y, z]);
  }

  get rgData(): ZoneData {
    return {
      holes: toJS(this.holes),
      polygons: toJS(this.polygons),
      realPoly: toJS(this.realPoly),
    };
  }
}

interface Bounds {
  left: number;
  width: number;
  top: number;
  height: number;
}

export class Zone implements RgGeneric<RG_TYPE.Zone, Site, Plot, ZoneData> {
  // implementation

  alpha: number;
  children: Plot[] = [];
  data = new Data();
  name: string;
  type = RG_TYPE.Zone as const;
  uuid: string;
  pos: V3;
  rot: V3;
  size: V3;
  anchor: V3;
  color: string;
  hash?: string | undefined;
  rg = true as const;
  parent: Site | undefined;
  modulousId: string | undefined;
  templateUuid: string | undefined;

  // custom data

  zoneType: ZoneType;
  offsets: number[] | null = null;
  brief: ZoneBrief;
  locked: boolean;
  label: string;
  constructor(config?: ZoneConfig) {
    this.brief = new ZoneBrief(this, config?.brief);
    this.locked = config?.locked ?? false;
    this.label = config?.label ?? "";
    this.alpha = config?.rgZone?.alpha ?? 1;
    this.zoneType = config?.zoneType ?? ZoneType.buildable;
    this.name = config?.rgZone?.name ?? "";
    this.type = config?.rgZone?.type ?? RG_TYPE.Zone;
    this.uuid = config?.rgZone?.uuid ?? crypto.randomUUID();
    this.pos = config?.rgZone?.pos ?? [0, 0, 0];
    this.rot = config?.rgZone?.rot ?? [0, 0, 0];
    this.size = config?.rgZone?.size ?? [0, 0, 0];
    this.anchor = config?.rgZone?.anchor ?? [0, 0, 0];
    this.color = config?.rgZone?.color ?? "#cccccc";
    this.modulousId = config?.rgZone?.modulousId;
    this.templateUuid = config?.rgZone?.templateUuid;
    this.data.setHoles(config?.rgZone?.data?.holes ?? []);
    this.data.setPolygons(
      config?.rgZone?.data?.polygons ?? [
        [
          [0, 0, 0],
          [10, 0, 0],
          [10, 0, 10],
          [0, 0, 10],
          [0, 0, 0],
        ],
      ]
    );
    this.data.setRealPoly(
      config?.rgZone?.data?.realPoly ?? [
        [0, 0, 0],
        [10, 0, 0],
        [10, 0, 10],
        [0, 0, 10],
        [0, 0, 0],
      ]
    );
    config?.rgZone?.children.forEach((rgPlot) => this.addPlot(new Plot({ rgPlot })));
    this.setOffsets(config?.offsets ?? null);

    makeAutoObservable(
      this,
      {
        pos: observable.ref,
        rot: observable.ref,
        size: observable.ref,
        anchor: observable.ref,
      },
      { autoBind: true }
    );
  }

  polygons: { innerPolygons: V3[][]; outterPolygons: V3[][]; outterPolygonsHoles: V3[][] } = {
    innerPolygons: [],
    outterPolygons: [],
    outterPolygonsHoles: [],
  };
  get geoOuterRing(): GeoPoint[] {
    if (!this.world) return [];
    return V3ToGeoPoints(this.outerRing, this.world.geoOrigin);
  }
  get metrics(): ArchitecturalMetrics {
    return new ArchitecturalMetrics(this.buildings);
  }
  get centroid(): V3 {
    return GetPolygonCentroid(this.outerRing);
  }

  get id() {
    return this.uuid;
  }
  get plots(): Plot[] {
    return this.children;
  }
  get buildings(): Building[] {
    return this.plots.flatMap((plot) => plot.buildings);
  }
  /** alias to data.realPoly */
  get outerRing(): V3[] {
    return this.data.realPoly.map(([x, y, z]) => [x, y, z]);
  }

  /** alias to data.polygon */
  get innerRings(): V3[][] {
    return this.data.polygons.map((polygon) => polygon.map(([x, y, z]) => [x, y, z]));
  }
  get siteAreaHectares() {
    const hectareInMeter = 0.0001;
    const points = this.outerRing;
    const n = points.length;
    if (n < 3) {
      // A polygon with less than 3 points doesn't have a meaningful area.
      return 0;
    }

    let area = 0;
    for (let i = 0; i < n; i++) {
      const xi = points[i][0];
      const yi = points[i][2];
      const xi1 = points[(i + 1) % n][0];
      const yi1 = points[(i + 1) % n][2];
      area += xi * yi1 - xi1 * yi;
    }

    area = Math.abs(area) / 2;
    return area * hectareInMeter;
  }

  private get world(): World | undefined {
    return this.parent?.parent;
  }

  get beBrief(): BeZoneBrief {
    return this.brief.beZoneBrief;
  }

  get rgZoneBrief(): RgZoneBrief | null {
    if (this.zoneType === ZoneType.exclusion) return null;
    return this.brief.rgZoneBrief;
  }

  get bounds(): Bounds {
    const x = this.outerRing.map((v) => v[0]);
    const y = this.outerRing.map((v) => v[2]);
    const left = Math.min(...x);
    const top = Math.min(...y);
    const width = Math.max(...x) - left;
    const height = Math.max(...y) - top;
    return { left, width, top, height };
  }

  get rgZone(): RgZone {
    return this.getRgZone(true);
  }

  get beZone(): ProjectScenarioSiteZoneHttpPayloadForCreation {
    return {
      brief: this.beBrief,
      id: this.uuid,
      label: this.label,
      region: {
        offsets: this.offsets,
        perimeter: this.geoOuterRing,
      },
      type: this.zoneType,
      is_locked: this.locked,
    };
  }

  setZoneType(type: ZoneType) {
    this.zoneType = type;
    if (type === ZoneType.exclusion) {
      this.children = [];
      this.setOffsets(null);
    }
  }

  setLabel(label: string) {
    this.label = label;
  }

  setLocked(locked: boolean) {
    this.locked = locked;
  }

  setBrief(brief: ZoneBrief) {
    this.brief = brief;
  }

  setOffsets(offsets: number[] | null) {
    this.offsets = offsets;
    if (!offsets) {
      this.polygons.innerPolygons = [this.outerRing];
      this.polygons.outterPolygons = [];
      this.polygons.outterPolygonsHoles = [];
      this.data.setPolygons([this.outerRing]);
      this.data.setRealPoly(this.outerRing);
      return;
    }
    const data = GetOffsetPolygons(V3ToV2(this.outerRing), offsets);
    const innerPolygons = data.innerPolygons.map(V2ToV3);
    this.polygons.innerPolygons = innerPolygons;
    this.polygons.outterPolygons = data.outterPolygons.map(V2ToV3);
    this.polygons.outterPolygonsHoles = data.outterPolygonsHoles.map(V2ToV3);
    this.data.setPolygons(innerPolygons);
  }

  setId(id: string) {
    this.uuid = id;
  }

  IsContainedInPolygon(polygon: V3[]) {
    return this.data.realPoly.every((point) =>
      booleanPointInPolygon([point[0], point[2]], Turfpolygon([V3ToV2(polygon)]), {
        ignoreBoundary: false,
      })
    );
  }

  setOuterRing(polygon: V3[]) {
    if (polygon.length < 3) throw new Error("Polygon must have at least 3 points");
    const first = polygon[0];
    const last = polygon[polygon.length - 1];
    const ring = IsVectorEqualV3(first, last) ? polygon : [...polygon, first];
    this.data.setRealPoly(ring);
    if (this.offsets?.length !== ring.length - 1) {
      this.setOffsets(null);
    }
  }

  hasZoneOverlap(zone: Zone) {
    const polygonA = this.outerRing.map((polygon) => V3ToV2(polygon));
    const polygonB = V3ToV2(zone.outerRing);
    const polygonASet = new Set(polygonA.map((v) => v.join(",")));
    const polygonBArr = polygonB.map((v) => v.join(","));
    if (polygonBArr.some((v) => polygonASet.has(v))) return true;
    const result = clip.intersection([polygonA], [polygonB]);
    return result.length > 0;
  }

  destroy() {
    this.parent?.parent?.removeZone(this);
  }
  addPlot(plot: Plot): void {
    if (this.children.includes(plot)) return;
    plot.parent = this;
    this.children.push(plot);
  }

  clone(includeChildren = true): Zone {
    const rgZone = includeChildren ? this.rgZone : this.getRgZone(false);
    const config: Required<ZoneConfig> = {
      rgZone,
      zoneType: this.zoneType,
      offsets: toJS(this.offsets),
      brief: this.brief.config,
      label: this.label,
      locked: this.locked,
    };
    return new Zone(structuredClone(config));
  }

  removePlot(plot: Plot | Plot[]): void {
    const removePlots = new Set(Array.isArray(plot) ? plot : [plot]);
    const updatedChildren: Plot[] = [];
    for (const child of this.children) {
      if (!removePlots.has(child)) {
        updatedChildren.push(child);
      } else {
        child.parent = undefined;
      }
    }
    if (updatedChildren.length === this.children.length) return;
    this.children = updatedChildren;
  }
  removeBuilding(building: Building | Building[]): void {
    const removeBuildings = Array.isArray(building) ? building : [building];
    for (const plot of this.children) {
      plot.removeBuilding(removeBuildings);
    }
  }
  /** move outerRing */
  moveBy(delta: V3): void {
    this.setOuterRing(
      this.outerRing.map(([x, y, z]) => [x + delta[0], y + delta[1], z + delta[2]])
    );
  }

  private getRgZone(includeChildren: boolean): RgZone {
    const rgZone: RgZone = {
      parent: undefined,
      data: this.data.rgData,
      pos: toJS(this.pos),
      rot: toJS(this.rot),
      size: toJS(this.size),
      children: [],
      rg: this.rg,
      hash: this.hash,
      alpha: this.alpha,
      name: this.name,
      type: this.type,
      uuid: this.uuid,
      anchor: toJS(this.anchor),
      color: this.color,
      modulousId: this.modulousId,
      templateUuid: this.templateUuid,
    };
    if (includeChildren) {
      rgZone.children = this.children.map((child) => {
        const v = child.rgParentlessSnapshot;
        v.parent = rgZone;
        return v;
      });
    }
    return rgZone;
  }
}

export const enum ZoneType {
  buildable = "buildable",
  exclusion = "exclusion",
}
