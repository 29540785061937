import type mapboxgl from "mapbox-gl";
import { useRef, useEffect } from "react";
import { useMap } from "react-three-map";

export const useMapMouseEvent = (
  event: string,
  cb: (evt: mapboxgl.MapMouseEvent & mapboxgl.EventData, map: mapboxgl.Map) => void
) => {
  const map = useMap();
  const cbRef = useRef(cb);
  cbRef.current = cb;
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const callback = (evt: any) => {
      if (!map) return;
      cbRef.current(evt, map);
    };
    map?.on(event, callback);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      map?.off(event, callback);
    };
  }, [event, map]);
};
