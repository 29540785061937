import { TrashIcon } from "@heroicons/react/20/solid";
import { Button, ButtonState } from "src/components/Button";
import { observer } from "mobx-react-lite";
import { useEvent } from "src/hooks/useEvent";
import type { Editor } from "src/types/Editor";

interface Props {
  editor: Editor;
}

export const DeleteButton = observer(function DeleteButton(props: Props) {
  const selection = props.editor.view.selection;
  const onClick = useEvent(() => {
    const world = props.editor.world;
    if (!selection.zones.length || !world) return;
    selection.zones.forEach((zone) => world.removeZone(zone));
    selection.clearZones();
    props.editor.regenerate(true);
  });
  return (
    <Button
      title="Delete"
      Icon={TrashIcon}
      state={selection.zones.length ? ButtonState.ACTIVE : ButtonState.DISABLED}
      onClick={onClick}
    />
  );
});
