import { makeAutoObservable, observable } from "mobx";
import type { FeElements } from "@project-rouge/rg-three";
import { getFeLayers, getFeVisibility } from "@project-rouge/rg-three";
import deepEqual from "deep-equal";
import type { World } from "./World";
import { BooleanState } from "./BooleanState";
import { NumberState } from "./NumberState";
import { SelectState } from "./SelectState";
import { bbox, polygon, transformScale } from "@turf/turf";
import type mapboxgl from "mapbox-gl";

type GeoBounds = [sw: [number, number], ne: [number, number]];

export enum ModelVisibilityPreset {
  Abstract = "Abstract",
  Architectural = "Architectural",
  Custom = "Custom",
}
export type FeElementKey = keyof FeElements;

const ModelVisibilityPresets: Record<ModelVisibilityPreset, FeElements | undefined> = {
  [ModelVisibilityPreset.Abstract]: {
    Unit: true,
    Cores: true,
    Corridors: true,
    Services: true,
    Amenities: true,
    Entrances: true,
    Facade: false,
    Roof: false,
    Parapets: false,
    Floors: false,
    Ceilings: false,
    Walls: false,
    Windows: false,
    Doors: false,
    Structure: false,
    Commercials: true,
  },
  [ModelVisibilityPreset.Architectural]: {
    Unit: false,
    Cores: false,
    Corridors: false,
    Services: false,
    Amenities: false,
    Entrances: false,
    Facade: true,
    Roof: true,
    Parapets: true,
    Floors: true,
    Ceilings: true,
    Walls: true,
    Windows: true,
    Doors: true,
    Structure: true,
    Commercials: false,
  },
  [ModelVisibilityPreset.Custom]: undefined,
};

function getPresetFromElements(elements: FeElements): ModelVisibilityPreset {
  for (const [preset, presetElements] of Object.entries(ModelVisibilityPresets)) {
    if (deepEqual(presetElements, elements)) return preset as ModelVisibilityPreset;
  }
  return ModelVisibilityPreset.Custom;
}

class ModelVisibility {
  elements: FeElements;
  preset: ModelVisibilityPreset;
  constructor() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.elements = { ...ModelVisibilityPresets.Abstract! };
    this.preset = ModelVisibilityPreset.Abstract;
    // this.visibility = getFeVisibility(elements);
    makeAutoObservable(this, {}, { autoBind: true });
  }
  get visibility() {
    return getFeVisibility(this.elements);
  }
  get layers() {
    return getFeLayers(this.elements);
  }
  get presetList(): ModelVisibilityPreset[] {
    let list = Object.values(ModelVisibilityPreset);
    // we don't let user select "Custom", instead it just gets selected when applicable
    if (this.preset !== ModelVisibilityPreset.Custom) {
      list = list.filter((item) => item !== ModelVisibilityPreset.Custom);
    }
    return list;
  }

  toggle(elem: FeElementKey) {
    const newElements = {
      ...this.elements,
      [elem]: !this.elements[elem],
    };
    this.elements = newElements;
    const newPreset = getPresetFromElements(newElements);
    if (this.preset === newPreset) return;
    this.preset = newPreset;
  }

  updatePreset(newPreset: ModelVisibilityPreset) {
    this.preset = newPreset;
    const newElements = ModelVisibilityPresets[newPreset];
    if (!newElements) return;
    if (deepEqual(this.elements, newElements)) return;
    this.elements = newElements;
  }
}

export enum ToolSelection {
  SELECT = "SELECT",
  CREATE = "CREATE",
  CUT = "CUT",
  SEARCH_PLACE = "SEARCH_PLACE",
  EDGE_OFFSET = "EDGE_OFFSET",
}

const TOOL_SELECTION = [
  { id: ToolSelection.SELECT },
  { id: ToolSelection.CREATE },
  { id: ToolSelection.CUT },
  { id: ToolSelection.SEARCH_PLACE },
  { id: ToolSelection.EDGE_OFFSET },
] as const;

const MAP_STYLES = [
  { id: "light", label: "Light", style: "mapbox://styles/mapbox/light-v11" },
  { id: "streets", label: "Streets", style: "mapbox://styles/mapbox/streets-v12" },
  { id: "satellite", label: "Satellite", style: "mapbox://styles/mapbox/satellite-streets-v12" },
] as const;

export class MapState {
  private mapGl: mapboxgl.Map | null = null;
  private world: World | null = null;
  floorLevels = new BooleanState(true);
  modelVisibility = new ModelVisibility();
  bearing = new NumberState();
  pitch = new NumberState(90 * (Math.PI / 180));
  selectedTool = new SelectState(TOOL_SELECTION, ToolSelection.SEARCH_PLACE);
  layerSiteContext = new BooleanState();
  viewStyle = new SelectState(MAP_STYLES, "streets");
  layerTopographyLines = new BooleanState();
  layerSiteBoundary = new BooleanState();
  layerZones = new BooleanState();
  constructor(world: World | null) {
    this.world = world;
    makeAutoObservable<MapState, "mapGl">(this, { mapGl: observable.ref }, { autoBind: true });
  }
  setMapGl(mapGl: mapboxgl.Map | null) {
    this.mapGl = mapGl;
  }
  setWorld(world: World | null) {
    this.world = world;
  }
  fitBounds(bbox: [number, number, number, number]) {
    this.mapGl?.fitBounds(bbox, { padding: 0, animate: true, duration: 1000 });
  }
  flyTo(cords: { longitude: number; latitude: number }) {
    this.mapGl?.flyTo({
      center: { lng: cords.longitude, lat: cords.latitude },
      zoom: 15,
      padding: 0,
      animate: true,
      duration: 1000,
    });
  }
  get hasWorld() {
    return !!this.world;
  }
  get geoPointOfInterest() {
    return this.world ? { lng: this.world.longitude, lat: this.world.latitude } : null;
  }
  get geoBounds(): GeoBounds {
    return getScaledGeoBounds(this.world);
  }

  get cursor() {
    const mode = this.selectedTool.value.id;
    switch (mode) {
      case ToolSelection.CREATE:
        return "pointer";
      case ToolSelection.CUT:
        return "crosshair";
      default:
        return "default";
    }
  }
}

const UK_BOUNDS = [
  [-8.649357, 49.863344], // southwestern corner of the bounds
  [1.76334, 60.860699], // northeastern corner of the bounds
] as [[number, number], [number, number]];

function getScaledGeoBounds(world: World | null): GeoBounds {
  if (!world?.hasSite) return UK_BOUNDS;
  const projectArea = world.zones.flatMap((zone) => {
    const geoRing = zone.geoOuterRing.map((v) => [v.longitude, v.latitude]);
    return geoRing;
  });

  const poly = polygon([[...projectArea, projectArea[0]]]);
  const offsetPolygon = transformScale(poly, 1.5);
  const offsetBbox = bbox(offsetPolygon);
  const geoBounds: GeoBounds = [
    [offsetBbox[0], offsetBbox[1]], // [minLng, minLat]
    [offsetBbox[2], offsetBbox[3]], // [maxLng, maxLat]
  ];

  return geoBounds;
}
