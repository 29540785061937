import classNames from "classnames";
import { type FunctionComponent, type PropsWithChildren } from "react";
import { InformationCircleIcon } from "@heroicons/react/20/solid";

function TableRow({ children }: PropsWithChildren) {
  return <div className="flex justify-evenly w-full">{children}</div>;
}

function TableCol({ children }: PropsWithChildren) {
  return <div className="flex flex-col w-full truncate">{children}</div>;
}

function StickySection({
  children,
  top = 0,
  zIndex,
}: PropsWithChildren<{ top?: number; zIndex?: number }>) {
  return (
    <div
      style={{
        top,
        zIndex,
      }}
      className="flex flex-col w-full sticky"
    >
      {children}
    </div>
  );
}

function TableHead(props: {
  label: string | number;
  variant: "section" | "header" | "selector";
  position?: "start" | "middle" | "end";
  symbol?: string;
  subLabel?: string;
}) {
  const { label, variant, symbol, position, subLabel } = props;
  // Used to transform a sub value like CO2 in to CO <sub>2</sub>
  let subTag = "";
  let smallTag = "";
  if (typeof subLabel === "string") {
    subTag = subLabel.slice(0, 2);
    smallTag = subLabel.substring(2);
  }

  return (
    <div
      className={classNames(
        variant === "section" && "bg-neutral-3 border-y border-neutral-4",
        variant === "header" && "bg-neutral-1 text-neutral-7 border-b border-neutral-4",
        position === "start" && "justify-start",
        position === "middle" && "justify-center",
        position === "end" && "justify-end",
        "flex items-center w-full h-9 px-4 space-x-2 text-subtitle-2"
      )}
    >
      <span className={classNames(variant === "header" && "font-semibold text-neutral-8")}>
        <span className="px-1">
          {subTag}
          <sub>{smallTag}</sub>
        </span>
        {label}
      </span>
      {symbol && <span className="text-neutral-6">{symbol}</span>}
    </div>
  );
}

function TableCellLabel(props: {
  label: string | number;
  variant: "total" | "cell" | "subtotal";
  title?: string;
}) {
  const { label, variant, title } = props;
  return (
    <div
      title={title}
      className={classNames(
        variant === "total" && "font-semibold mb-4",
        variant === "subtotal" && "font-semibold  border-b border-neutral-4",
        variant === "cell" && " border-b border-neutral-4",
        "w-full h-9 px-6 py-4 flex items-center text-neutral-8 text-body-1"
      )}
    >
      <span>{label}</span>
    </div>
  );
}

function TableCell(props: {
  value: number;
  variant: "cell" | "total" | "subtotal";
  symbol?: string;
  currency?: string;
  precision?: number;
  info?: boolean;
  title?: string;
}) {
  const { value, variant, symbol, currency, info, precision, title } = props;
  const valueConverted = value.toLocaleString("en", {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });

  return (
    <div
      className={classNames(
        variant === "total" && "font-semibold",
        variant === "subtotal" && "font-semibold border-b border-neutral-4",
        variant === "cell" && "border-b border-neutral-4",
        "w-full h-9 p-4 flex items-center text-neutral-8  justify-end gap-1 text-body-1"
      )}
    >
      {currency ? (
        <>
          <span>{currency}</span>
          <span>{valueConverted}</span>
        </>
      ) : (
        <>
          <span title={title}>
            {info ? (
              <div
                className="flex gap-2"
                title="We are working on accurately measuring the carbon footprint of the substructure, which will be included in the next release of TESSA"
              >
                - <InformationCircleIcon className="w-4" />
              </div>
            ) : (
              valueConverted
            )}
          </span>
          <span className="text-neutral-6 font-normal">{symbol}</span>
        </>
      )}
    </div>
  );
}

export const Table: FunctionComponent<PropsWithChildren> & {
  Head: typeof TableHead;
  Row: typeof TableRow;
  Sticky: typeof StickySection;
  Col: typeof TableCol;
  Cell: typeof TableCell;
  Label: typeof TableCellLabel;
} = ({ children }) => {
  return <div className="flex flex-1 flex-col scrollbar-none overflow-scroll">{children}</div>;
};
Table.Head = TableHead;
Table.Row = TableRow;
Table.Sticky = StickySection;
Table.Col = TableCol;
Table.Cell = TableCell;
Table.Label = TableCellLabel;
