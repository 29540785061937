import { observer } from "mobx-react-lite";

/** disabled until https://app.clickup.com/t/86933zwq3 */
export const SitesBoundaries = observer(() => {
  // const { polygons } = useMapState().history;
  // const boundaries = useMemo(() => {
  //   const forUnion = polygons.map((polygon) => [polygon.boundary.map(([x, _, y]) => [x, y] as V2)]);
  //   const rest = clip.union(forUnion).map(([polygon]) => polygon.map(([x, y]) => [x, 0, y] as V3));
  //   return rest;
  // }, [polygons]);
  // const width = window.devicePixelRatio * 4;
  // return (
  //   <>
  //     {boundaries.map((boundary, i) => (
  //       <Line key={i} points={boundary} lineWidth={width} color={0xffa500} />
  //     ))}
  //   </>
  // );
  return null;
});
