import { PencilIcon } from "@heroicons/react/20/solid";
import { Button, ButtonIntent, ButtonState } from "src/components/Button";
import type { MapState } from "src/types/MapState";
import { ToolSelection } from "src/types/MapState";

export function CreateButton({ mapState, onClick }: { mapState: MapState; onClick: () => void }) {
  const { selectedTool: mode } = mapState;
  return (
    <Button
      title="Create"
      Icon={PencilIcon}
      onClick={onClick}
      intent={ButtonIntent.SELECT}
      state={mode.value.id === ToolSelection.CREATE ? ButtonState.ACTIVE : ButtonState.NONE}
    />
  );
}
