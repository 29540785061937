import { zlibSync, zlib, strToU8 } from "fflate";

export function Compress(data: unknown): Uint8Array {
  return zlibSync(strToU8(JSON.stringify(data)), { level: 9 });
}

export async function CompressAsync(data: unknown): Promise<Uint8Array> {
  return new Promise((resolve, reject) => {
    zlib(strToU8(JSON.stringify(data)), { level: 9 }, (err, res) => {
      if (err) reject(err);
      else resolve(res);
    });
  });
}
