import { makeAutoObservable } from "mobx";
import type { World } from "./World";
import type { AggMangerWorkerMessage } from "src/workers/DesignsGenerator.worker";
import type { GeneratedItem } from "src/types/GeneratedItem";
import Worker from "src/workers/DesignsGenerator.worker?worker";

interface Config {
  projectId: string;
  listSize: number;
  tokenAdmin: string | null;
  tokenActor: string;
}

export class DesignsGenerator {
  private readonly id = crypto.randomUUID();
  list: GeneratedItem[] = [];
  listSize: number;
  tokenAdmin: string | null;
  tokenActor: string;
  readonly projectId: string;
  private worker: Worker | null = null;
  world: World | null = null;
  isRunning = false;
  constructor(config: Config) {
    this.projectId = config.projectId;
    this.listSize = config.listSize;
    this.tokenAdmin = config.tokenAdmin;
    this.tokenActor = config.tokenActor;
    makeAutoObservable(this);
  }

  generate(world: World | null) {
    if (this.world?.zonesBriefsHashKey === world?.zonesBriefsHashKey) return;
    this?.terminate();
    this.list = [];
    this.world = world?.clone() ?? null;
    if (!world) return;
    this.isRunning = true;
    this.worker = new Worker();
    this.worker.addEventListener("message", this.onData);
    const data: AggMangerWorkerMessage["Request"] = {
      listSize: this.listSize,
      projectId: this.projectId,
      messageId: this.id,
      rgBriefDto: world.rgBriefDto,
      tokenAdmin: this.tokenAdmin,
      tokenActor: this.tokenActor,
      zonesMeta: world.zonesMeta,
      type: "AGG_REQUEST",
    };
    this.worker.postMessage(data);
  }

  private onData = ({ data }: MessageEvent<AggMangerWorkerMessage["Response"]>) => {
    if (data.messageId !== this.id) return;
    if (!data.item) {
      this.worker?.removeEventListener("message", this.onData);
    } else {
      this.list.push(data.item);
    }
    if (this.list.length !== this.list.length) {
      const item = this.list.at(-1);
      if (item) this.list.push(item);
    }
    if (!data.item) {
      this.isRunning = false;
      this.terminate();
    }
  };

  terminate() {
    this.worker?.terminate();
  }

  get progress() {
    if (this.listSize === 0) return 1;
    return this.list.length / this.listSize;
  }
  get completedSize() {
    return this.list.length;
  }
  get pendingSize() {
    return this.listSize - this.completedSize;
  }
}
