import { getDefaultMix } from "@project-rouge/rg-core";
import { makeAutoObservable, toJS } from "mobx";
import { Dataset } from "src/constants/Dataset";
import { MathSum } from "src/utils/MathSum";
import { roundToDecimal } from "src/components/BriefPanel/UnitMixSection/roundToDecimal";
import { clamp } from "three/src/math/MathUtils";
import type { BeZoneBrief } from "./BeZoneBrief";
import { UnitsRegister } from "src/constants/UnitsRegister";
import type { RgUnitMix } from "./RgCorePackage";

interface Unit {
  id: string;
  unitColor: string;
  textColor: string;
  enabled: boolean;
  locked: boolean;
  /** "Scarlet 200_2B3Pv1", "Scarlet 202_2B3Pv2" */
  apartmentNames: string[];
  value: number;
  /** 1B2P | 2B3P | 2B4P | 3B5P | 3B6P **/
  label: string;
}

export interface UnitMixBriefConfig {
  categories: Unit[];
}

export class UnitMixBrief {
  private static fixTotalQuantity(values: number[], totalValue: number, decimalPlaces: number) {
    const sum = MathSum(values);
    if (sum === totalValue) return values;
    const draft = structuredClone(values);
    let balance = totalValue - sum;
    for (let i = 0; i < draft.length; i++) {
      const value = roundToDecimal(clamp(draft[i] + balance, 0, totalValue), decimalPlaces);
      balance -= value - draft[i];
      draft[i] = value;
    }
    return draft;
  }

  private static updateValue(props: {
    values: number[];
    index: number;
    value: number;
    decimalPlaces: number;
  }): number[] {
    const total = roundToDecimal(MathSum(props.values), props.decimalPlaces);
    const validValue = clamp(roundToDecimal(props.value, props.decimalPlaces), 0, total);
    const draft = props.values.filter((_, index) => index !== props.index);
    let perItem = (props.values[props.index] - validValue) / draft.length;

    for (let i = 0; i < draft.length; i++) {
      const value = draft[i] + perItem;
      draft[i] = value;
    }
    const negativeItems = draft.filter((item) => item < 0);
    for (let i = 0; i < negativeItems.length; i++) {
      let balance = negativeItems[i];
      for (let j = 0; j < draft.length; j++) {
        const value = Math.max(draft[j] + balance, 0);
        if (draft[j] > 0) {
          balance = balance + (draft[j] - value);
        }
        draft[j] = value;
      }
    }
    const values = draft.map((item) => roundToDecimal(item, props.decimalPlaces));
    // fix overflow number
    if (MathSum(values) !== total - validValue) {
      const diff = total - validValue - MathSum(values);
      values[0] = roundToDecimal(values[0] + diff, props.decimalPlaces);
    }
    values.splice(props.index, 0, validValue);

    return values;
  }

  static decimalPlaces = 2;
  categories: Unit[] = [];
  locked = new Set<string>();
  constructor(config?: UnitMixBriefConfig) {
    this.categories =
      config?.categories ??
      getDefaultMix(Dataset).map((unit) => ({
        label: unit.label,
        apartmentNames: unit.apartmentNames,
        value: unit.ratio,
        enabled: UnitsRegister[unit.label].enabled,
        locked: UnitsRegister[unit.label].locked,
        textColor: UnitsRegister[unit.label].textColor,
        unitColor: UnitsRegister[unit.label].unitColor,
        id: UnitsRegister[unit.label].id,
      }));
    makeAutoObservable(this);
  }

  updateUnit(index: number, value: number) {
    const enabled = this.categories.filter((item) => !item.locked && item.enabled);
    let values = enabled.map((v) => v.value);
    const relativeIndex = enabled.indexOf(this.categories[index]);
    values = UnitMixBrief.updateValue({
      values,
      index: relativeIndex,
      value,
      decimalPlaces: UnitMixBrief.decimalPlaces,
    });
    for (let i = 0; i < enabled.length; i++) {
      enabled[i].value = values[i];
    }
  }

  toggleUnitLock(index: number) {
    const unit = this.categories[index];
    unit.locked = !unit.locked;
  }

  toggleUnitEnabled(index: number) {
    const unit = this.categories[index];
    const enabled = !unit.enabled;
    if (!enabled && this.disabledCategories.length >= this.categories.length - 1) return;
    if (!enabled && unit.value > 0) {
      const enabledCategories = this.categories.filter((item) => item.enabled && item !== unit);
      const values = UnitMixBrief.fixTotalQuantity(
        enabledCategories.map((item) => item.value),
        1,
        2
      );
      enabledCategories.forEach((item, index) => {
        item.value = values[index];
      });
      unit.value = 0;
    }
    unit.enabled = enabled;
  }

  private get disabledCategories() {
    return this.categories.filter((item) => !item.enabled);
  }

  get mixToBriefMix(): BeZoneBrief["unit_category_mix"] {
    return this.categories.map((item) => ({
      category: item.label,
      value: item.value,
      units: item.apartmentNames,
    }));
  }
  get rgUnitMix(): RgUnitMix[] {
    return this.categories
      .filter((c) => c.enabled)
      .map((item) => ({
        label: item.label,
        ratio: item.value,
        apartmentNames: toJS(item.apartmentNames),
      }));
  }
  get beUnitMix(): BeZoneBrief["unit_category_mix"] {
    return this.categories
      .filter((c) => c.enabled)
      .map((item) => ({
        category: item.label,
        value: item.value,
        units: toJS(item.apartmentNames),
      }));
  }
  get config(): UnitMixBriefConfig {
    return {
      categories: this.categories.map((item) => ({
        label: item.label,
        apartmentNames: toJS(item.apartmentNames),
        value: item.value,
        enabled: item.enabled,
        locked: item.locked,
        textColor: item.textColor,
        unitColor: item.unitColor,
        id: item.id,
      })),
    };
  }
  clone() {
    return new UnitMixBrief(this.config);
  }
}
