import classNames from "classnames";
import { type FunctionComponent } from "react";

interface BlocksProps {
  name: string;
  onClick?: () => void;
  active: boolean;
}

export const Blocks: FunctionComponent<BlocksProps> = ({ name, onClick, active }) => {
  return (
    <button
      onClick={onClick}
      className={classNames(
        active === true && "bg-primary-6 border-primary-7 text-white",
        active === false &&
          "bg-neutral-2 border-neutral-4  hover:bg-primary-6 hover:border-primary-7 hover:text-white",
        "w-[68px] h-7  rounded-sm border  text-sm flex items-center justify-center hover:cursor-pointer"
      )}
    >
      {name}
    </button>
  );
};

type BlocksInfoItemProps = {
  label: string;
  value: string | number;
  excludeSeperator?: boolean;
  unit?: string;
  precision?: number;
};

export const BlocksInfoItem: FunctionComponent<BlocksInfoItemProps> = ({
  label,
  value,
  excludeSeperator,
  unit,
  precision,
}) => {
  const valueConverted = value.toLocaleString("en", {
    minimumFractionDigits: precision ?? 0,
    maximumFractionDigits: precision ?? 0,
  });

  return (
    <div
      className={classNames(
        excludeSeperator !== true && "border-r border-neutral-5",
        "w-full flex flex-col px-3"
      )}
    >
      <span className="text-neutral-7 font-bold text-overline">{label}</span>
      <div className="space-x-2">
        <span className="text-neutral-8">{valueConverted}</span>
        <span className="text-neutral-6">{unit}</span>
      </div>
    </div>
  );
};
