import { useQuery } from "@tanstack/react-query";
import { useId, useMemo } from "react";
import { type Api } from "src/types/Api";
import { useApi } from "src/contexts/api.context";
import { type User } from "src/types/User";
import { useAuth } from "src/contexts/auth.context";

export const useGetUser = () => {
  const api = useApi();
  const auth = useAuth();
  const userQueryKey = useId();
  const userQuery = useQuery({
    queryKey: [userQueryKey, "user"],
    queryFn: async () => await getUser(api),
    enabled: auth.isAuthorized,
  });
  return useMemo(() => ({ ...userQuery }), [userQuery]);
};

async function getUser(api: Api): Promise<User | null> {
  const user = await api.getUser({ userId: "me" });
  if (!user || undefined) return null;

  return {
    profile: {
      companyJobTitle: user.profile.company_job_title,
      companyName: user.profile.company_name,
      firstName: user.profile.name_first ?? "",
      lastName: user.profile.name_last ?? "",
    },
    email: user.email,
    id: user.id,
    createdAt: user.created_at,
    updatedAt: user.updated_at,
  };
}
