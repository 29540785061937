import { type V2 } from "@project-rouge/rg-core";
import { Fragment, memo } from "react";

interface SvgScaleProps {
  /** needs to know the content bounding box
   *  to position itself to the bottom left */
  box: DOMRect;
}

export const SvgScale = memo<SvgScaleProps>(({ box }) => {
  const scaleItems = Array(4).fill(true);
  const lineHeight = 0.2;
  const fontSize = 0.5;

  const pos: V2 = [box.x, box.y + box.height + 2];
  return (
    <g data-name="scale" transform={`translate(${pos.join(",")})`}>
      {scaleItems.map((_, i) => (
        <Fragment key={i}>
          <text fontSize={fontSize} alignmentBaseline="after-edge" x={i} y={lineHeight}>
            {i}
          </text>
          <rect
            x={i}
            y={i % 2 ? lineHeight : lineHeight * 2}
            fill="#000"
            width={1}
            height={lineHeight}
          />
        </Fragment>
      ))}
      <text fontSize={fontSize} alignmentBaseline="after-edge" x={scaleItems.length} y={lineHeight}>
        {scaleItems.length}M
      </text>
    </g>
  );
});
