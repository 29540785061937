import { makeAutoObservable, observable, toJS } from "mobx";
import type { RgBuilding } from "./RgCorePackage";
import type { BuildingChildren, BuildingData, RgGeneric, V3 } from "@project-rouge/rg-core";
import { RG_TYPE } from "@project-rouge/rg-core";
import type { Plot } from "./Plot";
import { ArchitecturalMetrics } from "./ArchitecturalMetrics";
import type { World } from "./World";

interface BuildingConfig {
  rgBuilding?: RgBuilding;
}

export class Building implements RgGeneric<RG_TYPE.Building, Plot, BuildingChildren, BuildingData> {
  alpha: number;
  children: BuildingChildren[] = [];
  data: BuildingData;
  name: string;
  type = RG_TYPE.Building as const;
  uuid: string;
  pos: V3;
  rot: V3;
  size: V3;
  anchor: V3;
  color: string;
  hash?: string | undefined;
  rg = true as const;
  modulousId: string | undefined;
  parent: Plot | undefined;
  templateUuid: string | undefined;
  constructor(config?: BuildingConfig) {
    // const building = createRgObject(DefaultBuilding, data);
    this.alpha = config?.rgBuilding?.alpha ?? 1;
    this.children = config?.rgBuilding?.children ?? [];
    this.data = config?.rgBuilding?.data ?? CreateDefaultData();
    this.name = config?.rgBuilding?.name ?? "";
    this.type = config?.rgBuilding?.type ?? RG_TYPE.Building;
    this.uuid = config?.rgBuilding?.uuid ?? crypto.randomUUID();
    this.pos = config?.rgBuilding?.pos ?? [0, 0, 0];
    this.rot = config?.rgBuilding?.rot ?? [0, 0, 0];
    this.size = config?.rgBuilding?.size ?? [0, 0, 0];
    this.anchor = config?.rgBuilding?.anchor ?? [0, 0, 0];
    this.color = config?.rgBuilding?.color ?? "#cccccc";
    this.modulousId = config?.rgBuilding?.modulousId;
    this.templateUuid = config?.rgBuilding?.templateUuid;
    makeAutoObservable(this, {
      children: observable.ref,
      data: observable.ref,
      pos: observable.ref,
      rot: observable.ref,
      size: observable.ref,
      anchor: observable.ref,
    });
  }

  get label() {
    let index = this.data.index;
    if (index < 0 || index === Infinity || index === -Infinity) {
      throw new Error("Invalid index");
    }

    let name = "";
    do {
      const charCode = 65 + (index % 26);
      name = String.fromCharCode(charCode) + name;
      index = Math.floor(index / 26) - 1;
    } while (index >= 0);

    return `Block ${name}`;
  }

  get world(): World | undefined {
    return this.parent?.parent?.parent?.parent;
  }
  get metrics(): ArchitecturalMetrics {
    return new ArchitecturalMetrics(this);
  }

  get id(): string {
    return this.uuid;
  }

  get rgParentlessSnapshot(): RgBuilding {
    return {
      parent: undefined,
      rg: this.rg,
      hash: this.hash,
      alpha: this.alpha,
      children: this.children,
      name: this.name,
      type: this.type,
      uuid: this.uuid,
      anchor: toJS(this.anchor),
      color: this.color,
      modulousId: this.modulousId,
      templateUuid: this.templateUuid,
      data: toJS(this.data),
      pos: toJS(this.pos),
      rot: toJS(this.rot),
      size: toJS(this.size),
    };
  }

  setRgBuilding(rgBuilding: RgBuilding) {
    this.alpha = rgBuilding.alpha;
    this.children = rgBuilding.children;
    this.data = rgBuilding.data;
    this.name = rgBuilding.name;
    this.type = rgBuilding.type;
    this.uuid = rgBuilding.uuid;
    this.pos = rgBuilding.pos;
    this.rot = rgBuilding.rot;
    this.size = rgBuilding.size;
    this.anchor = rgBuilding.anchor;
    this.color = rgBuilding.color;
    this.modulousId = rgBuilding.modulousId;
    this.templateUuid = rgBuilding.templateUuid;
  }
  destroy(): void {
    this.parent?.removeBuilding(this);
  }
  setId(id: string) {
    this.uuid = id;
  }
}

function CreateDefaultData(): BuildingData {
  return {
    index: -1,
    levels: [],
    anatomy: { pos: [0, 0, 0], bones: [], cornerFlip: false, id: "" },
  };
}
