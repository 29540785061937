import {
  createCostingViewForBuilding,
  createCostingViewForTotal,
} from "@project-rouge/service-cost-client/utility/custom-view";
import { calculateValuePerMetrics } from "@project-rouge/service-cost-client/data/value-per-metric";
import { localizeMetric } from "./localizeMetric";
import { sum } from "@project-rouge/service-core/data/number";
import type { Unit } from "../UnitSelect";
import { Table } from "../Table";
import type { Building } from "src/types/Building";
import type { World } from "src/types/World";
import type { MetricsCalculations } from "src/hooks/useMetricsCalculationsQuery";

interface Row {
  label: string;
  cost: number;
  perUnit: number;
  perMetric: number;
}

export function SummaryTable(props: {
  unit: Unit;
  metricsCalculations: MetricsCalculations;
  selectedBuilding: Building | null;
  design: World;
}) {
  const { unit } = props;

  const rows = useRows(props.design, props.selectedBuilding, props.metricsCalculations, unit);

  return (
    <div>
      <Table.Sticky>
        <Table.Head label="Summary" variant="section" />
        <Table.Row>
          <Table.Head label="" variant="header" position="start" />
          <Table.Head label="Cost" symbol="(£)" variant="header" position="end" />
          <Table.Head label="Per unit" symbol="(£)" variant="header" position="end" />
          <Table.Head label={`Per ${unit}`} symbol="(£)" variant="header" position="end" />
        </Table.Row>
      </Table.Sticky>
      <TableRow row={rows.modular} variant="cell" />
      <TableRow row={rows.fitOut} variant="cell" />
      <TableRow row={rows.baseBuild} variant="cell" />
      <TableRow row={rows.construction} variant="subtotal" currency="£" />
      <TableRow
        row={rows.preliminaries}
        variant="cell"
        labelTitle="Default value is 10% applied to Construction cost. Go to Cost Settings to adjust."
      />
      <TableRow
        row={rows.contingency}
        variant="cell"
        labelTitle="Default value is 3% applied to Construction and Prelims costs. Go to Cost Settings to adjust."
      />
      <TableRow
        row={rows.overheadsAndProfit}
        variant="cell"
        labelTitle="Default value is 5.5% applied to Construction, Prelims and Contingency costs. Go to Cost Settings to adjust."
      />
      <TableRow
        row={rows.total}
        variant="total"
        currency="£"
        labelTitle="Total cost is the sum of all costs. Go to Cost Settings to adjust."
      />
    </div>
  );
}

function TableRow(props: {
  row: Row;
  variant: "cell" | "subtotal" | "total";
  labelTitle?: string;
  currency?: string;
}) {
  return (
    <Table.Row>
      <Table.Col>
        <Table.Label label={props.row.label} variant={props.variant} title={props.labelTitle} />
      </Table.Col>
      <Table.Col>
        <Table.Cell value={props.row.cost} variant={props.variant} currency={props.currency} />
      </Table.Col>
      <Table.Col>
        <Table.Cell value={props.row.perUnit} variant={props.variant} currency={props.currency} />
      </Table.Col>
      <Table.Col>
        <Table.Cell value={props.row.perMetric} variant={props.variant} currency={props.currency} />
      </Table.Col>
    </Table.Row>
  );
}

function useRows(
  design: World,
  selectedBuilding: Building | null,
  metricsCalculations: MetricsCalculations,
  unit: Unit
): {
  total: Row;
  modular: Row;
  fitOut: Row;
  baseBuild: Row;
  construction: Row;
  preliminaries: Row;
  contingency: Row;
  overheadsAndProfit: Row;
} {
  const unitCount =
    selectedBuilding?.metrics.ApartmentModuleQuantityTotal ??
    design.architecturalMetrics.ApartmentModuleQuantityTotal;

  const gia = selectedBuilding?.metrics.GIATotal ?? design.architecturalMetrics.GIATotal;

  const costingView = selectedBuilding
    ? createCostingViewForBuilding(metricsCalculations, design.buildings.indexOf(selectedBuilding))
    : createCostingViewForTotal(metricsCalculations);

  const constructionFitOutValuePerMetric = calculateValuePerMetrics(
    costingView.summary.constructionFitOut,
    unitCount,
    gia
  );

  const constructionModulesValuePerMetric = calculateValuePerMetrics(
    costingView.summary.constructionModules,
    unitCount,
    gia
  );

  const constructionBaseBuildValuePerMetric = calculateValuePerMetrics(
    costingView.summary.constructionBaseBuild,
    unitCount,
    gia
  );

  const constructionSubTotalValue = sum([
    costingView.summary.constructionModules,
    costingView.summary.constructionFitOut,
    costingView.summary.constructionBaseBuild,
  ]);

  const constructionSubTotalValuePerMetric = calculateValuePerMetrics(
    constructionSubTotalValue,
    unitCount,
    gia
  );

  const onCostPreliminariesValuePerMetric = calculateValuePerMetrics(
    costingView.summary.preliminaries,
    unitCount,
    gia
  );

  const onCostContingencyValuePerMetric = calculateValuePerMetrics(
    costingView.summary.contingencies,
    unitCount,
    gia
  );

  const onCostOverheadsAndProfitValuePerMetric = calculateValuePerMetrics(
    costingView.summary.overheadsAndProfits,
    unitCount,
    gia
  );

  const construction = sum([
    costingView.summary.constructionModules,
    costingView.summary.constructionFitOut,
    costingView.summary.constructionBaseBuild,
  ]);

  const totalPerUnit = calculateValuePerMetrics(costingView.summary.total, unitCount, gia);

  return {
    total: {
      label: "Total",
      cost: costingView.summary.total,
      perUnit: totalPerUnit.unit,
      perMetric: localizeMetric(totalPerUnit, unit),
    },
    modular: {
      label: "Modular",
      cost: costingView.summary.constructionModules,
      perUnit: constructionModulesValuePerMetric.unit,
      perMetric: localizeMetric(constructionModulesValuePerMetric, unit),
    },
    fitOut: {
      label: "Fit out",
      cost: costingView.summary.constructionFitOut,
      perUnit: constructionFitOutValuePerMetric.unit,
      perMetric: localizeMetric(constructionFitOutValuePerMetric, unit),
    },
    baseBuild: {
      label: "Base Build",
      cost: costingView.summary.constructionBaseBuild,
      perUnit: constructionBaseBuildValuePerMetric.unit,
      perMetric: localizeMetric(constructionBaseBuildValuePerMetric, unit),
    },
    construction: {
      label: "Construction",
      cost: construction,
      perUnit: constructionSubTotalValuePerMetric.unit,
      perMetric: localizeMetric(constructionSubTotalValuePerMetric, unit),
    },
    preliminaries: {
      label: "Prelims",
      cost: costingView.summary.preliminaries,
      perUnit: onCostPreliminariesValuePerMetric.unit,
      perMetric: localizeMetric(onCostPreliminariesValuePerMetric, unit),
    },
    contingency: {
      label: "Contingency",
      cost: costingView.summary.contingencies,
      perUnit: onCostContingencyValuePerMetric.unit,
      perMetric: localizeMetric(onCostContingencyValuePerMetric, unit),
    },
    overheadsAndProfit: {
      label: "OH&P allowance",
      cost: costingView.summary.overheadsAndProfits,
      perUnit: onCostOverheadsAndProfitValuePerMetric.unit,
      perMetric: localizeMetric(onCostOverheadsAndProfitValuePerMetric, unit),
    },
  };
}
