import type React from "react";
import { useRef, useCallback } from "react";

export function useResize(options: {
  onDragStart?: (ev: MouseEvent) => void;
  onDragStop?: () => void;
  onDrag?: (ev: MouseEvent) => void;
}) {
  const optionsRef = useRef(options);
  optionsRef.current = options;
  return useCallback((ev: React.MouseEvent) => {
    trackDrag(ev.nativeEvent, {
      onDragStart: (ev) => optionsRef.current.onDragStart?.(ev),
      onDrag: (ev) => optionsRef.current.onDrag?.(ev),
      onDragStop: () => optionsRef.current.onDragStop?.(),
    });
  }, []);
}

export function trackDrag(
  evt: MouseEvent,
  options: {
    onDragStart?: (ev: MouseEvent) => void;
    onDragStop?: () => void;
    onDrag?: (ev: MouseEvent) => void;
  }
) {
  options.onDragStart?.(evt);
  evt.stopPropagation();
  evt.preventDefault();
  const onMouseUp = () => {
    options.onDragStop?.();
    window.removeEventListener("mousemove", onMouseMove);
    window.removeEventListener("mouseup", onMouseUp);
  };
  const onMouseMove = (ev: MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    options.onDrag?.(ev);
  };
  window.addEventListener("mouseup", onMouseUp);
  window.addEventListener("mousemove", onMouseMove);
}
