import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import { Cluster } from "../Cluster";
import { IconAlertTriangleFilled } from "@tabler/icons-react";
import type { Editor } from "src/types/Editor";
import { WorldBrief } from "src/types/WorldBrief";
import { useEvent } from "src/hooks/useEvent";

export const SiteBrief = observer(function SiteBrief(props: { editor: Editor }) {
  const brief = props.editor.world?.brief;
  const value = props.editor.world?.brief.buildingSeparation;
  const MAX_BUILDING_SEPARATION = 30;
  const [draft, setDraft] = useState<null | number>(null);
  const onSubmit = useEvent((value: number) => {
    if (!brief) return;
    const parsedValue = Math.max(0, Math.min(value, MAX_BUILDING_SEPARATION));
    setDraft(null);
    if (parsedValue === brief?.buildingSeparation) return;
    brief.setBuildingSeparation(parsedValue);
    props.editor.regenerate(false);
  });
  const isDefault = draft === null && value === WorldBrief.DEFAULT_BUILDING_SEPARATION;
  if (!brief) return null;
  return (
    <div className="bg-white rounded-t flex flex-col border-b border-neutral-4">
      <div className="border-b border-neutral-3 px-3 py-2 text-subtitle-2 text-neutral-8">Site</div>
      <div className="px-3 py-2 space-y-1">
        <div className="text-body-1 text-neutral-8">Building Separation</div>
        <SeparationRadioInput
          label="Default"
          checked={isDefault}
          onCheckedChange={() => {
            setDraft(null);
            onSubmit(WorldBrief.DEFAULT_BUILDING_SEPARATION);
          }}
          onInputValueChange={() => {}}
          readonlyInput
          autoFocus={false}
          inputValue={WorldBrief.DEFAULT_BUILDING_SEPARATION}
        />
        <SeparationRadioInput
          label="Custom"
          autoFocus
          checked={!isDefault}
          onCheckedChange={() => setDraft(brief.buildingSeparation)}
          readonlyInput={false}
          inputValue={draft ?? brief.buildingSeparation}
          onInputValueChange={onSubmit}
        />
        <Cluster visible={!isDefault}>
          <div className="bg-warning-2 p-2 items-center rounded-sm">
            <div className="flex space-x-1 ">
              <IconAlertTriangleFilled className="w-4 text-warning-6" />
              <span className="text-warning-8 text-subtitle-2">Regulations may apply</span>
            </div>

            <div
              className="text-info-6 text-link cursor-pointer pl-5"
              onClick={() =>
                window.open(
                  "https://help.tessa.ai/support/solutions/articles/150000088395-building-separation-offset",
                  "_blank"
                )
              }
            >
              Learn more
            </div>
          </div>
        </Cluster>
      </div>
    </div>
  );
});

function Checkbox(props: { checked: boolean; onChange: (checked: boolean) => void }) {
  return props.checked ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <circle cx="7" cy="7" r="6" stroke="#12736E" strokeWidth="2" />
      <circle cx="7" cy="7" r="4" fill="#12736E" />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      onClick={() => props.onChange(true)}
    >
      <circle cx="7" cy="7" r="6.5" stroke="#60666C" />
    </svg>
  );
}

function NumberInput(props: {
  readonly: boolean;
  value: number;
  autoFocus: boolean;
  suffix: string;
  onChange: (value: number) => void;
}) {
  const ref = useRef<HTMLInputElement>(null);
  const [draft, setDraft] = useState<string | null>(null);
  const onSubmit = () => {
    if (draft === null) return props.onChange(props.value);
    const value = parseFloat(draft);
    if (isNaN(value)) {
      setDraft(null);
      return;
    }
    props.onChange(parseFloat(value.toFixed(1)));
  };
  return (
    <div className="inline-flex flex-row border border-neutral-4 flex-grow-0 w-[84px] h-[22px] relative rounded-sm">
      <input
        ref={ref}
        autoFocus={props.autoFocus}
        className={classNames(
          "text-body-1 px-[6px] text-right w-full pr-5 absolute rounded-sm  border-neutral-4 ",
          props.readonly && "bg-neutral-1 text-neutral-6 border-neutral-4",
          props.autoFocus &&
            "focus:border-none focus:outline-none focus:ring-1.5 focus:ring-neutral-5"
        )}
        onKeyDown={(evt) => {
          if (evt.key === "Enter") ref.current?.blur();
        }}
        onBlur={onSubmit}
        value={draft ?? props.value}
        disabled={props.readonly}
        onInput={(evt) => setDraft(evt.currentTarget.value)}
      />
      <span className="text-body-1 text-neutral-6 absolute right-0 pr-1">m</span>
    </div>
  );
}

function SeparationRadioInput(props: {
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  readonlyInput: boolean;
  inputValue: number;
  label: string;
  autoFocus: boolean;
  onInputValueChange: (value: number) => void;
}) {
  return (
    <div className="flex flex-row h-[24px] max-h-[24px] items-center space-x-[6px]">
      <Checkbox checked={props.checked} onChange={props.onCheckedChange} />
      <span className="flex-1 text-body-1 text-neutral-8">{props.label}</span>
      {props.checked && (
        <NumberInput
          readonly={props.readonlyInput}
          value={props.inputValue}
          onChange={props.onInputValueChange}
          autoFocus={props.autoFocus}
          suffix="m"
        />
      )}
    </div>
  );
}
