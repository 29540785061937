import { makeAutoObservable } from "mobx";

export class Throttle {
  private delay: number;
  private invokeImmediately: boolean;
  private timer = 0;
  fetching = false;
  nextCallback: null | (() => Promise<void>) = null;
  constructor(delay: number, invokeImmediately: boolean) {
    this.delay = delay;
    this.invokeImmediately = invokeImmediately;
    makeAutoObservable(this);
  }
  private setFetching(fetching: boolean) {
    this.fetching = fetching;
  }
  private async post() {
    const callback = this.nextCallback;
    this.nextCallback = null;
    await callback?.();
    this.setFetching(false);
  }
  private setTimeout(callback: () => Promise<void>) {
    window.clearTimeout(this.timer);
    if (this.invokeImmediately && !this.fetching) {
      this.setFetching(true);
      this.post();
      return;
    }
    this.nextCallback = callback === this.nextCallback ? callback : async () => callback();
    this.timer = window.setTimeout(async () => {
      if (this.fetching) return;
      this.setFetching(true);
      await this.post();
      if (this.nextCallback) {
        setTimeout(this.nextCallback);
      }
    }, this.delay);
  }
  run(callback: () => Promise<void>) {
    this.setTimeout(callback);
    return () => window.clearTimeout(this.timer);
  }
}
