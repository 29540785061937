import { useMetricsCalculationsQuery } from "src/hooks/useMetricsCalculationsQuery";
import { CalculateCostPerValues } from "src/utils/CalculateCostPerValues";
import { observer } from "mobx-react-lite";
import { UnitType } from "../../types/UnitType";
import { InspectorGroup } from "./InspectorGroup";
import { InspectorRow, State } from "./InspectorRow";
import { CostGroupLoading } from "./CostGroupLoading";
import { ZoneType } from "src/types/Zone";
import type { Selection } from "src/types/Selection";
import type { World } from "src/types/World";
import { useActiveParams } from "src/hooks/useActiveParams";
import { sum } from "@project-rouge/service-core/data/number";
import { createCostingViewForTotal } from "@project-rouge/service-cost-client/utility/custom-view";
import { perSqmToPerSqft } from "src/hooks/meterToFeet";

export const CostMetricsInspector = observer(
  (props: { world: World; unit: UnitType; selection: Selection }) => {
    const projectId = useActiveParams().projectId ?? "";

    const selectedBuildings = props.selection.zones.at(0)?.buildings
      ? props.selection.zones.at(0)?.buildings
      : props.selection.buildings;

    const metricsCalculations = useMetricsCalculationsQuery(
      projectId,
      props.world,
      selectedBuildings
    );

    if (!metricsCalculations.data) return <CostGroupLoading />;
    const costingView = createCostingViewForTotal(metricsCalculations.data);

    const constructionSubTotalValue = sum([
      costingView.summary.constructionModules,
      costingView.summary.constructionFitOut,
      costingView.summary.constructionBaseBuild,
    ]);

    const calculateCostPerValues = CalculateCostPerValues(
      constructionSubTotalValue,
      props.world.architecturalMetrics.ApartmentModuleQuantityTotal,
      props.world.architecturalMetrics.GIATotal
    );
    if (
      props.selection.zones.length > 0 &&
      props.selection.zones.every((zone) => zone.zoneType === ZoneType.exclusion)
    )
      return null;
    return (
      <InspectorGroup label="Cost">
        <InspectorRow
          currency="£"
          state={State.FILLED}
          label={"Modules"}
          value={costingView.summary.constructionModules}
          decimalPoint={0}
        />
        <InspectorRow
          currency="£"
          state={State.FILLED}
          label={"Fit-out"}
          value={costingView.summary.constructionFitOut}
          decimalPoint={0}
        />
        <InspectorRow
          currency="£"
          state={State.FILLED}
          label={"Base build"}
          value={costingView.summary.constructionBaseBuild}
          decimalPoint={0}
        />
        <InspectorRow
          currency="£"
          state={State.FILLED}
          label={"Construction"}
          value={constructionSubTotalValue}
          decimalPoint={0}
        />
        <InspectorRow
          currency="£"
          state={State.FILLED}
          label={"Construction /unit"}
          value={calculateCostPerValues.unit}
          decimalPoint={0}
          unit={"/unit"}
        />
        <InspectorRow
          currency="£"
          state={State.FILLED}
          label={"Construction /area"}
          value={
            props.unit === UnitType.Metric
              ? calculateCostPerValues.sqm
              : perSqmToPerSqft(calculateCostPerValues.sqm)
          }
          decimalPoint={0}
          unit={props.unit === UnitType.Metric ? "/sqm" : "/sqft"}
        />
      </InspectorGroup>
    );
  }
);
