import { observer } from "mobx-react-lite";
import { ZoneType } from "src/types/Zone";
import { ChevronDownIcon, LockClosedIcon } from "@heroicons/react/20/solid";
import { useBoolean } from "src/hooks/useBoolean";
import { useWindowEvent } from "src/hooks/useWindowEvent";
import { LockOpenIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { useEvent } from "src/hooks/useEvent";
import { useLayoutEffect, useRef, useState } from "react";
import { InspectorGroup } from "./InspectorGroup";
import type { Selection } from "src/types/Selection";
import { IconEdit } from "@tabler/icons-react";
import type { Zone } from "src/types/Zone";
import { autorun } from "mobx";
import type { World } from "src/types/World";

export const ZoneInspector = observer(function ZoneInspector(props: {
  selection: Selection;
  world: World | null;
  readonly: boolean;
  onUpdate?: () => void;
}) {
  const zones = props.selection.zones.length ? props.selection.zones : props.world?.zones ?? [];
  return (
    <InspectorGroup label="Zones">
      {zones.map(
        (zone) =>
          props.world && (
            <ZoneSettings
              key={zone.id}
              zone={zone}
              readonly={props.readonly}
              world={props.world}
              onUpdate={props.onUpdate}
            />
          )
      )}
    </InspectorGroup>
  );
});

const usageOptions = [
  { label: "Buildable", id: ZoneType.buildable },
  { label: "Exclusion", id: ZoneType.exclusion },
] as const;

const ZoneSettings = observer(function ZoneSettings(props: {
  world: World;
  zone: Zone;
  readonly: boolean;
  onUpdate?: () => void;
}) {
  const onZoneTypeChange = useEvent((id: (typeof usageOptions)[number]["id"]) => {
    props.zone.setZoneType(id);
    props.onUpdate?.();
  });
  const onLockChange = useEvent((locked: boolean) => {
    props.zone.setLocked(locked);
    props.onUpdate?.();
  });
  return (
    <div className="flex flex-col border-b border-neutral-4 items-center flex-1 space-y-2 py-[10px]">
      <div className="flex flex-row flex-1 w-full items-center">
        <ZonePreview zone={props.zone} />
        <div className="flex flex-row flex-1 items-center group">
          <Label text={props.zone.label} onChange={props.zone.setLabel} readonly={props.readonly} />
          {props.zone.zoneType === ZoneType.buildable && (
            <LockButton locked={props.zone.locked} onChange={onLockChange} />
          )}
        </div>
      </div>
      <SelectInput
        options={usageOptions}
        selected={props.zone.zoneType}
        readonly={props.zone.locked || props.readonly}
        onChange={onZoneTypeChange}
      />
    </div>
  );
});

const Label = observer(function Label(props: {
  text: string;
  onChange: (text: string) => void;
  readonly: boolean;
}) {
  const [draft, setDraft] = useState<null | string>(null);
  const onSubmit = useEvent(() => {
    if (!draft) return;
    setDraft(null);
    if (props.text === draft) return;
    props.onChange(draft);
  });
  return (
    <div className="flex flex-row flex-1">
      {draft === null && (
        <div className="text-neutral-7 text-subtitle-2 flex-1 truncate flex flex-row">
          {props.text}
        </div>
      )}
      {draft !== null && (
        <input
          autoFocus
          className="px-1"
          value={draft}
          onInput={(evt) => setDraft(evt.currentTarget.value)}
          onBlur={onSubmit}
          onKeyDown={(evt) => {
            if (evt.key === "Enter") {
              evt.preventDefault();
              onSubmit();
            } else if (evt.key === "Escape") {
              evt.preventDefault();
              setDraft(null);
            }
          }}
        />
      )}
      {draft === null && (
        <button
          disabled={props.readonly}
          className={classNames(
            "text-neutral-5 rounded-sm box-border hidden justify-center items-center px-1",
            !props.readonly && "hover:text-neutral-6 cursor-pointer group-hover:flex"
          )}
          onClick={props.readonly ? undefined : () => setDraft(props.text)}
        >
          <IconEdit className="w-4 h-4" />
        </button>
      )}
    </div>
  );
});

const LockButton = observer(function LockButton(props: {
  locked: boolean;
  onChange: (locked: boolean) => void;
}) {
  const lock = props.locked ? <LockClosedIcon /> : <LockOpenIcon />;
  return (
    <button
      onClick={() => props.onChange(!props.locked)}
      className={classNames(
        "w-6 h-6 p-[6px] border box-border rounded-sm border-neutral-5 ml-2",
        !props.locked && "bg-neutral-1 text-neutral-6",
        props.locked && "bg-primary-6 text-white"
      )}
    >
      {lock}
    </button>
  );
});

const ZonePreview = observer(function ZonePreview(props: { zone: Zone }) {
  const ref = useRef<HTMLCanvasElement>(null);
  useLayoutEffect(() => {
    return autorun(() => {
      const ctx = ref.current?.getContext("2d");
      if (!ctx) return;
      const { height, left, top, width } = props.zone.bounds;
      const padding = 10;
      const scaleSize = Math.max(width, height);
      // scale the polygon to fit the canvas
      const points = props.zone.outerRing.map((p) => {
        const px = p[0];
        const py = p[2];
        const x = ((px - left) / scaleSize) * (ctx.canvas.width - padding);
        const y = ((py - top) / scaleSize) * (ctx.canvas.height - padding);
        return [x, y];
      });
      // center the polygon on canvas
      const minX = Math.min(...points.map((p) => p[0]));
      const maxX = Math.max(...points.map((p) => p[0]));
      const minY = Math.min(...points.map((p) => p[1]));
      const maxY = Math.max(...points.map((p) => p[1]));
      const offsetX = (ctx.canvas.width - (maxX - minX)) / 2;
      const offsetY = (ctx.canvas.height - (maxY - minY)) / 2;
      points.forEach((p) => {
        p[0] += offsetX;
        p[1] += offsetY;
      });

      ctx.imageSmoothingEnabled = true;
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      ctx.fillStyle = props.zone.zoneType === ZoneType.buildable ? "#DEEFEE" : "#F9CCD0";
      ctx.beginPath();
      ctx.strokeStyle = props.zone.zoneType === ZoneType.buildable ? "#92C9C6" : "#F6B3B9";
      ctx.lineWidth = 1;
      points.forEach(([x, y], i) => {
        if (i === 0) ctx.moveTo(x, y);
        else ctx.lineTo(x, y);
      });
      ctx.stroke();
      ctx.closePath();
      ctx.fill();
    });
  }, [props.zone.bounds, props.zone.outerRing, props.zone.zoneType]);
  const style = { width: 32, height: 32 };
  return (
    <div style={style} className="border border-neutral-4 mr-[6px] box-border rounded-sm">
      <canvas
        ref={ref}
        style={{ ...style, transform: "rotate(0deg)" }}
        width={style.width}
        height={style.height}
      />
    </div>
  );
});

interface SelectInputOption {
  id: string;
  label: string;
}

const SelectInput = observer(function SelectInput<T extends readonly SelectInputOption[]>(props: {
  options: T;
  selected: string;
  readonly: boolean;
  onChange?: (id: T[number]["id"]) => void;
}) {
  const selected = props.options.find((option) => option.id === props.selected) ?? props.options[0];
  const optionVisible = useBoolean();
  useWindowEvent("click", optionVisible.off);
  return (
    <div className="flex flex-row text-body-1 text-neutral-7 h-[24px] items-center w-full">
      <div
        className="relative border border-neutral-5 flex flex-row rounded-sm flex-1"
        onClick={(evt) => evt.stopPropagation()}
      >
        <div
          className={classNames(
            "flex flex-1 flex-row px-[6px] py-[2px]",
            props.readonly && "cursor-default bg-neutral-2",
            !props.readonly && "cursor-pointer"
          )}
          onClick={!props.readonly ? optionVisible.on : undefined}
        >
          <span className="flex-1">{selected.label}</span>
          <ChevronDownIcon className={classNames("w-4", props.readonly && "opacity-0")} />
        </div>
        {optionVisible.isOn && (
          <div className="absolute bg-white w-full border border-neutral-5">
            {props.options.map((option) => (
              <div
                className="px-[6px] hover:bg-primary-1 cursor-pointer"
                key={option.id}
                onClick={
                  props.readonly
                    ? undefined
                    : () => {
                        props.onChange?.(option.id);
                        optionVisible.off();
                      }
                }
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
});
