import { useRedirect } from "src/hooks/useRedirect";
import { useQueryClient } from "@tanstack/react-query";
import { useApi } from "src/contexts/api.context";
import { Modal } from "src/components/Modal";
import { useEvent } from "src/hooks/useEvent";
import { ProjectsQueryKey } from "src/hooks/useProjectQuery";
import { useToaster } from "src/contexts/toaster.context";
import { ToastType } from "./Toast";

export function DeleteProjectModal(props: {
  onClose: () => void;
  projectName: string;
  projectId: string;
}) {
  const api = useApi();
  const redirect = useRedirect();
  const queryClient = useQueryClient();
  const toaster = useToaster();

  const deleteProject = useEvent(async () => {
    await api.deleteProject({ projectId: props.projectId });
    queryClient.invalidateQueries(ProjectsQueryKey);
    toaster.show({
      text: "Deleted",
      type: ToastType.INFO,
      autoHide: true,
      id: crypto.randomUUID(),
    });
    props.onClose();
    redirect.home();
  });
  return (
    <Modal.Portal isOpen>
      <Modal.Card onClose={props.onClose}>
        <Modal.Header
          label={
            <span className="text-subtitle-1">
              Delete <span className="italic">{props.projectName}</span> ?
            </span>
          }
          onClose={props.onClose}
        />

        <span className="text-body-1">
          This will delete the project permanently. This action cannot be undone.
        </span>
        <div className="flex flex-row justify-end space-x-2">
          <Modal.Actions>
            <Modal.ActionCancelButton label="Cancel" onClick={props.onClose} />
            <Modal.ActionDangerButton label="Delete" onClick={deleteProject} />
          </Modal.Actions>
        </div>
      </Modal.Card>
    </Modal.Portal>
  );
}
