import { type V3 } from "@project-rouge/rg-core";

export function GetLinesSegementsIntersections(pointsA: V3[], pointsB: V3[]) {
  const intersections: V3[] = [];
  for (let i = 0; i < pointsA.length - 1; i++) {
    const x1 = pointsA[i][0];
    const y1 = pointsA[i][2];
    const x2 = pointsA[i + 1][0];
    const y2 = pointsA[i + 1][2];
    for (let n = 0; n < pointsB.length - 1; n++) {
      const x3 = pointsB[n][0];
      const y3 = pointsB[n][2];
      const x4 = pointsB[n + 1][0];
      const y4 = pointsB[n + 1][2];

      const value = GetIntersection(x1, y1, x2, y2, x3, y3, x4, y4);
      if (value) intersections.push([value.x, 0, value.y]);
    }
  }
  return intersections;
}

function GetIntersection(
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  x3: number,
  y3: number,
  x4: number,
  y4: number,
  tolerance: number = 0.001
) {
  // Check if none of the lines are of length 0
  if (
    (Math.abs(x1 - x2) < tolerance && Math.abs(y1 - y2) < tolerance) ||
    (Math.abs(x3 - x4) < tolerance && Math.abs(y3 - y4) < tolerance)
  ) {
    return false;
  }

  const denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);

  // Lines are parallel
  if (Math.abs(denominator) < tolerance) {
    return false;
  }

  let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
  let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;

  // is the intersection along the segments
  if (ua < -tolerance || ua > 1 + tolerance || ub < -tolerance || ub > 1 + tolerance) {
    return false;
  }

  // Return an object with the x and y coordinates of the intersection
  let x = x1 + ua * (x2 - x1);
  let y = y1 + ua * (y2 - y1);

  return { x, y };
}
