import { CheckIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Modal } from "src/components/Modal";
import { ArrowUturnLeftIcon, XMarkIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { useMemo, useState } from "react";
import { Cluster } from "src/components/Cluster";
import { useEvent } from "src/hooks/useEvent";
import { RegisteredColumns } from "./RegisteredColumns";
import type { RegisteredColumnId } from "./RegisteredColumnId";
import { observer } from "mobx-react-lite";
import { unitSuffix, type UnitCategory, type UnitType } from "src/types/UnitType";

interface Tag {
  id: string;
  label: string;
  symbol: string | null;
}

export const ExplorePanelSettings = observer(function ExplorePanelSettings(props: {
  onClose: () => void;
  currentSelectedTags: string[];
  defaultColumns: string[];
  unitType: UnitType;
  onChange: (data: string[]) => void;
}) {
  const [selectedColumns, setSelectedColumns] = useState(props.currentSelectedTags);

  const onSelect = useEvent((id: string, selected: boolean) => {
    if (selected) {
      setSelectedColumns((currentColumns) => {
        return [...currentColumns, id];
      });
    } else {
      setSelectedColumns((currentColumns) => {
        const newColumns = [];
        for (const i of currentColumns) {
          if (i !== id) {
            newColumns.push(i);
          }
        }
        return newColumns;
      });
    }
  });

  const selectedTags = useMemo<Tag[]>(() => {
    const excludedImage = "IMAGE";
    const excludedName = "NAME";
    const excludedIDs = [excludedImage, excludedName];

    const filteredSelectedColumns = selectedColumns.filter((label) => !excludedIDs.includes(label));

    return filteredSelectedColumns.map((id) => {
      const column = RegisteredColumns[id as RegisteredColumnId];
      return {
        id,
        label: column.label,
        symbol: unitSuffix(props.unitType, column.unitCategory),
      };
    });
  }, [props.unitType, selectedColumns]);

  const handleOnChange = useEvent(() => {
    props.onChange(selectedColumns);
    props.onClose();
  });

  const [search, setSearch] = useState("");

  const useSelectedColumns = useMemo(() => {
    return new Set(selectedColumns);
  }, [selectedColumns]);

  return (
    <Modal.Portal isOpen>
      <div className="p-5 overflow-hidden h-screen">
        <Modal.Card onClose={props.onClose}>
          <div className="overflow-hidden space-y-2 flex flex-col h-full w-[772px]">
            <div className="flex flex-col space-y-2">
              <Modal.Header label="Choose columns" onClose={props.onClose} />
              <Header
                onResetSelection={() => setSelectedColumns(props.defaultColumns)}
                selectedTags={selectedTags}
              />
              <Selection selectedTags={selectedTags} onSelect={onSelect} />
              <Search value={search} onChange={setSearch} />
            </div>
            <div className="overflow-y-scroll  flex flex-col flex-1 space-y-[14px] relative">
              <Table
                table={DesignTable}
                columnsCount={3}
                onSelect={onSelect}
                selectedIds={useSelectedColumns}
                filter={search}
                unitType={props.unitType}
              />

              <div className="pt-[8px]">
                <Table
                  table={CostTable}
                  columnsCount={3}
                  onSelect={onSelect}
                  selectedIds={useSelectedColumns}
                  filter={search}
                  unitType={props.unitType}
                />
              </div>
              <div className="pt-[8px]">
                <Table
                  table={SustainabilityTable}
                  columnsCount={2}
                  onSelect={onSelect}
                  selectedIds={useSelectedColumns}
                  filter={search}
                  unitType={props.unitType}
                />
              </div>
            </div>
          </div>
          <Modal.Actions>
            <Modal.ActionCancelButton label="Cancel" onClick={props.onClose} />
            <Modal.ActionPrimaryButton label="Update" onClick={handleOnChange} />
          </Modal.Actions>
        </Modal.Card>
      </div>
    </Modal.Portal>
  );
});

interface CellData {
  id: string;
  label: string;
  unitCategory: UnitCategory | null;
}

interface ColumnData {
  id: string;
  label: string;
  cells: CellData[];
}

interface TableData {
  label: string;
  columns: ColumnData[];
}

function Table(props: {
  table: TableData;
  columnsCount: number;
  selectedIds: Set<string>;
  filter: string;
  unitType: UnitType;
  onSelect: (id: TagId, selected: boolean) => void;
}) {
  const groups = useMemo(() => {
    const columns = props.table.columns;
    const columnsCount = props.columnsCount;
    const groups: ColumnData[][] = [];
    for (let i = 0; i < columns.length; i += columnsCount) {
      groups.push(columns.slice(i, i + columnsCount));
    }
    return groups;
  }, [props.columnsCount, props.table.columns]);

  const { filteredGroups, count } = useMemo(() => {
    const filteredGroups: ColumnData[][] = [];
    let count = 0;
    for (const group of groups) {
      const filteredColumns: ColumnData[] = [];
      for (const column of group) {
        const filteredCells: CellData[] = [];
        for (const cell of column.cells) {
          const { includes } = filterText(cell.label, props.filter);
          if (includes) {
            filteredCells.push(cell);
            count += 1;
          }
        }
        if (filteredCells.length > 0) {
          filteredColumns.push({ ...column, cells: filteredCells });
        }
      }
      if (filteredColumns.length > 0) {
        filteredGroups.push(filteredColumns);
      }
    }
    return { filteredGroups, count };
  }, [groups, props.filter]);

  return (
    <div className="flex flex-1 flex-col">
      <div className="p-[6px] bg-neutral-2 text-neutral-6 text-subtitle-2 sticky top-0 space-x-1 ">
        <span>{props.table.label}</span>
        <Cluster visible={!!props.filter}>
          <span className="text-overline">({count})</span>
        </Cluster>
      </div>
      <div className="flex flex-col space-y-2">
        {filteredGroups.map((group, index) => (
          <div key={index} className="flex flex-1 flow-row">
            {group.map((column) => (
              <Column
                key={column.id}
                column={column}
                selectedIds={props.selectedIds}
                onSelect={props.onSelect}
                filter={props.filter}
                unitType={props.unitType}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

function Column(props: {
  column: ColumnData;
  onSelect: (id: TagId, selected: boolean) => void;
  selectedIds: Set<string>;
  filter: string;
  unitType: UnitType;
}) {
  return (
    <div className="flex flex-1 flex-col space-y-1">
      <div className="border-neutral-4 border-y text-neutral-6 text-overline p-[6px] sticky top-[32px] bg-white">
        {props.column.label}
      </div>
      {props.column.cells.map((row) => {
        return (
          <Cell
            key={row.id}
            id={row.id}
            label={row.label}
            unitCategory={row.unitCategory}
            unitType={props.unitType}
            onSelect={props.onSelect}
            selected={props.selectedIds.has(row.id)}
            filter={props.filter}
          />
        );
      })}
    </div>
  );
}

function Checkbox(props: { checked: boolean; onChange: (checked: boolean) => void }) {
  return (
    <div
      onClick={() => props.onChange(!props.checked)}
      className={classNames(
        "w-4 h-4 rounded min-w-4 min-h-4 max-w-4 max-h-4 cursor-pointer",
        !props.checked && "border-neutral-6 border",
        props.checked && "bg-primary-6"
      )}
    >
      <Cluster visible={props.checked}>
        <CheckIcon className="w-4 h-4 text-white" />
      </Cluster>
    </div>
  );
}

function Cell(props: {
  id: string;
  label: string;
  unitCategory: UnitCategory | null;
  unitType: UnitType;
  selected: boolean;
  filter: string;
  onSelect: (id: TagId, selected: boolean) => void;
}) {
  const onSelect = useEvent((checked: boolean) => props.onSelect(props.id as TagId, checked));
  let CO2Index = props.label.indexOf("2");
  return (
    <div className="flex flex-nowrap whitespace-nowrap flex-row items-center space-x-1 px-[6px]">
      <div className="flex items-center space-x-[6px]">
        <Checkbox checked={props.selected} onChange={onSelect} />
        {CO2Index < 0 && <span className="text-neutral-8 text-body-1 ">{props.label}</span>}
        {CO2Index > 0 && subValue(props.label, CO2Index)}
      </div>
      <div className="items-center text-neutral-6 text-body-2">
        {props.unitCategory && <span>{unitSuffix(props.unitType, props.unitCategory)}</span>}
      </div>
    </div>
  );
}

function Search(props: { value: string; onChange: (value: string) => void }) {
  return (
    <div className="relative items-center flex">
      <input
        className="flex h-7 border w-full border-neutral-5 rounded-sm px-7 text-body-1"
        placeholder="Search"
        value={props.value}
        onChange={(ev) => props.onChange(ev.target.value)}
      />
      <MagnifyingGlassIcon className="w-4 h-4 absolute ml-[6px]" />
    </div>
  );
}

function Selection(props: {
  selectedTags: Tag[];
  onSelect: (id: TagId, selected: boolean) => void;
}) {
  return (
    <div className="h-[132px] bg-neutral-1 w-full border border-neutral-4 grid grid-cols-3 auto-rows-[32px] gap-2 p-2 overflow-y-scroll">
      {props.selectedTags.map((tag) => (
        <SelectedTag key={tag.id} tag={tag} onSelect={props.onSelect} />
      ))}
    </div>
  );
}

function SelectedTag(props: { tag: Tag; onSelect: (id: TagId, selected: boolean) => void }) {
  const CO2Index = props.tag.label.indexOf("2");
  return (
    <div className="bg-white flex flex-row flex-1 border border-neutral-5 rounded items-center pl-2 pr-1 py-1">
      <div className="flex flex-1 items-center whitespace-nowrap truncate space-x-1">
        {CO2Index < 0 && <span className="text-neutral-8 text-body-1 ">{props.tag.label}</span>}
        {CO2Index > 0 && subValue(props.tag.label, CO2Index)}
        <div>
          <span className="text-neutral-6 text-body-2">{props.tag.symbol}</span>
        </div>
      </div>
      <button
        className="rounded-sm p-1 hover:bg-primary-2"
        onClick={() => props.onSelect(props.tag.id as TagId, false)}
      >
        <XMarkIcon className="w-4 h-4" />
      </button>
    </div>
  );
}

function Header(props: { onResetSelection: () => void; selectedTags: Tag[] }) {
  return (
    <div className="flex justify-between flex-1 basis-[32px] items-center">
      <span className="text-overline space-x-1">
        <span className="text-neutral-7">Selected</span>
        <span className="text-neutral-6">{props.selectedTags.length}</span>
      </span>
      <button
        className="px-1 py-[2px] flex flex-row items-center space-x-1 text-primary-6 text-button"
        onClick={props.onResetSelection}
      >
        <ArrowUturnLeftIcon className="w-4 h-4" />
        <span>Reset to default</span>
      </button>
    </div>
  );
}

function filterText(text: string, filter: string) {
  const includes = text.toLowerCase().includes(filter.toLowerCase());
  const startIndex = text.toLowerCase().indexOf(filter.toLowerCase());
  const endIndex = startIndex + filter.length;
  return { includes, startIndex, endIndex };
}

type TagId = keyof typeof RegisteredColumns;

const DesignTable: TableData = {
  label: "Design",
  columns: [
    {
      id: "quantity",
      label: "Quantity",
      cells: [
        RegisteredColumns.BUILDINGS,
        RegisteredColumns.UNITS,
        RegisteredColumns.MODULES,
        RegisteredColumns.FLOORS,
        RegisteredColumns.PODIUMLEVELS,
        RegisteredColumns.FLOORSRESIDENTIAL,
        RegisteredColumns.ENTRANCES,
        RegisteredColumns.CORES,
        RegisteredColumns.FIREFIGHTINGCORES,
        RegisteredColumns.LIFTCORES,
        RegisteredColumns.NORTHFACINGUNITS,
        RegisteredColumns.PARTMCOMPLIANTUNIT,
        RegisteredColumns.DUALASPECT,
      ],
    },
    {
      id: "measurement",
      label: "Measurement",
      cells: [
        RegisteredColumns.BUILDINGHEIGHT,
        RegisteredColumns.FFL,
        RegisteredColumns.GEA,
        RegisteredColumns.GIA,
        RegisteredColumns.NIA,
        RegisteredColumns.BUILDINGFOOTPRINT,
        RegisteredColumns.COMMERCIALAREA,
        RegisteredColumns.NONRESIDENTIALAREA,
        RegisteredColumns.COREANDCORRIDOR,
        RegisteredColumns.SERVICEAREA,
        RegisteredColumns.AMENITIESAREA,
        RegisteredColumns.GLAZEDFACADEAREA,
        RegisteredColumns.GROSSFACADEAREA,
        RegisteredColumns.NETFACADEAREA,
        RegisteredColumns.ROOFAREA,
        RegisteredColumns.CONVEXFACADECORNER,
        RegisteredColumns.CONCAVEFACADECORNER,
      ],
    },
    {
      id: "calculated",
      label: "Calculated",
      cells: [
        RegisteredColumns.DENSITY,
        RegisteredColumns.DENSITY_HABITABLEROOMS,
        RegisteredColumns.RESIDENTIALEFFICIENCY,
        RegisteredColumns.BUILDINGEFFICIENCY,
        RegisteredColumns.LANDCOVERAGE,
        RegisteredColumns.UNITMIX,
        RegisteredColumns.UNITSPERCORE,
        RegisteredColumns.WALLTOFLOORRATIO,
      ],
    },
  ],
};

const CostTable: TableData = {
  label: "Cost",
  columns: [
    {
      id: "constructionCost",
      label: "Construction cost",
      cells: [
        RegisteredColumns.MODULESCOST,
        RegisteredColumns.CONSTRUCTIONCOST,
        RegisteredColumns.FITOUT,
        RegisteredColumns.BASEBUILD,
      ],
    },
    {
      id: "totalCost",
      label: "Total cost",
      cells: [
        RegisteredColumns.PRELIMS,
        RegisteredColumns.TOTALCOST,
        RegisteredColumns.CONTINGENCY,
        RegisteredColumns.OHAPALLOWANCE,
      ],
    },
    {
      id: "outputs",
      label: "Outputs",
      cells: [RegisteredColumns.CONSTRUCTIONSQFT, RegisteredColumns.CONSTRUCTIONUNIT],
    },
  ],
};

const SustainabilityTable: TableData = {
  label: "Sustainability",
  columns: [
    {
      id: "embodiedCarbonPerArea",
      label: "Embodied carbon per area",
      cells: [
        RegisteredColumns.CO2FOOTPRINTSQFT,
        RegisteredColumns.SUBSTRUCTURECO2FOOTPRINT,
        RegisteredColumns.SUPERSTRUCTURECO2FOOTPRINT,
        RegisteredColumns.CONCRETECO2FOOTPRINT,
        RegisteredColumns.STEELCO2FOOTPRINT,
      ],
    },
    {
      id: "embodiedCarbon",
      label: "Total embodied carbon",
      cells: [
        RegisteredColumns.CO2FOOTPRINT,
        RegisteredColumns.SUBSTRUCTURECO2FOOTPRINT2,
        RegisteredColumns.SUPERSTRUCTURECO2FOOTPRINT2,
        RegisteredColumns.CONCRETECO2FOOTPRINT2,
        RegisteredColumns.STEELCO2FOOTPRINT2,
      ],
    },
    {
      id: "operationalCarbon",
      label: "Operational carbon",
      cells: [RegisteredColumns.INDICATIVEFORMFACTOR],
    },
  ],
};
function subValue(label: string, CO2Index: number) {
  let frontTag = "";
  let smallTag = "";
  let backTag = "";

  frontTag = label.slice(0, CO2Index);
  smallTag = label.substring(CO2Index, CO2Index + 1);
  backTag = label.slice(CO2Index + 1, label.length);

  return (
    <span className="text-neutral-8 text-body-1">
      {frontTag}
      <sub>{smallTag}</sub>
      {backTag}
    </span>
  );
}
