import { State } from "./InspectorRow";
import classNames from "classnames";
import { ViewValue } from "./ViewValue";
import { roundToDecimal } from "../BriefPanel/UnitMixSection/roundToDecimal";
import { MathSum } from "src/utils/MathSum";
import { observer } from "mobx-react-lite";
import type { World } from "src/types/World";
import type { Selection } from "src/types/Selection";
import type {
  ArchitecturalMetrics,
  ArchitecturalMetricsSnapshot,
} from "src/types/ArchitecturalMetrics";

export const RoomUnitGroup = observer(function RoomUnitGroup({
  world,
  selection,
}: {
  world: World;
  selection: Selection;
}) {
  const buildingMetric = selection.buildings.at(0)?.metrics;
  const zoneMetrics = selection.zones.at(0)?.metrics;
  const siteMetrics = world.architecturalMetrics;

  const metrics = buildingMetric ? buildingMetric : zoneMetrics ? zoneMetrics : siteMetrics;

  const totalUnit = MathSum(metrics.UnitCategoryMixPerCategory.map((cateogry) => cateogry.count));
  const percentageCategories = useCategories(metrics, 1);

  const sortCategories = percentageCategories.slice().sort((a, b) => (a.name < b.name ? -1 : 1));
  return (
    <div className="border-b-[1px] border-neutral-3 pb-2 ">
      <div className="w-[248px] bg-white h-8 flex justify-between text-body-1 items-center">
        <span className="text-neutral-6">Units</span>

        <div className="text-end flex items-center">
          <span className="text-neutral-6 pr-1">Total:</span>
          <span className=" text-neutral-8">{totalUnit}</span>
        </div>
      </div>
      {sortCategories.map((cateogry) => (
        <RoomUnit
          key={cateogry.name}
          state={State.FILLED}
          label={cateogry.name}
          value={cateogry.percentage}
          room={cateogry.count}
          decimalPoint={1}
        />
      ))}
    </div>
  );
});

export function useCategories(
  metrics: ArchitecturalMetrics | ArchitecturalMetricsSnapshot,
  decimal: number
) {
  const categories = metrics.UnitCategoryMixPerCategory.map((cateogry) => ({
    ...cateogry,
    percentage: roundToDecimal(cateogry.ratio * 100, decimal),
  }));
  const sum = MathSum(categories.map((cateogry) => cateogry.percentage));
  const lastItem = categories.at(categories.length - 1);
  if (!lastItem) return categories;
  // fix last item to 100% total
  lastItem.percentage += 100 - sum;
  return categories;
}

export const getRoomColor = (name: string) => {
  switch (name) {
    case "1B1P":
      return "#F57EB6";
    case "1B2P":
      return "#D06381";
    case "2B3P":
      return "#4D9CDD";
    case "2B4P":
      return "#4DB58E";
    case "3B5P":
      return "#FFD87D";
    case "3B6P":
      return "#FF9562";
  }
};
export const RoomUnit = observer(function RoomUnit(props: {
  label: string;
  value: number;
  state: State;
  decimalPoint?: number;
  room: number;
}) {
  return (
    <div
      className="w-[248px] bg-white h-5 flex justify-between 
      text-body-1 text-neutral-8 "
    >
      <div className="flex items-center">
        <span className="text-neutral-8 pr-1 w-10">{props.label}</span>
        <div className="h-2 relative w-[103px]  overflow-hidden">
          <div
            className={classNames(
              "w-full h-full  absolute",
              props.state === State.EMPTY && "bg-neutral-2",
              props.state === State.LOADING && "bg-primary-2",
              props.state === State.FILLED && "bg-primary-2"
            )}
          />
          {props.state === State.FILLED && (
            <div
              className="h-full relative rounded-sm"
              style={{ width: `${props.value}%`, background: `${getRoomColor(props.label)}` }}
            />
          )}
        </div>
      </div>

      <div className="text-end flex items-center text-neutral-6">
        {props.state === State.EMPTY && <span className="px-1 ">-</span>}
        {props.state === State.FILLED && (
          <ViewValue value={props.value} decimalPoint={props.decimalPoint} />
        )}
        {props.state === State.LOADING && (
          <div className="mx-1 w-3 h-3 border-[2px] rounded-full border-primary-6 border-t-transparent animate-spin" />
        )}
        <span className="text-start w-[29px]">%</span>
        {props.state === State.EMPTY && <span>-</span>}
        {props.state === State.FILLED && (
          <span className="text-neutral-8 w-[30px]">{props.room}</span>
        )}
        {props.state === State.LOADING && <span>-</span>}
      </div>
    </div>
  );
});
