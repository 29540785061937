import { Blocks } from "./Blocks";
import { Thumbnail } from "./Thumbnail";
import { type BlockId, ALL_BLOCKS } from "./BlockId";
import type { World } from "src/types/World";

import { observer } from "mobx-react-lite";

export const BlockTags = observer(function BlockTags(props: {
  onChange: (id: BlockId) => void;
  blockId: BlockId;
  design: World;
}) {
  const buildings = props.design.buildings;
  // const buildings = useBuildings(props.design);
  return (
    <Thumbnail world={props.design}>
      <Blocks
        name="Total"
        onClick={() => props.onChange(ALL_BLOCKS)}
        active={props.blockId === ALL_BLOCKS}
      />
      {buildings.map((building, i) => (
        <Blocks
          key={i}
          name={building.label}
          onClick={() => props.onChange(i)}
          active={props.blockId === i}
        />
      ))}
    </Thumbnail>
  );
});
