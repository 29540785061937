import { Table } from "../Table";
import { type Unit } from "../UnitSelect";
import { MathSum } from "src/utils/MathSum";
import { meterToFeet } from "../../../hooks/meterToFeet";
import { useMemo } from "react";
import type { ArchitecturalMetrics } from "src/types/ArchitecturalMetrics";

interface Row {
  label: string;
  avgArea: number;
  quantity: number;
  mix: number;
}

export function UnitMixTable(props: { metrics: ArchitecturalMetrics; unit: Unit }) {
  const { rows, totalRow } = useRows(props.metrics, props.unit);
  return (
    <div>
      <Table.Sticky>
        <Table.Head label="Unit Mix" variant="section" />

        <Table.Row>
          <Table.Head label="Type" variant="header" position="start" />
          <Table.Head label="Avg area" variant="header" position="end" symbol={props.unit} />
          <Table.Head label="Quantity" variant="header" position="end" />
          <Table.Head label="Mix" variant="header" position="end" />
        </Table.Row>
      </Table.Sticky>
      {rows.map((row) => (
        <TableRow key={row.label} row={row} variant="cell" />
      ))}
      <TableRow row={totalRow} variant="total" />
    </div>
  );
}

function TableRow(props: { row: Row; variant: "cell" | "total" }) {
  return (
    <Table.Row key={props.row.label}>
      <Table.Col>
        <Table.Label label={props.row.label} variant={props.variant} />
      </Table.Col>
      <Table.Col>
        <Table.Cell value={props.row.avgArea} precision={2} variant={props.variant} />
      </Table.Col>
      <Table.Col>
        <Table.Cell value={props.row.quantity} variant={props.variant} />
      </Table.Col>
      <Table.Col>
        <Table.Cell value={props.row.mix} precision={1} variant={props.variant} symbol="%" />
      </Table.Col>
    </Table.Row>
  );
}

function useRows(metrics: ArchitecturalMetrics, unit: Unit): { rows: Row[]; totalRow: Row } {
  return useMemo(() => {
    const rows: Row[] = metrics.UnitCategoryMixPerCategory.map((mix, index) => {
      return {
        label: mix.name,
        avgArea: metrics.areaPerApartmentType[index],
        quantity: mix.count,
        mix: mix.ratio * 100,
      };
    }).sort((a, b) => a.label.localeCompare(b.label));

    const avgArea = Math.trunc(
      MathSum(
        metrics.UnitCategoryMixPerCategory.map((_, index) => metrics.areaPerApartmentType[index])
      ) / metrics.UnitCategoryMixPerCategory.length
    );

    const quantity = MathSum(metrics.UnitCategoryMixPerCategory.map((mix) => mix.count));

    const totalRow: Row = {
      label: "Total",
      avgArea: unit === "sqm" ? avgArea : meterToFeet(avgArea),
      quantity: unit === "sqm" ? quantity : meterToFeet(quantity),
      mix: MathSum(metrics.UnitCategoryMixPerCategory.map((mix) => mix.ratio)) * 100,
    };

    return {
      rows,
      totalRow,
    };
  }, [metrics.UnitCategoryMixPerCategory, metrics.areaPerApartmentType, unit]);
}
