import { ProfileButton } from "./Navigator/ProfileButton";
import { useBoolean } from "src/hooks/useBoolean";
import { HorizontalSeparator } from "./Navigator/HorizontalSeparator";
import { Logo } from "./Navigator/Logo";
import classNames from "classnames";

export function HomeNavigator() {
  const expand = useBoolean();
  return (
    <div className="flex flex-col relative w-[48px] bg-white">
      <div
        onMouseEnter={expand.on}
        onMouseLeave={expand.off}
        className={classNames(
          "bg-white flex flex-col absolute border-r h-full border-black/20 shrink-0 shadow-elevation-2 z-50",
          expand.isOff && "w-[48px]",
          expand.isOn && "w-[236px] shadow"
        )}
      >
        <div className="grid h-full">
          <div className="py-4 ml-2">
            <Logo expand={expand} />
          </div>
          <div className="self-end">
            <HorizontalSeparator />
            <ProfileButton expand={expand} />
          </div>
        </div>
      </div>
    </div>
  );
}
